import {Reducer} from "@reduxjs/toolkit";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";
import {
  ALL_FULL,
  ALL_PARTIAL,
  CRITERIA_SELECTION_MODE_ALL,
  CRITERIA_SELECTION_MODE_STEP_BY_STEP,
  CRITERIA_SELECTION_TYPE_DYNAMIC,
  CRITERIA_SELECTION_TYPE_FULL,
  CRITERIA_SELECTION_TYPE_PARTIAL,
  DATASET_ADDITIONAL_DATASET_CATALOG_FETCH,
  DATASET_ADDITIONAL_DATASET_CHANGE,
  DATASET_ADDITIONAL_DATASET_CREATE_VISIBILITY_SET,
  DATASET_ADDITIONAL_DATASET_CRITERIA_SET,
  DATASET_ADDITIONAL_DATASET_DELETE,
  DATASET_ADDITIONAL_DATASET_LIST_VISIBILITY_SET,
  DATASET_ADDITIONAL_DATASET_STURCTURE_FETCH,
  DATASET_ADDITIONAL_DATASET_SUBMIT,
  DATASET_CACHE_INFO_FETCH,
  DATASET_CACHE_INFO_TTL_UPDATE,
  DATASET_CHART_FILTERS_TREE_SUBMIT,
  DATASET_CHART_LAYOUT_SUBMIT,
  DATASET_CHART_LAYOUT_TERRITORY_DIM_VALUES_SET,
  DATASET_CHART_SETTINGS_SET,
  DATASET_CRITERIA_ALERT_HIDE,
  DATASET_CRITERIA_HIDE,
  DATASET_CRITERIA_OBS_COUNT_WARNING_HIDE,
  DATASET_CRITERIA_SHOW,
  DATASET_DELETE_TEMPLATE,
  DATASET_DOWNLOAD_SUBMIT,
  DATASET_DOWNLOAD_WARNING_HIDE,
  DATASET_FETCH_ENABLE,
  DATASET_HTML_GENERATING_TIME_SET,
  DATASET_INDICATOR_ARITHMETIC_MEAN_VALUES_SET,
  DATASET_INDICATOR_ARITHMETIC_MEAN_VISIBILITY_SET,
  DATASET_INDICATOR_COEFFICIENT_OF_VARIATION_VISIBILITY_SET,
  DATASET_INDICATOR_CREATE_VISIBILITY_SET,
  DATASET_INDICATOR_DELETE,
  DATASET_INDICATOR_LIST_VISIBILITY_SET,
  DATASET_INDICATOR_PREVIEW_FETCH,
  DATASET_INDICATOR_PREVIEW_RESET,
  DATASET_INDICATOR_PUBLISH,
  DATASET_INDICATOR_STANDARD_DEVIATION_VISIBILITY_SET,
  DATASET_INDICATOR_WARNING_HIDE,
  DATASET_LABEL_FORMAT_SET,
  DATASET_MAP_LAYOUT_SUBMIT,
  DATASET_MAP_SETTINGS_SET,
  DATASET_SDMX_QUERY_FETCH,
  DATASET_SDMX_QUERY_HIDE,
  DATASET_SDMX_QUERY_SHOW,
  DATASET_SET_FULLSCREEN,
  DATASET_STATE_BACKUP,
  DATASET_STATE_RESET,
  DATASET_STRUCTURE_CODELIST_FETCH,
  DATASET_STRUCTURE_CODELIST_FULL_FETCH,
  DATASET_STRUCTURE_CODELIST_FULL_HIDE,
  DATASET_STRUCTURE_CRITERIA_RESET,
  DATASET_STRUCTURE_CRITERIA_SET,
  DATASET_STRUCTURE_FETCH,
  DATASET_STRUCTURE_FETCH_ENABLE,
  DATASET_TABLE_FILTERS_TREE_SUBMIT,
  DATASET_TABLE_LAYOUT_SUBMIT,
  DATASET_TEMPORAL_DIM_ORDER_SET,
  DATASET_TERRITORY_DETAIL_LEVEL_SET,
  DATASET_TERRITORY_DETAIL_LEVEL_TREE_SET,
  DATASET_TERRITORY_DETAIL_LEVELS_FETCH,
  DATASET_TERRITORY_HIDE,
  DATASET_TERRITORY_LAST_YEAR_FETCH,
  DATASET_TERRITORY_SHOW,
  DATASET_TERRITORY_TERRITORIES_FETCH,
  DATASET_TERRITORY_TERRITORIES_SET,
  DATASET_TERRITORY_TERRITORIES_SUBMIT,
  DATASET_UNAVAILABLE_VIEW_WARNING_HIDE,
  DATASET_VARIATION_SET,
  DATASET_VIEW_ERROR_HIDE,
  DATASET_VIEW_TEMPLATE_HIDE,
  DATASET_VIEW_TEMPLATE_SHOW,
  DATASET_VIEW_TEMPLATE_SUBMIT,
  DATASET_VIEWER_CHART_TYPE_SET,
  DATASET_VIEWER_CHART_VISIBILITY_SET,
  DATASET_VIEWER_MAP_VISIBILITY_SET,
  DATASET_VIEWER_MODE_SET,
  DATASET_VIEWER_SET,
  DATASET_VIEWER_TABLE_VISIBILITY_SET,
  STEP_BY_STEP_FULL,
  STEP_BY_STEP_PARTIAL
} from "./datasetActions";
import {getNodes, getTreeFromArray} from "../../utils/tree";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../../components/label-format-selector/constants";
import {getViewerIdxFromStructure, getViewerIdxFromType} from "../../components/data-viewer/constant";
import {localizeI18nObj} from "../../utils/i18n";
import {
  FETCH_DATASET_ASYNC_HANDLER_ERROR,
  FETCH_DATASET_ASYNC_HANDLER_INIT,
  FETCH_DATASET_ASYNC_HANDLER_SUCCESS
} from "../../middlewares/fetch-dataset-async-handler/actions";
import {
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_PERIODS,
  CRITERIA_FILTER_TYPE_RANGE,
  getCriteriaObjectFromArray,
  getFreqFromCriteria,
  getMinAndMax
} from "../../utils/criteria";
import _ from "lodash";
import {CHART_TYPE_BAR} from "../../components/chart";
import {TABLE_EMPTY_CHAR_DEFAULT} from "../../utils/formatters";
import {getDatasetStorageKey, isStrCaseInsensitiveEquals} from "../../utils/other";
import {AdditionalDataset, Criteria, DatasetState, getViewTemplateBackupFromState, Indicator, ViewerMode} from "./constants";
import {getChartSettingsFromViewTemplateLayouts, getMapSettingsFromViewTemplateLayouts} from "../../utils/viewTemplate";
import {getActionExtras, getCurrentNodeConfig} from "../../middlewares/action-decorator/actionDecoratorMiddlewareFactory";
import {getDetailLevelFromTree, getDetailLevelTree, getFilteredCatalog} from "../../utils/catalog";
import {isValidIntegerInInclusiveRange} from "../../utils/validator";
import moment from "moment";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC} from "../../components/temporal-dim-order-selector/constants";
import {LocalCategoryProvider} from "../../model/LocalCategoryProvider";
import {IDataset} from "../../model/IDataset";
import {ICategoryProvider} from "../../model/ICategoryProvider";

export const GENERATING_HTML_TIME_KEY = "generatingHtml";
export const OBSERVATION_COUNT_KEY = "observationCount";
export const SERVER_TIMINGS_KEY = "serverTimings";

const MAX_ALLOWED_CELLS = 1000000;

const defaultIsTableVisible = true;
const defaultIsMapVisible = true;
const defaultIsChartVisible = false;

const initialState = {
  datasetId: null,
  nodeCode: null,
  categoryPath: null,
  viewId: null,
  dataset: null,
  isFirstFetch: false,
  datasetFetchStart: false,
  datasetFetchError: false,
  viewerMode: null,
  viewerModeBackup: null,
  isPartialData: false,
  isEmptyData: false,
  isTooBigData: false,
  isPointData: false,
  latAttributeId: null,
  longAttributeId: null,
  srid: null,
  isTooLongQuery: false,
  tableColCount: null,
  mapPointCount: null,
  isCriteriaVisible: false,
  isCriteriaAlertVisible: false,
  isObsCountWarningVisible: false,
  dimensions: null,
  territoryDimCodelist: null,
  territoryDim: null,
  timeDim: null,
  freqDim: null,
  pointDim: null,
  measureDim: null,
  mode: null,
  type: null,
  viewerIdx: null,
  isTableVisible: defaultIsTableVisible,
  isMapVisible: defaultIsMapVisible,
  isChartVisible: defaultIsChartVisible,
  chartType: CHART_TYPE_BAR,
  view: null,
  template: null,
  hasViewLayout: false,
  hasTemplateLayout: false,
  hasAnnotationLayout: false,
  tableLayout: null,
  mapLayout: null,
  chartLayout: null,
  tableFilterTree: null,
  mapFilterTree: null,
  chartFilterTree: null,
  timePeriodsByFreq: null,
  labelFormat: null,
  temporalDimOrder: null,
  showTrend: false,
  showCyclical: false,
  criteria: {},
  initialCriteria: {},
  decimalSeparator: null,
  decimalPlaces: null,
  tableEmptyChar: TABLE_EMPTY_CHAR_DEFAULT,
  chartSettings: {},
  mapSettings: {},
  enableCriteria: true,
  enableLayout: true,
  enableVariation: false,
  maxAllowedCells: MAX_ALLOWED_CELLS,
  codelists: null,
  codelistsLength: null,
  codelistFetchError: false,
  criteriaObsCount: null,
  timings: null,
  isFetchStructureDisabled: true,
  isFetchDatasetDisabled: true,
  isDownloadWarningVisible: false,
  isUnavailableViewWarningVisible: false,
  backup: false,
  isViewVisible: false,
  isViewErrorVisible: false,
  viewErrorMessage: null,
  isTemplateVisible: false,
  isQueryVisible: false,
  structureQuery: null,
  dataQuery: null,
  isTerritoryVisible: false,
  detailLevelTree: null,
  detailLevel: null,
  territoryTree: null,
  territories: null,
  lastTerritoryYear: null,
  isIndicatorCreateVisible: false,
  isIndicatorListVisible: false,
  indicators: [],
  indicatorPreview: null,
  missingIndicators: null,
  showArithmeticMean: false,
  arithmeticMeans: null,
  arithmeticMeanDims: null,
  showStandardDeviation: false,
  showCoefficientOfVariation: false,
  isAdditionalDatasetCreateVisible: false,
  isAdditionalDatasetListVisible: false,
  additionalDatasets: [],
  additionalDatasetCatalog: null,
  additionalDataset: null,
  datasetCacheInfo: null,
  isFullscreen: false,
  isTableEnabled: true,
  isMapEnabled: true,
  isChartEnabled: true,
  tableLockedDimensions: null,
  graphLockedDimensions: null,
  missingFilterValues: null,
  geometriesYear: null
};

const datasetReducer: Reducer<DatasetState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DATASET_STATE_BACKUP: {
      let backup = false;
      const storageKey = getDatasetStorageKey(state);
      if (state.dataset !== null) {
        sessionStorage.setItem(storageKey, JSON.stringify(getViewTemplateBackupFromState(state)));
        backup = true;
      }
      return {
        ...state,
        backup: backup,
        viewerModeBackup: state.viewerMode
      };
    }
    case DATASET_STATE_RESET: {
      const storageKey = getDatasetStorageKey(state);
      const storedDatasets = JSON.parse(sessionStorage.getItem("datasets") || "[]");
      if (storedDatasets.includes(storageKey)) {
        sessionStorage.setItem(storageKey, JSON.stringify(getViewTemplateBackupFromState(state)));
      }
      return {
        ...initialState,
        backup: state.backup,
        viewerMode: state.viewerModeBackup,
        viewerModeBackup: null
      };
    }
    case DATASET_VIEWER_MODE_SET: {
      return {
        ...initialState,
        viewerMode: action.viewerMode,
        isFetchStructureDisabled: false,
        datasetId: state.datasetId,
        nodeCode: state.nodeCode,
        categoryPath: state.categoryPath,
        viewId: state.viewId,
        detailLevelTree: state.detailLevelTree,
        detailLevel: state.detailLevel
      };
    }
    case DATASET_STRUCTURE_FETCH_ENABLE: {
      return {
        ...initialState,
        isFetchStructureDisabled: false,
        datasetId: action.payload.datasetId,
        nodeCode: action.payload.nodeCode,
        categoryPath: action.payload.categoryPath,
        viewId: (action.payload.viewId || null),
        detailLevelTree: state.detailLevelTree,
        detailLevel: state.detailLevel,
        backup: state.backup
      };
    }
    case DATASET_FETCH_ENABLE: {
      const tooMuchObs = (
        state.criteriaObsCount !== null &&
        action.payload.maxObservation !== undefined &&
        state.criteriaObsCount > action.payload.maxObservation
      );

      return {
        ...state,
        isFetchDatasetDisabled: tooMuchObs,
        criteriaObsCount: tooMuchObs ? state.criteriaObsCount : null,
        isObsCountWarningVisible: tooMuchObs
      };
    }
    case DATASET_CRITERIA_SHOW: {
      return {
        ...state,
        isCriteriaVisible: true
      };
    }
    case DATASET_CRITERIA_HIDE: {
      return {
        ...state,
        isCriteriaVisible: false,
        criteria: state.initialCriteria,
        codelists: null
      }
    }
    case DATASET_TERRITORY_SHOW: {
      return {
        ...state,
        isTerritoryVisible: true,
        lastTerritoryYear: null
      };
    }
    case DATASET_TERRITORY_HIDE: {
      return {
        ...state,
        isTerritoryVisible: false,
        criteria: state.initialCriteria,
        detailLevel: action.detailLevel,
        territories: action.territories,
        lastTerritoryYear: null
      };
    }
    case DATASET_TERRITORY_DETAIL_LEVEL_SET: {
      let detailLevel = state.detailLevel;
      let territories = state.territories;

      if (detailLevel !== action.detailLevel) {
        detailLevel = action.detailLevel;
        territories = null;
      }

      return {
        ...state,
        detailLevel: detailLevel,
        territories: territories
      };
    }
    case DATASET_TERRITORY_DETAIL_LEVEL_TREE_SET: {
      return {
        ...state,
        detailLevelTree: action.detailLevelTree
      }
    }
    case DATASET_TERRITORY_TERRITORIES_SET: {
      return {
        ...state,
        territories: action.territories
      };
    }
    case DATASET_TERRITORY_TERRITORIES_SUBMIT: {
      return {
        ...state,
        isTerritoryVisible: false,
        initialCriteria: action.payload.criteria,
        criteria: action.payload.criteria,
        isFetchDatasetDisabled: false
      };
    }
    case DATASET_TERRITORY_LAST_YEAR_FETCH: {
      return {
        ...state,
        lastTerritoryYear: action.payload.lastTerritoryYear
      };
    }
    case DATASET_CRITERIA_ALERT_HIDE: {
      return {
        ...state,
        isCriteriaAlertVisible: false
      };
    }
    case DATASET_CRITERIA_OBS_COUNT_WARNING_HIDE: {
      return {
        ...state,
        isObsCountWarningVisible: false
      };
    }
    case DATASET_STRUCTURE_CRITERIA_SET: {
      return {
        ...state,
        criteria: action.criteria,
        criteriaObsCount: null
      }
    }
    case DATASET_STRUCTURE_CRITERIA_RESET: {
      return {
        ...state,
        codelists: null,
        codelistsLength: null,
        codelistFetchError: false,
        criteriaObsCount: null
      }
    }
    case DATASET_VIEWER_SET: {
      return {
        ...state,
        viewerIdx: action.viewerIdx,
        isTableVisible: action.viewerIdx === 0,
        isMapVisible: action.viewerIdx === 1,
        isChartVisible: action.viewerIdx >= 2
      };
    }
    case DATASET_VIEWER_TABLE_VISIBILITY_SET: {
      return {
        ...state,
        isTableVisible: action.isVisible
      };
    }
    case DATASET_VIEWER_MAP_VISIBILITY_SET: {
      return {
        ...state,
        isMapVisible: action.isVisible
      };
    }
    case DATASET_VIEWER_CHART_VISIBILITY_SET: {
      return {
        ...state,
        isChartVisible: action.isVisible
      };
    }
    case DATASET_VIEWER_CHART_TYPE_SET: {
      return {
        ...state,
        chartType: action.chartType
      };
    }
    case DATASET_TABLE_LAYOUT_SUBMIT: {
      return {
        ...state,
        tableLayout: action.layout
      };
    }
    case DATASET_TABLE_FILTERS_TREE_SUBMIT: {
      return {
        ...state,
        tableFilterTree: action.filterTree
      };
    }
    case DATASET_MAP_LAYOUT_SUBMIT: {
      return {
        ...state,
        mapLayout: action.layout
      };
    }
    case DATASET_CHART_LAYOUT_SUBMIT: {
      return {
        ...state,
        chartLayout: action.layout
      };
    }
    case DATASET_CHART_FILTERS_TREE_SUBMIT: {
      return {
        ...state,
        chartFilterTree: action.filterTree
      };
    }
    case DATASET_CHART_LAYOUT_TERRITORY_DIM_VALUES_SET: {

      const territoryDim = state.isPointData
        ? state.pointDim
        : state.territoryDim;
      const primaryDim = state.chartLayout.primaryDim?.[0] || null;
      const secondaryDim = state.chartLayout.secondaryDim?.[0] || null;

      let newChartLayout = state.chartLayout;

      if (territoryDim && primaryDim && secondaryDim) {
        newChartLayout = {
          ...state.chartLayout,
          primaryDimValues: primaryDim === territoryDim
            ? action.territoryDimValues
            : state.dataset.dimension[primaryDim].category.index,
          secondaryDimValues: secondaryDim === territoryDim
            ? action.territoryDimValues
            : state.dataset.dimension[secondaryDim].category.index
        };
      }

      return {
        ...state,
        chartLayout: newChartLayout
      };
    }
    case DATASET_LABEL_FORMAT_SET: {
      return {
        ...state,
        labelFormat: action.labelFormat
      };
    }
    case DATASET_VARIATION_SET: {
      return {
        ...state,
        showTrend: action.variation?.showTrend || false,
        showCyclical: action.variation?.showCyclical || false
      };
    }
    case DATASET_TEMPORAL_DIM_ORDER_SET: {
      return {
        ...state,
        temporalDimOrder: action.temporalDimOrder
      };
    }
    case DATASET_CHART_SETTINGS_SET: {
      return {
        ...state,
        chartSettings: {
          ...state.chartSettings,
          ...action.chartSettings
        }
      };
    }
    case DATASET_MAP_SETTINGS_SET: {
      return {
        ...state,
        mapSettings: {
          ...state.mapSettings,
          ...action.mapSettings
        }
      };
    }
    case DATASET_DOWNLOAD_WARNING_HIDE: {
      return {
        ...state,
        isDownloadWarningVisible: false
      };
    }
    case DATASET_UNAVAILABLE_VIEW_WARNING_HIDE: {
      return {
        ...state,
        isUnavailableViewWarningVisible: false
      };
    }
    case DATASET_HTML_GENERATING_TIME_SET: {
      return {
        ...state,
        timings: {
          ...state.timings,
          [GENERATING_HTML_TIME_KEY]: action.time
        }
      };
    }
    case FETCH_DATASET_ASYNC_HANDLER_INIT: {
      return {
        ...state,
        isFetchDatasetDisabled: true,
        datasetFetchStart: true,
        datasetFetchError: false
      };
    }
    case FETCH_DATASET_ASYNC_HANDLER_SUCCESS: {
      if (action.payload.worker) {
        action.payload.worker.terminate();
      }

      const isResponseValid = !action.payload.isResponseNotValid;

      const tableLayout = isResponseValid ? action.payload.tableLayout : state.tableLayout;
      const mapLayout = isResponseValid ? action.payload.mapLayout : state.mapLayout;
      const chartLayout = isResponseValid ? action.payload.chartLayout : state.chartLayout;

      let missingIndicators = null;
      if (isResponseValid && state.indicators.length > 0) {
        const jsonStatIndicators = Object.values(action.payload.response?.extension?.marginalvalues || {})
          .map((value: any) => value.label || null)
          .filter((label: string) => label !== null);
        missingIndicators = state.indicators
          .map((indicator: Indicator) => localizeI18nObj(indicator.title, getActionExtras(action).language, getActionExtras(action).languages))
          .filter((label: string) => !jsonStatIndicators.includes(label))
          .map((label: string) => `"${label}"`);
      }

      let removeLastAD = false;
      if (!isResponseValid && state.isAdditionalDatasetCreateVisible) {
        removeLastAD = true;
      }

      let removeLastInd = false;
      if (!isResponseValid && state.isIndicatorCreateVisible) {
        removeLastInd = true;
      }

      return {
        ...state,
        dataset: isResponseValid ? action.payload.response : state.dataset,
        isFirstFetch: false,
        datasetFetchStart: false,
        datasetFetchError: false,

        isTooBigData: action.payload.isTooBigData,
        isEmptyData: action.payload.isEmptyData,
        isPartialData: action.payload.isPartialData,
        tableColCount: action.payload.tableColCount,
        mapPointCount: action.payload.mapPointCount,

        isPointData: action.payload.isPointData,
        pointDim: action.payload.pointDim,

        enableCriteria: state.viewerMode === ViewerMode.MultiViewer ? true : state.enableCriteria,
        isCriteriaVisible: state.isCriteriaVisible && !isResponseValid,
        isCriteriaAlertVisible: !isResponseValid,

        isTerritoryVisible: state.isTerritoryVisible && !isResponseValid,

        tableLayout: tableLayout,
        mapLayout: mapLayout,
        chartLayout: chartLayout,

        isTableVisible: tableLayout
          ? state.isTableVisible !== null
            ? state.isTableVisible
            : defaultIsTableVisible
          : null,
        isMapVisible: mapLayout
          ? state.isMapVisible !== null
            ? state.isMapVisible
            : defaultIsMapVisible
          : null,
        isChartVisible: chartLayout
          ? state.isChartVisible !== null
            ? state.isChartVisible
            : defaultIsChartVisible
          : null,

        tableFilterTree: isResponseValid ? action.payload.tableFilterTree : state.tableFilterTree,
        mapFilterTree: isResponseValid ? action.payload.mapFilterTree : state.mapFilterTree,
        chartFilterTree: isResponseValid ? action.payload.chartFilterTree : state.chartFilterTree,
        timePeriodsByFreq: isResponseValid ? action.payload.timePeriodsByFreq : state.timePeriodsByFreq,

        initialCriteria: isResponseValid ? state.criteria : state.initialCriteria,

        codelists: isResponseValid ? null : state.codelists,

        timings: {
          [OBSERVATION_COUNT_KEY]: action.payload.response?.value ? Object.keys(action.payload.response.value).length : null,
          [SERVER_TIMINGS_KEY]: action.payload.responseHeaders.backendtimers
            ? JSON.parse(action.payload.responseHeaders.backendtimers)
            : null
        },

        structureQuery: null,
        dataQuery: null,

        isIndicatorCreateVisible: isResponseValid ? false : state.isIndicatorCreateVisible,
        indicators: removeLastInd
          ? state.indicators.slice(0, -1)
          : state.indicators,
        missingIndicators: (missingIndicators && missingIndicators.length > 0) ? missingIndicators : null,

        isAdditionalDatasetCreateVisible: isResponseValid ? false : state.isAdditionalDatasetCreateVisible,
        additionalDatasets: removeLastAD
          ? state.additionalDatasets.slice(0, -1)
          : state.additionalDatasets,
        additionalDatasetCatalog: isResponseValid ? null : state.additionalDatasetCatalog,
        additionalDataset: isResponseValid ? null : state.additionalDataset
      };
    }
    case FETCH_DATASET_ASYNC_HANDLER_ERROR: {
      const isPayloadTooLarge = action.payload.statusCode === 413;
      const isTooLongQuery = action.payload.statusCode === 414;

      return {
        ...state,
        dataset: null,
        datasetFetchStart: false,
        datasetFetchError: true,
        isPartialData: false,
        isEmptyData: false,
        isTooBigData: isPayloadTooLarge,
        isTooLongQuery: isTooLongQuery,
        isCriteriaAlertVisible: (isPayloadTooLarge || isTooLongQuery)
      };
    }
    case DATASET_VIEW_TEMPLATE_SHOW: {
      return {
        ...state,
        isViewVisible: action.isView ? true : state.isViewVisible,
        isTemplateVisible: !action.isView ? true : state.isTemplateVisible
      };
    }
    case DATASET_VIEW_TEMPLATE_HIDE: {
      return {
        ...state,
        isViewVisible: action.isView ? false : state.isViewVisible,
        isTemplateVisible: !action.isView ? false : state.isTemplateVisible
      };
    }
    case DATASET_VIEW_ERROR_HIDE: {
      return {
        ...state,
        isViewErrorVisible: false,
        viewErrorMessage: null
      };
    }
    case DATASET_SDMX_QUERY_SHOW: {
      return {
        ...state,
        isQueryVisible: true
      };
    }
    case DATASET_SDMX_QUERY_HIDE: {
      return {
        ...state,
        isQueryVisible: false
      };
    }
    case DATASET_INDICATOR_CREATE_VISIBILITY_SET:
      return {
        ...state,
        isIndicatorCreateVisible: action.isVisible
      };
    case DATASET_INDICATOR_LIST_VISIBILITY_SET:
      return {
        ...state,
        isIndicatorListVisible: action.isVisible
      };
    case DATASET_INDICATOR_PREVIEW_RESET:
      return {
        ...state,
        indicatorPreview: null
      };
    case DATASET_INDICATOR_PUBLISH:
      return {
        ...state,
        indicators: [...state.indicators, action.indicator],
        isFetchDatasetDisabled: false
      };
    case DATASET_INDICATOR_DELETE:
      const newIndicators = [...state.indicators];
      newIndicators.splice(action.indicatorIdx, 1);

      return {
        ...state,
        indicators: newIndicators,
        isFetchDatasetDisabled: false
      };
    case DATASET_INDICATOR_WARNING_HIDE:
      return {
        ...state,
        missingIndicators: null
      };
    case DATASET_INDICATOR_ARITHMETIC_MEAN_VISIBILITY_SET:
      return {
        ...state,
        showArithmeticMean: action.visible
      };
    case DATASET_INDICATOR_ARITHMETIC_MEAN_VALUES_SET:
      return {
        ...state,
        arithmeticMeans: action.arithmeticMeans,
        arithmeticMeanDims: action.arithmeticMeanDims
      };
    case DATASET_INDICATOR_STANDARD_DEVIATION_VISIBILITY_SET:
      return {
        ...state,
        showStandardDeviation: action.visible
      };
    case DATASET_INDICATOR_COEFFICIENT_OF_VARIATION_VISIBILITY_SET:
      return {
        ...state,
        showCoefficientOfVariation: action.visible
      };
    case DATASET_ADDITIONAL_DATASET_CREATE_VISIBILITY_SET:
      return {
        ...state,
        isAdditionalDatasetCreateVisible: action.isVisible,
        additionalDatasetCatalog: null,
        additionalDataset: null
      };
    case DATASET_ADDITIONAL_DATASET_LIST_VISIBILITY_SET:
      return {
        ...state,
        isAdditionalDatasetListVisible: action.isVisible
      };
    case DATASET_ADDITIONAL_DATASET_CHANGE: {
      const isDatasetChanged = (
        (action.additionalDataset?.nodeCode && state.additionalDataset?.nodeCode !== action.additionalDataset.nodeCode) ||
        (action.additionalDataset?.datasetId && state.additionalDataset?.datasetId !== action.additionalDataset.datasetId)
      );

      return {
        ...state,
        additionalDataset: isDatasetChanged
          ? {
            ...action.additionalDataset
          }
          : {
            ...state.additionalDataset,
            ...action.additionalDataset
          }
      };
    }
    case DATASET_ADDITIONAL_DATASET_SUBMIT:
      return {
        ...state,
        additionalDatasets: state.additionalDataset !== null
          ? [...state.additionalDatasets, state.additionalDataset]
          : state.additionalDatasets,
        isFetchDatasetDisabled: false
      };
    case DATASET_ADDITIONAL_DATASET_DELETE:
      const newAdditionalDatasets = [...state.additionalDatasets];
      newAdditionalDatasets.splice(action.datasetIdx, 1);

      return {
        ...state,
        additionalDatasets: newAdditionalDatasets,
        isFetchDatasetDisabled: false
      };
    case DATASET_ADDITIONAL_DATASET_CRITERIA_SET: {
      const newAdditionalDatasets = [...state.additionalDatasets];
      newAdditionalDatasets[action.datasetIdx].criteria = action.criteria;

      return {
        ...state,
        additionalDatasets: newAdditionalDatasets
      };
    }
    case DATASET_STRUCTURE_CODELIST_FULL_HIDE: {
      return {
        ...state,
        missingFilterValues: null
      };
    }
    case DATASET_SET_FULLSCREEN: {
      return {
        ...state,
        isFullscreen: action.isFullscreen
      };
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case DATASET_STRUCTURE_FETCH: {
          return {
            ...state,
            isFetchStructureDisabled: true,
            isFirstFetch: true
          };
        }
        case DATASET_STRUCTURE_CODELIST_FETCH: {
          return {
            ...state,
            criteriaObsCount: null,
            codelistFetchError: false
          };
        }
        case DATASET_TERRITORY_TERRITORIES_FETCH: {
          return {
            ...state,
            territoryTree: null
          };
        }
        case DATASET_ADDITIONAL_DATASET_CATALOG_FETCH: {
          return {
            ...state,
            additionalDatasetCatalog: null
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case DATASET_STRUCTURE_FETCH: {
          const {
            language,
            languages
          } = getActionExtras(action);

          const criteriaView = action.payload.response.criteriaView;

          const VIEW_KEY = "view";
          const TEMPLATE_KEY = "template";

          const dimensions = (action.payload.response?.criteria || []);

          const latAttributeId = action.payload.response?.pointDataInfo?.latitudeField || null;
          const longAttributeId = action.payload.response?.pointDataInfo?.longitudeField || null;
          const srid = action.payload.response?.pointDataInfo?.srid || null;

          const isPointData = latAttributeId !== null && longAttributeId !== null && srid !== null;

          const territoryDim = action.payload.response?.territorialDimension || null;
          const timeDim = action.payload.response?.timeDimension || null;
          const freqDim = action.payload.response?.freqDimension || null;
          const measureDim = action.payload.response?.measureDimension || null;

          const territoryDimCodelist = territoryDim
            ? dimensions.find((dim: any) => dim.id === territoryDim)?.extra?.DataStructureRef || null
            : null;

          const isMultiViewerCompatible = timeDim !== null && ((territoryDim !== null && (state.detailLevelTree || []).length > 0) || isPointData);

          let viewerMode = state.viewerMode
            ? state.viewerMode
            : action.payload.extra.isMultiViewerTheme
              ? ViewerMode.MultiViewer
              : ViewerMode.SingleViewer;
          if (viewerMode === ViewerMode.MultiViewer && !isMultiViewerCompatible) {
            viewerMode = ViewerMode.Error; // = ViewerMode.SingleViewer // data viewer temporarily disabled in case of data incompatibility with MultiViewer mode
          }

          const viewViewerMode = action.payload.response[VIEW_KEY]?.mode || ViewerMode.SingleViewer;
          const hasView = !!action.payload.response[VIEW_KEY] && viewViewerMode === viewerMode;
          const view = hasView
            ? {
              ...action.payload.response[VIEW_KEY],
              layouts: JSON.parse(action.payload.response[VIEW_KEY].layouts)
            }
            : null;

          const templateViewerMode = action.payload.response[TEMPLATE_KEY]?.mode || ViewerMode.SingleViewer;
          const hasTemplate = !!action.payload.response[TEMPLATE_KEY] && templateViewerMode === viewerMode;
          const template = hasTemplate
            ? {
              ...action.payload.response[TEMPLATE_KEY],
              layouts: JSON.parse(action.payload.response[TEMPLATE_KEY].layouts)
            }
            : null;

          const storageKey = getDatasetStorageKey(state);
          const storageItem = sessionStorage.getItem(storageKey);
          const hasSessionBackup = storageItem !== null && storageItem !== "";
          const sessionBackup = hasSessionBackup
            ? {
              ...JSON.parse(storageItem || "{}"),
              layouts: JSON.parse(JSON.parse(storageItem || "{}").layouts)
            }
            : null;

          let viewTemplate = sessionBackup || view || template || null;
          let viewTemplateLayouts = null;

          if (viewTemplate) {
            viewTemplate = {
              ...viewTemplate,
              decimalSeparator: hasTemplate ? template.decimalSeparator : null,
              decimalNumber: hasTemplate ? template.decimalNumber : null,
              enableCriteria: hasTemplate ? template.enableCriteria : true,
              enableLayout: hasTemplate ? template.enableLayout : true,
              enableVariation: hasTemplate ? template.enableVariation : false
            };
            viewTemplateLayouts = {
              ...template?.layouts || {},
              ...view?.layouts || {},
              ...sessionBackup?.layouts || {}
            };
          }

          let structureCriteria = (action.payload.response.filters || []).length > 0
            ? action.payload.response.filters
            : null;

          let criteria = {};

          let isCriteriaVisible = false;
          let isTerritoryVisible = false;

          let tableLayout = (viewTemplateLayouts && viewTemplateLayouts?.tableLayout)
            ? viewTemplateLayouts.tableLayout
            : null;
          let mapLayout = ((territoryDim || isPointData) && viewTemplateLayouts && viewTemplateLayouts?.mapLayout)
            ? viewTemplateLayouts.mapLayout
            : null;
          let chartLayout = (viewTemplateLayouts && viewTemplateLayouts?.chartLayout)
            ? viewTemplateLayouts.chartLayout
            : null;

          let detailLevel = state.detailLevel;
          let territories = null;
          if (viewTemplateLayouts) {
            detailLevel = (viewTemplateLayouts.detailLevel !== null && viewTemplateLayouts.detailLevel !== undefined)
              ? viewTemplateLayouts.detailLevel
              : (viewTemplateLayouts.mapDetailLevel !== null && viewTemplateLayouts.mapDetailLevel !== undefined)
                ? viewTemplateLayouts.mapDetailLevel
                : null;
            territories = viewTemplateLayouts.territories;
          }

          let enableCriteria = (viewTemplate?.enableCriteria !== false && dimensions.length > 0);

          let isFetchDatasetDisabled = false;

          const disabledViewers = (action.payload.response?.disabledViewers || []).map((v: string) => v.toLowerCase());

          let isTableEnabled = !disabledViewers.includes("table");
          let isMapEnabled = !disabledViewers.includes("map");
          let isChartEnabled = !disabledViewers.includes("graph");

          let viewerIdx = null;

          let isTableVisible = null;
          let isMapVisible = null;
          let isChartVisible = null;

          let chartType = null;

          let tableLockedDimensions = (hasTemplate && template.layouts?.lockTableDimensions && (template.layouts?.tableLockedDimensions || []).length > 0)
            ? template.layouts?.tableLockedDimensions
            : action.payload.response?.tableLockedDimensions || [];

          let graphLockedDimensions = (hasTemplate && template.layouts?.lockGraphDimensions && (template.layouts?.graphLockedDimensions || []).length > 0)
            ? template.layouts?.graphLockedDimensions
            : action.payload.response?.graphLockedDimensions || [];

          switch (viewerMode) {
            case ViewerMode.SingleViewer: {

              if (viewTemplate) {
                viewerIdx = getViewerIdxFromType(viewTemplate.defaultView);
                if (viewerIdx !== null && viewerIdx === 0) {
                  isTableEnabled = true;
                } else if (viewerIdx !== null && viewerIdx === 1) {
                  isMapEnabled = true;
                } else if (viewerIdx !== null && viewerIdx >= 2) {
                  isChartEnabled = true;
                }
              } else if (action.payload.response.defaultView) {
                viewerIdx = getViewerIdxFromStructure(action.payload.response.defaultView);
                if (viewerIdx !== null && viewerIdx === 0) {
                  isTableEnabled = true;
                } else if (viewerIdx !== null && viewerIdx === 1) {
                  isMapEnabled = true;
                } else if (viewerIdx !== null && viewerIdx >= 2) {
                  isChartEnabled = true;
                }
              } else if (isTableEnabled) {
                viewerIdx = 0;
              } else if (isMapEnabled) {
                viewerIdx = 1;
              } else if (isChartEnabled) {
                viewerIdx = 2;
              }

              isTableVisible = viewerIdx !== null && viewerIdx === 0;
              isMapVisible = viewerIdx !== null && viewerIdx === 1;
              isChartVisible = viewerIdx !== null && viewerIdx >= 2;

              const criteriaArr = viewTemplate?.criteria || structureCriteria;
              criteria = getCriteriaObjectFromArray(criteriaArr);

              isCriteriaVisible = !criteriaArr;
              isTerritoryVisible = false;

              if (!tableLayout && action.payload.response?.layout) {
                tableLayout = action.payload.response.layout;
              }
              if (!chartLayout && action.payload.response?.layoutChart) {
                chartLayout = {
                  ...action.payload.response.layoutChart,
                  filters: undefined
                };
              }

              break;
            }
            case ViewerMode.MultiViewer: {

              criteria = getCriteriaObjectFromArray(viewTemplate?.criteria);

              isCriteriaVisible = false;
              isTerritoryVisible = territoryDim && !viewTemplate;

              if (isTerritoryVisible) {
                enableCriteria = false;
              }

              isTableVisible = (viewTemplateLayouts && viewTemplateLayouts?.isTableVisible !== null && viewTemplateLayouts?.isTableVisible !== undefined)
                ? viewTemplateLayouts.isTableVisible
                : state.isTableVisible;
              isMapVisible = (viewTemplateLayouts && viewTemplateLayouts?.isMapVisible !== null && viewTemplateLayouts?.isMapVisible !== undefined)
                ? viewTemplateLayouts.isMapVisible
                : state.isMapVisible;
              isChartVisible = (viewTemplateLayouts && viewTemplateLayouts?.isChartVisible !== null && viewTemplateLayouts?.isChartVisible !== undefined)
                ? viewTemplateLayouts.isChartVisible
                : state.isChartVisible;

              chartType = (viewTemplateLayouts && viewTemplateLayouts?.chartType !== null && viewTemplateLayouts?.chartType !== undefined)
                ? viewTemplateLayouts.chartType
                : state.chartType;

              break;
            }
            default: {

              isCriteriaVisible = false;
              isTerritoryVisible = false;
              isFetchDatasetDisabled = true;
              break;
            }
          }
          const mapSettings = {
            ...getActionExtras(action).mapDefaultSettings,
            ...getMapSettingsFromViewTemplateLayouts(viewTemplateLayouts)
          };

          const chartSettings = {
            ...getActionExtras(action).chartDefaultSettings,
            ...getChartSettingsFromViewTemplateLayouts(viewTemplateLayouts)
          };

          if (hasSessionBackup && state.backup) {
            sessionStorage.removeItem(getDatasetStorageKey(state));
          }

          return {
            ...state,
            backup: false,
            hasViewLayout: hasView,
            hasTemplateLayout: hasTemplate,
            hasAnnotationLayout: !!action.payload.response.layout,
            viewerMode: viewerMode,
            isPointData: isPointData,
            latAttributeId: latAttributeId,
            longAttributeId: longAttributeId,
            srid: srid,
            isTableVisible: isTableVisible,
            isMapVisible: isMapVisible,
            isChartVisible: isChartVisible,
            chartType: chartType,
            view: view,
            template: template,
            dimensions: dimensions,
            territoryDimCodelist: territoryDimCodelist,
            territoryDim: territoryDim,
            timeDim: timeDim,
            freqDim: freqDim,
            measureDim: measureDim,
            detailLevel: detailLevel,
            territories: territories,
            mode: (criteriaView === ALL_FULL || criteriaView === ALL_PARTIAL)
              ? CRITERIA_SELECTION_MODE_ALL
              : CRITERIA_SELECTION_MODE_STEP_BY_STEP,
            type: (criteriaView === ALL_FULL || criteriaView === STEP_BY_STEP_FULL)
              ? CRITERIA_SELECTION_TYPE_FULL
              : (criteriaView === ALL_PARTIAL || criteriaView === STEP_BY_STEP_PARTIAL)
                ? CRITERIA_SELECTION_TYPE_PARTIAL
                : CRITERIA_SELECTION_TYPE_DYNAMIC,
            codelistsLength: state.codelistsLength ? state.codelistsLength : action.payload.response.criteria.map(() => null),
            isCriteriaVisible: isCriteriaVisible,
            isTerritoryVisible: isTerritoryVisible,
            viewerIdx: viewerIdx,
            isUnavailableViewWarningVisible: (!!action.payload.extra?.viewId && !action.payload.response?.[VIEW_KEY]),
            tableLayout: tableLayout,
            mapLayout: mapLayout,
            chartLayout: chartLayout,
            labelFormat: (viewTemplateLayouts?.labelFormat || LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME),
            temporalDimOrder: (viewTemplateLayouts?.temporalDimOrder || action.payload.response.temporalDimOrder || TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC),
            showTrend: (viewTemplateLayouts?.showTrend || false),
            showCyclical: (viewTemplateLayouts?.showCyclical || false),
            criteria: criteria,
            initialCriteria: criteria,
            decimalSeparator: (Object.keys(viewTemplate?.decimalSeparator || {}).length > 0)
              ? localizeI18nObj(viewTemplate.decimalSeparator, language, languages)
              : action.payload.response.decimalSeparator,
            decimalPlaces: (viewTemplate?.decimalNumber !== null && viewTemplate?.decimalNumber !== undefined && viewTemplate?.decimalNumber !== -1)
              ? viewTemplate?.decimalNumber
              : action.payload.response.decimalPlaces,
            tableEmptyChar: (viewTemplateLayouts?.tableEmptyChar !== null && viewTemplateLayouts?.tableEmptyChar !== undefined)
              ? viewTemplateLayouts?.tableEmptyChar
              : action.payload.response.emptyCellPlaceHolder,
            chartSettings: chartSettings,
            mapSettings: mapSettings,
            enableCriteria: enableCriteria,
            enableLayout: viewTemplate?.enableLayout !== false,
            enableVariation: viewTemplate?.enableVariation === true,
            isFetchDatasetDisabled: isFetchDatasetDisabled || isCriteriaVisible || isTerritoryVisible,
            maxAllowedCells: (action.payload.response.maxTableCells || MAX_ALLOWED_CELLS),
            indicators: (viewTemplate?.indicatorsDefinition !== null && viewTemplate?.indicatorsDefinition !== undefined)
              ? JSON.parse(viewTemplate.indicatorsDefinition)
              : [],
            showArithmeticMean: !!viewTemplateLayouts?.showArithmeticMean,
            showStandardDeviation: !!viewTemplateLayouts?.showStandardDeviation,
            showCoefficientOfVariation: !!viewTemplateLayouts?.showCoefficientOfVariation,
            additionalDatasets: (viewTemplate?.additionalDatasets !== null && viewTemplate?.additionalDatasets !== undefined)
              ? JSON.parse(viewTemplate.additionalDatasets)
              : [],
            isTableEnabled: isTableEnabled,
            isMapEnabled: isMapEnabled,
            isChartEnabled: isChartEnabled,
            tableLockedDimensions: tableLockedDimensions,
            graphLockedDimensions: graphLockedDimensions,
            geometriesYear: action.payload.response?.geometriesYear || null
          };
        }
        case DATASET_STRUCTURE_CODELIST_FETCH: {

          const isMainDataset =
            isStrCaseInsensitiveEquals(state.nodeCode, action.payload.extra.nodeCode) &&
            isStrCaseInsensitiveEquals(state.datasetId, action.payload.extra.datasetId);

          const isCreatingAdditionalDataset =
            isStrCaseInsensitiveEquals(state.additionalDataset?.nodeCode, action.payload.extra.nodeCode) &&
            isStrCaseInsensitiveEquals(state.additionalDataset?.datasetId, action.payload.extra.datasetId);

          const additionalDatasetToUpdateIdx = state.additionalDatasets.findIndex((ad: AdditionalDataset) =>
            isStrCaseInsensitiveEquals(ad.nodeCode, action.payload.extra.nodeCode) &&
            isStrCaseInsensitiveEquals(ad.datasetId, action.payload.extra.datasetId)
          );

          let dimensions: any[] | null;
          let timeDim: string | null;
          let freqDim: string | null;
          let codelistsLength: (number | null)[] | null;
          let criteria: {[key: string]: Criteria};

          if (isMainDataset) {
            dimensions = state.dimensions;
            timeDim = state.timeDim;
            freqDim = state.freqDim;
            codelistsLength = state.codelistsLength;
            criteria = state.criteria;

          } else if (isCreatingAdditionalDataset && state.additionalDataset) {
            dimensions = state.additionalDataset.dimensions;
            timeDim = state.additionalDataset.timeDim;
            freqDim = state.additionalDataset.freqDim;
            codelistsLength = state.additionalDataset.codelistsLength;
            criteria = state.additionalDataset.criteria;

          } else if (additionalDatasetToUpdateIdx !== -1 && state.additionalDatasets[additionalDatasetToUpdateIdx]) {
            dimensions = state.additionalDatasets[additionalDatasetToUpdateIdx].dimensions;
            timeDim = state.additionalDatasets[additionalDatasetToUpdateIdx].timeDim;
            freqDim = state.additionalDatasets[additionalDatasetToUpdateIdx].freqDim;
            codelistsLength = state.additionalDatasets[additionalDatasetToUpdateIdx].codelistsLength;
            criteria = state.additionalDatasets[additionalDatasetToUpdateIdx].criteria;

          } else {
            return state;
          }

          const newCodelists: {[key: string]: any[]} = {};
          const newCodelistLength = codelistsLength ? [...codelistsLength] : (dimensions || []).map(() => null);
          const newCriteria: {[key: string]: Criteria} = _.cloneDeep(criteria);

          (dimensions || []).forEach((dimension, idx) => {

            const codelist = action.payload.response.criteria.find(({id}: any) => id === dimension.id);

            if (codelist) {

              const values = (codelist.values || []).map((code: any) => ({
                ...code,
                label: `[${code.id}] ${code.name}`
              }));

              newCodelists[dimension.id] = getTreeFromArray(values, "parentId", "children");

              if (!timeDim || dimension.id !== timeDim) {
                newCodelistLength[idx] = values.length;

                if (values.length === 1) {
                  newCriteria[dimension.id] = {
                    id: dimension.id,
                    type: CRITERIA_FILTER_TYPE_CODES,
                    filterValues: [values[0].id],
                    period: undefined,
                    from: undefined,
                    to: undefined
                  };
                }

              } else if (!newCriteria[timeDim]) {
                if (
                  freqDim &&
                  isValidIntegerInInclusiveRange(action.payload.extra.defaultLastNPeriods, 1) &&
                  state.type === CRITERIA_SELECTION_TYPE_PARTIAL
                ) {
                  newCriteria[timeDim] = {
                    id: timeDim,
                    type: CRITERIA_FILTER_TYPE_PERIODS,
                    period: action.payload.extra.defaultLastNPeriods,
                    filterValues: undefined,
                    from: undefined,
                    to: undefined
                  };

                } else {
                  const {min, max} = getMinAndMax(values, action.payload.extra.freq, getCurrentNodeConfig(action));

                  newCriteria[timeDim] = {
                    id: timeDim,
                    type: CRITERIA_FILTER_TYPE_RANGE,
                    from: min,
                    to: max,
                    filterValues: undefined,
                    period: undefined
                  };
                }
              }
            }
          });

          const newAdditionalDataset: AdditionalDataset | null = _.cloneDeep(state.additionalDataset);
          if (isCreatingAdditionalDataset && newAdditionalDataset) {
            newAdditionalDataset.criteria = newCriteria;
            newAdditionalDataset.codelists = newCodelists;
            newAdditionalDataset.codelistsLength = newCodelistLength;
          }

          const newAdditionalDatasets: AdditionalDataset[] = _.cloneDeep(state.additionalDatasets);
          if (additionalDatasetToUpdateIdx !== -1 && newAdditionalDatasets[additionalDatasetToUpdateIdx]) {
            newAdditionalDatasets[additionalDatasetToUpdateIdx].criteria = newCriteria;
            newAdditionalDatasets[additionalDatasetToUpdateIdx].codelists = newCodelists;
            newAdditionalDatasets[additionalDatasetToUpdateIdx].codelistsLength = newCodelistLength;
          }

          return {
            ...state,
            criteria: isMainDataset ? newCriteria : state.criteria,
            codelists: isMainDataset ? newCodelists : state.codelists,
            codelistsLength: isMainDataset ? newCodelistLength : state.codelistsLength,
            additionalDataset: newAdditionalDataset,
            additionalDatasets: newAdditionalDatasets
          };
        }
        case DATASET_CACHE_INFO_FETCH: {
          return {
            ...state,
            datasetCacheInfo: action.payload.response
          };
        }
        case DATASET_CACHE_INFO_TTL_UPDATE: {
          const datasetCacheInfo = state.datasetCacheInfo;
          datasetCacheInfo.ttl = action.payload.response.ttl;
          return {
            ...state,
            datasetCacheInfo: datasetCacheInfo
          };
        }
        case DATASET_DELETE_TEMPLATE: {
          return {
            ...initialState,
            isFetchStructureDisabled: false,
            datasetId: state.datasetId,
            nodeCode: state.nodeCode,
            categoryPath: state.categoryPath,
            viewId: state.viewId,
            detailLevelTree: state.detailLevelTree,
            detailLevel: state.detailLevel
          };
        }
        case DATASET_TERRITORY_DETAIL_LEVELS_FETCH: {
          const {t} = getActionExtras(action);
          const detailLevelTree = getDetailLevelTree((action.payload.response?.catalogCategoryLayers || []), t);

          return {
            ...state,
            detailLevelTree: detailLevelTree,
            detailLevel: getDetailLevelFromTree(detailLevelTree, action.payload.extra.detailLevel)
          };
        }
        case DATASET_TERRITORY_TERRITORIES_FETCH: {

          const territoryCodelist = action.payload.response.criteria[0] || {};
          const territoryCodelistValue = territoryCodelist.values ? territoryCodelist.values : [];

          const territoryTree = getTreeFromArray((territoryCodelistValue || []), "parentId", "children");
          const territories = (state.territories || []).length === 0
            ? getNodes(territoryTree, "children", () => true).map(({id}) => id)
            : state.territories;

          return {
            ...state,
            territoryTree: territoryTree,
            territories: territories
          };
        }
        case DATASET_TERRITORY_LAST_YEAR_FETCH: {
          const dimensionCodelist = action.payload.response.criteria[0] || {};
          const freq = getFreqFromCriteria(state.criteria);
          const {max} = getMinAndMax(dimensionCodelist.values, freq, getCurrentNodeConfig(action));

          return {
            ...state,
            lastTerritoryYear: max
              ? moment(max).format("YYYY")
              : null
          };
        }
        case DATASET_VIEW_TEMPLATE_SUBMIT: {
          return action.payload.extra.isView
            ? {
              ...state,
              isViewVisible: false,
              isTemplateVisible: false
            }
            : {
              ...initialState,
              isFetchStructureDisabled: false,
              datasetId: state.datasetId,
              nodeCode: state.nodeCode,
              categoryPath: state.categoryPath,
              viewId: state.viewId,
              detailLevelTree: state.detailLevelTree,
              detailLevel: state.detailLevel
            };
        }
        case DATASET_SDMX_QUERY_FETCH: {
          return {
            ...state,
            structureQuery: action.payload.response?.structureUrl || null,
            dataQuery: action.payload.response?.dataflowUrl || null
          };
        }
        case DATASET_INDICATOR_PREVIEW_FETCH: {
          return {
            ...state,
            indicatorPreview: (action.payload.response?.id || []).length > 0
              ? action.payload.response
              : ""
          };
        }
        case DATASET_ADDITIONAL_DATASET_CATALOG_FETCH: {
          const t = getActionExtras(action)?.t;

          const catalog = new LocalCategoryProvider(
            action.payload.response.categoryGroups,
            action.payload.response.datasetMap,
            action.payload.response.datasetUncategorized,
            action.payload.extra.nodeId,
            action.payload.response.catalogLayers,
            t
          );

          const allDatasetIds = [state.datasetId, ...state.additionalDatasets.map(({datasetId}) => datasetId)];
          const filter = (datasetId: string, dataset: IDataset) => !!(!allDatasetIds.includes(datasetId) && state.detailLevel !== null && (dataset.detailsLevels || []).includes(state.detailLevel))

          const filteredCatalog: ICategoryProvider = getFilteredCatalog(catalog, filter);

          return {
            ...state,
            additionalDatasetCatalog: filteredCatalog
          }
        }
        case DATASET_ADDITIONAL_DATASET_STURCTURE_FETCH: {
          return {
            ...state,
            additionalDataset: {
              ...state.additionalDataset,
              dimensions: (action.payload.response?.criteria || []),
              timeDim: action.payload.response.timeDimension,
              freqDim: action.payload.response.freqDimension,
              territoryDim: action.payload.response.territorialDimension,
              codelists: null,
              codelistsLength: null,
              criteria: {},
              detailLevels: (state.additionalDatasetCatalog && state.additionalDataset)
                ? state.additionalDatasetCatalog.datasets[state.additionalDataset.datasetId]
                  ? state.additionalDatasetCatalog.datasets[state.additionalDataset.datasetId].detailsLevels
                  : state.additionalDatasetCatalog.uncategorizedDatasets.find((dataset: any) => dataset.identifier === state.additionalDataset?.datasetId)?.detailsLevels || []
                : []
            }
          }
        }
        case DATASET_STRUCTURE_CODELIST_FULL_FETCH: {
          const codelist = (action.payload.response.criteria[0] || {}).values || [];
          return {
            ...state,
            missingFilterValues: codelist.filter((code: any) => action.payload.extra.missingFilterValueIds.includes(code.id))
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case DATASET_STRUCTURE_FETCH: {
          return {
            ...initialState,
            viewerMode: ViewerMode.Error,
            datasetId: state.datasetId,
            nodeCode: state.nodeCode,
            categoryPath: state.categoryPath,
            viewId: state.viewId,
            detailLevelTree: state.detailLevelTree,
            detailLevel: state.detailLevel,
            enableCriteria: false,
            enableLayout: false
          };
        }
        case DATASET_STRUCTURE_CODELIST_FETCH: {
          const isTooLongQuery = action.payload.statusCode === 414;

          return {
            ...state,
            isTooLongQuery: isTooLongQuery,
            isCriteriaAlertVisible: isTooLongQuery,
            codelistFetchError: true
          };
        }
        case DATASET_DOWNLOAD_SUBMIT: {
          return {
            ...state,
            isDownloadWarningVisible: action.payload.statusCode === 406
          };
        }
        case DATASET_CACHE_INFO_FETCH: {
          return {
            ...state,
            datasetCacheInfo: null
          };
        }
        case DATASET_TERRITORY_DETAIL_LEVELS_FETCH: {
          return {
            ...state,
            detailLevelTree: [],
            detailLevel: null
          };
        }
        case DATASET_VIEW_TEMPLATE_SUBMIT: {
          return {
            ...state,
            isViewErrorVisible: !!(action.payload.extra.isView && action.payload.statusCode === 409 && action.payload.response),
            viewErrorMessage: action.payload.response
          };
        }
        case DATASET_SDMX_QUERY_FETCH: {
          return {
            ...state,
            isQueryVisible: false,
            structureQuery: null,
            dataQuery: null
          };
        }
        case DATASET_INDICATOR_PREVIEW_FETCH: {
          return {
            ...state,
            indicatorPreview: ""
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default datasetReducer;