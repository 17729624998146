import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const PyramidIcon = props =>
  <SvgIcon {...props}>
      <path
        d="M 10.25 4 C 10.25 4 10.25 6.25 10.25 6.25 C 10.25 6.25 11.92 6.25 11.92 6.25 C 11.92 6.25 11.92 4 11.92 4 C 11.92 4 10.25 4 10.25 4 Z"
      />
      <path
        d="M 12.75 4 C 12.75 4 12.75 6.25 12.75 6.25 C 12.75 6.25 14.42 6.25 14.42 6.25 C 14.42 6.25 14.42 4 14.42 4 C 14.42 4 12.75 4 12.75 4 Z"
      />
      <path
        d="M 8 7.67 C 8 7.67 8 9.92 8 9.92 C 8 9.92 11.92 9.92 11.92 9.92 C 11.92 9.92 11.92 7.67 11.92 7.67 C 11.92 7.67 8 7.67 8 7.67 Z"
      />
      <path
        d="M 12.75 7.67 C 12.75 7.67 12.75 9.92 12.75 9.92 C 12.75 9.92 17.08 9.92 17.08 9.92 C 17.08 9.92 17.08 7.67 17.08 7.67 C 17.08 7.67 12.75 7.67 12.75 7.67 Z"
      />
      <path
        d="M 8.17 11.42 C 8.17 11.42 8.17 13.67 8.17 13.67 C 8.17 13.67 11.92 13.67 11.92 13.67 C 11.92 13.67 11.92 11.42 11.92 11.42 C 11.92 11.42 8.17 11.42 8.17 11.42 Z"
      />
      <path
        d="M 12.75 11.42 C 12.75 11.42 12.75 13.67 12.75 13.67 C 12.75 13.67 19.58 13.67 19.58 13.67 C 19.58 13.67 19.58 11.42 19.58 11.42 C 19.58 11.42 12.75 11.42 12.75 11.42 Z"
      />
      <path
        d="M 4.33 15 C 4.33 15 4.33 17.33 4.33 17.33 C 4.33 17.33 11.92 17.33 11.92 17.33 C 11.92 17.33 11.92 15 11.92 15 C 11.92 15 4.33 15 4.33 15 Z"
      />
      <path
        d="M 12.75 15 C 12.75 15 12.75 17.33 12.75 17.33 C 12.75 17.33 22 17.33 22 17.33 C 22 17.33 22 15 22 15 C 22 15 12.75 15 12.75 15 Z"
      />
      <path
        d="M 2 18.75 C 2 18.75 2 21 2 21 C 2 21 11.92 21 11.92 21 C 11.92 21 11.92 18.75 11.92 18.75 C 11.92 18.75 2 18.75 2 18.75 Z"
      />
      <path
        d="M 12.75 18.75 C 12.75 18.75 12.75 21 12.75 21 C 12.75 21 21.17 21 21.17 21 C 21.17 21 21.17 18.75 21.17 18.75 C 21.17 18.75 12.75 18.75 12.75 18.75 Z"
      />
  </SvgIcon>

export default PyramidIcon;