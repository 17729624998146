import React, {Fragment} from 'react';
import Dialog from "@material-ui/core/Dialog";
import {DialogActions} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import {useTranslation} from "react-i18next";
import FullscreenDialog from "../fullscreen-dialog";

const SettingsDialog = ({title, children, onSubmit, onClose, open, maxWidth, hasSubmit, noMinHeight, customSubmitLabel, noFullScreen}) => {

  const {t} = useTranslation();

  const dialogContent = (
    <Fragment>
      <CustomDialogTitle onClose={onClose}>
        {title}
      </CustomDialogTitle>
      <DialogContent
        style={{
          minHeight: noMinHeight ? undefined : 400,
          paddingBottom: noMinHeight ? 16 : undefined
        }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        {hasSubmit
          ? (
            <Fragment>
              <Button onClick={onClose}>
                {t("commons.confirm.cancel")}
              </Button>
              <Button onClick={onSubmit}>
                {customSubmitLabel || t("commons.confirm.submit")}
              </Button>
            </Fragment>
          )
          : (
            <Button onClick={onClose}>
              {t("commons.confirm.close")}
            </Button>
          )}
      </DialogActions>
    </Fragment>
  )

  return noFullScreen
    ? (
      <Dialog
        open={open || false}
        disableEnforceFocus
        maxWidth={maxWidth || "md"}
        fullWidth
        onClose={onClose}
      >
        {dialogContent}
      </Dialog>
    )
    : (
      <FullscreenDialog
        open={open || false}
        disableEnforceFocus
        onClose={onClose}
      >
        {dialogContent}
      </FullscreenDialog>
    );
};

export default SettingsDialog;
