import React from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from '@material-ui/core/CardActions';
import CatalogMetadataButton from "../catalog-metadata-button";
import CatalogInfoButton from "../catalog-info-button";
import CustomLink from "../custom-link";

const styles = () => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

const CardsGrid = ({
                     t,
                     classes,
                     list,
                     getHref,
                     onClick,
                     imageStyle,
                     hideLabelOnElemWithImage,
                     icon,
                     xs,
                     sm,
                     md,
                     lg,
                     xl
                   }) =>
  <Grid container spacing={2}>
    {list.map((c, i) =>
      <Grid
        key={i}
        item
        xs={xs || 12}
        sm={sm || 12}
        md={md || 6}
        lg={lg || 6}
        xl={xl || 6}
      >
        <Card className={classes.card} id={`cards-grid__card-${c.id}`}>
          <CustomLink
            to={getHref(c)}
            text={(hideLabelOnElemWithImage && c?.image)
              ? null
              : c?.label
            }
            textStyle={(hideLabelOnElemWithImage && c?.image)
              ? {
                padding: 0
              }
              : {
                padding: 16,
                fontSize: 20
              }
            }
            icon={(hideLabelOnElemWithImage && c?.image)
              ? null
              : icon
            }
            image={c?.image}
            imageStyle={{
              maxHeight: 220,
              ...imageStyle
            }}
            onClick={onClick}
          />
          {(c.description || c.metadataUrl) && (
            <CardActions>
              {c.description && (
                <CatalogInfoButton
                  title={c?.label}
                  description={c.description}
                />
              )}
              {c.metadataUrl && (
                <CatalogMetadataButton
                  metadataUrl={c.metadataUrl}
                  iconSize="small"
                />
              )}
            </CardActions>
          )}
        </Card>
      </Grid>
    )}
  </Grid>;

export default compose(
  withStyles(styles),
  withTranslation()
)(CardsGrid);