import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import {DragDropContext} from "react-beautiful-dnd";
import Grid from "@material-ui/core/Grid";
import FilterListIcon from '@material-ui/icons/FilterList';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import LayoutSection from "../layout-section";
import {ERROR_SNACKBAR_SEVERITY_WARNING} from "../error-snackbar/ErrorSnackbar";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  leftSection: {
    padding: 8,
    height: 480
  },
  rightSection: {
    padding: 8,
    height: 240,
    maxWidth: "100%"
  }
});

const reorder = (list, startIndex, endIndex) => {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const [removed] = source.splice(droppableSource.index, 1);
  destination.splice(droppableDestination.index, 0, removed);
};

const swap = (sourceArr, destinationArr, droppableSource) => {
  const [removedSrc] = sourceArr.splice(droppableSource.index, 1);
  const [removedDst] = destinationArr.splice(0, 1, removedSrc);
  sourceArr.splice(sourceArr.length, 0, removedDst);
};

const onDragEnd = (result, dimensions, setDimensions, lockedDimensions, t) => {

  const {
    source,
    destination
  } = result;

  // dropped outside the list
  if (!destination) {
    return;
  }

  const newDimensions = JSON.parse(JSON.stringify(dimensions));

  if (source.droppableId === destination.droppableId) {
    reorder(
      newDimensions[source.droppableId],
      source.index,
      destination.index
    );

  } else {
    const srcDimension = newDimensions[source.droppableId][source.index];

    if (lockedDimensions.includes(srcDimension)) {
      window.error.show(t("components.tableLayout.drag.alert"));
      return;
    }

    if (
      (source.droppableId === "secondaryDim" && destination.droppableId === "filters") ||
      (source.droppableId === "filters" && destination.droppableId === "secondaryDim" && newDimensions[destination.droppableId].length === 0)
    ) {
      move(
        newDimensions[source.droppableId],
        newDimensions[destination.droppableId],
        source,
        destination
      );

    } else {

      const swapDimension = newDimensions[destination.droppableId][0];

      if (lockedDimensions.includes(swapDimension)) {
        window.error.show(t("components.tableLayout.drag.alert"), ERROR_SNACKBAR_SEVERITY_WARNING);
        return;
      }

      swap(
        newDimensions[source.droppableId],
        newDimensions[destination.droppableId],
        source
      );
    }

  }

  setDimensions(newDimensions);
};

const ChartLayout = ({
                       t,
                       classes,
                       jsonStat,
                       layout,
                       setLayout,
                       lockedDimensions
                     }) => {

  const {
    primaryDim,
    secondaryDim,
    filters
  } = layout;

  const layoutLockedDimensions = (lockedDimensions || []);

  return (
    <div className={classes.root}>
      <DragDropContext onDragEnd={result => onDragEnd(result, layout, setLayout, layoutLockedDimensions, t)}>
        <Grid container style={{height: "100%"}}>
          <Grid item xs={6} className={classes.leftSection}>
            <LayoutSection
              id="filters"
              title={t("components.chartLayout.filters.title")}
              Icon={<FilterListIcon/>}
              dimensions={filters}
              jsonStat={jsonStat}
              lockedDimensions={layoutLockedDimensions}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" style={{height: "100%"}}>
              <Grid item xs={6} className={classes.rightSection}>
                <LayoutSection
                  id="primaryDim"
                  title={t("components.chartLayout.primaryDim.title")}
                  Icon={<ViewCompactIcon/>}
                  dimensions={primaryDim}
                  jsonStat={jsonStat}
                  disabled
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
              <Grid item xs={6} className={classes.rightSection}>
                <LayoutSection
                  id="secondaryDim"
                  title={t("components.chartLayout.secondaryDim.title")}
                  Icon={<ViewStreamIcon/>}
                  dimensions={secondaryDim}
                  jsonStat={jsonStat}
                  lockedDimensions={layoutLockedDimensions}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DragDropContext>
    </div>
  )
};

export default compose(
  withStyles(styles),
  withTranslation()
)(ChartLayout)