import React from 'react';
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

export const PDF_ORIENTATION_VERTICAL = "vertical";
export const PDF_ORIENTATION_HORIZONTAL = "horizontal";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    "& > *:not(:last-child)": {
      marginBottom: 16
    }
  },
  paper: {
    padding: theme.spacing(2),
    "& > *:not(:last-child)": {
      marginBottom: 16
    }
  }
});

function ExportForm(props) {
  const {
    t,
    classes,
    settings,
    onSettingsSet
  } = props;

  const {
    showTitle,
    title,
    showFilters,
    isLegendVisible,
    showLegend,
    showDate,
    sheetOrientation
  } = settings || {}

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <FormControl fullWidth>
          <FormControlLabel
            label={t("components.exportButton.exportForm.showTitle.label")}
            control={
              <Checkbox
                checked={showTitle || false}
                onChange={(e, value) => onSettingsSet({...settings, showTitle: value})}
              />
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t("components.exportButton.exportForm.title.label")}
            variant="outlined"
            value={title || ""}
            onChange={({target}) => onSettingsSet({...settings, title: target.value})}
          />
        </FormControl>
      </Paper>
      <FormControl fullWidth>
        <FormControlLabel
          label={t("components.exportButton.exportForm.showFilters.label")}
          control={
            <Checkbox
              checked={showFilters || false}
              onChange={(e, value) => onSettingsSet({...settings, showFilters: value})}
            />
          }
        />
      </FormControl>
      {showDate !== null && showDate !== undefined && (
        <FormControl fullWidth>
          <FormControlLabel
            label={t("components.exportButton.exportForm.showDate.label")}
            control={
              <Checkbox
                checked={showDate || false}
                onChange={(e, value) => onSettingsSet({...settings, showDate: value})}
              />
            }
          />
        </FormControl>
      )}
      {isLegendVisible && showLegend !== null && showLegend !== undefined && (
        <FormControl fullWidth>
          <FormControlLabel
            label={t("components.exportButton.exportForm.showLegend.label")}
            control={
              <Checkbox
                checked={showLegend || false}
                onChange={(e, value) => onSettingsSet({...settings, showLegend: value})}
              />
            }
          />
        </FormControl>
      )}
      {sheetOrientation !== null && sheetOrientation !== undefined && (
        <FormControl fullWidth>
          <TextField
            select
            fullWidth
            label={t("components.exportButton.exportForm.sheetOrientation.label")}
            value={sheetOrientation}
            variant="outlined"
            onChange={ev => onSettingsSet({...settings, sheetOrientation: ev.target.value})}
          >
            <MenuItem value={PDF_ORIENTATION_VERTICAL}>
              {t("components.exportButton.exportForm.sheetOrientation.values.vertical")}
            </MenuItem>
            <MenuItem value={PDF_ORIENTATION_HORIZONTAL}>
              {t("components.exportButton.exportForm.sheetOrientation.values.horizontal")}
            </MenuItem>
          </TextField>
        </FormControl>
      )}
    </div>
  )
}

export default compose(
  withStyles(styles),
  withTranslation()
)(ExportForm);