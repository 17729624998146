import React, {Component, createRef} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import ChartJs from "chart.js";
import "chartjs-plugin-zoom";
import "chartjs-plugin-datalabels";
import "chartjs-plugin-annotation";
import {getFormattedValue} from "../../utils/formatters";
import {
  FREQ_DIMENSION_KEY,
  getAttributeLabel,
  getAttributeValueLabel,
  getCompleteValueDims,
  getDataIdxFromCoordinatesArray,
  getDimensionAttributeMap,
  getDimensionValuesIndexesMap,
  getFilterTreeFromJsonStat,
  getFormattedDimensionLabel,
  getFormattedDimensionValueLabel,
  getObservationAttributeMap,
  getVariationValueLabels,
  VARIATION_DIMENSION_KEY,
  VARIATION_VALUE_CYCLICAL_KEY,
  VARIATION_VALUE_TREND_KEY,
  VARIATION_VALUE_VALUE_KEY
} from "../../utils/jsonStat";
import CustomEmpty from "../custom-empty";
import _ from "lodash";
import {v4 as uuidv4} from "uuid";
import {getLightOrDarkColorBasedOnContrastRatio, getNthValorizedElementIndexInBooleanArray} from "../../utils/other";
import {CHART_COLORS_ALL_DIMENSION_VALUES_KEY} from "../chart-settings-forms/Colors";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../label-format-selector/constants";
import {localizeI18nObj} from "../../utils/i18n";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  }
});

const PERCENTAGE_AXES_ID = "PERCENTAGE_AXES_ID";
const VALUE_AXES_ID = "VALUE_AXES_ID";

const AXIS_DECIMAL_PLACES_DEFAULT = 2;

export const CHART_LEGEND_POSITION_TOP = "top";
export const CHART_LEGEND_POSITION_RIGHT = "right";
export const CHART_LEGEND_POSITION_BOTTOM = "bottom";
export const CHART_LEGEND_POSITION_lEFT = "left";

export const CHART_DATA_LABEL_TYPE_NONE = "none";
export const CHART_DATA_LABEL_TYPE_VALUE = "value";
export const CHART_DATA_LABEL_TYPE_PERCENTAGE = "percentage";

const defaultChartSettings = {
  stacked: false,
  legendPosition: CHART_LEGEND_POSITION_TOP,
  colors: {},
  showAxesLabel: true,
  customizeCategoryAxis: false,
  categoryAxisLabel: {},
  valueAxisLabel: {},
  dataLabelType: CHART_DATA_LABEL_TYPE_NONE
};

const getCompleteChartSettings = chartSetting => {
  const newChartSettings = {};

  if (chartSetting === null || chartSetting === undefined) {
    return defaultChartSettings;
  }

  Object.keys(defaultChartSettings).forEach(key => {
    newChartSettings[key] = (chartSetting[key] !== null && chartSetting[key] !== undefined)
      ? chartSetting[key]
      : defaultChartSettings[key];
  });

  return newChartSettings;
};

const getFilterDims = (jsonStat, chartLayout) => jsonStat.id.filter(dim => chartLayout.secondaryDim.includes(dim) || chartLayout.filters.includes(dim));

export const CHART_TYPE_BAR = "bar";
export const CHART_TYPE_HORIZONTAL_BAR = "horizontalBar";
export const CHART_TYPE_LINE = "line";
export const CHART_TYPE_AREA = "area";
export const CHART_TYPE_DOUGHNUT = "doughnut";
export const CHART_TYPE_PIE = "pie";
export const CHART_TYPE_RADAR = "radar";
export const CHART_TYPE_POLAR_AREA = "polarArea";
export const CHART_TYPE_PYRAMID = "pyramid";

const getChartType = type => {
  switch (type) {
    case CHART_TYPE_BAR:
      return "bar";
    case CHART_TYPE_HORIZONTAL_BAR:
      return "horizontalBar";
    case CHART_TYPE_LINE:
      return "bar"; // needed to fix line + bar chart
    case CHART_TYPE_AREA:
      return "line";
    case CHART_TYPE_DOUGHNUT:
      return "doughnut";
    case CHART_TYPE_PIE:
      return "pie";
    case CHART_TYPE_RADAR:
      return "radar";
    case CHART_TYPE_POLAR_AREA:
      return "polarArea";
    case CHART_TYPE_PYRAMID:
      return "horizontalBar";
    default:
      return type;
  }
};

const getVariationChartType = type => {
  switch (type) {
    case CHART_TYPE_BAR:
      return "line";
    case CHART_TYPE_LINE:
      return "bar";
    default:
      return null;
  }
};

const isBarChart = type => (type === CHART_TYPE_BAR || type === CHART_TYPE_HORIZONTAL_BAR || type === CHART_TYPE_PYRAMID);

const isLineChart = type => (type === CHART_TYPE_LINE || type === CHART_TYPE_AREA || type === CHART_TYPE_RADAR);

const isBarOrLineChart = type => (isBarChart(type) || isLineChart(type));

const getDimensionValueTree = (jsonStat, layout) => {
  const dims = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);
  const layoutDims = [...layout.primaryDim, ...layout.secondaryDim, ...layout.filters];

  if (dims.length !== layoutDims.length) {
    const orderedDims = [...layoutDims, ...dims.filter(dim => !layoutDims.includes(dim))];
    return getFilterTreeFromJsonStat(orderedDims, jsonStat);

  } else {
    return null;
  }
};

const getData = (
  chartType,
  jsonStat,
  layout,
  dimensionValueTree,
  timePeriodsByFreq,
  labelFormat,
  chartSettings,
  showTrend = false,
  showCyclical = false,
  t,
  defaultChartColor,
  invertedDims
) => {

  const {
    primaryDim: primaryDimArr,
    primaryDimValues: originalPrimaryDimValues,
    secondaryDim: secondaryDimArr,
    secondaryDimValues: originalSecondaryDimValues,
    filters,
    filtersValue
  } = layout;

  const primaryDim = primaryDimArr[0];
  const primaryDimValues = primaryDim
    ? jsonStat.dimension[primaryDim].category.index.filter(dimVal => originalPrimaryDimValues.includes(dimVal))
    : originalPrimaryDimValues;
  if (chartType === CHART_TYPE_PYRAMID) {
    primaryDimValues.reverse();
  }

  const secondaryDim = secondaryDimArr[0];
  const secondaryDimValues = secondaryDim
    ? jsonStat.dimension[secondaryDim].category.index.filter(dimVal => originalSecondaryDimValues.includes(dimVal))
    : originalSecondaryDimValues;

  const datasetId = jsonStat?.extension?.datasets?.[0];

  const indexesMap = getDimensionValuesIndexesMap(jsonStat);
  const dimAttributeMap = getDimensionAttributeMap(jsonStat, t)[datasetId];
  const obsAttributeMap = getObservationAttributeMap(jsonStat);
  if (obsAttributeMap === null) {
    console.error(t("components.chart.error.observationAttribute"));
  }

  const valorizedDataMap = {};
  secondaryDimValues.forEach(secondaryDimValue => {
    valorizedDataMap[secondaryDimValue] = new Array(primaryDimValues.length).fill(false);
  });
  const valorizedDataArr = new Array(primaryDimValues.length).fill(false);

  const timeDim = jsonStat.role?.time?.[0];

  let reversedData = false;
  if ((invertedDims || []).includes(layout.primaryDim[0])) {
    reversedData = true;
  }

  let reversedDatasets = false;
  if ((invertedDims || []).includes(layout.secondaryDim[0])) {
    reversedDatasets = true;
  }

  const orderedDims = [primaryDim, secondaryDim, ...layout.filters, ...jsonStat.id.filter(dim => dim !== primaryDim && dim !== secondaryDim && !layout.filters.includes(dim))];

  let datasets = [];

  const getDataset = (secondaryDimValue, secDimIdx) => {
    const datasets = [];

    const variations = [];
    if (jsonStat.id.includes(VARIATION_DIMENSION_KEY) && showTrend && getVariationChartType(chartType)) {
      variations.push(VARIATION_VALUE_TREND_KEY);
    }
    if (jsonStat.id.includes(VARIATION_DIMENSION_KEY) && showCyclical && getVariationChartType(chartType)) {
      variations.push(VARIATION_VALUE_CYCLICAL_KEY);
    }

    const getDataAndAttributes = filtersValue => {
      const data = [];
      const dataAttributes = [];

      primaryDimValues.forEach((primaryDimValue, primDimIdx) => {
        let dimValueArray = jsonStat.id.map(dim => {
          if (dim === primaryDim) {
            return primaryDimValue;
          } else if (secondaryDim && dim === secondaryDim) {
            return secondaryDimValue;
          } else if (filtersValue[dim]) {
            return filtersValue[dim];
          } else {
            return null;
          }
        });

        if (dimValueArray.findIndex(val => val === null) > -1 && dimensionValueTree) {
          dimValueArray = getCompleteValueDims(dimValueArray, jsonStat.id, orderedDims, dimensionValueTree);
        }

        if (dimValueArray) {
          const dimValueIndexArray = dimValueArray.map((value, idx) => indexesMap[jsonStat.id[idx]][value]);
          const valueIdx = getDataIdxFromCoordinatesArray(dimValueIndexArray, jsonStat.size);

          const jsonStatValue = jsonStat.value[valueIdx];
          const value = (jsonStatValue !== null && jsonStatValue !== "" && !isNaN(jsonStatValue))
            ? Number(jsonStatValue)
            : null;

          if (value !== null) {
            if (secondaryDim && chartType === CHART_TYPE_PYRAMID && secondaryDimValues.length === 2 && secDimIdx === 0) {
              data.push(-value);
            } else {
              data.push(value);
            }
            if (secondaryDimValue) {
              valorizedDataMap[secondaryDimValue][primDimIdx] = true;
            } else {
              valorizedDataArr[primDimIdx] = true;
            }

          } else {
            data.push(null);
          }

          const attributes = {
            observation: obsAttributeMap?.[valueIdx] || null,
            primaryDim: dimAttributeMap?.[primaryDim]?.[primaryDimValue] || null,
            secondaryDim: dimAttributeMap?.[secondaryDim]?.[secondaryDimValue] || null
          };
          dataAttributes.push(attributes);

        } else {
          data.push(null);
          dataAttributes.push({
            observation: null,
            primaryDim: null,
            secondaryDim: null
          });
        }
      });

      if (reversedData) {
        data.reverse();
        dataAttributes.reverse();
      }

      return {
        data,
        dataAttributes
      };
    };

    const filtersValueFull = {...filtersValue};

    if (secondaryDim) {
      filtersValueFull[secondaryDim] = secondaryDimValue;
    }

    if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
      filtersValueFull[VARIATION_DIMENSION_KEY] = VARIATION_VALUE_VALUE_KEY;
    }

    const {data, dataAttributes} = getDataAndAttributes(filtersValueFull);
    const label = variations.length === 0
      ? secondaryDim
        ? getFormattedDimensionValueLabel(jsonStat, null, secondaryDim, secondaryDimValue, labelFormat, t)
        : ""
      : secondaryDim
        ? getFormattedDimensionValueLabel(jsonStat, null, secondaryDim, secondaryDimValue, labelFormat, t) + ` (${getVariationValueLabels(t)[VARIATION_VALUE_VALUE_KEY]})`
        : getVariationValueLabels(t)[VARIATION_VALUE_VALUE_KEY];

    datasets.push({
      type: chartType === CHART_TYPE_LINE ? "line" : getChartType(chartType), // needed to fix line + bar chart
      data: data,
      label: label,
      dataAttributes: dataAttributes,
      filtersValue: filtersValueFull
    });

    if (variations.length > 0) {
      variations.forEach(variationDimValue => {

        filtersValueFull[VARIATION_DIMENSION_KEY] = variationDimValue;

        const {data, dataAttributes} = getDataAndAttributes(filtersValueFull);
        const label = secondaryDim
          ? getFormattedDimensionValueLabel(jsonStat, null, secondaryDim, secondaryDimValue, labelFormat, t) + ` (${getVariationValueLabels(t)[variationDimValue]})`
          : getVariationValueLabels(t)[variationDimValue];

        datasets.push({
          type: getVariationChartType(chartType), // needed to fix line + bar chart
          data: data,
          label: label,
          dataAttributes: dataAttributes,
          filtersValue: filtersValueFull,
          yAxisID: PERCENTAGE_AXES_ID,
          // stack: label,
          isVariation: true
        });
      });
    }

    return datasets;
  };

  if (secondaryDimValues && secondaryDimValues.length > 0) {
    secondaryDimValues.forEach((secondaryDimValue, secDimIdx) => {
      datasets = datasets.concat(getDataset(secondaryDimValue, secDimIdx));
    });

  } else {
    datasets = datasets.concat(getDataset());
  }

  let valorizedValues;

  if (chartType === CHART_TYPE_LINE && primaryDim === timeDim && timePeriodsByFreq) {
    let availableTimePeriods = [];
    if (filters.includes(FREQ_DIMENSION_KEY)) {
      availableTimePeriods = timePeriodsByFreq[filtersValue[FREQ_DIMENSION_KEY]];
    } else { // FREQ as secondary dimension
      secondaryDimValues.forEach(freq => availableTimePeriods = availableTimePeriods.concat(timePeriodsByFreq[freq]));
    }
    valorizedValues = primaryDimValues.map(primDimVal => availableTimePeriods.includes(primDimVal));

  } else if (secondaryDimValues && secondaryDimValues.length > 0) {
    valorizedValues = new Array(primaryDimValues.length);
    primaryDimValues.forEach((_, idx) => {
      let found = false;
      secondaryDimValues.forEach(secDimVal => {
        if (!found && valorizedDataMap[secDimVal][idx] === true) {
          found = true;
        }
      });
      valorizedValues[idx] = found;
    });

  } else {
    valorizedValues = [...valorizedDataArr];
  }

  datasets.forEach(dataset => {
    dataset.data = dataset.data.filter((_, idx) => valorizedValues[idx]);
    dataset.dataAttributes = dataset.dataAttributes.filter((_, idx) => valorizedValues[idx]);
  });

  const labels = [];
  primaryDimValues.forEach((value, idx) => {
    if (valorizedValues[idx]) {
      labels.push(getFormattedDimensionValueLabel(jsonStat, null, primaryDim, value, labelFormat, t));
    }
  });

  if (reversedDatasets) {
    datasets.reverse();
  }
  if (reversedData) {
    labels.reverse();
  }

  const colorArr = defaultChartColor;

  const getPrimaryDimValueColors = datasetIdx => {
    return datasets[datasetIdx].data.map((_, valIdx) => {
      const idx = getNthValorizedElementIndexInBooleanArray(valorizedValues, valIdx);

      const customizedColor = secondaryDim
        ? chartSettings.colors?.[secondaryDim]?.[secondaryDimValues[reversedDatasets ? (secondaryDimValues.length - datasetIdx - 1) : datasetIdx]]
        : (
          chartSettings.colors?.[primaryDim]?.[primaryDimValues[reversedData ? (primaryDimValues.length - idx - 1) : idx]] ||
          chartSettings.colors?.[primaryDim]?.[CHART_COLORS_ALL_DIMENSION_VALUES_KEY]
        );

      return customizedColor || colorArr[datasetIdx % colorArr.length];
    });
  };

  const getDatasetColors = datasetIdx => {
    if (isBarChart(chartType)) {
      return getPrimaryDimValueColors(datasetIdx);

    } else if (isLineChart(chartType)) {
      return (secondaryDim && chartSettings.colors?.[secondaryDim]?.[secondaryDimValues[reversedDatasets ? (secondaryDimValues.length - datasetIdx - 1) : datasetIdx]]) ||
        (!secondaryDim && chartSettings.colors?.[primaryDim]?.[CHART_COLORS_ALL_DIMENSION_VALUES_KEY]) ||
        colorArr[datasetIdx % colorArr.length];

    } else {
      return datasets[datasetIdx].data.map((_, valIdx) => {
        const idx = getNthValorizedElementIndexInBooleanArray(valorizedValues, valIdx);

        return chartSettings.colors?.[primaryDim]?.[primaryDimValues[reversedData ? (primaryDimValues.length - idx - 1) : idx]] ||
          colorArr[valIdx % colorArr.length];
      });
    }
  };

  const getFill = datasetIdx => {
    let fill = false;
    if (chartType === CHART_TYPE_AREA) {
      fill = chartSettings.stacked
        ? datasetIdx === 0 ? "origin" : "-1"
        : "origin";
    } else if (chartType === CHART_TYPE_RADAR) {
      fill = "origin";
    }
    return fill;
  };

  return {
    labels: labels,
    datasets: datasets.map((dataset, datasetIdx) => ({
      ...dataset,
      backgroundColor: getDatasetColors(datasetIdx),
      borderColor: isBarOrLineChart(chartType) ? getDatasetColors(datasetIdx) : "white",
      borderWidth: 2,
      lineTension: chartType === CHART_TYPE_AREA ? 0 : undefined,
      fill: getFill(datasetIdx),
      pointBackgroundColor: getPrimaryDimValueColors(datasetIdx),
      pointRadius: 4,
      pointHoverRadius: 4,
      pointBorderColor: "white",
      pointBorderWidth: 1,
      pointHoverBorderWidth: 1
    }))
  };
};

const getOptions = (
  chartType,
  jsonStat,
  layout,
  data,
  labelFormat,
  primaryDimLabel,
  decimalSeparator,
  decimalPlaces,
  chartSettings,
  disableWheelZoom,
  showTrend = false,
  showCyclical = false,
  showArithmeticMean,
  arithmeticMeanDims,
  arithmeticMeans,
  defaultLanguage,
  languages,
  t
) => {

  let max = 0;
  data.datasets.forEach(dataset => dataset.data.forEach(value => max = Math.max(max, Math.abs(value))));
  max += Math.floor(max / 100 * 20);

  const categoryAxisLabel = localizeI18nObj(chartSettings.categoryAxisLabel, defaultLanguage, languages);
  const valueAxisLabel = localizeI18nObj(chartSettings.valueAxisLabel, defaultLanguage, languages);

  const xAxes = [];
  if ((isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR)) {
    xAxes.push({
      scaleLabel: (chartType !== CHART_TYPE_HORIZONTAL_BAR && chartType !== CHART_TYPE_PYRAMID)
        ? {
          display: chartSettings.showAxesLabel,
          labelString: (chartSettings.customizeCategoryAxis && (categoryAxisLabel || "").length > 0)
            ? categoryAxisLabel
            : primaryDimLabel
        }
        : {
          display: (chartSettings.showAxesLabel && (valueAxisLabel || "").length > 0),
          labelString: valueAxisLabel
        },
      gridLines: {
        display: chartType !== CHART_TYPE_BAR
      },
      ticks: {
        beginAtZero: (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID),
        callback: value => {
          if (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID) {
            const axisDecimalPlaces = decimalPlaces !== -1 ? decimalPlaces : AXIS_DECIMAL_PLACES_DEFAULT;
            if (chartType === CHART_TYPE_PYRAMID && data.datasets.length === 2 && value < 0) {
              return getFormattedValue(-value, decimalSeparator, axisDecimalPlaces);
            } else {
              return getFormattedValue(value, decimalSeparator, axisDecimalPlaces);
            }
          } else {
            return value;
          }
        },
        max: chartType === CHART_TYPE_PYRAMID ? max : undefined,
        min: chartType === CHART_TYPE_PYRAMID ? -(max) : undefined
      },
      stacked: (chartType === CHART_TYPE_PYRAMID || (chartSettings.stacked && isBarOrLineChart(chartType)))
    });
  }

  const yAxes = [];
  if ((isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR)) {
    yAxes.push({
      id: VALUE_AXES_ID,
      scaleLabel: (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID)
        ? {
          display: chartSettings.showAxesLabel,
          labelString: (chartSettings.customizeCategoryAxis && (categoryAxisLabel || "").length > 0)
            ? categoryAxisLabel
            : primaryDimLabel
        }
        : {
          display: (chartSettings.showAxesLabel && (valueAxisLabel || "").length > 0),
          labelString: valueAxisLabel
        },
      gridLines: {
        display: (chartType !== CHART_TYPE_HORIZONTAL_BAR && chartType !== CHART_TYPE_PYRAMID)
      },
      ticks: {
        beginAtZero: (chartType !== CHART_TYPE_HORIZONTAL_BAR && chartType !== CHART_TYPE_PYRAMID),
        callback: value => {
          if ((chartType !== CHART_TYPE_HORIZONTAL_BAR && chartType !== CHART_TYPE_PYRAMID)) {
            const axisDecimalPlaces = decimalPlaces !== -1 ? decimalPlaces : AXIS_DECIMAL_PLACES_DEFAULT;
            return getFormattedValue(value, decimalSeparator, axisDecimalPlaces);
          } else {
            return value;
          }
        }
      },
      stacked: (chartType === CHART_TYPE_PYRAMID || (chartSettings.stacked && isBarOrLineChart(chartType)))
    });
    if (jsonStat.id.includes(VARIATION_DIMENSION_KEY) && (showTrend || showCyclical) && getVariationChartType(chartType)) {
      yAxes.push({
        id: PERCENTAGE_AXES_ID,
        scaleLabel: {
          display: chartSettings.showAxesLabel,
          labelString: t("components.chart.axis.variation.label") + " (%)"
        },
        gridLines: {
          display: false
        },
        position: "right"
      });
    }
  }

  const isArithmeticMeanVisible = (
    showArithmeticMean &&
    chartType === CHART_TYPE_BAR &&
    Object.keys(arithmeticMeans || {}).length > 0 &&
    getFilterDims(jsonStat, layout).every((dim, idx) => dim === arithmeticMeanDims[idx])
  );

  return {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      animationDuration: 0
    },
    scales: {
      xAxes: xAxes,
      yAxes: yAxes
    },
    legend: {
      display: (!isBarOrLineChart(chartType) || (data.datasets || []).length > 1),
      position: chartSettings.legendPosition
    },
    tooltips: {
      enabled: true,
      mode: "point",
      callbacks: {
        title: (tooltipItems, data) => (data.labels[tooltipItems[0].index] || ""),
        label: (tooltipItem, data) => {
          const getValue = dataset => {
            let value = dataset.data[tooltipItem.index];
            if (!value) {
              return null;
            }
            value = getFormattedValue(value, decimalSeparator, decimalPlaces);
            if (chartType === CHART_TYPE_PYRAMID && data.datasets.length === 2 && tooltipItem.datasetIndex === 0 && value[0] === "-") {
              return value.slice(1);
            } else {
              return value;
            }
          };
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = getValue(dataset);
          return value
            ? `${dataset.label}: ${value}` + (dataset.isVariation ? "%" : "")
            : null;
        },
        footer: (tooltipItems, data) => {
          const datasetIdx = tooltipItems[0].datasetIndex;
          const dataIdx = tooltipItems[0].index;

          const attributes = data.datasets[datasetIdx].dataAttributes[dataIdx];
          const labels = [];

          /** observation **/
          if ((attributes.observation || []).length > 0) {
            labels.push(t("components.chart.tooltip.attributes.observation.label") + ":");
            attributes.observation.forEach(attr => {
              labels.push(`- ${getAttributeLabel(attr, labelFormat)}: ${getAttributeValueLabel(attr, labelFormat)}`);
            });
          }

          return labels;
        }
      },
      footerFontStyle: "normal",
      footerMarginTop: 12
    },
    plugins: {
      zoom: disableWheelZoom
        ? {}
        : {
          pan: {
            enabled: (isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR),
            mode: () => (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID) ? "x" : "y"
          },
          zoom: {
            enabled: (isBarOrLineChart(chartType) && chartType !== CHART_TYPE_RADAR),
            mode: () => (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID) ? "x" : "y"
          }
        },
      datalabels: {
        labels: {
          value: {
            display: chartSettings.dataLabelType === CHART_DATA_LABEL_TYPE_NONE
              ? false
              : "auto",
            formatter: (value, ctx) => {
              const dataset = data.datasets[ctx.datasetIndex];

              if (chartSettings.dataLabelType === CHART_DATA_LABEL_TYPE_VALUE) {
                return getFormattedValue(value, decimalSeparator, decimalPlaces) + (dataset.isVariation ? "%" : "");

              } else if (chartSettings.dataLabelType === CHART_DATA_LABEL_TYPE_PERCENTAGE) {
                let sum = 0;
                dataset.data.forEach(data => {
                  sum += data;
                });
                return (value * 100 / sum).toFixed(1) + "%";

              } else {
                return "";
              }
            },
            color: ctx => {
              const rgbaLightColor = "rgba(255, 255, 255, 0.9)";
              const rgbaDarkColor = "rgba(42, 42, 42, 1)";

              if (isLineChart(chartType)) {
                return rgbaDarkColor;
              }

              const color = ctx.dataset.backgroundColor[ctx.dataIndex];
              return color.startsWith("rgba")
                ? getLightOrDarkColorBasedOnContrastRatio(color, rgbaLightColor, rgbaDarkColor)
                : rgbaLightColor;
            },
            font: {
              size: 14
            },
            align: (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID) ? "left" : "bottom",
            offset: 2
          },
          attribute: {
            formatter: (value, ctx) => {
              const attributes = data.datasets[ctx.datasetIndex].dataAttributes[ctx.dataIndex];
              return (attributes?.observation !== null && attributes?.observation !== undefined)
                ? "(*)"
                : null;
            },
            font: {
              size: 12,
              family: "Do Hyeon"
            },
            align: (chartSettings.chartDataLabelType === CHART_DATA_LABEL_TYPE_NONE)
              ? "center"
              : (chartType === CHART_TYPE_HORIZONTAL_BAR || chartType === CHART_TYPE_PYRAMID)
                ? "right"
                : "top",
            offset: 2
          }
        },
        clamp: true
      }
    },
    annotation: {
      drawTime: "afterDraw",
      events: ["mouseenter", "mouseleave"],
      annotations: isArithmeticMeanVisible
        ? (
          data.datasets.filter(({isVariation}) => !isVariation).map(dataset => {
            const meanIdx = getFilterDims(jsonStat, layout).map(dim => dataset.filtersValue[dim]).join("+");

            const secondaryDim = layout.secondaryDim.length > 0
              ? layout.secondaryDim[0]
              : null;
            const secondaryDimValue = secondaryDim
              ? dataset.filtersValue[secondaryDim]
              : null;
            const secondaryDimValueLabel = secondaryDimValue
              ? getFormattedDimensionValueLabel(jsonStat, null, secondaryDim, secondaryDimValue, labelFormat)
              : null;

            return {
              type: "line",
              mode: "horizontal",
              scaleID: VALUE_AXES_ID,
              value: arithmeticMeans[meanIdx],
              borderColor: dataset.borderColor,
              borderWidth: 2,
              label: {
                enabled: true,
                position: "left",
                xAdjust: 10,
                content: t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean") + (secondaryDimValue ? ` ${secondaryDimValueLabel}` : ""),
                backgroundColor: "rgba(0, 0, 0, 0.75)"
              }
            };
          })
        )
        : []
    }
  };
};

class Chart extends Component {

  constructor(props) {
    super(props);
    this.chartRef = createRef();

    const chartId = props.chartId || `cart__${uuidv4()}`;

    this.state = {
      chartId: chartId,
      data: null,
      dimensionValueTree: null
    };

    window.ChartJs = window.ChartJs || {};
    window.ChartJs[chartId] = {};

    window.ChartJs.setChartStyle = (
      chartId,
      fontSize,
      gridLineWidth,
      legendBoxWidth,
      legendPadding,
      increaseLegendElementPadding,
      borderWidth,
      pointRadius,
      pointBorderWidth
    ) => {

      window.ChartJs[chartId].options.scales.xAxes.forEach(axis => {
        axis.scaleLabel.fontSize = fontSize;
        axis.ticks.fontSize = fontSize;
        axis.gridLines.lineWidth = gridLineWidth;
      });

      window.ChartJs[chartId].options.scales.yAxes.forEach(axis => {
        axis.scaleLabel.fontSize = fontSize;
        axis.ticks.fontSize = fontSize;
        axis.gridLines.lineWidth = gridLineWidth;
      });

      window.ChartJs[chartId].legend.options.labels.fontSize = fontSize;
      window.ChartJs[chartId].options.legend.labels.boxWidth = legendBoxWidth || 40;
      window.ChartJs[chartId].legend.afterFit = function() {
        this.height = this.height + (legendPadding || 0);
      };

      if (increaseLegendElementPadding) {
        window.ChartJs[chartId].labelsBackup = window.ChartJs[chartId].config.data.datasets.map(({label}) => label);
      }
      window.ChartJs[chartId].config.data.datasets.forEach((dataset, idx) => {
        dataset.label = increaseLegendElementPadding
          ? dataset.label + "   "
          : window.ChartJs[chartId].labelsBackup
            ? window.ChartJs[chartId].labelsBackup[idx]
            : dataset.label;

        dataset.borderWidth = borderWidth || 2;
        dataset.pointRadius = pointRadius || 4;
        dataset.pointHoverRadius = pointRadius || 4;
        dataset.pointBorderWidth = pointBorderWidth || 1;
        dataset.pointHoverBorderWidth = pointBorderWidth || 1;
      });
      if (!increaseLegendElementPadding) {
        window.ChartJs[chartId].labelsBackup = null;
      }

      window.ChartJs[chartId].options.plugins.datalabels.labels.attribute.font.size = fontSize;

      window.ChartJs[chartId].update();
    };
  };

  componentDidMount() {

    const {
      chartId
    } = this.state;

    const {
      t,
      defaultLanguage,
      languages,
      defaultChartColor,
      type,
      jsonStat,
      layout,
      timePeriodsByFreq,
      disableWheelZoom = false,
      labelFormat = LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
      decimalSeparator,
      decimalPlaces,
      chartSettings: externalChartSettings,
      showTrend,
      showCyclical,
      invertedDims,
      showArithmeticMean,
      arithmeticMeanDims,
      arithmeticMeans
    } = this.props;

    ChartJs.defaults.global.defaultFontFamily = "'Roboto', 'Helvetica', 'Arial', sans-serif";

    const chartSettings = getCompleteChartSettings(externalChartSettings);

    const dimensionValueTree = getDimensionValueTree(jsonStat, layout);
    this.setState({dimensionValueTree: dimensionValueTree});

    const data = getData(
      type,
      jsonStat,
      layout,
      dimensionValueTree,
      timePeriodsByFreq,
      labelFormat,
      chartSettings,
      showTrend,
      showCyclical,
      t,
      defaultChartColor,
      invertedDims
    );
    this.setState({data: data});

    const options = getOptions(
      type,
      jsonStat,
      layout,
      data,
      labelFormat,
      getFormattedDimensionLabel(jsonStat, null, layout.primaryDim[0], labelFormat, t),
      decimalSeparator,
      decimalPlaces,
      chartSettings,
      disableWheelZoom,
      showTrend,
      showCyclical,
      showArithmeticMean,
      arithmeticMeanDims,
      arithmeticMeans,
      defaultLanguage,
      languages,
      t
    );

    this.myChart = new ChartJs(this.chartRef.current, {
      type: getChartType(type),
      data: data,
      options: options,
      plugins: [
        {
          beforeDraw: function(chart) {
            const ctx = chart.chart.ctx;
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.chart.width, chart.chart.height);
          }
        }
      ]
    });

    window.ChartJs[chartId] = this.myChart;
  }

  componentDidUpdate(prevProps) {

    const {
      t,
      defaultLanguage,
      languages,
      defaultChartColor,
      type,
      jsonStat,
      layout,
      timePeriodsByFreq,
      disableWheelZoom,
      labelFormat,
      decimalSeparator,
      decimalPlaces,
      chartSettings: externalChartSettings,
      showTrend,
      showCyclical,
      invertedDims,
      showArithmeticMean,
      arithmeticMeanDims,
      arithmeticMeans
    } = this.props;

    const prevChartSettings = getCompleteChartSettings(prevProps.chartSettings);
    const newChartSettings = getCompleteChartSettings(externalChartSettings);

    if (
      prevProps.jsonStat !== jsonStat ||
      prevProps.type !== type ||
      !_.isEqual(prevProps.layout, layout) ||
      (prevProps.disableWheelZoom !== disableWheelZoom) ||
      (prevProps.labelFormat !== labelFormat) ||
      (prevProps.decimalSeparator !== decimalSeparator) ||
      (prevProps.decimalPlaces !== decimalPlaces) ||
      !_.isEqual(prevChartSettings, newChartSettings) ||
      (prevProps.showTrend !== showTrend) ||
      (prevProps.showCyclical !== showCyclical) ||
      !_.isEqual(prevProps.invertedDims, invertedDims) ||
      prevProps.showArithmeticMean !== showArithmeticMean ||
      !_.isEqual(prevProps.arithmeticMeanDims, arithmeticMeanDims) ||
      !_.isEqual(prevProps.arithmeticMeans, arithmeticMeans)
    ) {

      let dimensionValueTree = this.state.dimensionValueTree;

      if (prevProps.jsonStat !== jsonStat || !_.isEqual(prevProps.layout, layout)) {
        dimensionValueTree = getDimensionValueTree(jsonStat, layout);
        this.setState({dimensionValueTree: dimensionValueTree});
      }

      const data = getData(
        type,
        jsonStat,
        layout,
        dimensionValueTree,
        timePeriodsByFreq,
        labelFormat,
        newChartSettings,
        showTrend,
        showCyclical,
        t,
        defaultChartColor,
        invertedDims
      );
      this.setState({data: data});

      const options = getOptions(
        type,
        jsonStat,
        layout,
        data,
        labelFormat,
        getFormattedDimensionLabel(jsonStat, null, layout.primaryDim[0], labelFormat, t),
        decimalSeparator,
        decimalPlaces,
        newChartSettings,
        disableWheelZoom,
        showTrend,
        showCyclical,
        showArithmeticMean,
        arithmeticMeanDims,
        arithmeticMeans,
        defaultLanguage,
        languages,
        t
      );

      this.myChart.config.type = getChartType(type);
      this.myChart.data = data;
      this.myChart.options = options;
      this.myChart.update();
    }
  }

  componentWillUnmount() {

    const {
      chartId
    } = this.state;

    window.ChartJs[chartId] = null;
  }

  render() {

    const {
      chartId,
      data
    } = this.state;

    const {
      t,
      classes,
      type,
      layout
    } = this.props;

    const isInvalidPyramid = (type === CHART_TYPE_PYRAMID && ((layout.secondaryDim || []).length !== 1 || (layout.secondaryDimValues || []).length !== 2));
    const dataCount = (data?.datasets || []).reduce((acc, {data}) => acc + data.length, 0);
    const isChartEmpty = dataCount === 0;

    const isChartVisible = (!isInvalidPyramid && !isChartEmpty);

    return (
      <div className={classes.root} aria-hidden={true}>
        {!isChartVisible && (
          <CustomEmpty
            text={isChartEmpty
              ? t("components.chart.noDataToDisplay")
              : t("components.chart.invalidPyramid")
            }
          />
        )}
        <canvas
          id={chartId}
          ref={this.chartRef}
          style={{
            display: isChartVisible ? "block" : "none"
          }}
        />
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(state => ({
    defaultLanguage: state.app.language,
    languages: state.app.languages,
    defaultChartColor: state.appConfig.chartConfig.defaultChartColor
  }))
)(Chart);