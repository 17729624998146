import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomDialogTitle from "../custom-dialog-title";
import Button from "@material-ui/core/Button";
import AttributeList from "../attribute-list";
import {getDatasetAttributeMap, getSeriesAttributeMap} from "../../utils/jsonStat";
import {DECIMAL_SEPARATOR_DEFAULT} from "../../utils/formatters";
import {connect} from "react-redux";
import {compose} from "redux";
import {validateI18nObj} from "../../utils/i18n";
import DataViewerTimings from "./Timings";
import ViewBuilder from "./ViewBuilder";
import {ViewerMode} from "../../state/dataset/constants";
import SingleViewerTemplateBuilder from "./single-viewer/TemplateBuilder";
import MultiViewerTemplateBuilder from "./multi-viewer/TemplateBuilder";
import {getCriteriaArrayFromObject} from "../../utils/criteria";
import {
  fetchDatasetSdmxQuery,
  hideDatasetSdmxQuery,
  hideDatasetViewError,
  hideDatasetViewTemplate,
  setDatasetViewerMode,
  showDatasetSdmxQuery,
  showDatasetViewTemplate,
  submitDatasetDownload,
  submitDatasetViewTemplate
} from "../../state/dataset/datasetActions";
import FullscreenDialog from "../fullscreen-dialog";
import Query from "../query";
import {isValidIntegerInInclusiveRange} from "../../utils/validator";
import Call from "../../hocs/call";
import {isValidTemplateDefaultView, viewersFactory} from "./constant";
import {getViewTemplateLayoutsFromChartSettings, getViewTemplateLayoutsFromMapSettings} from "../../utils/viewTemplate";
import SingleViewerHeader from "./single-viewer/Header";
import MultiViewerHeader from "./multi-viewer/Header";
import ButtonSelect from "../button-select";
import HelpIcon from '@material-ui/icons/Help';
import {MERGED_DATASETS_TITLE_SEPARATOR} from "../../utils/constants";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    width: "100%"
  },
  queryContainer: {
    '& > *': {
      marginBottom: theme.spacing(2)
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  headerWrapper: {
    display: "inline-block",
    verticalAlign: "top"
  },
  selectorWrapper: {
    display: "inline-block",
    verticalAlign: "top"
  },
  selector: {
    display: "none",
    width: 48
  }
});

const mapStateToProps = state => ({
  themeConfig: state.app.themeConfig,
  exportConfig: state.hub.hub.exportConfig,
  user: state.user,
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  nodes: state.hub.nodes,
  node: state.node,
  nodeCode: state.node.code,
  jsonStat: state.dataset.dataset,
  viewerIdx: state.dataset.viewerIdx,
  territoryDimCodelist: state.dataset.territoryDimCodelist,
  timeDim: state.dataset.timeDim,
  isTableVisible: state.dataset.isTableVisible,
  isMapVisible: state.dataset.isMapVisible,
  isChartVisible: state.dataset.isChartVisible,
  chartType: state.dataset.chartType,
  view: state.dataset.view,
  template: state.dataset.template,
  hasViewLayout: state.dataset.hasViewLayout,
  hasTemplateLayout: state.dataset.hasTemplateLayout,
  hasAnnotationLayout: state.dataset.hasAnnotationLayout,
  tableLayout: state.dataset.tableLayout,
  mapLayout: state.dataset.mapLayout,
  chartLayout: state.dataset.chartLayout,
  timePeriodsByFreq: state.dataset.timePeriodsByFreq,
  labelFormat: state.dataset.labelFormat,
  temporalDimOrder: state.dataset.temporalDimOrder,
  enableVariation: state.dataset.enableVariation,
  showTrend: state.dataset.showTrend,
  showCyclical: state.dataset.showCyclical,
  criteria: state.dataset.criteria,
  decimalSeparator: state.dataset.decimalSeparator,
  decimalPlaces: state.dataset.decimalPlaces,
  tableEmptyChar: state.dataset.tableEmptyChar,
  chartSettings: state.dataset.chartSettings,
  mapSettings: state.dataset.mapSettings,
  timings: state.dataset.timings,
  isViewVisible: state.dataset.isViewVisible,
  isViewErrorVisible: state.dataset.isViewErrorVisible,
  viewErrorMessage: state.dataset.viewErrorMessage,
  isTemplateVisible: state.dataset.isTemplateVisible,
  isQueryVisible: state.dataset.isQueryVisible,
  structureQuery: state.dataset.structureQuery,
  dataQuery: state.dataset.dataQuery,
  detailLevel: state.dataset.detailLevel,
  territories: state.dataset.territories,
  indicators: state.dataset.indicators,
  showArithmeticMean: state.dataset.showArithmeticMean,
  showStandardDeviation: state.dataset.showStandardDeviation,
  showCoefficientOfVariation: state.dataset.showCoefficientOfVariation,
  additionalDatasets: state.dataset.additionalDatasets,
  isTableEnabled: state.dataset.isTableEnabled,
  isMapEnabled: state.dataset.isMapEnabled,
  isChartEnabled: state.dataset.isChartEnabled
});

const mapDispatchToProps = dispatch => ({
  onViewTemplateShow: isView => dispatch(showDatasetViewTemplate(isView)),
  onViewTemplateHide: isView => dispatch(hideDatasetViewTemplate(isView)),
  onViewTemplateSubmit: (nodeId, viewTemplate, isView) => dispatch(submitDatasetViewTemplate(nodeId, viewTemplate, isView)),
  onViewErrorHide: isView => dispatch(hideDatasetViewError(isView)),
  onDownloadSubmit: (nodeId, datasetId, datasetTitle, criteria, layout, format, extension, zipped, params, defaultLanguage, languages, t) =>
    dispatch(submitDatasetDownload(nodeId, datasetId, datasetTitle, criteria, layout, format, extension, zipped, params, defaultLanguage, languages, t)),
  onQueryShow: () => dispatch(showDatasetSdmxQuery()),
  onQueryHide: () => dispatch(hideDatasetSdmxQuery()),
  fetchQuery: ({nodeId, datasetId, criteria, datasetTitle}) =>
    dispatch(fetchDatasetSdmxQuery(nodeId, datasetId, criteria, datasetTitle)),
  setViewerMode: viewerMode => dispatch(setDatasetViewerMode(viewerMode))
});

function DataViewerHeader({classes, ...props}) {

  const {
    nodeId,
    datasetId,
    datasetTitle,
    viewId,
    onRender,

    themeConfig,
    defaultLanguage,
    languages,
    jsonStat,
    viewerMode,
    viewerIdx,
    territoryDimCodelist,
    timeDim,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    chartType,
    view,
    template,
    hasTemplateLayout,
    tableLayout,
    mapLayout,
    chartLayout,
    timePeriodsByFreq,
    labelFormat,
    temporalDimOrder,
    showTrend,
    showCyclical,
    criteria,
    decimalSeparator,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    timings,
    isViewVisible,
    isViewErrorVisible,
    viewErrorMessage,
    isTemplateVisible,
    isQueryVisible,
    structureQuery,
    dataQuery,
    detailLevel,
    territories,
    indicators,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    additionalDatasets,
    isTableEnabled,
    isMapEnabled,
    isChartEnabled,

    onViewTemplateShow,
    onViewTemplateHide,
    onViewTemplateSubmit,
    onViewErrorHide,
    onQueryHide,
    fetchQuery,
    setViewerMode
  } = props;

  const {t} = useTranslation();

  const viewers = viewersFactory(t);

  const [tempView, setTempView] = useState(null);
  const [tempTemplate, setTempTemplate] = useState(null);

  const [datasetAttributeMap, setDatasetAttributeMap] = useState({});
  const [seriesAttributeMap, setSeriesAttributeMap] = useState({});

  const [attributes, setAttributes] = useState(null);
  const [notes, setNotes] = useState(null);
  const [isTimingsVisible, setTimingsVisibility] = useState(false);

  const [isUpdatingView, setIsUpdatingView] = useState(false);

  useEffect(() => {
    setDatasetAttributeMap(getDatasetAttributeMap(jsonStat));
  }, [jsonStat]);

  useEffect(() => {
    setSeriesAttributeMap(getSeriesAttributeMap(jsonStat, labelFormat));
  }, [jsonStat, labelFormat]);

  useEffect(() => {
    if (onRender) {
      onRender();
    }
  });

  useEffect(() => {
    const viewerModeSelectorWidth = $("#data-viewer__header__actions").outerWidth(true);
    $("#data-viewer__header__header").width(`calc(100% - ${viewerModeSelectorWidth}px)`);
  }, []);

  const getCompleteViewTemplate = (viewTemplate, isUpdating) => ({
    defaultView: "table",
    enableCriteria: true,
    enableLayout: true,
    enableVariation: false,
    hiddenDimensions: [],
    ...viewTemplate,
    datasetId: datasetId,
    title: isUpdating
      ? viewTemplate.title
      : {[defaultLanguage]: [datasetTitle, ...additionalDatasets.map(({datasetTitle}) => datasetTitle)].join(` ${MERGED_DATASETS_TITLE_SEPARATOR} `)},
    mode: viewerMode,
    criteria: getCriteriaArrayFromObject(criteria),
    layouts: {
      ...viewTemplate?.layouts,
      detailLevel: detailLevel,
      mapDetailLevel: undefined,
      territories: territories,
      labelFormat: labelFormat,
      temporalDimOrder: temporalDimOrder,
      showTrend: showTrend,
      showCyclical: showCyclical,
      isTableVisible: isTableVisible,
      isMapVisible: isMapVisible,
      isChartVisible: isChartVisible,
      chartType: chartType
    },
    decimalSeparator: {
      ...(viewTemplate?.decimalSeparator || {}),
      [defaultLanguage]: (decimalSeparator !== null && decimalSeparator !== undefined)
        ? decimalSeparator
        : DECIMAL_SEPARATOR_DEFAULT
    },
    decimalNumber: (decimalPlaces === null || decimalPlaces === undefined || decimalPlaces < 0)
      ? ""
      : decimalPlaces
  });

  const handleViewOpen = isUpdating => {
    setIsUpdatingView(isUpdating);
    onViewTemplateShow(true);
    const completeView = getCompleteViewTemplate(view, isUpdating);
    setTempView({
      ...completeView,
      type: "view",
      defaultView: viewers[viewerIdx]?.type,
      layouts: {
        ...completeView.layouts,
        tableLayout: (viewerIdx === null || viewerIdx === 0) ? tableLayout : undefined,
        tableEmptyChar: (viewerIdx === null || viewerIdx === 0) ? tableEmptyChar : undefined,
        mapLayout: (viewerIdx === null || viewerIdx === 1) ? mapLayout : undefined,
        ...((viewerIdx === null || viewerIdx === 1) ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
        chartLayout: (viewerIdx === null || viewerIdx >= 2) ? chartLayout : undefined,
        ...((viewerIdx === null || viewerIdx >= 2) ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {}),
        showArithmeticMean: showArithmeticMean,
        showStandardDeviation: showStandardDeviation,
        showCoefficientOfVariation: showCoefficientOfVariation
      },
      indicatorsDefinition: JSON.stringify(indicators),
      additionalDatasets: JSON.stringify(additionalDatasets)
    });
  };

  const handleViewClose = () => {
    onViewTemplateHide(true);
    setTempView(null);
  };

  const handleViewSubmit = viewId => {
    onViewTemplateSubmit(
      nodeId,
      {
        ...tempView,
        viewTemplateId: viewId ? Number(viewId) : undefined,
        layouts: JSON.stringify(tempView.layouts)
      },
      true
    );
  };

  const handleTemplateOpen = isUpdating => {
    onViewTemplateShow(false);
    const completeTemplate = getCompleteViewTemplate(template, isUpdating);
    setTempTemplate({
      ...completeTemplate,
      type: "template",
      layouts: {
        ...completeTemplate.layouts,
        tableLayout: tableLayout,
        tableDefaultLayout: "custom",
        tableEmptyChar: tableLayout ? tableEmptyChar : undefined,
        mapLayout: mapLayout,
        ...(mapLayout ? getViewTemplateLayoutsFromMapSettings(mapSettings) : {}),
        chartLayout: chartLayout,
        ...(chartLayout ? getViewTemplateLayoutsFromChartSettings(chartSettings) : {})
      }
    });
  };

  const handleTemplateClose = () => {
    onViewTemplateHide(false);
    setTempTemplate(null);
  };

  const handleTemplateSubmit = () => {
    onViewTemplateSubmit(
      nodeId,
      {
        ...tempTemplate,
        layouts: JSON.stringify({
          ...tempTemplate.layouts,
          tableDefaultLayout: undefined
        }),
        decimalNumber: (tempTemplate.decimalNumber === null || tempTemplate.decimalNumber === undefined || tempTemplate.decimalNumber === "")
          ? -1
          : tempTemplate.decimalNumber
      },
      false
    );
  };

  const handleNotesShow = notes => {
    setNotes(notes);
  };

  const handleNotesHide = () => {
    setNotes(null);
  };

  const handleAttributesShow = attributes => {
    setAttributes(attributes);
  };

  const handleAttributesHide = () => {
    setAttributes(null);
  };

  const handleTimingsShow = () => {
    setTimingsVisibility(true);
  };

  const handleTimingsHide = () => {
    setTimingsVisibility(false);
  };

  const isValidTemplateDecimalNumber = () => (
    (tempTemplate?.decimalNumber || "").length === 0 ||
    isValidIntegerInInclusiveRange(tempTemplate?.decimalNumber, 0, 20)
  );

  const canSaveTemplate = () => (
    validateI18nObj(tempTemplate?.title) &&
    validateI18nObj(tempTemplate?.decimalSeparator) &&
    isValidTemplateDecimalNumber() &&
    isValidTemplateDefaultView(tempTemplate, isTableEnabled, isMapEnabled, isChartEnabled)
  );

  const TemplateDialogTitle = (
    <CustomDialogTitle onClose={handleTemplateClose}>
      {hasTemplateLayout
        ? t("scenes.dataViewer.header.dialogs.template.title.update")
        : t("scenes.dataViewer.header.dialogs.template.title.create")
      }
    </CustomDialogTitle>
  );

  const TemplateDialogActions = (
    <DialogActions>
      <Button onClick={handleTemplateClose}>
        {t("commons.confirm.cancel")}
      </Button>
      <Button
        color="primary"
        autoFocus
        onClick={handleTemplateSubmit}
        disabled={!canSaveTemplate()}
      >
        {t("commons.confirm.save")}
      </Button>
    </DialogActions>
  );

  return (
    <div className={classes.root}>
      <div id="data-viewer__header__header" className={classes.headerWrapper}>
        {(() => {
          switch (viewerMode) {
            case ViewerMode.SingleViewer: {
              return (
                <SingleViewerHeader
                  {...props}
                  datasetAttributeMap={datasetAttributeMap}
                  seriesAttributeMap={seriesAttributeMap}
                  handleNotesShow={handleNotesShow}
                  handleAttributesShow={handleAttributesShow}
                  handleTimingsShow={handleTimingsShow}
                  handleViewOpen={handleViewOpen}
                  handleTemplateOpen={handleTemplateOpen}
                />
              );
            }
            case ViewerMode.MultiViewer: {
              return (
                <MultiViewerHeader
                  {...props}
                  datasetAttributeMap={datasetAttributeMap}
                  seriesAttributeMap={seriesAttributeMap}
                  handleNotesShow={handleNotesShow}
                  handleAttributesShow={handleAttributesShow}
                  handleTimingsShow={handleTimingsShow}
                  handleViewOpen={handleViewOpen}
                  handleTemplateOpen={handleTemplateOpen}
                />
              );
            }
            default: {
              return null;
            }
          }
        })()}
      </div>
      <div id="data-viewer__header__actions" className={classes.selectorWrapper}>
        {themeConfig.isMultiViewer && (
          <div id="dataset-viewer-mode-btn" className={classes.selector}>
            <ButtonSelect
              icon={<HelpIcon/>}
              ariaLabel={t("scenes.dataViewer.header.action.viewerMode.label")}
              tooltip={t("scenes.dataViewer.header.action.viewerMode.tooltip")}
              color="default"
              onChange={({mode}) => setViewerMode(mode)}
            >
              <div data-value={{mode: ViewerMode.SingleViewer}}>
                {ViewerMode.SingleViewer}
              </div>
              <div data-value={{mode: ViewerMode.MultiViewer}}>
                {ViewerMode.MultiViewer}
              </div>
            </ButtonSelect>
          </div>
        )}
      </div>

      <Dialog
        open={attributes !== null}
        fullWidth
        maxWidth="md"
        onClose={handleAttributesHide}
      >
        <CustomDialogTitle onClose={handleAttributesHide}>
          {t("scenes.dataViewer.header.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <AttributeList
            datasetAttributes={attributes?.datasetAttributes || []}
            seriesAttributes={attributes?.seriesAttributes || []}
            labelFormat={labelFormat}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAttributesHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isTimingsVisible}
        onClose={handleTimingsHide}
      >
        <DialogContent style={{width: 400}}>
          <DataViewerTimings
            timings={timings}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleTimingsHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isViewVisible}
        maxWidth="sm"
        fullWidth
        onClose={handleViewClose}
      >
        <CustomDialogTitle onClose={handleViewClose}>
          {isUpdatingView
            ? t("scenes.dataViewer.header.dialogs.view.title.update")
            : t("scenes.dataViewer.header.dialogs.view.title.create")
          }
        </CustomDialogTitle>
        <DialogContent>
          <ViewBuilder
            view={tempView}
            onChange={setTempView}
            criteria={criteria}
            timeDim={timeDim}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => handleViewSubmit(isUpdatingView ? viewId : null)}
            disabled={!tempView || !validateI18nObj(tempView.title)}
          >
            {t("commons.confirm.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isViewErrorVisible}
        maxWidth="md"
        onClose={onViewErrorHide}
      >
        <CustomDialogTitle onClose={onViewErrorHide}>
          {t("scenes.dataViewer.header.dialogs.duplicateViewError.title")}
        </CustomDialogTitle>
        <DialogContent>
          {viewErrorMessage && (
            <Fragment>
              {t("scenes.dataViewer.header.dialogs.duplicateViewError.content") + ": "}
              <b>{Object.keys(viewErrorMessage).join(", ")}</b>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onViewErrorHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      {viewerMode === ViewerMode.SingleViewer && (
        <FullscreenDialog
          open={isTemplateVisible}
          onClose={handleTemplateClose}
        >
          {TemplateDialogTitle}
          <DialogContent>
            <SingleViewerTemplateBuilder
              defaultLanguage={defaultLanguage}
              languages={languages}
              nodeId={nodeId}
              template={tempTemplate}
              onChange={setTempTemplate}
              viewers={viewers.filter(({hidden}) => hidden !== true)}
              jsonStat={jsonStat}
              timePeriodsByFreq={timePeriodsByFreq}
              labelFormat={labelFormat}
              tableLayout={tableLayout}
              chartLayout={chartLayout}
              mapLayout={mapLayout}
              criteria={criteria}
              territoryDimCodelist={territoryDimCodelist}
              timeDim={timeDim}
              isTableEnabled={isTableEnabled}
              isMapEnabled={isMapEnabled}
              isChartEnabled={isChartEnabled}
            />
          </DialogContent>
          {TemplateDialogActions}
        </FullscreenDialog>
      )}

      {viewerMode === ViewerMode.MultiViewer && (
        <Dialog
          open={isTemplateVisible}
          maxWidth="sm"
          fullWidth
          onClose={handleTemplateClose}
        >
          {TemplateDialogTitle}
          <DialogContent>
            <MultiViewerTemplateBuilder
              template={tempTemplate}
              onChange={setTempTemplate}
              criteria={criteria}
              timeDim={timeDim}
            />
          </DialogContent>
          {TemplateDialogActions}
        </Dialog>
      )}

      <Dialog
        open={notes !== null}
        fullWidth
        maxWidth="md"
        onClose={handleNotesHide}
      >
        <CustomDialogTitle onClose={handleNotesHide}>
          {t("scenes.dataViewer.header.dialogs.notes.title")}
        </CustomDialogTitle>
        <DialogContent>
          {notes}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNotesHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isQueryVisible}
        fullWidth
        maxWidth="md"
        onClose={onQueryHide}
      >
        <CustomDialogTitle onClose={onQueryHide}>
          {t("scenes.dataViewer.header.dialogs.query.title")}
        </CustomDialogTitle>
        <DialogContent className={classes.queryContainer}>
          <Call
            cb={fetchQuery}
            cbParam={{nodeId, datasetId, criteria, datasetTitle}}
            disabled={structureQuery !== null && dataQuery !== null}
          >
            {structureQuery && (
              <Query
                title={t("scenes.dataViewer.header.dialogs.query.content.structureQuery.title")}
                query={structureQuery}
              />
            )}
            {dataQuery && (
              <Query
                title={t("scenes.dataViewer.header.dialogs.query.content.dataQuery.title")}
                query={dataQuery}
              />
            )}
          </Call>
        </DialogContent>
        <DialogActions>
          <Button onClick={onQueryHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DataViewerHeader);