import React, {Fragment, useEffect, useState} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import I18nTextField from "../../i18n-text-field";
import Table from "../../table";
import {localizeI18nObj} from "../../../utils/i18n";
import CustomEmpty from "../../custom-empty";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {v4 as uuidv4} from 'uuid';
import Chart from "../../chart";
import Map from "../../map";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import {VARIATION_DIMENSION_KEY} from "../../../utils/jsonStat";
import {isValidIntegerInInclusiveRange} from "../../../utils/validator";
import {getChartSettingsFromViewTemplateLayouts, getMapSettingsFromViewTemplateLayouts} from "../../../utils/viewTemplate";
import Alert from "@material-ui/lab/Alert";
import {CRITERIA_FILTER_TYPE_PERIODS} from "../../../utils/criteria";
import {isValidTemplateDefaultView} from "../constant";
import {
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC,
  TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET
} from "../../temporal-dim-order-selector/constants";

const $ = window.jQuery;

const styles = theme => ({
  card: {
    padding: theme.spacing(2)
  },
  viewer: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  viewerTable: {
    width: "100%",
    height: "calc(100% - 16px - 64px)",
    marginTop: 16
  },
  viewerChart: {
    width: "100%",
    height: "calc(100% - 16px - 64px)",
    position: "relative",
    marginTop: 16
  },
  tabContent: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 48px)",
    paddingTop: 16
  }
});

const handleStyle = () => {
  const alertHeight = $("#template-builder__alert").outerHeight(true) || 0;
  const tabsHeight = $("#template-builder__tabs").outerHeight(true) || 0;
  $("#template-builder__tab-content").height(`calc(100% - ${alertHeight + tabsHeight}px)`);
};

function SingleViewerTemplateBuilder(props) {
  const {
    classes,
    defaultLanguage,
    languages,
    nodeId,
    template,
    onChange,
    viewers,
    jsonStat,
    timePeriodsByFreq,
    labelFormat,
    tableLayout,
    chartLayout,
    mapLayout,
    criteria,
    timeDim,
    isTableEnabled,
    isMapEnabled,
    isChartEnabled
  } = props;

  const {t} = useTranslation();

  const [tabId, setTabId] = useState(0);

  const [mapId] = useState("map__" + uuidv4());

  const [chartType, setChartType] = useState("bar");

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle)
  }, []);

  useEffect(() => {
    handleStyle();
  });

  if (!template) {
    return <span/>;
  }

  const hiddenDimensions = _.uniq([
    ...(jsonStat?.id || []).filter(dim => dim !== VARIATION_DIMENSION_KEY),
    ...(template?.hiddenDimensions || [])
  ]);

  const tableLockedDimensions = _.uniq([
    ...(jsonStat?.id || []).filter(dim => dim !== VARIATION_DIMENSION_KEY),
    ...(template?.layouts?.tableLockedDimensions || [])
  ]);

  const graphLockedDimensions = _.uniq([
    ...(jsonStat?.id || []).filter(dim => dim !== VARIATION_DIMENSION_KEY),
    ...(template?.layouts?.graphLockedDimensions || [])
  ]);

  const mapSettings = getMapSettingsFromViewTemplateLayouts(template.layouts);
  const chartSettings = getChartSettingsFromViewTemplateLayouts(template.layouts);

  const isTimeDimInTable = !tableLayout.filters.includes(timeDim);
  const isTimeDimInChart = !chartLayout.filters.includes(timeDim);

  return (
    <Fragment>
      {criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS && (
        <Alert
          id="template-builder__alert"
          severity="warning"
        >
          {t("scenes.dataViewer.warnings.viewTemplateLastNPeriods.label")}
        </Alert>
      )}
      <Box id="template-builder__tabs">
        <Tabs
          value={tabId}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event, newValue) => {
            if (newValue === 0) {
              document.getElementById("template-builder__tab-content").style.overflowY = "auto";
            } else {
              document.getElementById("template-builder__tab-content").style.overflowY = "hidden";
            }
            setTabId(newValue);
          }}
        >
          <Tab key={0} label={t("scenes.dataViewer.templateBuilder.tabs.options.label")}/>
          <Tab key={1} label={t("scenes.dataViewer.templateBuilder.tabs.table.label")}/>
          <Tab key={2} label={t("scenes.dataViewer.templateBuilder.tabs.chart.label")}/>
          {mapLayout && <Tab key={3} label={t("scenes.dataViewer.templateBuilder.tabs.map.label")}/>}
        </Tabs>
      </Box>
      <div id="template-builder__tab-content" className={classes.tabContent}>
        {tabId === 0 && (
          <Fragment>
            <Card variant="outlined">
              <CardHeader
                title={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.title")}
                titleTypographyProps={{variant: "subtitle1"}}
              />
              <Grid container spacing={3} className={classes.card}>
                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.field}>
                    <I18nTextField
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.title")}
                      required
                      variant="outlined"
                      value={template.title}
                      onChange={value => onChange({...template, title: value})}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      select
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.defaultView")}
                      defaultValue="table"
                      value={template.defaultView}
                      variant="outlined"
                      onChange={ev => onChange({...template, defaultView: ev.target.value})}
                      SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                      error={!isValidTemplateDefaultView(template, isTableEnabled, isMapEnabled, isChartEnabled)}
                      helperText={!isValidTemplateDefaultView(template, isTableEnabled, isMapEnabled, isChartEnabled)
                        ? t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.invalidDefaultView")
                        : null
                      }
                    >
                      {viewers.map(({type, title}, idx) =>
                        <MenuItem key={idx} value={type}>{title}</MenuItem>
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.enableCriteria")}
                    className={classes.field}
                    control={
                      <Checkbox
                        checked={template.enableCriteria}
                        onChange={(ev, value) => onChange({...template, enableCriteria: value})}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.enableLayout")}
                    className={classes.field}
                    control={
                      <Checkbox
                        checked={template.enableLayout}
                        onChange={(ev, value) => onChange({...template, enableLayout: value})}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.enableVariation")}
                    className={classes.field}
                    control={
                      <Checkbox
                        checked={template.enableVariation}
                        onChange={(ev, value) => onChange({...template, enableVariation: value})}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}/>
                {isTableEnabled && (
                  <Grid item xs={3} key="checkBoxTable">
                    <FormControlLabel
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.disableTable")}
                      className={classes.field}
                      control={
                        <Checkbox
                          checked={template.layouts.disableTable || false}
                          onChange={(ev,value) => onChange({
                            ...template,
                            layouts: {
                              ...template.layouts,
                              disableTable: value
                            }
                          })}
                        />
                      }
                    />
                  </Grid>
                )}
                {isChartEnabled && (
                  <Grid item xs={3} key="checkBoxChart">
                    <FormControlLabel
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.disableChart")}
                      className={classes.field}
                      control={
                        <Checkbox
                          checked={template.layouts.disableChart || false}
                          onChange={(ev,value) => onChange({
                            ...template,
                            layouts: {
                              ...template.layouts,
                              disableChart: value
                            }
                          })}
                        />
                      }
                    />
                  </Grid>
                )}
                {isMapEnabled && (
                  <Grid item xs={3} key="checkBoxMap">
                    <FormControlLabel
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.disableMap")}
                      className={classes.field}
                      control={
                        <Checkbox
                          checked={template.layouts.disableMap || false}
                          onChange={(ev,value) => onChange({
                            ...template,
                            layouts: {
                              ...template.layouts,
                              disableMap: value
                            }
                          })}
                        />
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={3 + (([isTableEnabled, isMapEnabled, isChartEnabled].filter(el => !el) || []).length * 3)}/>
                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.decimalPlaces.label")}
                      value={template.decimalNumber}
                      variant="outlined"
                      type="number"
                      required
                      onChange={ev => onChange({...template, decimalNumber: ev.target.value})}
                      error={(template.decimalNumber || "").length > 0 && !isValidIntegerInInclusiveRange(template.decimalNumber, 0, 20)}
                      helperText={(template.decimalNumber || "").length > 0 && !isValidIntegerInInclusiveRange(template.decimalNumber, 0, 20)
                        ? t("commons.validation.inRange", {min: 0, max: 20})
                        : null
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth className={classes.field}>
                    <I18nTextField
                      select
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.decimalSeparator.label")}
                      value={template.decimalSeparator}
                      defaultValue="."
                      variant="outlined"
                      required
                      onChange={value => onChange({...template, decimalSeparator: value})}
                      SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                    >
                      <MenuItem value=".">
                        {t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.decimalSeparator.values.dot")}
                      </MenuItem>
                      <MenuItem value=",">
                        {t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.decimalSeparator.values.comma")}
                      </MenuItem>
                    </I18nTextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    variant="outlined"
                    options={hiddenDimensions}
                    value={template.hiddenDimensions || []}
                    onChange={(e, values) => {
                      onChange({...template, hiddenDimensions: values})
                    }}
                    renderInput={params => (
                      <FormControl fullWidth className={classes.field}>
                        <TextField
                          {...params}
                          label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.hiddenDimensions.label")}
                          placeholder={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.hiddenDimensions.placeholder")}
                          variant="outlined"
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      select
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.temporalDimOrder.label")}
                      value={template.layouts.temporalDimOrder || TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET}
                      variant="outlined"
                      onChange={ev => onChange({
                        ...template,
                        layouts: {
                          ...template.layouts,
                          temporalDimOrder: ev.target.value !== TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET
                            ? ev.target.value
                            : null
                        }
                      })}
                      SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                    >
                      <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_UNSET}>
                        {t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.temporalDimOrder.values.unset")}
                      </MenuItem>
                      <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_ASC}>
                        {t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.temporalDimOrder.values.asc")}
                      </MenuItem>
                      <MenuItem value={TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC}>
                        {t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.temporalDimOrder.values.desc")}
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>
            <Card variant="outlined" style={{marginTop: 16}}>
              <CardHeader
                title={t("scenes.dataViewer.templateBuilder.tabs.options.cards.table.title")}
                titleTypographyProps={{variant: "subtitle1"}}
              />
              <Grid container spacing={3} className={classes.card}>
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.table.form.emptyCellPlaceholder")}
                      value={template.layouts.tableEmptyChar}
                      variant="outlined"
                      required
                      onChange={ev => onChange({
                        ...template,
                        layouts: {
                          ...template.layouts,
                          tableEmptyChar: ev.target.value
                        }
                      })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} key="checkBoxLockTableDimensions">
                  <FormControlLabel
                    label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.table.form.lockDimensions")}
                    className={classes.field}
                    control={
                      <Checkbox
                        checked={template.layouts.lockTableDimensions || false}
                        onChange={(ev,value) => onChange({
                          ...template,
                          layouts: {
                            ...template.layouts,
                            lockTableDimensions: value
                          }
                        })}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={9}>
                  <Autocomplete
                    multiple
                    variant="outlined"
                    options={tableLockedDimensions}
                    value={template.layouts.tableLockedDimensions || []}
                    onChange={(ev,values) => onChange({
                    ...template,
                    layouts: {
                      ...template.layouts,
                      tableLockedDimensions: values
                    }
                    })}
                    renderInput={params => (
                      <FormControl fullWidth className={classes.field}>
                        <TextField
                          {...params}
                          label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.tableLockedDimensions.label")}
                          placeholder={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.tableLockedDimensions.placeholder")}
                          variant="outlined"
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card variant="outlined" style={{marginTop: 16}}>
              <CardHeader
                title={t("scenes.dataViewer.templateBuilder.tabs.options.cards.graph.title")}
                titleTypographyProps={{variant: "subtitle1"}}
              />
              <Grid container spacing={3} className={classes.card}>
                <Grid item xs={3} key="checkBoxLockGraphDimensions">
                  <FormControlLabel
                    label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.graph.form.lockDimensions")}
                    className={classes.field}
                    control={
                      <Checkbox
                        checked={template.layouts.lockGraphDimensions || false}
                        onChange={(ev,value) => onChange({
                          ...template,
                          layouts: {
                            ...template.layouts,
                            lockGraphDimensions: value
                          }
                        })}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={9}>
                  <Autocomplete
                    multiple
                    variant="outlined"
                    options={graphLockedDimensions}
                    value={template.layouts.graphLockedDimensions || []}
                    onChange={(ev,values) => onChange({
                    ...template,
                    layouts: {
                      ...template.layouts,
                      graphLockedDimensions: values
                    }
                    })}
                    renderInput={params => (
                      <FormControl fullWidth className={classes.field}>
                        <TextField
                          {...params}
                          label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.graphLockedDimensions.label")}
                          placeholder={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.graphLockedDimensions.placeholder")}
                          variant="outlined"
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Card>
          </Fragment>
        )}
        {tabId === 1 && jsonStat && tableLayout && (
          <div className={classes.viewer}>
            {isTableEnabled && !template.layouts.disableTable
              ? (
                <Fragment>
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      select
                      label={t("scenes.dataViewer.templateBuilder.tabs.table.form.defaultLayout.label")}
                      value={template.layouts.tableDefaultLayout}
                      variant="outlined"
                      onChange={ev => onChange({
                        ...template,
                        layouts: {
                          ...template.layouts,
                          tableDefaultLayout: ev.target.value,
                          tableLayout: ev.target.value === "custom"
                            ? tableLayout
                            : null
                        }
                      })}
                      SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                    >
                      <MenuItem value="custom">
                        {t("scenes.dataViewer.templateBuilder.tabs.table.form.defaultLayout.values.custom")}
                      </MenuItem>
                      <MenuItem value="default">
                        {t("scenes.dataViewer.templateBuilder.tabs.table.form.defaultLayout.values.default")}
                      </MenuItem>
                    </TextField>
                  </FormControl>
                  <div className={classes.viewerTable}>
                    {template.layouts.tableDefaultLayout === "custom"
                      ? (
                        <Table
                          jsonStat={jsonStat}
                          layout={tableLayout}
                          labelFormat={labelFormat}
                          decimalSeparator={localizeI18nObj(template.decimalSeparator, defaultLanguage, languages)}
                          decimalPlaces={template.decimalNumber}
                          emptyChar={template.layouts.tableEmptyChar}
                          invertedDims={(template.layouts.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && isTimeDimInTable)
                            ? [timeDim]
                            : null
                          }
                        />
                      )
                      : (
                        <CustomEmpty
                          text={t("scenes.dataViewer.templateBuilder.tabs.table.form.defaultLayout.defaultPlaceholder")}
                        />
                      )
                    }
                  </div>
                </Fragment>
              )
              : (
                <CustomEmpty
                  text={t("scenes.dataViewer.templateBuilder.tabs.table.disabledViewer")}
                />
              )
            }
          </div>
        )}
        {tabId === 2 && jsonStat && chartLayout && (
          <div className={classes.viewer}>
            {isChartEnabled && !template.layouts.disableChart
              ? (
                <Fragment>
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      select
                      label={t("scenes.dataViewer.templateBuilder.tabs.chart.cards.form.chartType.label")}
                      defaultValue="table"
                      value={chartType}
                      variant="outlined"
                      onChange={ev => setChartType(ev.target.value)}
                      SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
                    >
                      {viewers.slice(2).map(({type, title}, idx) =>
                        <MenuItem key={idx} value={type}>{title}</MenuItem>
                      )}
                    </TextField>
                  </FormControl>
                  <div className={classes.viewerChart}>
                    <Chart
                      type={chartType}
                      jsonStat={jsonStat}
                      layout={chartLayout}
                      timePeriodsByFreq={timePeriodsByFreq}
                      labelFormat={labelFormat}
                      decimalSeparator={localizeI18nObj(template.decimalSeparator, defaultLanguage, languages)}
                      decimalPlaces={template.decimalNumber}
                      chartSettings={chartSettings}
                      invertedDims={(template.layouts.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && isTimeDimInChart)
                        ? [timeDim]
                        : null
                      }
                    />
                  </div>
                </Fragment>
              )
              : (
                <CustomEmpty
                  text={t("scenes.dataViewer.templateBuilder.tabs.chart.disabledViewer")}
                />
              )}
          </div>
        )}
        {tabId === 3 && jsonStat && mapLayout && (
          <div className={classes.viewer}>
            {isMapEnabled && !template.layouts.disableMap
              ? (
                <Map
                  mapId={mapId}
                  nodeId={nodeId}
                  jsonStat={jsonStat}
                  layout={mapLayout}
                  labelFormat={labelFormat}
                  decimalSeparator={localizeI18nObj(template.decimalSeparator, defaultLanguage, languages)}
                  decimalPlaces={template.decimalNumber}
                  defaultDetailLevel={template.layouts.detailLevel}
                  disableDetailLevelSelector
                  initialBaseLayer={mapSettings?.baseLayer}
                  defaultSettings={mapSettings}
                  disableSettings
                  disableBaseLayer
                />
              )
              : (
                <CustomEmpty
                  text={t("scenes.dataViewer.templateBuilder.tabs.map.disabledViewer")}
                />
              )
            }
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default withStyles(styles)(SingleViewerTemplateBuilder);