import React, {Fragment, useCallback, useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {v4 as uuidv4} from "uuid";
import withStyles from "@material-ui/core/styles/withStyles";
import {getDimensionValueFromIdx, getPreviewTableHtml, getTableHtml, TABLE_HEADER_MERGED, TABLE_HEADER_NORMAL} from "./utils";
import "./style.css";
import Scrollbars from "./Scrollbars";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../label-format-selector/constants";
import CustomEmpty from "../custom-empty";
import {sanitize} from "dompurify";
import {
  getAttributeLabel,
  getAttributeValueLabel,
  getDimensionAttributeMap,
  getFormattedDimensionLabel,
  getFormattedDimensionValueLabel,
  getMarginalDimensions,
  MARGINAL_DIMENSION_KEY,
  VARIATION_DIMENSION_KEY
} from "../../utils/jsonStat";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import {localizeI18nObj} from "../../utils/i18n";
import Popover from "@material-ui/core/Popover";
import ObservationFilterForm from "./ObservationFilterForm";
import DimensionFilterForm from "./DimensionFilterForm";
import BitSet from "bitset";
import SanitizedHTML from "../sanitized-html";

const $ = window.jQuery;

export const JSONSTAT_TABLE_FONT_SIZE_SM = "s";
export const JSONSTAT_TABLE_FONT_SIZE_MD = "m";
export const JSONSTAT_TABLE_FONT_SIZE_LG = "l";

const COLS_PER_PAGE = 30;
const ROWS_PER_PAGE = 50;

const SLIDER_SAFETY_MARGIN_PERCENTAGE = 20;

let isFirstRender = false;
let scrollToRow = false;
let isFiltering = false;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  tableContainer: {
    width: "100%",
    height: "100%"
  },
  table: {
    width: "100%",
    height: "100%"
  },
  filters: {
    width: 320,
    padding: 8,
    overflow: "hidden",
    "& .MuiOutlinedInput-input": {
      padding: 8
    },
    "& .MuiSelect-outlined": {
      paddingRight: 32
    }
  }
});

const mapStateToProps = ({app, appConfig}) => ({
  appLanguage: app.language,
  languages: app.languages,
  hiddenDimensionValueLabels: appConfig.hiddenDimensionValueLabels
});

const isElementVerticallyInContainer = (element, container) => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return (
    elementRect.top >= containerRect.top &&
    elementRect.bottom <= containerRect.bottom
  );
};

const isElementHorizontallyInContainer = (element, container) => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return (
    elementRect.left >= containerRect.left &&
    elementRect.right <= containerRect.right
  );
};

const valueSorter = (a, b, asc) => {
  if (a === b) {
    return 0;
  } else if (a === null || a === undefined || a === "") {
    return 1;
  } else if (b === null || b === undefined || b === "") {
    return -1;
  } else {
    return asc ? (a - b) : (b - a);
  }
};

function Table(props) {
  const {
    classes,

    appLanguage,
    languages,
    hiddenDimensionValueLabels,

    jsonStat,
    layout,
    labelFormat = LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
    customLabelFormats = null,
    dimensionValueModifiers = null,
    customDimensionLabels = null,
    fontSize = JSONSTAT_TABLE_FONT_SIZE_MD,
    isFullscreen,
    isPreview = false,
    removeEmptyLines = !isPreview,
    decimalSeparator,
    decimalPlaces,
    emptyChar,
    disableWheelZoom = false,
    showTrend = false,
    showCyclical = false,
    tableHeaderType = TABLE_HEADER_NORMAL,
    dimensionFilterValues,
    firstRowDimValues,
    highlightedRowsDimValues,
    rowHover = false,
    onRowClick,
    sortable = false,
    filterable = false,
    onFilter,
    scrollToLastRow = false,
    scrollToLastCol = false,
    isPointData = false,
    invertedDims,
    hierarchyOnlyAttributes,
    hideHierarchyOnlyRows,
    enableMeasuresOfSynthesisAndVariability = false,
    showArithmeticMean = false,
    showStandardDeviation = false,
    showCoefficientOfVariation = false,
    onPageGenerationComplete,
    onStructureGenerationComplete
  } = props;

  const {t} = useTranslation();

  const [uuid] = useState(uuidv4());

  const [tableSupportStructures, setTableSupportStructures] = useState(null);
  const [htmlTable, setHtmlTable] = useState("");

  const [row, setRow] = useState(null);
  const [col, setCol] = useState(null);

  const [visibleRowCount, setVisibleRowCount] = useState(1);
  const [visibleColCount, setVisibleColCount] = useState(1);

  const [order, setOrder] = useState([null, true]); // [colIdx, isOrderedAscending]
  const [filters, setFilters] = useState({}); // map with colIdx as key and filterStr as value
  const [filterColIdx, setFilterColIdx] = useState(null);
  const [filterDimId, setFilterDimId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isHorizontalScrollbarVisible, setHorizontalScrollbarVisibility] = useState(false);
  const [isVerticalScrollbarVisible, setVerticalScrollbarVisibility] = useState(false);

  const [attributes, setAttributes] = useState(null);

  const [date, setDate] = useState(Date.now()); // used to force table rerender when firstRow change, but it already corresponds to current first row of the rendered table

  const [worker] = useState(() => new Worker("./workers/getTableSupportStructuresWorker.js"));

  useEffect(() => {
    return () => {
      if (worker) {
        worker.terminate();
      }
    };
  }, [worker]);

  const handleObservationFilterOpen = useCallback((colIdx, anchorEl) => {
    setAnchorEl(anchorEl);
    setFilterColIdx(colIdx);
  }, []);

  const handleDimensionFilterOpen = useCallback((dimId, anchorEl) => {
    setAnchorEl(anchorEl);
    setFilterDimId(dimId);
  }, []);

  const handleFilterClose = useCallback(() => {
    setAnchorEl(null);
    setFilterColIdx(null);
    setFilterDimId(null);
  }, []);

  const handleFilterApply = useCallback(filter => {
    handleFilterClose();

    const newFilters = _.cloneDeep(filters);
    if (filterColIdx !== null) {
      newFilters[filterColIdx] = filter;
    }
    if (filterDimId !== null) {
      newFilters[filterDimId] = filter;
    }
    setFilters(newFilters);

    isFiltering = true;
    setRow(0);
  }, [filters, filterColIdx, filterDimId, handleFilterClose]);

  const handleFilterRemove = useCallback(() => {
    handleFilterClose();

    if ((filterColIdx !== null && filters[filterColIdx]) || (filterDimId !== null && filters[filterDimId])) {
      const newFilters = _.cloneDeep(filters);
      if (filterColIdx !== null) {
        delete newFilters[filterColIdx];
      }
      if (filterDimId !== null) {
        delete newFilters[filterDimId];
      }
      setFilters(newFilters);
      isFiltering = true;
      setRow(0);
    }
  }, [filters, filterColIdx, filterDimId, handleFilterClose]);

  const handleStyle = useCallback(isResizing => {
    if (!isPreview && tableSupportStructures) {
      if (!isResizing) {
        const $attributeTooltip = $(`#jsonstat-table__${uuid} #jsonstat-table__tooltip__attribute`);
        const $mergedHeaderTooltip = $(`#jsonstat-table__${uuid} #jsonstat-table__tooltip__merged-header`);

        /** attribute's tooltip handling **/
        $(`#jsonstat-table__${uuid} .ca .ct`)
          .hover(
            function() {
              $mergedHeaderTooltip.css({visibility: "hidden"});

              const $elem = $(this).get(0);
              const rect = $elem.getBoundingClientRect();

              let attributes;
              if ($elem.className.includes("ctd")) {
                const dataIdx = $elem.id;
                attributes = tableSupportStructures.obsAttributeMap[dataIdx];
              } else {
                const datasetId = $elem.id.split(":")[0];
                const dim = $elem.id.split(":")[1];
                const dimVal = $elem.id.split(":")[2];
                attributes = tableSupportStructures.dimAttributeMap[datasetId][dim][dimVal];
              }

              const ATTRIBUTE_HEIGHT = 18;

              $attributeTooltip.empty();
              attributes.forEach(attribute => {
                $attributeTooltip.append($(
                  `<li class="cttt"><b>${getAttributeLabel(attribute, labelFormat)}</b>: ${getAttributeValueLabel(attribute, labelFormat)}</li>`
                ));
              });

              const left = rect.x < (window.innerWidth / 4)
                ? (rect.right + 16)
                : (rect.left - $attributeTooltip.innerWidth() - 16);

              $attributeTooltip.css({
                visibility: "visible",
                top: rect.top - (ATTRIBUTE_HEIGHT * attributes.length) - 30,
                left: left
              });
            },
            function() {
              if ($mergedHeaderTooltip.children().length > 0) {
                $mergedHeaderTooltip.css({visibility: "visible"});
              }
              $attributeTooltip.attr("style", "").empty();
            }
          )
          .off("click")
          .click(
            function(ev) {
              ev.stopPropagation();

              const $elem = $(this).get(0);

              let attributes;
              if ($elem.className.includes("ctd")) {
                const dataIdx = $elem.id;
                attributes = tableSupportStructures.obsAttributeMap[dataIdx];
              } else {
                const datasetId = $elem.id.split(":")[0];
                const dim = $elem.id.split(":")[1];
                const dimVal = $elem.id.split(":")[2];
                attributes = tableSupportStructures.dimAttributeMap[datasetId][dim][dimVal];
              }

              setAttributes(attributes);
            }
          );

        /** merged header tooltip handling **/
        if (tableHeaderType === TABLE_HEADER_MERGED) {
          $(`#jsonstat-table__${uuid} #h-1 th.c:not(.c-rb)`)
            .hover(
              function() {
                const {
                  jsonStat,
                  dimValuesMap,
                  dimSpanMap
                } = tableSupportStructures;

                const $elem = $(this).get(0);
                const rect = $elem.getBoundingClientRect();

                const col = Number($elem.id.split("-")[1]);
                const dims = $elem.id.split("-")[3].split(",");

                const appendDim = (dim, value) =>
                  $mergedHeaderTooltip.append($(`<li class="cttt" style="white-space: normal"><b>${dim}</b>: <i>${value}</i></li>`));

                $mergedHeaderTooltip.empty();
                dims.forEach(dim => {
                  const dimValue = getDimensionValueFromIdx(dim, col, dimValuesMap, dimSpanMap);
                  if (dim !== MARGINAL_DIMENSION_KEY) {
                    appendDim(
                      getFormattedDimensionLabel(jsonStat, null, dim, labelFormat, t),
                      getFormattedDimensionValueLabel(jsonStat, null, dim, dimValue, labelFormat, t)
                    );

                  } else {
                    const marginal = jsonStat.extension.marginalvalues[dimValue];
                    if (marginal.label) {
                      appendDim(
                        getFormattedDimensionLabel(jsonStat, null, dim, labelFormat, t),
                        marginal.label
                      );
                    } else {
                      if (jsonStat.extension.datasets.length > 1) {
                        $mergedHeaderTooltip.append($(`<div class="cttt-dataset"><b>${jsonStat.extension.datasetlabels[marginal.datasetid]}</b></div>`));
                      }
                      getMarginalDimensions(jsonStat, marginal.datasetid).forEach(marginalDim => {
                        appendDim(
                          getFormattedDimensionLabel(jsonStat, marginal.datasetid, marginalDim, labelFormat, t),
                          getFormattedDimensionValueLabel(jsonStat, marginal.datasetid, marginalDim, marginal.dimensionvalues[marginalDim], labelFormat, t)
                        );
                      });
                    }
                  }
                });

                const left = rect.x < (window.innerWidth / 4)
                  ? (rect.right + 16)
                  : (rect.left - $mergedHeaderTooltip.innerWidth() - 16);

                $mergedHeaderTooltip.css({
                  visibility: "visible",
                  top: (rect.top - 32),
                  left: left
                });
              },
              function() {
                $mergedHeaderTooltip.attr("style", "").empty();
              }
            );
        }

        /** sorting handling **/
        $(`#jsonstat-table__${uuid} .c .table-icon.col-sort`)
          .off("click")
          .click(
            function() {
              const $elem = $(this).get(0);

              const col = Number($elem.id.split("-")[1]);
              const dir = $elem.className.includes("col-sort--a");

              setOrder(prevOrder => {
                if (prevOrder[0] === col && prevOrder[1] === dir) {
                  return [null, true];
                } else {
                  return [col, dir];
                }
              });

              setRow(0);
            }
          );

        /** observation filters handling **/
        $(`#jsonstat-table__${uuid} .c.csh .table-icon.col-filter`)
          .off("click")
          .click(
            function() {
              const $elem = $(this).get(0);
              const col = Number($elem.id.split("-")[1]);
              handleObservationFilterOpen(col, $elem);
            }
          );

        /** dimension filters handling **/
        $(`#jsonstat-table__${uuid} .c.ch .table-icon.col-filter`)
          .off("click")
          .click(
            function() {
              const $elem = $(this).get(0);
              const dim = $elem.id.split("-")[1];
              handleDimensionFilterOpen(dim, $elem);
            }
          );

        /** hover and click on rows handling **/
        $(`#jsonstat-table__${uuid} tbody tr.jsonstat-table__body__row`)
          .off("click")
          .click(
            function() {
              if (onRowClick) {
                const {layout, dimValuesMap, dimSpanMap} = tableSupportStructures;

                const $elem = $(this).get(0);
                const rowIdx = $elem.id.split("-")[1];
                const isActiveAfterClick = !$elem.className.includes("jsonstat-table__body__row--selected");

                const row = {};
                layout.rows.forEach(dim => {
                  row[dim] = getDimensionValueFromIdx(dim, rowIdx, dimValuesMap, dimSpanMap);
                });

                onRowClick(row, isActiveAfterClick);
              }
            }
          );
      }
    }
  }, [uuid, tableSupportStructures, tableHeaderType, labelFormat, isPreview, handleObservationFilterOpen, handleDimensionFilterOpen, onRowClick, t]);

  const handleScrollbar = useCallback(updateVisibleColAndROwCount => {
    if (!isPreview) {
      let $tableContainer = $(`#jsonstat-table__${uuid}`);
      if ($tableContainer && tableSupportStructures) {
        const {rowCount, colCount} = tableSupportStructures;
        const $table = $(`#jsonstat-table__${uuid} table`);

        const isVerticalScrollbarVisible = row !== null && (
          row !== 0 ||
          rowCount > ROWS_PER_PAGE ||
          ($table.height() > $tableContainer.height())
        );
        setVerticalScrollbarVisibility(isVerticalScrollbarVisible);

        const isHorizontalScrollbarVisible = col !== null && (
          col !== 0 ||
          colCount > COLS_PER_PAGE ||
          ($table.width() > $tableContainer.width())
        );
        setHorizontalScrollbarVisibility(isHorizontalScrollbarVisible);

        if (updateVisibleColAndROwCount) {
          let visibleRowCount = 0;
          $(`#jsonstat-table__${uuid} tbody tr`).each((idx, el) => {
            if (isElementVerticallyInContainer(el, $tableContainer.get(0))) {
              visibleRowCount++;
            }
          });
          setVisibleRowCount(visibleRowCount);

          let visibleColCount = 0;
          $(`#jsonstat-table__${uuid} tbody tr:not(.rs):first td`).each((idx, el) => {
            if (isElementHorizontallyInContainer(el, $tableContainer.get(0))) {
              visibleColCount++;
            }
          });
          setVisibleColCount(visibleColCount);
        }
      }
    }
  }, [uuid, tableSupportStructures, row, col, isPreview]);

  /* resize handler */
  useEffect(() => {
    const func = () => {
      handleStyle(true);
      handleScrollbar(true);
    };
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func);
  }, [handleStyle, handleScrollbar]);

  /* generating support table structure */
  useEffect(() => {
    if (jsonStat && layout) {
      setTableSupportStructures(null);
      setHtmlTable("");
      setHorizontalScrollbarVisibility(false);
      setVerticalScrollbarVisibility(false);

      const newLayout = Object.assign({}, layout);
      if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
        newLayout.cols = [...layout.cols, VARIATION_DIMENSION_KEY];
      }

      if (!isPreview) {
        worker.onmessage = event => {
          if (onFilter && isFiltering) {
            onFilter(event.data.filteredRows);
            isFiltering = false;
          }

          const valorizedColsFromArr = new BitSet(event.data.valorizedColsArr);
          const valorizedSectionRowsFromArr = event.data.valorizedSectionRowsArr.map(valorizedRowsArr => new BitSet(valorizedRowsArr));

          if (onStructureGenerationComplete) {
            onStructureGenerationComplete({
              arithmeticMeans: event.data.arithmeticMeans,
              arithmeticMeanDims: jsonStat.id.filter(dim => newLayout.cols.includes(dim))
            });
          }

          setTableSupportStructures({
            ...event.data,
            jsonStat,
            layout: newLayout,
            dimAttributeMap: getDimensionAttributeMap(jsonStat, t),
            valorizedCols: valorizedColsFromArr,
            valorizedColsFromArr: undefined,
            valorizedSectionRows: valorizedSectionRowsFromArr,
            valorizedSectionRowsFromArr: undefined
          });

          if (event.data.obsAttributeMap === null) {
            console.error(t("components.table.error.observationAttribute"));
          }
        };
        worker.onerror = () => {
          setTableSupportStructures(null);
        };
        worker.postMessage({
          jsonStat,
          layout: newLayout,
          isPreview,
          removeEmptyLines,
          showTrend,
          showCyclical,
          dimensionFilterValues,
          filterable,
          filters,
          onFilterComplete: null,
          labelFormat,
          customLabelFormats,
          dimensionValueModifiers,
          decimalSeparator,
          decimalPlaces,
          invertedDims,
          hierarchyOnlyAttributes,
          hideHierarchyOnlyRows,
          enableMeasuresOfSynthesisAndVariability
        });

      } else {
        setTableSupportStructures({
          jsonStat,
          layout: newLayout,
          isPreview: true
        });
      }

      setRow(null);
      setCol(null);
      isFirstRender = true;
    }
  }, [worker, jsonStat, layout, isPreview, removeEmptyLines, showTrend, showCyclical, dimensionFilterValues, filterable, onFilter, filters, labelFormat, customLabelFormats, dimensionValueModifiers, decimalSeparator, decimalPlaces, invertedDims, hierarchyOnlyAttributes, hideHierarchyOnlyRows, onStructureGenerationComplete, enableMeasuresOfSynthesisAndVariability, t]);

  /* handling scroll to row */
  useEffect(() => {
    if (firstRowDimValues !== null && firstRowDimValues !== undefined) {
      scrollToRow = true;
    }
  }, [firstRowDimValues]);

  /* generating html table */
  useEffect(() => {
    if (tableSupportStructures) {
      const {
        layout,
        dimValuesMap,
        dimSpanMap,
        valorizedSectionRows,
        valueMatrix,
        sectionRowsOrder
      } = tableSupportStructures;

      const {
        rows
      } = layout;

      const orderedCol = order[0];
      const isOrderedAscending = order[1];

      let newSectionRowsOrder;
      if (orderedCol !== null) {
        newSectionRowsOrder = {};
        Object.keys(sectionRowsOrder).forEach((s) => {
          newSectionRowsOrder[s] = {};
          const prevOrder = Object.keys(sectionRowsOrder[s]).sort((a, b) => a - b);
          const nextOrder = Object.keys(sectionRowsOrder[s]).sort((a, b) => {
            const aVal = valueMatrix?.[s]?.[orderedCol]?.[a];
            const bVal = valueMatrix?.[s]?.[orderedCol]?.[b];
            return valueSorter(aVal, bVal, isOrderedAscending);
          });
          prevOrder.forEach((prevPos, index) => {
            newSectionRowsOrder[s][prevPos] = Number(nextOrder[index]);
          });
        });
      } else {
        newSectionRowsOrder = sectionRowsOrder;
      }

      if (scrollToRow) {

        let i = 0, rowCount = 0, found = false;
        for (let b of valorizedSectionRows[0]) {
          if (b) {
            const clonedI = i;
            if (!rows.find(dim => firstRowDimValues[dim] && firstRowDimValues[dim] !== getDimensionValueFromIdx(dim, clonedI, dimValuesMap, dimSpanMap))) {
              found = true;
              break;
            }
            rowCount++;
          }
          i++;
        }

        setRow(found ? rowCount : 0);
        setCol(0);

        scrollToRow = false;

        setDate(Date.now());

      } else if (row === null || col === null) {

        if (row === null) {
          setRow(0);
        }
        if (col === null) {
          setCol(0);
        }

      } else {

        if (!isPreview) {
          setHtmlTable(getTableHtml(
            uuid,
            tableSupportStructures,
            tableHeaderType,
            {
              rowStart: row || 0,
              rowPerPage: ROWS_PER_PAGE,
              colStart: col || 0,
              colPerPage: COLS_PER_PAGE
            },
            labelFormat,
            customLabelFormats,
            dimensionValueModifiers,
            customDimensionLabels,
            fontSize,
            decimalSeparator,
            decimalPlaces,
            sanitize(emptyChar),
            sortable,
            orderedCol,
            isOrderedAscending,
            newSectionRowsOrder,
            filterable,
            highlightedRowsDimValues,
            rowHover,
            localizeI18nObj(hiddenDimensionValueLabels, appLanguage, languages),
            isPointData,
            showArithmeticMean,
            showStandardDeviation,
            showCoefficientOfVariation,
            hierarchyOnlyAttributes,
            onPageGenerationComplete,
            t
          ));

        } else {
          setHtmlTable(getPreviewTableHtml(
            uuid,
            tableSupportStructures,
            labelFormat,
            customLabelFormats,
            dimensionValueModifiers,
            customDimensionLabels
          ));
        }

        $(`#jsonstat-table__${uuid}`).scrollTop(0).scrollLeft(0);
      }
    }
  }, [tableSupportStructures, uuid, tableHeaderType, labelFormat, customLabelFormats, dimensionValueModifiers, customDimensionLabels, fontSize, decimalSeparator, decimalPlaces, emptyChar, sortable, isPreview, row, col, isFullscreen, order, firstRowDimValues, highlightedRowsDimValues, rowHover, appLanguage, languages, hiddenDimensionValueLabels, onPageGenerationComplete, t, date, filterable, isPointData, showArithmeticMean, showStandardDeviation, showCoefficientOfVariation, hierarchyOnlyAttributes]);

  /* exec style and scrollbar handler */
  useEffect(() => {
    if (htmlTable && htmlTable.length > 0) {
      handleStyle(false);
      handleScrollbar(isFirstRender);
      isFirstRender = false;
    }
  });

  return (
    <Fragment>

      <div className={`${classes.root} jsonstat-table`} aria-hidden={true}>
        {(tableSupportStructures && row !== null && col !== null)
          ? !isPreview
            ? (tableSupportStructures.colCount + tableSupportStructures.rowCount === 0)
              ? (
                <CustomEmpty text={t("components.table.noDataToDisplay")} />
              )
              : (
                <Scrollbars
                  verticalValue={row}
                  verticalMaxValue={tableSupportStructures.rowCount}
                  verticalTicks={tableSupportStructures.rowCount -
                    (scrollToLastRow ? 1 : (visibleRowCount - Math.floor(visibleRowCount / 100 * SLIDER_SAFETY_MARGIN_PERCENTAGE)))
                  }
                  onVerticalScroll={setRow}
                  isVerticalScrollbarVisible={isVerticalScrollbarVisible}
                  horizontalValue={col}
                  horizontalMaxValue={tableSupportStructures.colCount}
                  horizontalTicks={tableSupportStructures.colCount -
                    (scrollToLastCol ? 1 : (visibleColCount - Math.floor(visibleColCount / 100 * SLIDER_SAFETY_MARGIN_PERCENTAGE)))
                  }
                  onHorizontalScroll={setCol}
                  isHorizontalScrollbarVisible={isHorizontalScrollbarVisible}
                  disableWheelZoom={disableWheelZoom}
                >
                  <div
                    id={`jsonstat-table__${uuid}`}
                    className={classes.table}
                    style={{overflow: "hidden"}}
                    dangerouslySetInnerHTML={{__html: htmlTable}}
                  />
                </Scrollbars>
              )
            : (
              <div
                id={`jsonstat-table__${uuid}`}
                className={`${classes.table} jsonstat-table__preview`}
                style={{overflow: "auto"}}
                dangerouslySetInnerHTML={{__html: htmlTable}}
              />
            )
          : (
            <CustomEmpty
              text={t("components.table.loading") + "..."}
              image={<CircularProgress />}
            />
          )
        }
      </div>

      <Dialog
        open={attributes !== null}
        onClose={() => setAttributes(null)}
      >
        <CustomDialogTitle onClose={() => setAttributes(null)}>
          {t("components.table.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {(attributes || []).map((attribute, idx) =>
              <Grid item key={idx} xs={12}>
                <SanitizedHTML
                  html={`- <b>${getAttributeLabel(attribute, labelFormat)}</b>: ${getAttributeValueLabel(attribute, labelFormat)}`}
                  allowTarget
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttributes(null)}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        PaperProps={{
          className: classes.filters
        }}
      >
        {filterColIdx !== null
          ? (
            <ObservationFilterForm
              initialFilter={filters[filterColIdx]}
              onApply={handleFilterApply}
              onRemove={handleFilterRemove}
            />
          )
          : (
            <DimensionFilterForm
              initialFilter={filters[filterDimId]}
              onApply={handleFilterApply}
              onRemove={handleFilterRemove}
            />
          )
        }
      </Popover>

    </Fragment>
  );
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Table);