import React, {Fragment} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import NodeHeader from "../node-header";
import Page from "../page";
import {useLocation} from "react-router";
import Container from "@material-ui/core/Container";
import Footer from "../footer";
import {HashLink} from 'react-router-hash-link';
import {getPageTitle} from "../../utils/other";
import ModuleLoader from "../module-loader";
import CustomEmpty from "../custom-empty";

const styles = theme => ({
  root: {
    backgroundColor: "#f5f5f5",
    width: "100%",
    height: "100%",
    padding: "0 !important"
  },
  fullWidthContainer: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  }
});

const mapStateToProps = state => ({
  hub: state.hub,
  node: state.node,
  catalog: state.catalog
});

function ModulePage(props) {
  const {
    classes,

    moduleId,
    moduleComponent,
    moduleFallback,
    urlParam,
    isDefault,

    hub,
    node,
    catalog,
  } = props;

  const {t} = useTranslation();
  const location = useLocation();

  return (
    <Fragment>
      <HashLink
        to={{hash: "#main", search: location.search}}
        className="skip-link sr-only"
      >
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink
        to={{hash: "#footer", search: location.search}}
        className="skip-link sr-only"
      >
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page title={getPageTitle([], t)}>
        <div key={moduleId} className={classes.root}>
          <NodeHeader
            noNode={!node}
            hub={hub.hub}
            nodes={hub.nodes}
            node={node ? node : undefined}
            nodeId={node ? node.nodeId : undefined}
            isDefault={node ? isDefault : undefined}
            catalog={node ? catalog : undefined}
            title={node ? node.name : undefined}
          />
          <div id="main" className={classes.fullWidthContainer}>
            <Container>
              <div id={`${node ? "node" : "hub"}__${moduleId}`}>
                <ModuleLoader
                  id={moduleId}
                  component={moduleComponent}
                  fallback={
                    <CustomEmpty
                      text={moduleFallback || t("components.modulePage.suspense.text") + "..."}
                      style={{padding: 48}}
                    />
                  }
                  urlParam={urlParam}
                />
              </div>
            </Container>
            <Footer/>
          </div>
        </div>
      </Page>
    </Fragment>
  )
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(ModulePage);