import React, {Fragment, useEffect} from 'react';
import {Route, Switch} from 'react-router';
import Home from '../components/home';
import {connect} from 'react-redux';
import {fetchHub} from '../state/hub/hubActions';
import NodeDomain from './NodeDomain';
import {HashRouter} from 'react-router-dom';
import DashboardsDomain from './DashboardsDomain';
import {goToHome} from '../links';
import Call from '../hocs/call';
import MultiLanguageDomain from './MultiLanguageDomain';
import {showUserSetPasswordForm, submitUserEmailConfirmation} from "../state/user/userActions";
import WithCustomTheme, {
  CUSTOM_CSS_SELECTOR_A11Y_NODE_PREFIX,
  CUSTOM_CSS_SELECTOR_A11Y_PREFIX,
  CUSTOM_CSS_SELECTOR_NODE_PREFIX,
  CUSTOM_CSS_SELECTOR_PREFIX
} from "../components/with-custom-theme";
import A11yDomain from "./A11yDomain";
import {clearPendingRequests} from "../state/pending-request/pendingRequestActions";
import ExternalLogin from "../components/external-login";
import ModulePage from "../components/module-page";
import Error from "../components/error";

import themeConfig from "../config/theme/config.json";

const mapStateToProps = state => ({
  hub: state.hub,
  defaultLanguage: state.app.language,
  isA11y: state.app.isA11y,
  modulesConfig: state.app.modulesConfig
});

const mapDispatchToProps = dispatch => ({
  fetchHub: () => dispatch(fetchHub()),
  onUserSetPasswordFormShow: token => dispatch(showUserSetPasswordForm(token)),
  clearPendingRequests: () => dispatch(clearPendingRequests()),
  onMailValidationSubmit: ({username, token}) => dispatch(submitUserEmailConfirmation(username, token))
});

const WithCustomThemeForNode = ({children, nodeCode, isA11y}) =>
  <WithCustomTheme
    getPath={selectorText => {
      const prefix = CUSTOM_CSS_SELECTOR_NODE_PREFIX + nodeCode + "__";
      const a11yPrefix = CUSTOM_CSS_SELECTOR_A11Y_NODE_PREFIX + nodeCode + "__";
      if (selectorText.startsWith(prefix)) {
        return selectorText.substr(prefix.length);
      } else if (selectorText.startsWith(CUSTOM_CSS_SELECTOR_PREFIX) &&
        !selectorText.startsWith(CUSTOM_CSS_SELECTOR_NODE_PREFIX)) {
        return selectorText.substr(CUSTOM_CSS_SELECTOR_PREFIX.length);
      } else if (isA11y && selectorText.startsWith(a11yPrefix)) {
        return selectorText.substr(a11yPrefix.length);
      } else if (isA11y && selectorText.startsWith(CUSTOM_CSS_SELECTOR_A11Y_PREFIX) &&
        !selectorText.startsWith(CUSTOM_CSS_SELECTOR_A11Y_NODE_PREFIX)) {
        return selectorText.substr(CUSTOM_CSS_SELECTOR_A11Y_PREFIX.length);
      } else {
        return false;
      }
    }}
    nodeCode={nodeCode}
  >
    {children}
  </WithCustomTheme>;

const WithCustomThemeNoNode = ({children, isA11y}) =>
  <WithCustomTheme
    getPath={selectorText => {
      if (selectorText.startsWith(CUSTOM_CSS_SELECTOR_PREFIX) &&
        !selectorText.startsWith(CUSTOM_CSS_SELECTOR_NODE_PREFIX)) {
        return selectorText.substr(CUSTOM_CSS_SELECTOR_PREFIX.length);
      } else if (isA11y && selectorText.startsWith(CUSTOM_CSS_SELECTOR_A11Y_PREFIX) &&
        !selectorText.startsWith(CUSTOM_CSS_SELECTOR_A11Y_NODE_PREFIX)) {
        return selectorText.substr(CUSTOM_CSS_SELECTOR_A11Y_PREFIX.length);
      } else {
        return false;
      }
    }}
  >
    {children}
  </WithCustomTheme>;

const HubDomain = ({
                     hub,
                     fetchHub,
                     isA11y,
                     modulesConfig,
                     onUserSetPasswordFormShow,
                     clearPendingRequests,
                     onMailValidationSubmit
                   }) => {

  useEffect(() => {
    window.addEventListener("popstate", clearPendingRequests, false);
  }, [clearPendingRequests]);

  useEffect(() => {
    const exitFullscreen = () => {
      const fullscreenElements = document.getElementsByClassName("viewer--fullscreen");
      if (fullscreenElements.length > 0 && window.fullscreenIds) {
        const root = document.getElementById("root");
        const element = document.getElementById(window.fullscreenIds.element);
        const elementParent = document.getElementById(window.fullscreenIds.parent);

        if (root && element && elementParent) {
          elementParent.appendChild(element);
          element.classList.remove("viewer--fullscreen");
          root.style.visibility = "";
        }

        if ((window.fullscreenIds.elementsToRemove || []).length > 0) {
          window.fullscreenIds.elementsToRemove.forEach(id => {
            document.getElementById(id).style.display = "";
          });
        }

        window.fullscreenIds = null;
      }
    };
    window.addEventListener("popstate", exitFullscreen, false);
  }, []);

  useEffect(() => {
    if (!hub) {
      fetchHub();
    }
  }, [hub, fetchHub]);

  const defaultNode = hub?.nodes.find(node => node.default === true);

  return (
    <Fragment>
      {hub && (
        <HashRouter>
          <A11yDomain>
            <Switch>
              <Route
                path='/'
                exact
                render={props => {
                  if (defaultNode !== undefined) {
                    return (
                      <WithCustomThemeForNode nodeCode={defaultNode.code} isA11y={isA11y}>
                        <NodeDomain
                          {...props}
                          nodeCode={defaultNode.code}
                          isDefault
                        />
                      </WithCustomThemeForNode>
                    );
                  } else {
                    return (
                      <WithCustomThemeNoNode isA11y={isA11y}>
                        <Home
                          {...props}
                        />
                      </WithCustomThemeNoNode>
                    );
                  }
                }}
              />
              <Route
                path='/auth/shibboleth'
                exact
                render={props => {
                  const loginCode = new URLSearchParams(props.location.search).get("code");
                  return (
                    <WithCustomThemeNoNode isA11y={isA11y}>
                      <ExternalLogin
                        loginCode={loginCode}
                      />
                    </WithCustomThemeNoNode>
                  );
                }}
              />
              <Route
                path="/error"
                exact
                render={props =>
                  <WithCustomThemeNoNode isA11y={isA11y}>
                    <Error
                      {...props}
                    />
                  </WithCustomThemeNoNode>
                }
              />
              <Route
                path='/:lang'
                exact
                render={props => {
                  if (defaultNode !== undefined) {
                    return (
                      <MultiLanguageDomain language={props.match.params.lang}>
                        <WithCustomThemeForNode nodeCode={defaultNode.code} isA11y={isA11y}>
                          <NodeDomain
                            {...props}
                            nodeCode={defaultNode.code}
                            isDefault
                          />
                        </WithCustomThemeForNode>
                      </MultiLanguageDomain>
                    );
                  } else {
                    return (
                      <MultiLanguageDomain language={props.match.params.lang}>
                        <WithCustomThemeNoNode isA11y={isA11y}>
                          <Home
                            {...props}
                          />
                        </WithCustomThemeNoNode>
                      </MultiLanguageDomain>
                    );
                  }
                }}
              />
              {themeConfig.enableDashboard && (
                <Route
                  path="/:lang/dashboards"
                  exact
                  render={props => (
                    <MultiLanguageDomain language={props.match.params.lang}>
                      <WithCustomThemeNoNode isA11y={isA11y}>
                        <DashboardsDomain
                          {...props}
                          isDefault={defaultNode !== undefined}
                        />
                      </WithCustomThemeNoNode>
                    </MultiLanguageDomain>
                  )}
                />
              )}
              {themeConfig.enableDashboard && (
                <Route
                  path="/:lang/dashboards/:dashboardId"
                  exact
                  render={props => (
                    <MultiLanguageDomain language={props.match.params.lang}>
                      <WithCustomThemeNoNode isA11y={isA11y}>
                        <DashboardsDomain
                          {...props}
                          key={props.match.params.dashboardId}
                          dashboardId={props.match.params.dashboardId}
                          isDefault={defaultNode !== undefined}
                        />
                      </WithCustomThemeNoNode>
                    </MultiLanguageDomain>
                  )}
                />
              )}
              <Route
                path='/:lang/resetPassword'
                exact
                render={props => {
                  const tokenParam = new URLSearchParams(props.location.search).get('token');
                  if (tokenParam) {
                    return (
                      <MultiLanguageDomain language={props.match.params.lang}>
                        <Call cb={onUserSetPasswordFormShow} cbParam={tokenParam}>
                          {defaultNode !== undefined
                            ? (
                              <WithCustomThemeForNode nodeCode={defaultNode.code} isA11y={isA11y}>
                                <NodeDomain
                                  {...props}
                                  nodeCode={defaultNode.code}
                                  isDefault
                                />
                              </WithCustomThemeForNode>
                            )
                            : (
                              <WithCustomThemeNoNode isA11y={isA11y}>
                                <Home
                                  {...props}
                                />
                              </WithCustomThemeNoNode>
                            )
                          }
                        </Call>
                      </MultiLanguageDomain>
                    );
                  } else {
                    goToHome();
                  }
                }}
              />
              <Route
                path='/:lang/confirmEmail'
                exact
                render={props => {
                  const usernameParamBase64 = new URLSearchParams(props.location.search).get('username');
                  const usernameParam = atob(usernameParamBase64);
                  const tokenParam = new URLSearchParams(props.location.search).get('token');

                  if (usernameParam && tokenParam) {
                    return (
                      <MultiLanguageDomain language={props.match.params.lang}>
                        <Call
                          cb={onMailValidationSubmit}
                          cbParam={{
                            username: usernameParam,
                            token: tokenParam
                          }}
                        >
                          <Fragment>
                            {defaultNode !== undefined
                              ? (
                                <WithCustomThemeForNode nodeCode={defaultNode.code} isA11y={isA11y}>
                                  <NodeDomain
                                    {...props}
                                    nodeCode={defaultNode.code}
                                    isDefault
                                  />
                                </WithCustomThemeForNode>
                              )
                              : (
                                <WithCustomThemeNoNode isA11y={isA11y}>
                                  <Home
                                    {...props}
                                  />
                                </WithCustomThemeNoNode>
                              )
                            }
                          </Fragment>
                        </Call>
                      </MultiLanguageDomain>
                    );
                  } else {
                    goToHome();
                  }
                }}
              />
              {(modulesConfig.hubRoutes || []).map((module, idx) => {
                return (
                  <Route
                    key={idx}
                    path={[`/:lang/${module.route}/*`, `/:lang/${module.route}`]}
                    exact
                    render={props => {
                      return (
                        <MultiLanguageDomain language={props.match.params.lang}>
                          <WithCustomThemeNoNode isA11y={isA11y}>
                            <ModulePage
                              moduleId={module.id}
                              moduleComponent={module.component}
                              moduleFallback={module.fallback}
                              urlParam={props.match.params[0]}
                            />
                          </WithCustomThemeNoNode>
                        </MultiLanguageDomain>
                      )
                    }}
                  />
                );
              })}
              <Route
                path='/:lang/:nodeCode'
                render={props => {
                  const node = hub.nodes.find(({code}) =>
                    code.toLowerCase() === props.match.params.nodeCode.toLowerCase()
                  );
                  if (node) {
                    return (
                      <MultiLanguageDomain language={props.match.params.lang}>
                        <WithCustomThemeForNode nodeCode={node.code} isA11y={isA11y}>
                          <NodeDomain
                            {...props}
                            nodeCode={props.match.params.nodeCode}
                            isDefault={defaultNode?.nodeId === node.nodeId}
                          />
                        </WithCustomThemeForNode>
                      </MultiLanguageDomain>
                    );
                  } else {
                    goToHome();
                  }
                }}
              />
            </Switch>
          </A11yDomain>
        </HashRouter>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HubDomain);
