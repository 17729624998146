import {Reducer} from "redux";
import {INIT} from '../rootActions';

export type DashboardConfigState = object | null;

const dashboardConfigReducer: Reducer<DashboardConfigState> = (
  state = null,
  action
) => {
  switch (action.type) {
    case INIT: {
      return action.payload.dashboardFilterConfig;
    }
    default:
      return state;
  }
};

export default dashboardConfigReducer;