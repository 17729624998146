import {getFilteredTreeWithPaths, getMappedTree, getNodes} from "./tree";
import {IDataset} from "../model/IDataset";
import {ICategoryGroup} from "../model/ICategoryGroup";
import {ICategory} from "../model/ICategory";
import {ICategoryProvider} from "../model/ICategoryProvider";

export const getDetailLevelTree = (tree: any, t: any) => getMappedTree(tree, "layers", (node: any, idx: number) => ({
  ...node,
  key: (node.id || `root_${idx}`).toString(),
  label: node.parentCategory
    ? t(`detailLevels.${node.parentCategory}.values.${node.nameIdentifier}`) /* i18next-extract-disable-line */
    : t(`detailLevels.${node.category}.label`), /* i18next-extract-disable-line */
  layers: (node.layers || [])
    .map((child: any) => ({...child, parentCategory: node.category || null}))
    .sort(((a: any, b: any) => (a.order - b.order) || (a.id - b.id)))
}));

export const getDetailLevelFromTree = (tree: any, prefValue: number | null = null) => {
  const nodes = getNodes(tree, "layers", (node: any) => (node.id !== null && node.id !== undefined)).map(({id}) => id);
  if (nodes.length > 0) {
    if (prefValue !== null) {
      return nodes.includes(prefValue)
        ? prefValue
        : null;
    } else if (nodes.includes(3)) {
      return 3;
    } else {
      return nodes[0];
    }
  }
};

export const getFilteredCatalog = (catalog: ICategoryProvider, filter: (id: string, dataset: IDataset) => boolean) => {
  const datasets: {[key: string]: IDataset} = {};
  Object.keys(catalog.datasets)
    .filter(id => filter(id, catalog.datasets[id]))
    .forEach(id => datasets[id] = catalog.datasets[id]);

  const categoryGroups = (catalog.categoryGroups || [])
    .map((group: ICategoryGroup) => ({
      ...group,
      categories: getFilteredTreeWithPaths(
        getMappedTree(group.categories, "childrenCategories", (cat: ICategory) => ({
          ...cat,
          datasetIdentifiers: (cat.datasetIdentifiers || []).filter(id => filter(id, catalog.datasets[id]))
        })),
        "childrenCategories",
        (cat: ICategory) => cat.datasetIdentifiers && cat.datasetIdentifiers.length > 0
      )
    }))
    .filter((group: ICategoryGroup) => group.categories && group.categories.length > 0);

  const uncategorizedDatasets = (catalog.uncategorizedDatasets || [])
    .filter((dataset: IDataset) => filter(dataset.identifier, dataset));

  return {
    ...catalog,
    categoryGroups: categoryGroups,
    datasets: datasets,
    uncategorizedDatasets: uncategorizedDatasets,
    isEmpty: Object.keys(datasets).length === 0 && (uncategorizedDatasets || []).length === 0
  };
};