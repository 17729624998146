import React, {Fragment} from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const PageSection = ({t, sectiontitle, rightActions, children, ...props}) => (
  <Box {...props}>
    {(sectiontitle || rightActions) && (
      <Fragment>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" gutterBottom margin={4} style={{fontSize: 34}}>
              {sectiontitle}
            </Typography>
          </Grid>
          <Grid item>
            {rightActions}
          </Grid>
        </Grid>
        <Divider/>
      </Fragment>
    )}
    {children}
  </Box>
);

export default PageSection;