import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Call from "../../hocs/call";
import {
  CRITERIA_SELECTION_MODE_ALL,
  CRITERIA_SELECTION_TYPE_DYNAMIC,
  fetchDatasetStructureCodelist,
  fetchDatasetStructureCodelistFull,
  hideDatasetCriteriaObsCountWarning,
  hideDatasetStructureCodelistFull
} from "../../state/dataset/datasetActions";
import EnhancedTree from "../enhanced-tree";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import InfiniteScrollTable from "../infinite-scroll-table";
import TimePeriod from "./TimePeriod";
import Typography from "@material-ui/core/Typography";
import {withTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import CustomEmpty from "../custom-empty";
import {
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_EMPTY,
  CRITERIA_FILTER_TYPE_PERIODS,
  CRITERIA_FILTER_TYPE_RANGE,
  CRITERIA_FILTER_TYPE_STRING,
  criteriaFilterTypes,
  getFreqFromCriteria,
  getTimePeriod
} from "../../utils/criteria";
import IconButton from "@material-ui/core/IconButton";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import {getFilteredTreeWithPaths, getMappedTree, getMaxTreeDepth, getNodes} from "../../utils/tree";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import CustomDialogTitle from "../custom-dialog-title";
import InfoIcon from "@material-ui/icons/Info";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {ViewerMode} from "../../state/dataset/constants";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%"
  },
  criteriaContainer: {
    width: "100%",
    padding: "16px 24px 0",
    overflowY: "hidden"
  },
  criteriaContainerDimension: {
    height: "100%"
  },
  codelistInfo: {
    width: "100%",
    marginBottom: 8
  },
  onlySelectedSwitch: {
    marginLeft: 0
  },
  missingValuesWarning: {
    width: "100%",
    marginBottom: 12,
    alignItems: "center"
  },
  missingValuesWarningIcon: {
    marginLeft: 8
  },
  codelistContainer: {
    height: "100%",
    width: "100%"
  },
  emptyContainer: {
    height: "100%",
    width: "100%"
  },
  timePeriodContainer: {
    height: "100%",
    width: "100%"
  },
  treeActions: {
    marginBottom: 8
  },
  tabLabel: {
    display: "-webkit-box",
    lineClamp: 3,
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

const mapStateToProps = ({app, node, dataset}) => ({
  themeConfig: app.themeConfig,
  node: node,
  mode: dataset.mode,
  type: dataset.type,
  codelistFetchError: dataset.codelistFetchError,
  isObsCountWarningVisible: dataset.isObsCountWarningVisible,
  missingFilterValues: dataset.missingFilterValues
});

const mapDispatchToProps = dispatch => ({
  fetchCodelist: (nodeId, nodeCode, datasetId, mode, type, dimensionId, criteria, freq, defaultLastNPeriods, preserveFiltersWithDynamic) =>
    dispatch(fetchDatasetStructureCodelist(nodeId, nodeCode, datasetId, mode, type, dimensionId, criteria, freq, defaultLastNPeriods, preserveFiltersWithDynamic)),
  onCriteriaObsCountWarningHide: () => dispatch(hideDatasetCriteriaObsCountWarning()),
  fetchCodelistFull: (nodeId, datasetId, dimensionId, missingFilterValueIds) => dispatch(fetchDatasetStructureCodelistFull(nodeId, datasetId, dimensionId, missingFilterValueIds)),
  onCodelistFullHide: () => dispatch(hideDatasetStructureCodelistFull())
});

const handleStyle = () => {
  const headerHeight = $("#criteria__header").outerHeight(true) || 0;
  $("#criteria__container").height(`calc(100% - ${headerHeight}px)`);

  const codelistHeaderHeight = $("#criteria-container__codelist-header").outerHeight(true) || 0;
  const codelistWarningsHeight = $("#criteria-container__codelist-warnings").outerHeight(true) || 0;
  $("#criteria-container__dimensions").css({height: `calc(100% - ${codelistHeaderHeight + codelistWarningsHeight}px)`});
};

function Criteria(props) {
  const {
    t,
    classes,

    themeConfig,
    node,
    mode,
    type,
    codelistsLength,
    codelists,
    codelistFetchError,
    isObsCountWarningVisible,
    missingFilterValues,

    viewerMode,
    nodeId,
    nodeCode,
    datasetId,
    dimensions,
    timeDim,
    freqDim,
    territoryDim,
    hideTerritoryDim,
    criteria,
    isCriteriaValid,
    territoryDimCriteria,
    setCriteriaValidity,
    onSubmit,
    defaultLastNPeriods,
    showCodelistInfo = false,
    preserveFiltersWithDynamic = false,

    fetchCodelist,
    onSetCriteria,
    onCriteriaObsCountWarningHide,
    fetchCodelistFull,
    onCodelistFullHide
  } = props;

  const initialTimePeriod = {
    selectorType: CRITERIA_FILTER_TYPE_RANGE,
    freq: themeConfig?.defaultFreq || null,
    minDate: null,
    maxDate: null,
    fromDate: null,
    toDate: null,
    periods: null,
    missingRange: false
  };

  const [tabId, setTabId] = useState(null);
  const [isCallDisabled, setIsCallDisabled] = useState(false);

  const [criteriaBackup, setCriteriaBackup] = useState(null);
  const [tabIdBackup, setTabIdBackup] = useState(null);

  const [isTree, setIsTree] = useState(null);
  const [data, setData] = useState(null);
  const [dataBackup, setDataBackup] = useState(null);

  const [checkedKeys, setCheckedKeys] = useState([]);
  const [checkedKeysSnapshot, setCheckedKeysSnapshot] = useState(null);

  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState(null);

  const [timePeriod, setTimePeriod] = useState(initialTimePeriod);

  const [missingFilterValueIds, setMissingFilterValueIds] = useState([]);

  const [lastNotCodedFilter, setLastNotCodedFilter] = useState(null);

  const [treeKey, setTreeKey] = useState(Date.now());

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle);
  }, []);

  useEffect(() => {
    handleStyle();
  });

  useEffect(() => {
    if (dimensions.length > 0 && tabId !== null) {
      const data = codelists?.[dimensions[tabId].id];
      setData(data);
      setIsTree(getMaxTreeDepth(data, "children") > 1);
    }
  }, [mode, codelists, dimensions, tabId]);

  useEffect(() => {
    if (dimensions.length > 0 && tabId !== null) {
      setCheckedKeys(criteria[dimensions[tabId].id]?.filterValues || []);
    }
  }, [criteria, dimensions, tabId]);

  useEffect(() => {
    if (timeDim && codelists) {
      setTimePeriod(prevTimeP => getTimePeriod(
        prevTimeP,
        criteria,
        timeDim,
        codelists[timeDim] || null,
        codelists[freqDim] || null,
        node
      ));
    }
  }, [node, codelists, timeDim, freqDim, criteria]);

  useEffect(() => {
    const dataFlatValues = isTree ? getNodes(data, "children", () => true) : data;
    setMissingFilterValueIds(checkedKeys.filter(key => !(dataFlatValues || []).map(({id}) => id).includes(key)));
  }, [data, isTree, checkedKeys]);

  useEffect(() => {
    if (checkedKeysSnapshot && !dataBackup) {
      setData(prevTree => {
        let newTree;
        if (((prevTree || []).length > 1 || (prevTree[0].children || []).length > 0)) {
          newTree = getFilteredTreeWithPaths(prevTree, "children", ({id}) => checkedKeysSnapshot.includes(id));
          newTree = getMappedTree(newTree, "children", node => ({
            ...node,
            isSelectable: node.isSelectable && checkedKeysSnapshot.includes(node.id)
          }));
        } else {
          newTree = prevTree.filter(({id}) => checkedKeysSnapshot.includes(id));
          newTree = newTree.map(node => ({
            ...node,
            isSelectable: node.isSelectable && checkedKeysSnapshot.includes(node.id)
          }));
        }
        setData(newTree);
        setDataBackup(prevTree);
      });
    } else if (!checkedKeysSnapshot && dataBackup) {
      setData(dataBackup);
      setDataBackup(null);
    }
  }, [checkedKeysSnapshot, dataBackup]);

  useEffect(() => {
    setCheckedKeysSnapshot(null);
    setDefaultExpandedKeys(null);
  }, [tabId]);

  useEffect(() => {
    setTreeKey(Date.now());
  }, [tabId, checkedKeysSnapshot]);

  useEffect(() => {
    if (tabId === null) {
      let tabId;
      if (viewerMode === ViewerMode.SingleViewer) {
        tabId = 0;

      } else {
        const lastValorizedDimension = dimensions
          .filter(({id}) => !territoryDim || !hideTerritoryDim || id !== territoryDim)
          .map(({id}) => id)
          .reverse()
          .find(dim => (criteria[dim]?.filterValues || []).length > 0 || criteria[dim]?.period > 0 || (criteria[dim]?.from && criteria[dim]?.to));

        tabId = lastValorizedDimension
          ? dimensions.map(({id}) => id).indexOf(lastValorizedDimension)
          : (!territoryDim || !hideTerritoryDim || dimensions[0].id !== territoryDim)
            ? 0
            : 1;
      }

      setTabId(tabId);
    }
  }, [tabId, viewerMode, dimensions, criteria, type, territoryDim, hideTerritoryDim]);

  const defaultSelectedKeys = useMemo(() => {
    return (dimensions.length > 0 && tabId !== null)
      ? criteria[dimensions[tabId].id]?.filterValues || []
      : [];
  }, [dimensions, tabId, criteria]);

  const isCheckDisabled = useMemo(() => {
    return !preserveFiltersWithDynamic && !checkedKeysSnapshot && (data || []).length === 1 && (data[0].children || []).length === 0;
  }, [preserveFiltersWithDynamic, checkedKeysSnapshot, data]);

  const getTreeNodeIsSelectable = useCallback(({isSelectable}) => {
    return isSelectable && !isCheckDisabled;
  }, [isCheckDisabled]);

  const handleFetch = ({dimensionId, criteria}) => {
    let fullCriteria;
    if (territoryDimCriteria) {
      fullCriteria = {
        [territoryDim]: territoryDimCriteria,
        ...criteria
      };
    } else {
      fullCriteria = criteria;
    }

    fetchCodelist(
      nodeId,
      nodeCode,
      datasetId,
      mode,
      type,
      dimensionId,
      fullCriteria,
      timePeriod.freq,
      defaultLastNPeriods,
      preserveFiltersWithDynamic
    );

    setIsCallDisabled(true);
  };

  const handleTabChange = newTabId => {
    const newCriteria = _.cloneDeep(criteria);

    const dimension = dimensions[tabId].id;
    const filterValue = criteria?.[dimension]?.filterValues?.[0];
    if (type === CRITERIA_SELECTION_TYPE_DYNAMIC && !dimensions[tabId]?.extra?.DataStructureRef && filterValue) {
      setLastNotCodedFilter({dimension, filterValue});
    }

    if (type === CRITERIA_SELECTION_TYPE_DYNAMIC && newTabId < (dimensions.length - 1) && !preserveFiltersWithDynamic) {

      const criteriaToRemove = dimensions
        .map(({id}) => id)
        .slice(newTabId + 1)
        .filter(dim => newCriteria[dim] !== null && newCriteria[dim] !== undefined);

      if (criteriaToRemove.length > 0) {
        criteriaToRemove.forEach(dim => newCriteria[dim] = undefined);
        setCriteriaBackup(newCriteria);
        setTabIdBackup(newTabId);

      } else {
        setTabId(newTabId);
        setIsCallDisabled(false);
      }

    } else {
      setTabId(newTabId);

      if (mode !== CRITERIA_SELECTION_MODE_ALL) {
        setIsCallDisabled(false);
      }
    }
  };

  const handleCriteriaChange = useCallback((checkedKeys, type) => {
    const newCriteria = _.cloneDeep(criteria);
    const dimensionId = dimensions[tabId].id;
    const newType = (type && criteriaFilterTypes.includes(type))
      ? type
      : CRITERIA_FILTER_TYPE_CODES;

    let newDimensionCriteria = undefined;

    if (checkedKeys && ((checkedKeys.length === 1 && checkedKeys[0] !== "") || checkedKeys.length > 1)) {
      newDimensionCriteria = {
        id: dimensionId,
        type: newType,
        filterValues: checkedKeys,
        period: null,
        from: null,
        to: null
      };
    }

    newCriteria[dimensionId] = newDimensionCriteria;

    onSetCriteria(newCriteria);

    setTimePeriod(prevTimePeriod => ({
      ...prevTimePeriod,
      freq: getFreqFromCriteria(newCriteria)
    }));

  }, [criteria, dimensions, tabId, onSetCriteria]);

  const handleTimePeriodChange = useCallback(timePeriod => {
    const type = (timePeriod.selectorType || CRITERIA_FILTER_TYPE_EMPTY);

    const timePeriodCriteria = (type === CRITERIA_FILTER_TYPE_EMPTY || (type === CRITERIA_FILTER_TYPE_PERIODS && timePeriod.periods === null))
      ? undefined
      : {
        id: timeDim,
        type: type,
        filterValues: null,
        period: type === CRITERIA_FILTER_TYPE_PERIODS ? timePeriod.periods : null,
        from: type === CRITERIA_FILTER_TYPE_RANGE ? timePeriod.fromDate : null,
        to: type === CRITERIA_FILTER_TYPE_RANGE ? timePeriod.toDate : null
      };

    onSetCriteria({
      ...criteria,
      [timeDim]: timePeriodCriteria
    });

    setTimePeriod(timePeriod);
  }, [criteria, timeDim, onSetCriteria]);

  if (!dimensions || tabId === null) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Box id="criteria__header">
        <Tabs
          value={tabId}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event, newValue) => handleTabChange(newValue)}
        >
          {dimensions.map((dim, idx) => (
            <Tab
              key={idx}
              label={
                <Tooltip title={dim.label ? `[${dim.id}] ${dim.label}` : dim.id}>
                  <Grid container justifyContent="center" direction="column" alignItems="center">
                    <Grid item>
                      <span className={classes.tabLabel}>
                        {dim.label || dim.id}
                      </span>
                    </Grid>
                    {codelistsLength && codelistsLength[idx] !== null && dimensions[idx]?.extra?.DataStructureRef && (
                      <Grid item>
                        {`(${(criteria[dim.id]?.filterValues || []).length}/${codelistsLength[idx]})`}
                      </Grid>
                    )}
                  </Grid>
                </Tooltip>
              }
              id={`tab__${dim.id}`}
              aria-controls={`dimension__${dim.id}`}
              tabIndex={0}
              disabled={!isCriteriaValid}
              style={{
                display: (dim.id === territoryDim && hideTerritoryDim)
                  ? "none"
                  : "unset"
              }}
            />
          ))}
        </Tabs>
      </Box>
      <div id="criteria__container" className={classes.criteriaContainer}>
        {showCodelistInfo && dimensions[tabId].id !== timeDim && (
          <Grid
            id="criteria-container__codelist-header"
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            className={classes.codelistInfo}
          >
            <Grid item>
              <Typography id="criteria-container__codelist-header__info">
                {`${dimensions[tabId].label || dimensions[tabId].id}${dimensions[tabId]?.extra?.DataStructureRef ? ` (${dimensions[tabId].extra.DataStructureRef})` : ""}`}
              </Typography>
            </Grid>
            {(checkedKeysSnapshot !== null || (data || []).length > 1 || (data?.[0]?.children || []).length > 0) && dimensions[tabId].id !== timeDim && (
              <Grid item>
                <Tooltip
                  title={t("components.criteria.codelistHeader.onlySelected.tooltip")}
                >
                  <FormControlLabel
                    id="criteria-container__codelist-header__only-selected-switch"
                    label={t("components.criteria.codelistHeader.onlySelected.label")}
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={checkedKeysSnapshot !== null}
                        onChange={({target}) => {
                          const tree = getFilteredTreeWithPaths(data, "children", ({id}) => checkedKeys.includes(id));
                          const nodes = getNodes(tree, "children", ({children}) => (children || []).length > 0).map(({id}) => id);
                          setDefaultExpandedKeys(nodes);
                          setCheckedKeysSnapshot(target.checked ? checkedKeys : null);
                        }}
                        disabled={checkedKeysSnapshot === null && (checkedKeys || []).length === 0}
                      />
                    }
                    className={classes.onlySelectedSwitch}
                  />
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )}
        {data && missingFilterValueIds.length > 0 && dimensions[tabId]?.extra?.DataStructureRef && (
          <Alert id="criteria-container__codelist-warnings" severity="warning" className={classes.missingValuesWarning}>
            {t("components.criteria.warnings.missingFilterValueIds.message.label")}
            <Tooltip title={t("components.criteria.warnings.missingFilterValueIds.action.tooltip")}>
              <IconButton
                aria-label={t("components.criteria.warnings.missingFilterValueIds.action.ariaLabel")}
                size="small"
                color="primary"
                onClick={() => fetchCodelistFull(nodeId, datasetId, dimensions[tabId].id, missingFilterValueIds)}
                className={classes.missingValuesWarningIcon}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Alert>
        )}
        <div id="criteria-container__dimensions">
          {dimensions.map((dim, idx) => (
            <div
              key={idx}
              id={`dimension__${dim.id}`}
              aria-labelledby={`tab__${dim.id}`}
              hidden={idx !== tabId}
              className={classes.criteriaContainerDimension}
            >
              <Call
                cb={handleFetch}
                cbParam={{
                  dimensionId: dim.id,
                  criteria: {
                    ...criteria,
                    [dim.id]: undefined
                  }
                }}
                disabled={idx !== tabId || (mode === CRITERIA_SELECTION_MODE_ALL && !!codelists) || isCallDisabled}
              >
                {codelistFetchError
                  ? (
                    <div className={classes.emptyContainer}>
                      <CustomEmpty text={t("components.criteria.errors.fetchingCodelist")} />
                    </div>
                  )
                  : (!data || idx !== tabId)
                    ? (
                      <div className={classes.emptyContainer}>
                        <CustomEmpty text={""} />
                      </div>
                    )
                    : dimensions[tabId].id === timeDim
                      ? (
                        <div className={classes.timePeriodContainer}>
                          <TimePeriod
                            timePeriod={timePeriod}
                            freqDim={freqDim}
                            onSetTimePeriod={handleTimePeriodChange}
                            isCriteriaValid={isCriteriaValid}
                            setCriteriaValidity={setCriteriaValidity}
                          />
                        </div>
                      )
                      : (
                        <div className={classes.codelistContainer}>
                          {((data || []).length === 0)
                            ? dimensions[tabId]?.extra?.DataStructureRef
                              ? (
                                <CustomEmpty
                                  text={lastNotCodedFilter
                                    ? t("components.criteria.dialogs.warning.notCodedDimension.label", lastNotCodedFilter)
                                    : t("components.criteria.emptyCodelist.label")
                                  }
                                />
                              )
                              : (
                                <Fragment>
                                  <div style={{margin: "16px 0"}}>
                                    {t("components.criteria.noCodelistAssociated.info")}
                                  </div>
                                  <TextField
                                    value={checkedKeys[0] || ""}
                                    placeholder={t("components.criteria.noCodelistAssociated.noFilter")}
                                    variant="outlined"
                                    style={{width: "100%"}}
                                    onChange={({target}) => {
                                      const newCheckedKeys = (target.value || "").length === 0 ? [] : [target.value];
                                      handleCriteriaChange(newCheckedKeys, CRITERIA_FILTER_TYPE_STRING);
                                    }}
                                  />
                                </Fragment>
                              )
                            : isTree
                              ? (
                                <EnhancedTree
                                  key={treeKey}
                                  tree={data}
                                  idKey="id"
                                  labelKey="label"
                                  childrenKey="children"
                                  defaultExpandedKeys={defaultExpandedKeys}
                                  selectable
                                  getIsNodeSelectable={getTreeNodeIsSelectable}
                                  defaultSelectedKeys={defaultSelectedKeys}
                                  onSelect={handleCriteriaChange}
                                  showLevelSelector
                                  showChildrenSelector
                                />
                              )
                              : (
                                <InfiniteScrollTable
                                  data={data}
                                  getRowKey={({id}) => id}
                                  showHeader={false}
                                  columns={[
                                    {
                                      title: "",
                                      dataIndex: "label",
                                      minWidthToContent: true,
                                      noFilter: true
                                    }
                                  ]}
                                  rowSelection={{
                                    selectedRowKeys: checkedKeys,
                                    onChange: handleCriteriaChange
                                  }}
                                  leftActions={
                                    <Fragment>
                                      <Tooltip title={t("components.criteria.table.actions.selectAll.tooltip")}>
                                        <span>
                                          <IconButton
                                            aria-label={t("components.criteria.table.actions.selectAll.ariaLabel")}
                                            onClick={() => handleCriteriaChange(data.map(({id}) => id))}
                                            style={{padding: 8}}
                                            disabled={isCheckDisabled}
                                          >
                                            <LibraryAddCheckIcon />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                      <Tooltip title={t("components.criteria.table.actions.deselectAll.tooltip")}>
                                        <span>
                                          <IconButton
                                            aria-label={t("components.criteria.table.actions.deselectAll.ariaLabel")}
                                            onClick={() => handleCriteriaChange([])}
                                            style={{padding: 8}}
                                            disabled={isCheckDisabled}
                                          >
                                            <FilterNoneIcon style={{padding: 1}} />
                                          </IconButton>
                                        </span>
                                      </Tooltip>
                                    </Fragment>
                                  }
                                  isCheckDisabled={isCheckDisabled}
                                />
                              )
                          }
                        </div>
                      )
                }
              </Call>
            </div>
          ))}
        </div>
      </div>

      <Dialog
        open={criteriaBackup !== null}
        fullWidth
        maxWidth="sm"
        onClose={() => setCriteriaBackup(null)}
      >
        <DialogContent>
          {t("components.criteria.dialogs.warning.losingFilters.title")}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCriteriaBackup(null);
              setTabIdBackup(null);
            }}
          >
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setCriteriaBackup(null);
              onSetCriteria(criteriaBackup);
              setTabIdBackup(null);
              setTabId(tabIdBackup);
              handleFetch({
                dimensionId: dimensions[tabIdBackup].id,
                criteria: {
                  ...criteriaBackup,
                  [dimensions[tabIdBackup].id]: undefined
                }
              });
            }}
            color="primary"
          >
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isObsCountWarningVisible}
        fullWidth
        maxWidth="sm"
        onClose={onCriteriaObsCountWarningHide}
      >
        <DialogContent>
          {t("components.criteria.dialogs.warning.obsCountWarning.content")}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCriteriaObsCountWarningHide}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            autoFocus
            onClick={() => {
              onCriteriaObsCountWarningHide();
              onSubmit();
            }}
          >
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={missingFilterValues !== null}
        fullWidth
        maxWidth="sm"
        onClose={onCodelistFullHide}
      >
        <CustomDialogTitle onClose={onCodelistFullHide}>
          {t("components.criteria.dialogs.warning.missingFilterValues.title", {dimension: dimensions[tabId].label})}
        </CustomDialogTitle>
        <DialogContent>
          <InfiniteScrollTable
            data={missingFilterValues}
            getRowKey={({id}) => id}
            showHeader={false}
            columns={[
              {
                title: "",
                dataIndex: "name",
                render: (_, {id, name}) => `[${id}] ${name}`,
                renderText: (_, {id, name}) => `[${id}] ${name}`,
                noFilter: true,
                noSort: true
              }
            ]}
            height={400}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCodelistFullHide}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Criteria);