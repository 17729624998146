import moment from "moment";
import {FREQ_DIMENSION_KEY} from "./jsonStat";

export const CRITERIA_FILTER_TYPE_CODES = "CodeValues";
export const CRITERIA_FILTER_TYPE_STRING = "StringValues";
export const CRITERIA_FILTER_TYPE_RANGE = "TimeRange";
export const CRITERIA_FILTER_TYPE_PERIODS = "TimePeriod";
export const criteriaFilterTypes = [
  CRITERIA_FILTER_TYPE_CODES,
  CRITERIA_FILTER_TYPE_STRING,
  CRITERIA_FILTER_TYPE_RANGE,
  CRITERIA_FILTER_TYPE_PERIODS
];

export const CRITERIA_FILTER_TYPE_EMPTY = "EmptyValue";

export const FREQ_ANNUAL = "A";
export const FREQ_SEMESTER = "S";
export const FREQ_QUARTERLY = "Q";
export const FREQ_MONTHLY = "M";
const frequencies = [FREQ_MONTHLY, FREQ_QUARTERLY, FREQ_SEMESTER, FREQ_ANNUAL];

export const getDimensionCriteria = (criteria, dimensionId) => (criteria || []).find(({id}) => id === dimensionId) || null;

const getDefaultMaxPeriod = freq => {
  if (freq === FREQ_ANNUAL) {
    return moment().add(-1, 'y').format("YYYY-MM-DD");
  } else if (freq === FREQ_SEMESTER) {
    return moment().add(-6, 'M').format("YYYY-MM-DD");
  } else if (freq === FREQ_QUARTERLY) {
    return moment().add(-3, 'M').format("YYYY-MM-DD");
  } else if (freq === FREQ_MONTHLY) {
    return moment().add(-1, 'M').format("YYYY-MM-DD");
  } else {
    return moment().add(-1, 'd').format("YYYY-MM-DD");
  }
};

export const getMinAndMax = (timePeriodCodes, freq, nodeConfig) => {

  const nodeExtras = nodeConfig?.extras || [];
  const nodeConfigMin = nodeExtras.find(({key}) => key === "TimePeriodRangeStart")?.value || null;
  const nodeConfigMax = nodeExtras.find(({key}) => key === "TimePeriodRangeEnd")?.value || null;

  let min = nodeConfigMin || moment().add(-20, 'y').format("YYYY") + "-01-01";
  let max = nodeConfigMax || getDefaultMaxPeriod(freq);

  return {
    min: timePeriodCodes && timePeriodCodes[0] && timePeriodCodes[0].id
      ? moment(timePeriodCodes[0].id).format("YYYY-MM-DD")
      : min,
    max: timePeriodCodes && timePeriodCodes[1] && timePeriodCodes[1].id
      ? moment(timePeriodCodes[1].id).format("YYYY-MM-DD")
      : max,
    missingRange: (!timePeriodCodes || timePeriodCodes.length !== 2)
  }
};

export const getFreqFromCriteria = (criteria, freqDimCodes) => {
  const defaultFreq = "A";

  let availableFreqValues = [...frequencies];

  if (freqDimCodes) {
    availableFreqValues = availableFreqValues.filter(freq => freqDimCodes.map(({id}) => id).includes(freq));
  }

  const selectedFreqValues = (criteria && criteria[FREQ_DIMENSION_KEY])
    ? (criteria[FREQ_DIMENSION_KEY].filterValues || [])
    : [];

  return availableFreqValues.find(freq => selectedFreqValues.includes(freq)) || availableFreqValues[0] || defaultFreq;
};

export const getTimePeriod = (initialTimePeriod, criteria, timeDim, timeDimCodes, freqDimCodes, nodeConfig) => {

  const freq = freqDimCodes
    ? getFreqFromCriteria(criteria, freqDimCodes)
    : initialTimePeriod.freq;

  const minAndMax = timeDimCodes
    ? getMinAndMax(timeDimCodes, freq, nodeConfig)
    : {
      min: initialTimePeriod.minDate,
      max: initialTimePeriod.maxDate,
      missingRange: initialTimePeriod.missingRange,
    };

  return {
    freq: freq,
    selectorType: (criteria?.[timeDim]?.type || initialTimePeriod.selectorType || CRITERIA_FILTER_TYPE_RANGE),
    minDate: minAndMax.min,
    maxDate: minAndMax.max,
    fromDate: (criteria?.[timeDim]?.from && criteria?.[timeDim]?.from > minAndMax.min)
      ? criteria?.[timeDim]?.from
      : minAndMax.min,
    toDate: (criteria?.[timeDim]?.to && criteria?.[timeDim]?.to < minAndMax.max)
      ? criteria?.[timeDim]?.to
      : minAndMax.max,
    periods: (criteria?.[timeDim]?.period || 1),
    missingRange: minAndMax.missingRange
  }
};

export const getCriteriaArrayFromObject = criteriaObj => {
  const criteriaArr = [];
  Object.keys(criteriaObj).forEach(key => {
    if (criteriaObj[key] !== null && criteriaObj[key] !== undefined) {
      criteriaArr.push({
        ...criteriaObj[key],
        filterValues: (criteriaObj[key]?.filterValues || []).sort()
      });
    }
  });

  return criteriaArr.sort();
};

export const getCriteriaObjectFromArray = criteriaArr => {
  let criteriaObj = {};
  (criteriaArr || []).forEach((dimensionCriteria) => {
    criteriaObj = {
      ...criteriaObj,
      [dimensionCriteria.id]: dimensionCriteria
    }
  });

  return criteriaObj;
};