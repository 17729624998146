import React, {Fragment, useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import DataViewerHeader from "./Header";
import DataViewerSidebar from "./Sidebar";
import DataViewerViewer from "./Viewer";
import DataViewerFooter from "./Footer";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
  fetchDataset,
  hideDatasetCriteriaAlert,
  hideDatasetDownloadWarning,
  hideDatasetUnavailableViewWarning,
  showDatasetCriteria
} from "../../state/dataset/datasetActions";
import Call from "../../hocs/call";
import {v4 as uuidv4} from 'uuid';
import FullscreenDialog from "../fullscreen-dialog";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "auto"
  },
  header: {
    width: "100%",
    padding: "4px 16px 12px"
  },
  page: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "0 16px",
    minHeight: 400,
    minWidth: 560
  },
  sidebar: {
    height: "100%"
  },
  viewer: {
    height: "100%",
  },
  footer: {
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    padding: 8
  }
});

const mapStateToProps = ({config, app, appConfig, catalog, dataset}) => ({
  indicatorsBaseUrl: config.externalServices?.indicator,
  defaultLanguage: app.language,
  languages: app.languages,
  maxTableColCount: appConfig.tableConfig.maxColCount,
  maxMapPointCount: appConfig.mapConfig.maxPointCount,
  datasetMap: (catalog.uncategorizedDatasets || []).reduce((acc, v) => ({...acc, [v.identifier]: v}), (catalog.datasets || {})),
  viewerMode: dataset.viewerMode,
  dataset: dataset.dataset,
  datasetFetchStart: dataset.datasetFetchStart,
  datasetFetchError: dataset.datasetFetchError,
  isFetchDatasetDisabled: dataset.isFetchDatasetDisabled,
  dimensions: dataset.dimensions,
  territoryDim: dataset.territoryDim,
  timeDim: dataset.timeDim,
  criteria: dataset.criteria,
  isDownloadWarningVisible: dataset.isDownloadWarningVisible,
  isUnavailableViewWarningVisible: dataset.isUnavailableViewWarningVisible,
  isCriteriaAlertVisible: dataset.isCriteriaAlertVisible,
  isEmptyData: dataset.isEmptyData,
  isTooBigData: dataset.isTooBigData,
  isTooLongQuery: dataset.isTooLongQuery,
  tableColCount: dataset.tableColCount,
  mapPointCount: dataset.mapPointCount,
  isIndicatorCreateVisible: dataset.isIndicatorCreateVisible,
  indicators: dataset.indicators,
  isAdditionalDatasetCreateVisible: dataset.isAdditionalDatasetCreateVisible,
  additionalDatasets: dataset.additionalDatasets,
  isFullscreen: dataset.isFullscreen
});

const mapDispatchToProps = dispatch => ({
  onDatasetFetch: ({nodeId, nodeCode, datasetId, datasetTitle, criteria, timeDim, territoryDim, indicators, additionalDatasets, defaultLanguage, languages, indicatorsBaseUrl}) =>
    dispatch(fetchDataset(nodeId, nodeCode, datasetId, datasetTitle, criteria, timeDim, territoryDim, indicators, additionalDatasets, defaultLanguage, languages, indicatorsBaseUrl)),
  onDownloadHide: () => dispatch(hideDatasetDownloadWarning()),
  onUnavailableViewHide: () => dispatch(hideDatasetUnavailableViewWarning()),
  onCriteriaAlertHide: () => dispatch(hideDatasetCriteriaAlert()),
  onCriteriaShow: () => dispatch(showDatasetCriteria())
});

const handleStyle = () => {
  // const hubFooterHeight = document.getElementById("footer").offsetHeight || 0;
  // $("#data-viewer").height(`calc(100% - ${hubFooterHeight}px)`);

  const headerHeight = document.getElementById("data-viewer__header").offsetHeight || 0;
  const footerHeight = document.getElementById("data-viewer__footer").offsetHeight || 0;
  $("#data-viewer__page").height(`calc(100% - ${headerHeight + footerHeight}px)`);

  const sidebarWidth = document.getElementById("data-viewer__page__sidebar").offsetWidth || 0;
  $("#data-viewer__page__viewer").width(`calc(100% - ${sidebarWidth}px)`);
};

function DataViewer(props) {
  const {
    classes,

    nodeId,
    nodeCode,
    categoryPath,
    datasetId,
    datasetTitle,
    viewId,
    attachedFiles,
    nodeExtras,
    maxObservation,

    indicatorsBaseUrl,
    defaultLanguage,
    languages,
    maxTableColCount,
    maxMapPointCount,
    datasetMap,
    viewerMode,
    dataset,
    datasetFetchStart,
    datasetFetchError,
    isFetchDatasetDisabled,
    dimensions,
    territoryDim,
    timeDim,
    criteria,
    isDownloadWarningVisible,
    isUnavailableViewWarningVisible,
    isCriteriaAlertVisible,
    isEmptyData,
    isTooBigData,
    isTooLongQuery,
    tableColCount,
    mapPointCount,
    isIndicatorCreateVisible,
    indicators,
    isAdditionalDatasetCreateVisible,
    additionalDatasets,

    onDatasetFetch,
    onDownloadHide,
    onUnavailableViewHide,
    onCriteriaShow,
    onCriteriaAlertHide
  } = props;

  const {t} = useTranslation();

  const [chartId] = useState("chart__" + uuidv4());

  const [mapId] = useState("map__" + uuidv4());

  const [metadataUrl, setMetadataUrl] = useState(null);

  const handleMetadataOpen = metadataUrl => {
    setMetadataUrl(metadataUrl);
  };

  const handleMetadataClose = () => {
    setMetadataUrl(null);
  };

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle)
  }, []);

  useEffect(() => {
    handleStyle();
  });

  return (
    <Fragment>
      <div id="data-viewer__wrapper" className={classes.root}>
        <div id="data-viewer" className={classes.root}>
          <Call
            cb={onDatasetFetch}
            cbParam={{
              nodeId,
              nodeCode,
              datasetId,
              criteria,
              datasetTitle,
              timeDim,
              territoryDim,
              indicators,
              additionalDatasets,
              defaultLanguage,
              languages,
              indicatorsBaseUrl
            }}
            disabled={!nodeId || !datasetId || !criteria || !datasetTitle || isFetchDatasetDisabled}
          >
            <div id="data-viewer__header" className={classes.header}>
              <DataViewerHeader
                viewerMode={viewerMode}
                nodeId={nodeId}
                nodeExtras={nodeExtras}
                datasetId={datasetId}
                datasetTitle={datasetTitle}
                viewId={viewId}
                attachedFiles={attachedFiles}
                chartId={chartId}
                mapId={mapId}
                onRender={handleStyle}
                onMetadataShow={handleMetadataOpen}
                datasetMap={datasetMap}
              />
            </div>
            <div id="data-viewer__page" className={classes.page}>
              <div id="data-viewer__page__sidebar" className={classes.sidebar}>
                <DataViewerSidebar
                  viewerMode={viewerMode}
                  nodeId={nodeId}
                  nodeCode={nodeCode}
                  nodeExtras={nodeExtras}
                  datasetId={datasetId}
                  datasetTitle={datasetTitle}
                  onMetadataShow={handleMetadataOpen}
                  maxObservation={maxObservation}
                  isCriteriaAlertVisible={isCriteriaAlertVisible}
                  isEmptyData={isEmptyData}
                  isTooBigData={isTooBigData}
                  isTooLongQuery={isTooLongQuery}
                  tableColCount={tableColCount}
                  maxTableColCount={maxTableColCount}
                  mapPointCount={mapPointCount}
                  maxMapPointCount={maxMapPointCount}
                  onCriteriaShow={onCriteriaShow}
                  onCriteriaAlertHide={onCriteriaAlertHide}
                  chartId={chartId}
                  mapId={mapId}
                  datasetMap={datasetMap}
                  isIndicatorCreateVisible={isIndicatorCreateVisible}
                  isAdditionalDatasetCreateVisible={isAdditionalDatasetCreateVisible}
                />
              </div>
              <div id="data-viewer__page__viewer" className={classes.viewer}>
                <DataViewerViewer
                  viewerMode={viewerMode}
                  nodeId={nodeId}
                  nodeExtras={nodeExtras}
                  datasetId={datasetId}
                  datasetTitle={datasetTitle}
                  dataset={dataset}
                  datasetFetchStart={datasetFetchStart}
                  datasetFetchError={datasetFetchError}
                  dimensions={dimensions}
                  isEmptyData={isEmptyData}
                  maxObservation={maxObservation}
                  chartId={chartId}
                  mapId={mapId}
                  timeDim={timeDim}
                />
              </div>
            </div>
            <div id="data-viewer__footer" className={classes.footer}>
              <DataViewerFooter
                viewerMode={viewerMode}
                dataset={{
                  nodeCode: nodeCode,
                  categoryPath: categoryPath,
                  datasetId: datasetId,
                  datasetTitle: datasetTitle,
                  viewId: viewId
                }}
                onDatasetsChange={handleStyle}
              />
            </div>
          </Call>
        </div>
      </div>

      <Dialog
        open={isDownloadWarningVisible}
        maxWidth="md"
        onClose={onDownloadHide}
      >
        <DialogContent>
          {t("scenes.dataViewer.dialogs.downloadFormat.content")}
        </DialogContent>
        <DialogActions>
          <Button onClick={onDownloadHide}>
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isUnavailableViewWarningVisible}
        maxWidth="md"
        onClose={onUnavailableViewHide}
      >
        <DialogContent>
          {t("scenes.dataViewer.dialogs.unavailableView.content")}
        </DialogContent>
        <DialogActions>
          <Button onClick={onUnavailableViewHide}>
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <FullscreenDialog
        open={metadataUrl !== null}
        onClose={handleMetadataClose}
      >
        <CustomDialogTitle onClose={handleMetadataClose}>
          {t("scenes.dataViewer.sidebar.dialogs.metadata.title")}
        </CustomDialogTitle>
        <DialogContent style={{height: 480}}>
          <iframe
            title={"title"}
            src={metadataUrl}
            style={{
              border: 0,
              width: "100%",
              height: "calc(100% - 6px)"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMetadataClose}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </FullscreenDialog>
    </Fragment>
  );
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DataViewer);