import React, {Fragment, useState} from "react";
import {withTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FullscreenDialog from "../fullscreen-dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import MetadataIcon from "../custom-icons/MetadataIcon";

const CatalogMetadataButton = ({
                                 t,
                                 metadataUrl,
                                 label,
                                 iconSize
                               }) => {

  const [isVisible, setVisibility] = useState(false);

  const onOpen = () => {
    setVisibility(true);
  };

  const onClose = ev => {
    ev.stopPropagation();
    setVisibility(false);
  };

  if (!metadataUrl) {
    return <span/>;
  }

  return (
    <Fragment>
      <Tooltip title={label || t("components.catalogMetadataButton.label")}>
        <IconButton
          size={iconSize}
          onClick={ev => {
            ev.stopPropagation();
            onOpen();
          }}
          aria-label={label || t("components.catalogMetadataButton.label")}
        >
          <MetadataIcon/>
        </IconButton>
      </Tooltip>

      <FullscreenDialog
        open={isVisible}
        onClose={onClose}
      >
        <CustomDialogTitle onClose={onClose}>
          {label || t("components.catalogMetadataButton.label")}
        </CustomDialogTitle>
        <DialogContent>
          <iframe
            title={"title"}
            src={metadataUrl}
            style={{
              border: 0,
              width: "100%",
              height: "calc(100% - 6px)"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </FullscreenDialog>

    </Fragment>
  );
}

export default withTranslation()(CatalogMetadataButton);