import React, {useMemo} from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import {getDimensionAttributeMap, getFiltersValueInfo} from "../../utils/jsonStat";
import DatasetFilterAttributeIcon from "../dataset-filter-attribute-icon";

const styles = theme => ({
  filters: {
    fontSize: 14
  },
  filter: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  filterElement: {
    display: "inline-block",
    verticalAlign: "middle"
  }
});

const DatasetStaticFilters = ({
                                t,
                                classes,
                                jsonStat,
                                layout,
                                labelFormat,
                                isDimensionAllowed
                              }) => {

  const dimAttributeMap = useMemo(() => getDimensionAttributeMap(jsonStat, t), [jsonStat, t]);

  const filtersValueInfo = useMemo(() => {
    if (!layout || !jsonStat) {
      return {};
    } else {
      const filterDims = layout.filters.filter(dim => !isDimensionAllowed || isDimensionAllowed(dim));
      return getFiltersValueInfo(filterDims, layout.filtersValue, jsonStat, labelFormat, t);
    }
  }, [layout, isDimensionAllowed, jsonStat, labelFormat, t]);

  return (
    <div className={classes.filters}>
      {(filtersValueInfo?.values || []).map(({dim, dimLabel, value, valueLabel}, idx, arr) => (
        <span key={idx} className={classes.filter}>
          <span className={classes.filterElement}>
            <b>{dimLabel}</b>:&nbsp;
          </span>
          <span className={classes.filterElement}>
            <i>{valueLabel}</i>
          </span>
          <span className={classes.filterElement}>
            <DatasetFilterAttributeIcon
              datasetId={filtersValueInfo?.datasetId}
              jsonStat={jsonStat}
              dimension={dim}
              dimensionValues={[value]}
              datasetDimAttributeMap={dimAttributeMap?.[filtersValueInfo?.datasetId]}
              labelFormat={labelFormat}
            />
          </span>
          <span className={classes.filterElement}>
            {idx < arr.length - 1 ? "," : ""}&nbsp;
          </span>
        </span>
      ))}
    </div>
  );
};

export default compose(
  withTranslation(),
  withStyles(styles)
)(DatasetStaticFilters);