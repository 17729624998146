import React, {useEffect} from "react";
import CustomEmpty from "../custom-empty";
import CircularProgress from "@material-ui/core/CircularProgress";

const ExternalLogin = ({loginCode}) => {

  useEffect(() => {
    if (loginCode) {
      localStorage.setItem("loginCode", loginCode);
    }
    let new_window = window.open(window.location.href, '_self');
    new_window.close();
  }, [loginCode]);

  return (
    <CustomEmpty
      text="Loading..."
      image={<CircularProgress style={{color: "white"}}/>}
      style={{color: "white"}}
    />
  )
};

export default ExternalLogin;