import {REQUEST_SUCCESS} from "../request/requestActions";

const actionTransformerMiddleware = () => next => action => {
  let transformedAction = {
    ...action
  };

  if (action.type === REQUEST_SUCCESS) {
  }
  return next(transformedAction);
};

export default actionTransformerMiddleware;
