import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {withTheme} from "@material-ui/styles";
import {useTranslation} from "react-i18next";
import Call from "../../hocs/call/index";
import {v4 as uuidv4} from "uuid";
import {connect} from "react-redux";
import CustomEmpty from "../custom-empty";
import {clearMapGeometry, fetchMapGeometry, setMapGeometryChangedStatus} from "../../state/maps/mapsActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from '@material-ui/icons/Error';

const $ = window.jQuery;

const styles = theme => ({
  mapWrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  map: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 0,
    filter: "blur(0)"
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    visibility: "hidden"
  }
});

const spinnerOverlayStyle = {
  position: "absolute",
  zIndex: 3,
  color: "white",
  background: "rgba(0, 0, 0, 0.1)"
};

const spinnerTextStyle = {
  fontWeight: "bold"
};

const mapStateToProps = ({appConfig, maps}) => ({
  mapPreviewSettings: appConfig.mapTerritoryPreviewConfig,
  mapLayersConfig: maps.mapLayersConfig,
  maps: maps.maps
});

const mapDispatchToProps = dispatch => ({
  onGeometryFetch: (mapStateUuid, nodeId, territoryDimValues, format, detailLevel, timeDimValue, territoryDimCodelist) =>
    dispatch(fetchMapGeometry(mapStateUuid, nodeId, territoryDimValues, format, detailLevel, timeDimValue, territoryDimCodelist, true)),
  onGeometryChangedStatusSet: (mapStateUuid, changed) => dispatch(setMapGeometryChangedStatus(mapStateUuid, changed)),
  onGeometryClear: mapStateUuid => dispatch(clearMapGeometry(mapStateUuid))
});

function MapPreview(props) {
  const {
    classes,
    theme,

    mapPreviewSettings,
    mapLayersConfig,
    defaultExtent,
    maps,

    onGeometryFetch,
    onGeometryChangedStatusSet,
    onGeometryClear,

    nodeId,
    detailLevel,
    territoryDimCodelist,
    timeDimValue,
    territories,
    changedTerritories
  } = props;

  const {t} = useTranslation();
  const [mapId, setMapId] = useState(null);

  const [isFetchEnabled, setIsFetchEnabled] = useState(true);

  const [filteredGeometries, setFilteredGeometries] = useState(null);
  const [geometryMap, setGeometryMap] = useState(null);

  const [isLayerReady, setIsLayerReady] = useState(false);

  const showSpinner = useCallback(() => {
    $(`#${mapId}__wrapper`).addClass("map__overlay--visible");
  }, [mapId]);

  const hideSpinner = useCallback(() => {
    $(`#${mapId}__wrapper`).removeClass("map__overlay--visible");
  }, [mapId]);

  const fetchGeometry = useCallback((cbParam) => {
    const {
      nodeId,
      detailLevel,
      territoryDimCodelist,
      territories,
      mapStateUuid
    } = cbParam;

    onGeometryFetch(mapStateUuid, nodeId, territories, "geojson", detailLevel, (timeDimValue || null), (territoryDimCodelist || null));
    showSpinner();
  }, [timeDimValue, onGeometryFetch, showSpinner]);

  // handle mapId and destroy map on component unmount
  useEffect(() => {
    const mapId = uuidv4();
    setMapId(mapId);

    return () => {
      if (window.document.getElementById(mapId) && window.LMap.isInitialized(mapId)) {
        window.LMap.destroyMap(mapId);
      }
    }
  }, [onGeometryClear]);

  // init map
  useEffect(() => {
    if (mapId && !window.LMap.isInitialized(mapId)) {

      const baseLayer = mapPreviewSettings.baseLayer || mapLayersConfig?.[0]?.id || null;
      const layers = (mapLayersConfig || []).filter(({id}) => id === baseLayer);

      const mapOptions = {
        baseLayer: baseLayer,
        layers: layers,
        defaultExtent: mapPreviewSettings.defaultExtent,
        showZoom: false,
        showSelection: false,
        showBaseLayerRadio: false,
        showScale: false,
        showLegend: false,
        disablePanAndZoom: true,
        primaryColor: theme.palette.primary.main
      };

      window.LMap.initMap(mapId, mapOptions);
    }
  }, [mapId, mapPreviewSettings, mapLayersConfig, defaultExtent, theme.palette.primary.main, t]);

  // handle resize
  useEffect(() => {
    if (mapId) {
      const func = () => window.LMap.handleViewportChange(mapId);
      window.addEventListener("resize", func);
      return () => {
        window.removeEventListener("resize", func);
      }
    }
  }, [mapId]);

  // handle detailLevel changes
  useEffect(() => {
    if (mapId) {
      setFilteredGeometries(null);
      setGeometryMap(null);

      setIsFetchEnabled(true);
    }
  }, [mapId, detailLevel]);

  // handle new fetched geometries
  useEffect(() => {
    if (mapId && maps?.[mapId]?.changed === true) {
      const geometries = maps[mapId].geometries;
      if (geometries) {

        const filteredGeometries = geometries.map(geometry => ({
          value: 0,
          id: geometry.id,
          identifier: geometry.uniqueId,
          geometry: geometry.geoJson
        }));
        setFilteredGeometries(filteredGeometries);

        const geometryMap = {};
        filteredGeometries.forEach(({id, identifier}) => geometryMap[id] = identifier);
        setGeometryMap(geometryMap);
      }

      onGeometryChangedStatusSet(mapId, false);
      setIsFetchEnabled(false);
    }
  }, [mapId, maps, onGeometryChangedStatusSet]);

  // add or update layer with filtered geometries
  useEffect(() => {
    if (filteredGeometries !== null) {

      showSpinner();

      const mapSettings = {
        paletteStartColor: mapPreviewSettings.geometryColor,
        paletteEndColor: mapPreviewSettings.geometryColor
      }

      setTimeout(
        () => {
          const layerOptions = {
            srid: "EPSG:4326",
            useGeoJSON: true,
            settings: mapSettings,
            onDataRender: () => {
              hideSpinner();
              setIsLayerReady(true);
            },
            borderColor: mapPreviewSettings.geometryBorderColor,
            disableSettings: true,
            onDataHover: null
          };
          window.LMap.updateLayer(mapId, filteredGeometries, null, layerOptions);
        },
        0
      );
    }
  }, [mapId, filteredGeometries, mapPreviewSettings, showSpinner, hideSpinner]);

  // handle selected geometries
  useEffect(() => {
    if (isLayerReady && geometryMap) {
      const changed = [];
      changedTerritories.forEach(({id, selected}) => {
        if (geometryMap[id]) {
          changed.push({
            identifier: geometryMap[id],
            color: selected
              ? mapPreviewSettings.selectedGeometryColor
              : mapPreviewSettings.geometryColor,
            borderColor: selected
              ? mapPreviewSettings.selectedGeometryBorderColor
              : mapPreviewSettings.geometryBorderColor
          });
        }
      });

      window.LMap.setDataStyle(mapId, changed);
    }
  }, [mapId, isLayerReady, geometryMap, mapPreviewSettings, theme.palette.secondary.main, changedTerritories]);

  if (!mapId) {
    return <span/>;
  }

  const detailLevelMissing = !detailLevel;
  const geometriesMissing = filteredGeometries && filteredGeometries.length === 0;
  const fetchingGeometriesError = maps[mapId]?.error;

  const isMapUnavailable = detailLevelMissing || geometriesMissing || fetchingGeometriesError;

  let unavailableMapErrorText;
  if (isMapUnavailable) {
    showSpinner();
    if (detailLevelMissing) {
      console.debug("missing detail level");
      unavailableMapErrorText = t("components.map.noDetailLevel");
    } else if (geometriesMissing) {
      unavailableMapErrorText = t("components.map.noDataToDisplay"); // TODO: add detailLevel information
    } else if (fetchingGeometriesError) {
      unavailableMapErrorText = t("components.map.fetchingGeometriesError");
    } else {
      unavailableMapErrorText = null;
    }
  }

  return (
    <Fragment>
      <Call
        cb={fetchGeometry}
        cbParam={{
          nodeId: nodeId,
          detailLevel: detailLevel !== 9999 ? detailLevel : 3, // TODO: add support for point data with different detail level
          territoryDimCodelist: territoryDimCodelist,
          territories: territories,
          mapStateUuid: mapId
        }}
        disabled={!isFetchEnabled || !detailLevel || !territories}
      >
        <div id={`${mapId}__wrapper`} className={`${classes.mapWrapper} map__overlay--visible`}>
          <div className={`overlay ${classes.overlay}`}>
            <CustomEmpty
              text={isMapUnavailable
                ? unavailableMapErrorText
                : isFetchEnabled
                  ? t("components.map.spinners.loading") + "..."
                  : t("components.map.spinners.rendering") + "..."
              }
              style={spinnerOverlayStyle}
              textStyle={spinnerTextStyle}
              image={isMapUnavailable
                ? <ErrorIcon/>
                : isFetchEnabled
                  ? <CircularProgress style={{color: "white"}}/>
                  : null
              }
            />
          </div>
          <div id={mapId} className={`map ${classes.map}`}/>
        </div>
      </Call>
    </Fragment>
  );
}

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(MapPreview);
