import React, {Component, Fragment} from 'react';
import Box from "@material-ui/core/Box";
import Hero from "../hero";
import CardsGrid from "../cards-grid";
import PageSection from "../page-section";
import Button from "@material-ui/core/Button";
import Page from "../page";
import NodeHeader from "../node-header";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";
import {getNodeInternalUrl, goToHubDashboards} from "../../links";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import SanitizedHTML from "../sanitized-html";
import {HashLink} from 'react-router-hash-link';
import {withRouter} from "react-router";
import {getPageTitle} from "../../utils/other";
import Footer from "../footer";
import Grid from "@material-ui/core/Grid";
import {NodeVisibility} from "../../model/IHubMinimalNode.d.ts";

import themeConfig from "../../config/theme/config.json";

const styles = theme => ({
  defaultBackground: {
    backgroundColor: theme.palette.primary.light
  },
  main: {
    width: "100%",
    height: "100%"
  },
  fullWidthContainer: {
    backgroundColor: "#f5f5f5",
    width: "100%"
  },
  container: {
    paddingTop: theme.spacing(3)
  },
  anchorContainer: {
    position: "relative"
  },
  anchor: {
    position: "absolute",
    top: -theme.mixins.toolbar.minHeight
  },
  section: {
    paddingTop: theme.spacing(3)
  },
  nodesContainer: {
    marginTop: theme.spacing(2)
  }
});

const mapStateToProps = state => ({
  hub: state.hub,
  baseURL: state.config.baseURL,
  isA11y: state.app.isA11y
});

class Home extends Component {

  info = React.createRef();
  nodesRef = React.createRef();

  state = {
    isListMode: true,
  };

  scrollToRef(ref) {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
    ref.current.focus({preventScroll: true});
  }

  /*  onListModeToggle = () => {
      this.setState({isListMode: !this.state.isListMode});
    };*/

  render() {

    const {classes, hub, baseURL, isA11y, t, location} = this.props;
    // const {isListMode} = this.state;

    const params = new URLSearchParams(location.search);
    params.set("accessible", "true");
    const paramsStr = params.toString();
    const path = location.pathname;

    return (
      <Fragment>
        {!isA11y && (
          <a
            href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
            target="_self"
            className="skip-link sr-only"
          >
            {t("commons.hashLinks.accessible")}
          </a>
        )}
        <HashLink
          to={{hash: "#main", search: location.search}}
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.main")}
        </HashLink>
        {hub.hub.description && hub.hub.description.length > 0 && (
          <HashLink
            to={{hash: "#hub-information", search: location.search}}
            className="skip-link sr-only"
          >
            {t("commons.hashLinks.hubInformation")}
          </HashLink>
        )}
        <HashLink
          to={{hash: "#nodes", search: location.search}}
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.nodes")}
        </HashLink>
        <HashLink
          to={{hash: "#footer", search: location.search}}
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.footer")}
        </HashLink>
        <Page
          title={hub?.hub?.name
            ? getPageTitle([t("scenes.hubWithName.title", {hubName: hub.hub.name})], t)
            : getPageTitle([t("scenes.hubWithoutName.title")], t)
          }
          id="landing-page"
        >
          <NodeHeader
            noNode
            defaultNodeConfigOpen={this.props.defaultNodeConfigOpen}
            defaultAppConfigOpen={this.props.defaultAppConfigOpen}
            defaultUserConfigOpen={this.props.defaultUserConfigOpen}
            defaultNodesConfigOpen={this.props.defaultNodesConfigOpen}
            hub={hub.hub}
            nodes={hub.nodes}
          />
          <main id="main" className={`${classes.main} home`}>
            <Hero
              title={hub.hub.name}
              slogan={hub.hub.slogan}
              logo={hub.hub.logoURL && <img src={baseURL + hub.hub.logoURL} alt=""/>}
              background={hub.hub.backgroundMediaURL
                ? hub.hub.backgroundMediaURL.match(/\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG|svg|SVG)$/)
                  ? <div style={{background: `url("${baseURL + hub.hub.backgroundMediaURL}") center center / cover no-repeat`}}/>
                  : (
                    <video autoPlay muted loop>
                      <source src={baseURL + hub.hub.backgroundMediaURL}/>
                    </video>
                  )
                : <div className={classes.defaultBackground}/>
              }
              showExtraLogos
            >
              <Grid container justifyContent="center" spacing={2}>
                {hub.hub.description && hub.hub.description.length > 0 && (
                  <Grid item>
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={() => this.scrollToRef(this.info)}
                      id="infos-btn"
                    >
                      {t("scenes.hub.informations")}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    id="nodes-btn"
                    size="large"
                    color="secondary"
                    variant="contained"
                    onClick={() => this.scrollToRef(this.nodesRef)}
                  >
                    {t("scenes.hub.nodes")}
                  </Button>
                </Grid>
                {!isA11y && hub.hub.dashboards && hub.hub.dashboards.length > 0 && themeConfig.enableDashboard && (
                  <Grid item>
                    <Button
                      id="dashboards-btn"
                      size="large"
                      color="secondary"
                      variant="contained"
                      onClick={goToHubDashboards}
                    >
                      {t("scenes.hub.dashboards")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Hero>
            <div className={classes.fullWidthContainer}>
              <Container className={classes.container} id="section-container">
                {hub.hub.description && hub.hub.description.length > 0 && (
                  <div className={classes.anchorContainer} id="description-section">
                    <div
                      id="hub-information"
                      className={classes.anchor}
                      ref={this.info}
                      tabIndex={-1}
                    />
                    <PageSection
                      className={`hub-information ${classes.section}`}
                      sectiontitle={t("scenes.hub.informations")}
                    >
                      <Box textAlign="justify">
                        <SanitizedHTML html={hub.hub.description} allowTarget/>
                      </Box>
                    </PageSection>
                  </div>
                )}
                <div className={classes.anchorContainer} id="nodes-section">
                  <div
                    id="nodes"
                    className={classes.anchor}
                    ref={this.nodesRef}
                    tabIndex={-1}
                  />
                  <PageSection
                    className={classes.section}
                    sectiontitle={t("scenes.hub.nodes")}
                  >
                    <Box className={classes.nodesContainer}>
                      <nav aria-label={t("scenes.hub.nodes")}>
                        <CardsGrid
                          list={hub.nodes
                            .filter(({visible}) => visible !== NodeVisibility.No)
                            .sort((a, b) => a.order - b.order)
                            .map(({code, name, backgroundMediaURL}) => ({
                              code,
                              id: code,
                              label: name,
                              image: backgroundMediaURL ? baseURL + backgroundMediaURL : "./images/default-node-background.jpg"
                            }))
                          }
                          getHref={node => getNodeInternalUrl(node?.code)}
                          {...themeConfig.nodesGridProps}
                        />
                      </nav>
                    </Box>
                  </PageSection>
                </div>
              </Container>
              <Footer/>
            </div>
          </main>
        </Page>
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps),
  withRouter
)(Home);