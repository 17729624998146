import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MetadataIcon = ({style, ...props}) =>
  <SvgIcon {...props} style={{transform: "scale(0.9)", ...style}}>
    <text>
      <tspan
        x="0.31067601"
        y="21.447357"
        style={{
          fontFamily: "Roboto",
          fontSize: "27.4252px",
          fontWeight: "bold",
          strokeWidth: "1.28554"
        }}
      >
        M
      </tspan>
    </text>
  </SvgIcon>;

export default MetadataIcon;