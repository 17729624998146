import {initRequest, RequestMethod} from "../../middlewares/request/requestActions";
import {
  clearDatasetCacheUrl,
  getCreateTemplateUrl,
  getCreateViewUrl,
  getDatasetCacheInfoUrl,
  getDatasetCacheUpdateUrl,
  getDatasetCustomDownloadUrl,
  getDatasetDetailLevels,
  getDatasetDownloadUrl,
  getDatasetExportMetadataUrl,
  getDatasetSdmxQueryUrl,
  getDatasetStructureCodelistsUrl,
  getDatasetStructureCodelistUrl,
  getDatasetStructureDynamicCodelistUrl,
  getDatasetStructureUrl,
  getDatasetTerritories,
  getDatasetUrl,
  getDatasetWithIndicatorsUrl,
  getDatasetWithMarginalCustomDownloadUrl,
  getDatasetWithMarginalUrl,
  getDeleteTemplateUrl,
  getNodeCatalogUrl
} from "../../serverApi/urls";
import {CRITERIA_FILTER_TYPE_RANGE, getCriteriaArrayFromObject} from "../../utils/criteria";
import {AdditionalDataset, Criteria, getIndicatorsDefinition, getMarginalDefinition, Indicator} from "./constants";
import moment from "moment";
import {
  DOWNLOAD_FORMAT_CSV,
  DOWNLOAD_FORMAT_EXCEL,
  downloadFormats,
  getCustomExportRequestBody,
  getCustomExportRequestBodyWithMarginal
} from "../../utils/download";

export const DATASET_STRUCTURE_FETCH_ENABLE = "dataset/structure/fetch/enable";
export const DATASET_STRUCTURE_FETCH = "dataset/structure/fetch";
export const DATASET_FETCH_ENABLE = "dataset/fetch/enable";
export const DATASET_FETCH = "dataset/fetch";
export const DATASET_CRITERIA_SHOW = "dataset/criteria/show";
export const DATASET_CRITERIA_HIDE = "dataset/criteria/hide";
export const DATASET_CRITERIA_ALERT_HIDE = "dataset/criteria/alert/hide";
export const DATASET_CRITERIA_OBS_COUNT_WARNING_HIDE = "dataset/criteria/obs/count/warning/hide";
export const DATASET_STRUCTURE_CODELIST_FETCH = "dataset/structure/codelist/fetch";
export const DATASET_STRUCTURE_CRITERIA_SET = "dataset/structure/criteria/set";
export const DATASET_STRUCTURE_CRITERIA_RESET = "dataset/structure/criteria/reset";

export const DATASET_TERRITORY_SHOW = "dataset/territory/show";
export const DATASET_TERRITORY_HIDE = "dataset/territory/hide";
export const DATASET_TERRITORY_DETAIL_LEVELS_FETCH = "dataset/territory/detail/levels/fetch";
export const DATASET_TERRITORY_DETAIL_LEVEL_TREE_SET = "dataset/territory/detail/level/tree/set";
export const DATASET_TERRITORY_DETAIL_LEVEL_SET = "dataset/territory/detail/level/set";
export const DATASET_TERRITORY_TERRITORIES_FETCH = "dataset/territory/territories/fetch";
export const DATASET_TERRITORY_TERRITORIES_SET = "dataset/territory/territories/set";
export const DATASET_TERRITORY_TERRITORIES_SUBMIT = "dataset/territory/territories/submit";
export const DATASET_TERRITORY_LAST_YEAR_FETCH = "dataset/territory/last/year/fetch";
export const DATASET_CACHE_INFO_FETCH = "dataset/cacheinfo/fetch";
export const DATASET_CACHE_CLEAR = "dataset/cache/clear";
export const DATASET_DELETE_TEMPLATE = "dataset/template/delete";
export const DATASET_CACHE_INFO_TTL_UPDATE = "dataset/cacheinfo/ttl/update";

export const DATASET_STATE_BACKUP = "dataset/state/backup";
export const DATASET_STATE_RESET = "dataset/state/reset";

export const DATASET_VIEWER_MODE_SET = "dataset/viewer/mode/set";

export const DATASET_VIEWER_SET = "dataset/viewer/set";
export const DATASET_VIEWER_TABLE_VISIBILITY_SET = "dataset/viewer/table/visibility/set";
export const DATASET_VIEWER_MAP_VISIBILITY_SET = "dataset/viewer/map/visibility/set";
export const DATASET_VIEWER_CHART_VISIBILITY_SET = "dataset/viewer/chart/visibility/set";
export const DATASET_VIEWER_CHART_TYPE_SET = "dataset/viewer/chart/type/set";

export const DATASET_TABLE_LAYOUT_SUBMIT = "dataset/table/layout/submit";
export const DATASET_TABLE_FILTERS_TREE_SUBMIT = "dataset/table/filters/tree/submit";
export const DATASET_MAP_LAYOUT_SUBMIT = "dataset/map/layout/submit";
export const DATASET_CHART_LAYOUT_SUBMIT = "dataset/chart/layout/submit";
export const DATASET_CHART_FILTERS_TREE_SUBMIT = "dataset/chart/filters/tree/submit";
export const DATASET_CHART_LAYOUT_TERRITORY_DIM_VALUES_SET = "dataset/chart/layout/territory/dim/values/set";

export const DATASET_LABEL_FORMAT_SET = "dataset/label/format/set";

export const DATASET_VARIATION_SET = "dataset/variation/set";

export const DATASET_TEMPORAL_DIM_ORDER_SET = "dataset/temporalDimOrder/set";

export const DATASET_CHART_SETTINGS_SET = "dataset/chart/settings/set";

export const DATASET_MAP_SETTINGS_SET = "dataset/map/settings/set";

export const DATASET_DOWNLOAD_SUBMIT = "dataset/download/submit";
export const DATASET_DOWNLOAD_WARNING_HIDE = "dataset/download/warning/hide";

export const DATASET_VIEW_TEMPLATE_SHOW = "dataset/view/template/show";
export const DATASET_VIEW_TEMPLATE_HIDE = "dataset/view/template/hide";
export const DATASET_VIEW_TEMPLATE_SUBMIT = "dataset/view/template/submit";
export const DATASET_VIEW_ERROR_HIDE = "dataset/view/error/hide";

export const DATASET_UNAVAILABLE_VIEW_WARNING_HIDE = "dataset/unavailable/view/warning/hide";

export const DATASET_HTML_GENERATING_TIME_SET = "dataset/html/generating/time/set";

export const DATASET_SDMX_QUERY_SHOW = "dataset/sdmx/query/show";
export const DATASET_SDMX_QUERY_HIDE = "dataset/sdmx/query/hide";
export const DATASET_SDMX_QUERY_FETCH = "dataset/sdmx/query/fetch";

export const DATASET_INDICATOR_CREATE_VISIBILITY_SET = "dataset/indicator/create/visibility/set";
export const DATASET_INDICATOR_LIST_VISIBILITY_SET = "dataset/indicator/list/visibility/set";
export const DATASET_INDICATOR_PREVIEW_FETCH = "dataset/indicator/preview/fetch";
export const DATASET_INDICATOR_PREVIEW_RESET = "dataset/indicator/preview/reset";
export const DATASET_INDICATOR_PUBLISH = "dataset/indicator/publish";
export const DATASET_INDICATOR_DELETE = "dataset/indicator/delete";
export const DATASET_INDICATOR_WARNING_HIDE = "dataset/indicator/warning/hide";
export const DATASET_INDICATOR_ARITHMETIC_MEAN_VISIBILITY_SET = "dataset/indicator/arithmeticMean/visibility/set";
export const DATASET_INDICATOR_ARITHMETIC_MEAN_VALUES_SET = "dataset/indicator/arithmeticMean/values/set";
export const DATASET_INDICATOR_STANDARD_DEVIATION_VISIBILITY_SET = "dataset/indicator/standardDeviation/visibility/set";
export const DATASET_INDICATOR_COEFFICIENT_OF_VARIATION_VISIBILITY_SET = "dataset/coefficientOfVariation/visibility/set";

export const DATASET_ADDITIONAL_DATASET_CREATE_VISIBILITY_SET = "dataset/additionalDataset/create/visibility/set";
export const DATASET_ADDITIONAL_DATASET_LIST_VISIBILITY_SET = "dataset/additionalDataset/list/visibility/set";
export const DATASET_ADDITIONAL_DATASET_CATALOG_FETCH = "dataset/additionalDataset/catalog/fetch";
export const DATASET_ADDITIONAL_DATASET_CHANGE = "dataset/additionalDataset/change";
export const DATASET_ADDITIONAL_DATASET_STURCTURE_FETCH = "dataset/additionalDataset/structure/fetch";
export const DATASET_ADDITIONAL_DATASET_SUBMIT = "dataset/additionalDataset/submit";
export const DATASET_ADDITIONAL_DATASET_DELETE = "dataset/additionalDataset/delete";
export const DATASET_ADDITIONAL_DATASET_CRITERIA_SET = "dataset/additionalDataset/criteria/set";

export const DATASET_DOWNLOAD_METADATA_SUBMIT = "dataset/download/metadata/submit";

export const DATASET_STRUCTURE_CODELIST_FULL_FETCH = "dataset/structure/codelist/full/fetch";
export const DATASET_STRUCTURE_CODELIST_FULL_HIDE = "dataset/structure/codelist/full/hide";

export const DATASET_SET_FULLSCREEN = "dataset/set/fullscreen";

export const ALL_FULL = "FullAll";
export const ALL_PARTIAL = "PartialAll";
export const STEP_BY_STEP_FULL = "FullStep";
export const STEP_BY_STEP_PARTIAL = "PartialStep";
export const STEP_BY_STEP_DYNAMIC = "Dynamic";

export const CRITERIA_SELECTION_MODE_STEP_BY_STEP = "stepByStep";
export const CRITERIA_SELECTION_MODE_ALL = "all";

export const CRITERIA_SELECTION_TYPE_FULL = "full";
export const CRITERIA_SELECTION_TYPE_PARTIAL = "partial";
export const CRITERIA_SELECTION_TYPE_DYNAMIC = "dynamic";

export const enableDatasetStructureFetch = (nodeCode: string, categoryPath: string[], datasetId: string, viewId: string) => ({
  type: DATASET_STRUCTURE_FETCH_ENABLE,
  payload: {
    datasetId: datasetId,
    nodeCode: nodeCode,
    categoryPath: categoryPath,
    viewId: viewId
  }
});

export const fetchDatasetStructure = (nodeId: number, datasetId: string, viewId: string, isMultiViewerTheme: boolean) => initRequest(
  DATASET_STRUCTURE_FETCH,
  getDatasetStructureUrl(nodeId, datasetId, viewId),
  RequestMethod.GET,
  undefined,
  t => ({
    onStart: t("scenes.dataset.actions.fetchingDatasetStructure")
  }),
  {
    nodeId: nodeId,
    datasetId: datasetId,
    viewId: viewId,
    isMultiViewerTheme: isMultiViewerTheme
  }
);

export const enableDatasetFetch = (maxObservation?: number) => ({
  type: DATASET_FETCH_ENABLE,
  payload: {
    maxObservation
  }
});

export const fetchDataset = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  datasetTitle: string,
  criteria: {[key: string]: Criteria},
  timeDim: string,
  territoryDim: string | null,
  indicators: Indicator[],
  additionalDatasets: AdditionalDataset[],
  defaultLanguage: string,
  languages: string[],
  indicatorsBaseUrl?: string
) => {

  let url, body, baseUrl = undefined;

  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle,
      criteria: criteria,
      timeDim: timeDim,
      territoryDim: territoryDim
    },
    ...additionalDatasets
  ];

  if (indicators.length > 0) {
    url = getDatasetWithIndicatorsUrl(false);
    body = getIndicatorsDefinition(indicators, datasets, timeDim, defaultLanguage, languages);
    baseUrl = indicatorsBaseUrl;

  } else if (additionalDatasets.length > 0) {
    url = getDatasetWithMarginalUrl();
    body = getMarginalDefinition(datasets);
    baseUrl = indicatorsBaseUrl;

  } else {
    url = getDatasetUrl(nodeId, datasetId);
    body = getCriteriaArrayFromObject(criteria)
  }

  return initRequest(
    DATASET_FETCH,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: additionalDatasets.length === 0
        ? t("scenes.dataset.actions.fetchingDataset", {title: datasetTitle})
        : t("scenes.dataset.actions.fetchingMergedDatasets")
    }),
    {
      datasetId: datasetId
    },
    baseUrl,
    (statusCode: number) => (statusCode === 413 || statusCode === 414)
  );
};

export const showDatasetCriteria = () => ({
  type: DATASET_CRITERIA_SHOW
});

export const hideDatasetCriteria = () => ({
  type: DATASET_CRITERIA_HIDE
});

export const hideDatasetCriteriaAlert = () => ({
  type: DATASET_CRITERIA_ALERT_HIDE
});

export const hideDatasetCriteriaObsCountWarning = () => ({
  type: DATASET_CRITERIA_OBS_COUNT_WARNING_HIDE
});

export const fetchDatasetStructureCodelist = (nodeId: number, nodeCode: string, datasetId: string, mode: string, type: string, dimensionId: string, criteria: {[key: string]: Criteria}, freq: string, defaultLastNPeriods: number, preserveFiltersWithDynamic: boolean) => {

  let flatCriteria = getCriteriaArrayFromObject(criteria);

  if (mode === CRITERIA_SELECTION_MODE_ALL) {
    return initRequest(
      DATASET_STRUCTURE_CODELIST_FETCH,
      getDatasetStructureCodelistsUrl(nodeId, datasetId, type === CRITERIA_SELECTION_TYPE_FULL),
      undefined,
      undefined,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingCodelists")
      }),
      {
        nodeCode: nodeCode,
        datasetId: datasetId,
        defaultLastNPeriods: defaultLastNPeriods,
        freq: freq
      }
    );

  } else if (type === CRITERIA_SELECTION_TYPE_DYNAMIC) {
    return initRequest(
      DATASET_STRUCTURE_CODELIST_FETCH,
      getDatasetStructureDynamicCodelistUrl(nodeId, datasetId, dimensionId),
      RequestMethod.POST,
      flatCriteria,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingCodelist")
      }),
      {
        nodeCode: nodeCode,
        datasetId: datasetId,
        defaultLastNPeriods: defaultLastNPeriods,
        preserveFiltersWithDynamic: preserveFiltersWithDynamic,
        freq: freq
      },
      undefined,
      (statusCode: number) => statusCode === 414
    );

  } else {
    return initRequest(
      DATASET_STRUCTURE_CODELIST_FETCH,
      getDatasetStructureCodelistUrl(nodeId, datasetId, dimensionId, type === CRITERIA_SELECTION_TYPE_FULL),
      undefined,
      undefined,
      t => ({
        onStart: t("scenes.dataset.actions.fetchingCodelist")
      }),
      {
        nodeCode: nodeCode,
        datasetId: datasetId,
        defaultLastNPeriods: defaultLastNPeriods,
        freq: freq
      }
    );
  }
};

export const setDatasetStructureCriteria = (criteria: {[key: string]: Criteria}) => ({
  type: DATASET_STRUCTURE_CRITERIA_SET,
  criteria
});

export const resetDatasetStructureCriteria = () => ({
  type: DATASET_STRUCTURE_CRITERIA_RESET
});

export const showDatasetTerritory = () => ({
  type: DATASET_TERRITORY_SHOW
});

export const hideDatasetTerritory = (detailLevel: number, territories: string[]) => ({
  type: DATASET_TERRITORY_HIDE,
  detailLevel,
  territories
});

export const fetchDatasetCacheInfo = (nodeId: number, datasetId: string) => initRequest(
  DATASET_CACHE_INFO_FETCH,
  getDatasetCacheInfoUrl(nodeId, datasetId),
  RequestMethod.GET,
  undefined,
  t => ({
    onStart: t("scenes.dataset.actions.fetchingDatasetCacheInfo")
  })
);

export const updateDatasetCacheTtl = (nodeId: number, datasetId: string, ttl: number) => initRequest(
  DATASET_CACHE_INFO_TTL_UPDATE,
  getDatasetCacheUpdateUrl(nodeId, datasetId),
  RequestMethod.PUT,
  {ttl: ttl},
  t => ({
      onStart: t("scenes.nodesSettings.cacheSettings.messages.updateDataflowCache.start")
  }),
  {
    ttl: ttl,
    nodeId: nodeId,
    datasetId: datasetId
  }
);

export const clearCacheDataset = (nodeId: number, datasetId: string) => initRequest(
  DATASET_CACHE_CLEAR,
  clearDatasetCacheUrl(nodeId, datasetId),
  RequestMethod.POST,
  undefined,
  t => ({
    onStart: t("scenes.dataset.actions.clearCache")
  })
);

export const deleteDatasetTemplate = (nodeId: number, id: number) => initRequest(
  DATASET_DELETE_TEMPLATE,
  getDeleteTemplateUrl(nodeId, id),
  RequestMethod.DELETE,
  undefined,
  t => ({
    onStart: t("scenes.dataset.actions.deletetemplate")
  }),
  {
    nodeId
  }
);

export const fetchDatasetTerritoryDetailLevels = (nodeId: number, datasetId: string, detailLevel: number) => initRequest(
  DATASET_TERRITORY_DETAIL_LEVELS_FETCH,
  getDatasetDetailLevels(nodeId, datasetId),
  RequestMethod.POST,
  undefined,
  t => ({
    onStart: t("scenes.dataset.actions.fetchingDetailLevels")
  }),
  {
    detailLevel: detailLevel
  }
);

export const setDatasetTerritoryDetailLevelTree = (detailLevelTree: any) => ({
  type: DATASET_TERRITORY_DETAIL_LEVEL_TREE_SET,
  detailLevelTree
});

export const setDatasetTerritoryDetailLevel = (detailLevel: number) => ({
  type: DATASET_TERRITORY_DETAIL_LEVEL_SET,
  detailLevel
});

export const fetchDatasetTerritoryTerritories = (nodeId: number, datasetId: string, territoryDim: string, detailLevel: number) => initRequest(
  DATASET_TERRITORY_TERRITORIES_FETCH,
  getDatasetTerritories(nodeId, datasetId, territoryDim, detailLevel),
  RequestMethod.POST,
  undefined,
  t => ({
    onStart: t("scenes.dataset.actions.fetchingTerritories")
  })
);

export const setDatasetTerritoryTerritories = (territories: string) => ({
  type: DATASET_TERRITORY_TERRITORIES_SET,
  territories
});

export const submitDatasetTerritoryTerritories = (criteria: {[key: string]: Criteria}) => ({
  type: DATASET_TERRITORY_TERRITORIES_SUBMIT,
  payload: {
    criteria
  }
});

export const fetchDatasetTerritoryLastYear = (nodeId: number, datasetId: string, timeDim: string, criteria: {[key: string]: Criteria}) => {

  if (criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_RANGE && criteria?.[timeDim]?.to) {
    return {
      type: DATASET_TERRITORY_LAST_YEAR_FETCH,
      payload: {
        lastTerritoryYear: moment(criteria[timeDim].to).format("YYYY")
      }
    };

  } else {
    const newCriteria = {
      ...criteria,
      [timeDim]: undefined
    };

    return initRequest(
      DATASET_TERRITORY_LAST_YEAR_FETCH,
      getDatasetStructureDynamicCodelistUrl(nodeId, datasetId, timeDim),
      RequestMethod.POST,
      getCriteriaArrayFromObject(newCriteria),
      t => ({
        onStart: t("scenes.dataset.actions.fetchingTimePeriod")
      })
    );
  }
};

export const backupDatasetState = () => ({
  type: DATASET_STATE_BACKUP
});

export const resetDatasetState = () => ({
  type: DATASET_STATE_RESET
});

export const setDatasetViewerMode = (viewerMode: string) => ({
  type: DATASET_VIEWER_MODE_SET,
  viewerMode
});

export const setDatasetViewer = (viewerIdx: number) => ({
  type: DATASET_VIEWER_SET,
  viewerIdx
});

export const setDatasetViewerTableVisibility = (isVisible: boolean) => ({
  type: DATASET_VIEWER_TABLE_VISIBILITY_SET,
  isVisible
});

export const setDatasetViewerMapVisibility = (isVisible: boolean) => ({
  type: DATASET_VIEWER_MAP_VISIBILITY_SET,
  isVisible
});

export const setDatasetViewerChartVisibility = (isVisible: boolean) => ({
  type: DATASET_VIEWER_CHART_VISIBILITY_SET,
  isVisible
});

export const setDatasetViewerChartType = (chartType: string) => ({
  type: DATASET_VIEWER_CHART_TYPE_SET,
  chartType
});

export const submitDatasetTableLayout = (layout: any) => ({
  type: DATASET_TABLE_LAYOUT_SUBMIT,
  layout
});

export const submitDatasetTableFilterTree = (filterTree: any) => ({
  type: DATASET_TABLE_FILTERS_TREE_SUBMIT,
  filterTree
});

export const submitDatasetMapLayout = (layout: any) => ({
  type: DATASET_MAP_LAYOUT_SUBMIT,
  layout
});

export const submitDatasetChartLayout = (layout: any) => ({
  type: DATASET_CHART_LAYOUT_SUBMIT,
  layout
});

export const submitDatasetChartFilterTree = (filterTree: any) => ({
  type: DATASET_CHART_FILTERS_TREE_SUBMIT,
  filterTree
});

export const setDatasetChartLayoutTerritoryDimValues = (territoryDimValues: string[]) => ({
  type: DATASET_CHART_LAYOUT_TERRITORY_DIM_VALUES_SET,
  territoryDimValues
});

export const setDatasetLabelFormat = (labelFormat: string) => ({
  type: DATASET_LABEL_FORMAT_SET,
  labelFormat
});

export const setDatasetVariation = (variation: { [key: string]: boolean }) => ({
  type: DATASET_VARIATION_SET,
  variation
});

export const setDatasetTemporalDimOrder = (temporalDimOrder: string) => ({
  type: DATASET_TEMPORAL_DIM_ORDER_SET,
  temporalDimOrder
});

export const setDatasetChartSettings = (chartSettings: any) => ({
  type: DATASET_CHART_SETTINGS_SET,
  chartSettings
});

export const setDatasetMapSettings = (mapSettings: any) => ({
  type: DATASET_MAP_SETTINGS_SET,
  mapSettings
});

export const submitDatasetDownload = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string,
  criteria: {[key: string]: Criteria},
  layout: any,
  format: string,
  extension: string,
  zipped: boolean,
  params: any,
  defaultLanguage: string,
  languages: string[],
  t: any
) => {

  let url, body;

  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url = getDatasetCustomDownloadUrl(nodeId, format, zipped);
    body = getCustomExportRequestBody(datasetId, datasetTitle, criteria, layout, params, defaultLanguage, languages, t);

  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url = getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped);
    body = getCriteriaArrayFromObject(criteria);
  }

  const type = format === DOWNLOAD_FORMAT_EXCEL
    ? "application/vnd.ms-excel"
    : "text/plain;charset=utf-8";

  return initRequest(
    DATASET_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: extension,
        type: type
      }
    },
    undefined,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  );
};

export const submitDatasetWithMarginalDownload = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  datasetTitle: string,
  criteria: {[key: string]: Criteria},
  timeDim: string,
  territoryDim: string,
  layout: any,
  indicators: Indicator[] | null,
  additionalDatasets: any[],
  format: string,
  extension: string,
  zipped: boolean,
  params: any,
  defaultLanguage: string,
  languages: string[],
  t: any,
  indicatorsBaseUrl?: string
) => {

  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle,
      criteria: criteria,
      timeDim: timeDim,
      territoryDim: territoryDim,
      layout: layout
    },
    ...additionalDatasets
  ];

  let url, body, baseUrl = undefined;

  if (format === DOWNLOAD_FORMAT_EXCEL || format === DOWNLOAD_FORMAT_CSV) {
    url = getDatasetWithMarginalCustomDownloadUrl(format, zipped);
    body = getCustomExportRequestBodyWithMarginal(datasets, indicators, timeDim, layout, params, defaultLanguage, languages, t);
    baseUrl = indicatorsBaseUrl;

  } else {
    // @ts-ignore
    const formatParam = downloadFormats()[format]?.param || format;
    url = getDatasetDownloadUrl(nodeId, datasetId, formatParam, zipped);
    body = getCriteriaArrayFromObject(criteria);
  }

  const type = format === DOWNLOAD_FORMAT_EXCEL
    ? "application/vnd.ms-excel"
    : "text/plain;charset=utf-8";

  return initRequest(
    DATASET_DOWNLOAD_SUBMIT,
    url,
    RequestMethod.POST,
    body,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDataset")
    }),
    {
      fileSave: {
        name: datasets.map(({datasetId, datasetTitle}) => `${datasetTitle} (${datasetId})`).join(`, `),
        extension: extension,
        type: type
      }
    },
    baseUrl,
    (statusCode: number) => statusCode === 406,
    undefined,
    undefined,
    true
  );
};

export const hideDatasetDownloadWarning = () => ({
  type: DATASET_DOWNLOAD_WARNING_HIDE
});

export const showDatasetViewTemplate = (isView: boolean) => ({
  type: DATASET_VIEW_TEMPLATE_SHOW,
  isView
});

export const hideDatasetViewTemplate = (isView: boolean) => ({
  type: DATASET_VIEW_TEMPLATE_HIDE,
  isView
});

export const submitDatasetViewTemplate = (nodeId: number, viewTemplate: any, isView: boolean) => initRequest(
  DATASET_VIEW_TEMPLATE_SUBMIT,
  isView
    ? getCreateViewUrl(nodeId)
    : getCreateTemplateUrl(nodeId),
  RequestMethod.POST,
  viewTemplate,
  t => ({
    onStart: isView
      ? t("scenes.dataset.actions.savingView")
      : t("scenes.dataset.actions.savingTemplate")
  }),
  {
    isView: isView
  },
  undefined,
  (statusCode: number) => statusCode === 409,
);

export const hideDatasetViewError = () => ({
  type: DATASET_VIEW_ERROR_HIDE
});

export const hideDatasetUnavailableViewWarning = () => ({
  type: DATASET_UNAVAILABLE_VIEW_WARNING_HIDE
});

export const setDatasetHtmlGeneratingTime = (time: number) => ({
  type: DATASET_HTML_GENERATING_TIME_SET,
  time
});

export const showDatasetSdmxQuery = () => ({
  type: DATASET_SDMX_QUERY_SHOW
});

export const hideDatasetSdmxQuery = () => ({
  type: DATASET_SDMX_QUERY_HIDE
});

export const fetchDatasetSdmxQuery = (nodeId: number, datasetId: string, criteria: {[key: string]: Criteria}, datasetTitle: string) => initRequest(
  DATASET_SDMX_QUERY_FETCH,
  getDatasetSdmxQueryUrl(nodeId, datasetId),
  RequestMethod.POST,
  getCriteriaArrayFromObject(criteria),
  t => ({
    onStart: t("scenes.dataset.actions.fetchingDatasetSdmxQuery", {title: datasetTitle})
  })
);

export const setDatasetIndicatorCreateVisibility = (isVisible: boolean) => ({
  type: DATASET_INDICATOR_CREATE_VISIBILITY_SET,
  isVisible
});

export const setDatasetIndicatorListVisibility = (isVisible: boolean) => ({
  type: DATASET_INDICATOR_LIST_VISIBILITY_SET,
  isVisible
});

export const setDatasetAdditionalDatasetCreateVisibility = (isVisible: boolean) => ({
  type: DATASET_ADDITIONAL_DATASET_CREATE_VISIBILITY_SET,
  isVisible
});

export const setDatasetAdditionalDatasetListVisibility = (isVisible: boolean) => ({
  type: DATASET_ADDITIONAL_DATASET_LIST_VISIBILITY_SET,
  isVisible
});

export const fetchDatasetAdditionalDatasetCatalog = (nodeId: number) => initRequest(
  DATASET_ADDITIONAL_DATASET_CATALOG_FETCH,
  getNodeCatalogUrl(nodeId),
  undefined,
  undefined,
  t => ({
    onStart: t("domains.catalog.messages.fetch.start")
  })
);

export const changeDatasetAdditionalDataset = (additionalDataset: any) => ({
  type: DATASET_ADDITIONAL_DATASET_CHANGE,
  additionalDataset
});

export const fetchDatasetAdditionalDatasetStructure = (nodeId: number, datasetId: string) => initRequest(
  DATASET_ADDITIONAL_DATASET_STURCTURE_FETCH,
  getDatasetStructureUrl(nodeId, datasetId),
  RequestMethod.GET,
  undefined,
  t => ({
    onStart: t("scenes.dataset.actions.fetchingAdditionalDatasetStructure")
  })
);

export const submitDatasetAdditionalDataset = () => ({
  type: DATASET_ADDITIONAL_DATASET_SUBMIT
});

export const deleteDatasetAdditionalDataset = (datasetIdx: number) => ({
  type: DATASET_ADDITIONAL_DATASET_DELETE,
  datasetIdx
});

export const setDatasetAdditionalDatasetCriteria = (datasetIdx: number, criteria: {[key: string]: Criteria}) => ({
  type: DATASET_ADDITIONAL_DATASET_CRITERIA_SET,
  datasetIdx,
  criteria
});

export const fetchDatasetIndicatorPreview = (
  nodeId: number,
  nodeCode: string,
  datasetId: string,
  criteria: {[key: string]: Criteria},
  timeDim: string,
  territoryDim: string,
  indicators: Indicator[],
  additionalDatasets: any[],
  defaultLanguage: string,
  languages: string[],
  indicatorsBaseUrl?: string
) => {

  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      criteria: criteria,
      timeDim: timeDim,
      territoryDim: territoryDim
    },
    ...additionalDatasets
  ];

  return initRequest(
    DATASET_INDICATOR_PREVIEW_FETCH,
    getDatasetWithIndicatorsUrl(true),
    RequestMethod.POST,
    getIndicatorsDefinition(indicators, datasets, timeDim, defaultLanguage, languages),
    t => ({
      onStart: t("scenes.dataset.actions.fetchingIndicatorPreview")
    }),
    undefined,
    indicatorsBaseUrl
  );
};

export const resetDatasetIndicatorPreview = () => ({
  type: DATASET_INDICATOR_PREVIEW_RESET,
});

export const publishDatasetIndicator = (indicator: Indicator) => ({
  type: DATASET_INDICATOR_PUBLISH,
  indicator
});

export const deleteDatasetIndicator = (indicatorIdx: number) => ({
  type: DATASET_INDICATOR_DELETE,
  indicatorIdx
});

export const hideDatasetIndicatorWarning = () => ({
  type: DATASET_INDICATOR_WARNING_HIDE
});

export const setDatasetIndicatorArithmeticMeanVisibility = (visible: boolean) => ({
  type: DATASET_INDICATOR_ARITHMETIC_MEAN_VISIBILITY_SET,
  visible
});

export const setDatasetIndicatorArithmeticMeanValues = (arithmeticMeanDims: string[] | null, arithmeticMeans: {[key: string]: number} | null) => ({
  type: DATASET_INDICATOR_ARITHMETIC_MEAN_VALUES_SET,
  arithmeticMeanDims,
  arithmeticMeans
});

export const setDatasetIndicatorStandardDeviationVisibility = (visible: boolean) => ({
  type: DATASET_INDICATOR_STANDARD_DEVIATION_VISIBILITY_SET,
  visible
});

export const setDatasetIndicatorCoefficientOfVariationVisibility = (visible: boolean) => ({
  type: DATASET_INDICATOR_COEFFICIENT_OF_VARIATION_VISIBILITY_SET,
  visible
});

export const submitReferenceMetadataDownload = (
  nodeId: number,
  datasetId: string,
  datasetTitle: string
) => {

  return initRequest(
    DATASET_DOWNLOAD_METADATA_SUBMIT,
    getDatasetExportMetadataUrl(nodeId,datasetId),
    RequestMethod.POST,
    undefined,
    t => ({
      onStart: t("scenes.dataset.actions.downloadingDatasetMetadata")
    }),
    {
      fileSave: {
        name: `${datasetTitle} (${datasetId})`,
        extension: "html",
        type: "text/html;charset=utf-8"
      }
    }
  )
};

export const fetchDatasetStructureCodelistFull = (nodeId: number, datasetId: string, dimensionId: string, missingFilterValueIds: string[]) => initRequest(
  DATASET_STRUCTURE_CODELIST_FULL_FETCH,
  getDatasetStructureCodelistUrl(nodeId, datasetId, dimensionId, true),
  undefined,
  undefined,
  t => ({
    onStart: t("scenes.dataset.actions.fetchingCodelist")
  }),
  {
    missingFilterValueIds: missingFilterValueIds
  }
);

export const hideDatasetStructureCodelistFull = () => ({
  type: DATASET_STRUCTURE_CODELIST_FULL_HIDE
});

export const setFullScreen = (isFullscreen : boolean) => ({
  type: DATASET_SET_FULLSCREEN,
  isFullscreen
});