import React, {Fragment, useState} from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import ButtonSelect from "../button-select";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  DOWNLOAD_FORMAT_CHART_IMAGE,
  DOWNLOAD_FORMAT_CHART_PDF,
  DOWNLOAD_FORMAT_CSV,
  DOWNLOAD_FORMAT_EXCEL,
  DOWNLOAD_FORMAT_MAP_IMAGE,
  DOWNLOAD_FORMAT_MAP_PDF,
  DOWNLOAD_FORMAT_SHAPEFILES,
  DOWNLOAD_REFERENCE_METADATA,
  DOWNLOAD_VIEWER_CHART,
  DOWNLOAD_VIEWER_MAP,
  DOWNLOAD_VIEWER_TABLE,
  downloadFormats,
  exportViewerCanvas,
  isDownloadFormatValid
} from "../../utils/download";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomDialogTitle from "../custom-dialog-title";
import Button from "@material-ui/core/Button";
import ExportForm from "./ExportForm";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import Select from "@material-ui/core/Select";
import Alert from "@material-ui/lab/Alert";
import {getTextWidth} from "../../utils/style";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../label-format-selector/constants";
import ModuleMenuItemPlaceholder from "../module-menu-item-placeholder";
import "./style.css";

const $ = window.jQuery;

const styles = theme => ({
  root: {},
  divider: {
    width: "100%",
    color: "gray",
    fontStyle: "italic",
    fontSize: 14
  },
  exportLabel: {
    padding: "12px 12px 12px 0"
  },
  alert: {
    marginBottom: 12
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  fieldLastRow: {
    marginBottom: 0
  }
});

const getFilterCombinationCount = (jsonStat, filters) => {
  return (jsonStat && filters)
    ? filters.reduce((acc, dim) => acc * jsonStat.size[jsonStat.id.indexOf(dim)], 1)
    : 0
};

const COLS_SEPARATOR_COMMA = ",";
const COLS_SEPARATOR_SEMICOLON = ";";
const COLS_SEPARATOR_PIPE = "|";
const COLS_SEPARATOR_TAB = "\t";

const getColsSeparators = t => ([
  {
    value: COLS_SEPARATOR_COMMA,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.comma") : "Comma (,)"
  },
  {
    value: COLS_SEPARATOR_SEMICOLON,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.semicolon") : "Semicolon (;)"
  },
  {
    value: COLS_SEPARATOR_PIPE,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.pipe") : "Pipe (|)"
  },
  {
    value: COLS_SEPARATOR_TAB,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.tab") : "Tab ( )"
  }
]);

const getInitialCsvParams = csvSeparator => ({
  labelFormat: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  colsSeparator: (csvSeparator && getColsSeparators().map(({value}) => value).includes(csvSeparator))
    ? csvSeparator
    : COLS_SEPARATOR_COMMA,
  textQualifier: ""
});

const getClonedCanvas = $oldCanvas => {
  const oldCanvas = $oldCanvas.get(0);

  // create a new canvas
  const newCanvas = document.createElement("canvas");
  const context = newCanvas.getContext("2d");

  // set dimensions
  newCanvas.width = oldCanvas.width;
  newCanvas.height = oldCanvas.height;
  newCanvas.style.width = oldCanvas.offsetWidth + 'px';
  newCanvas.style.height = oldCanvas.offsetHeight + 'px';

  // apply the old canvas to the new one
  context.drawImage(oldCanvas, 0, 0);

  // return the new canvas
  return $(newCanvas);
};

const ExportButton = ({
                        classes,
                        t,
                        exportConfig,
                        exportSettings,
                        modulesConfig,
                        icon,
                        formats: nodeFormats,
                        jsonStat,
                        viewerIdx,
                        isTableVisible,
                        isMapVisible,
                        isChartVisible,
                        tableLayout,
                        mapContainerId,
                        mapId,
                        mapLayout,
                        mapSettings,
                        chartContainerId,
                        chartId,
                        chartLayout,
                        chartSettings,
                        labelFormat,
                        datasetTitle,
                        submitDownload,
                        showAsBottomNavigation = false,
                        shapefilesUrl,
                        submitMetadataDownload,
                        disabled,
                        ...props
                      }) => {

  const [exportChartFormat, setExportChartFormat] = useState(null);
  const [exportMapFormat, setExportMapFormat] = useState(null);
  const [tempSettings, setTmpSettings] = useState(null);

  const [isExportExcelVisible, setExportExcelVisibility] = useState(false);
  const [singleSheet, setSingleSheet] = useState(true);

  const [isExportCsvVisible, setExportCsvVisibility] = useState(false);
  const [filteredDataset, setFilteredDataset] = useState(true);
  const [csvParams, setCsvParams] = useState(getInitialCsvParams(exportConfig?.csvSeparator));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleExportClick = (format, extension) => {
    if (format) {
      if (format === DOWNLOAD_FORMAT_MAP_IMAGE || format === DOWNLOAD_FORMAT_MAP_PDF) {
        handleExportMapShow(format, extension);
      } else if (format === DOWNLOAD_FORMAT_CHART_IMAGE || format === DOWNLOAD_FORMAT_CHART_PDF) {
        handleExportChartShow(format, extension);
      } else if (format === DOWNLOAD_FORMAT_SHAPEFILES) {
        window.open(shapefilesUrl);
      } else if (format === DOWNLOAD_FORMAT_EXCEL && getFilterCombinationCount(jsonStat, tableLayout?.filters) > 1) {
        handleExportExcelShow();
      } else if (format === DOWNLOAD_FORMAT_CSV) {
        handleExportCsvShow();
      } else if (format === DOWNLOAD_REFERENCE_METADATA) {
        submitMetadataDownload();
      } else {
        submitDownload(format, extension);
      }
      setAnchorEl(null);
    }
  };

  const getDefaultSettings = (format, extension, showLegend) => ({
    showTitle: true,
    title: datasetTitle,
    showFilters: true,
    extension: extension,
    isLegendVisible: showLegend,
    showLegend: showLegend,
    showDate: (format === DOWNLOAD_FORMAT_MAP_PDF || format === DOWNLOAD_FORMAT_CHART_PDF) ? true : undefined,
    sheetOrientation: format === DOWNLOAD_FORMAT_MAP_PDF
      ? exportSettings.DefaultMapSheetOrientation
      : exportSettings.DefaultGraphSheetOrientation,
    logoURL: exportSettings.logoURL
  });

  const handleExportChartShow = (format, extension) => {
    setTmpSettings(getDefaultSettings(format, extension));
    setExportChartFormat(format);
  };

  const handleExportMapShow = (format, extension) => {
    setTmpSettings(getDefaultSettings(format, extension, !mapSettings?.isLegendCollapsed));
    setExportMapFormat(format);
  };

  const handleExportChartOrMapHide = () => {
    setExportChartFormat(null);
    setExportMapFormat(null);
    setTmpSettings(null);
  };

  const handleExportChartOrMapSubmit = () => {

    const {
      showTitle,
      title,
      showFilters,
      extension,
      showLegend,
      showDate,
      sheetOrientation,
      logoURL
    } = tempSettings;

    if (exportChartFormat) {

      const $chartCanvas = $(`#${chartId}`);
      const $chartCanvasClone = getClonedCanvas($chartCanvas);
      const $chartContainer = $(`#${chartContainerId}`);

      $chartContainer.before($chartCanvasClone);
      $chartContainer.css({visibility: "hidden"});
      $chartContainer.addClass(exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF ? "export-chart--pdf" : "");

      window.ChartJs[chartId].options.animation.duration = 0;
      window.ChartJs.setChartStyle(
        chartId,
        exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF ? 48 : undefined,
        exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF ? 3 : undefined,
        exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF ? 100 : undefined,
        exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF ? 50 : undefined,
        exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF,
        exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF ? 8 : undefined,
        exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF ? 16 : undefined,
        exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF ? 4 : undefined
      );

      setTimeout(
        () => {
          exportViewerCanvas(
            $chartCanvas.get(0),
            $chartContainer.width(),
            $chartContainer.height(),
            exportChartFormat,
            `${datasetTitle}.${extension}`,
            showTitle === true ? title : null,
            showFilters === true ? chartLayout : null,
            showFilters === true ? jsonStat : null,
            showDate === true ? showDate : false,
            sheetOrientation,
            logoURL,
            labelFormat,
            t,
            () => {
              $chartContainer.removeClass("export-chart--pdf");

              window.ChartJs.setChartStyle(chartId);
              window.ChartJs[chartId].options.animation.duration = 1000;

              setTimeout(
                () => {
                  $chartContainer.css({visibility: "visible"});
                  $chartCanvasClone.remove();
                },
                100
              );
            }
          );

          setExportChartFormat(null);
          setExportMapFormat(null);
          setTmpSettings(null);
        },
        100
      );

    } else if (exportMapFormat) {

      const $mapContainer = $(`#${mapContainerId}`);

      window.LMap.getCanvas(
        mapId,
        canvas => {
          exportViewerCanvas(
            canvas,
            $mapContainer.width(),
            $mapContainer.height(),
            exportMapFormat,
            `${datasetTitle}.${extension}`,
            showTitle === true ? title : null,
            showFilters === true ? mapLayout : null,
            showFilters === true ? jsonStat : null,
            showDate === true ? showDate : false,
            sheetOrientation,
            logoURL,
            labelFormat,
            t
          );
        },
        null,
        !showLegend
      );

      setExportChartFormat(null);
      setExportMapFormat(null);
      setTmpSettings(null);
    }
  };

  const handleExportExcelShow = () => {
    setSingleSheet(true);
    setExportExcelVisibility(true);
  };

  const handleExportExcelHide = () => {
    setExportExcelVisibility(false);
  };

  const handleExportExcelSubmit = () => {
    const additionParams = {
      exportOnlyCurrentView: singleSheet
    };
    submitDownload(DOWNLOAD_FORMAT_EXCEL, downloadFormats()[DOWNLOAD_FORMAT_EXCEL].extension, additionParams);
    handleExportExcelHide();
  };

  const handleExportCsvShow = () => {
    setFilteredDataset(true);
    setExportCsvVisibility(true);
    setCsvParams(getInitialCsvParams(exportConfig?.csvSeparator));
  };

  const handleExportCsvHide = () => {
    setExportCsvVisibility(false);
  };

  const handleExportCsvSubmit = () => {
    const additionParams = {
      exportOnlyCurrentView: filteredDataset,
      ...csvParams
    };
    submitDownload(DOWNLOAD_FORMAT_CSV, downloadFormats()[DOWNLOAD_FORMAT_CSV].extension, additionParams);
    handleExportCsvHide();
  };

  const information = [];

  if (submitMetadataDownload || (modulesConfig.placeholders?.["export-button-menu"] || []).length > 0) {
    information.push({
      label: t("components.exportButton.sections.information"),
      className: classes.divider
    });
    if (submitMetadataDownload) {
      information.push({
        label: t("commons.downloadFormat.referencemetadata"),
        "data-value": {format: DOWNLOAD_REFERENCE_METADATA, extension: ""},
        format: DOWNLOAD_REFERENCE_METADATA,
        extension: ""
      });
    }
  }

  const formats = [];

  const formatsTable = (nodeFormats || []).filter(format => isDownloadFormatValid(format) && (downloadFormats()[format].viewers || []).includes(DOWNLOAD_VIEWER_TABLE));
  if (isTableVisible && formatsTable.length > 0) {
    formats.push({
      label: t("components.exportButton.sections.table"),
      className: classes.divider
    });
    formatsTable.forEach(format => {
      const {label, extension} = downloadFormats(t)[format];
      formats.push({
        label: label,
        "data-value": {format, extension},
        format: format,
        extension: extension
      });
    });
  }

  const formatsMap = (nodeFormats || []).filter(format => isDownloadFormatValid(format) && (downloadFormats()[format].viewers || []).includes(DOWNLOAD_VIEWER_MAP));
  if (isMapVisible && formatsMap.length > 0) {
    formats.push({
      label: t("components.exportButton.sections.map"),
      className: classes.divider
    });
    formatsMap.forEach(format => {
      const {label, extension} = downloadFormats(t)[format];
      formats.push({
        label: label,
        "data-value": {format, extension},
        format: format,
        extension: extension
      });
    });
    if (shapefilesUrl) {
      formats.push({
        label: t("components.exportButton.shapefiles"),
        "data-value": {format: DOWNLOAD_FORMAT_SHAPEFILES},
        format: DOWNLOAD_FORMAT_SHAPEFILES
      });
    }
  }

  const formatsChart = (nodeFormats || []).filter(format => isDownloadFormatValid(format) && (downloadFormats()[format].viewers || []).includes(DOWNLOAD_VIEWER_CHART));
  if (isChartVisible && formatsChart.length > 0) {
    formats.push({
      label: t("components.exportButton.sections.chart"),
      className: classes.divider
    });
    formatsChart.forEach(format => {
      const {label, extension} = downloadFormats(t)[format];
      formats.push({
        label: label,
        "data-value": {format, extension},
        format: format,
        extension: extension
      });
    });
  }

  const formatsOthers = (nodeFormats || []).filter(format => isDownloadFormatValid(format) && !downloadFormats()[format].viewers);
  if (formatsOthers.length > 0) {
    formats.push({
      label: t("components.exportButton.sections.others"),
      className: classes.divider
    });
    formatsOthers.forEach(format => {
      const {label, extension} = downloadFormats(t)[format];
      formats.push({
        label: label,
        "data-value": {format, extension},
        format: format,
        extension: extension
      });
    });
  }

  if (formats.length === 0) {
    return <span />;
  }

  const getButtonSelectItem = (format, idx) => (
    <div
      key={idx}
      id={`export-button__format--${format}`}
      className={format.className}
      data-value={format["data-value"]}
      aria-label={t("components.exportButton.export.format.ariaLabel", {
        datasetTitle: datasetTitle,
        formatLabel: format.label
      })}
    >
      {format.label}
    </div>
  );

  const getMenuItem = (label, format, extension, idx) => format
    ? (
      <MenuItem
        key={idx}
        id={`export-button__format--${format}`}
        className={classes.floatingMenuOption}
        onClick={() => handleExportClick(format, extension)}
      >
        {label}
      </MenuItem>
    )
    : (
      <div
        key={idx}
        className={classes.divider}
        style={{
          cursor: "default",
          padding: "6px 16px"
        }}
      >
        {label}
      </div>
    );

  return (
    <Fragment>
      {!showAsBottomNavigation
        ? (
          <ButtonSelect
            {...props}
            className={classes.root}
            icon={icon ? icon : <GetAppIcon />}
            ariaLabel={t("components.exportButton.export.ariaLabel")}
            tooltip={t("components.exportButton.export.tooltip")}
            color="default"
            onChange={({format, extension}) => handleExportClick(format, extension)}
            disabled={disabled}
          >
            {[
              ...information.map((format, idx) => getButtonSelectItem(format, `information-${idx}`)),
              ...(modulesConfig.placeholders?.["export-button-menu"] || []).map((module, idx) => (
                <ModuleMenuItemPlaceholder
                  isModulePlaceholder
                  key={`module-${idx}`}
                  module={module}
                />
              )),
              ...formats.map((format, idx) => getButtonSelectItem(format, `format-${idx}`))
            ]}
          </ButtonSelect>
        )
        : (
          <Fragment>
            <BottomNavigation
              onChange={({currentTarget}) => setAnchorEl(currentTarget)}
              showLabels
            >
              <BottomNavigationAction
                label={t("components.exportButton.export.label")}
                icon={icon ? icon : <GetAppIcon/>}
                role={props.role || undefined}
                disabled={disabled}
              />
            </BottomNavigation>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {[
                ...information.map(({label, format, extension}, idx) => getMenuItem(label, format, extension, `information-${idx}`)),
                ...(modulesConfig.placeholders?.["export-button-menu"] || []).map((module, idx) => (
                  <MenuItem
                    key={`module-${idx}`}
                    style={{padding: 0}}
                    onClick={() => setAnchorEl(null)}
                  >
                    <ModuleMenuItemPlaceholder
                      isModulePlaceholder
                      module={module}
                    />
                  </MenuItem>
                )),
                ...formats.map(({label, format, extension}, idx) => getMenuItem(label, format, extension, `format-${idx}`))
              ]}
            </Menu>
          </Fragment>
        )
      }

      <Dialog
        open={!!(exportChartFormat || exportMapFormat)}
        maxWidth="md"
        onClose={handleExportChartOrMapHide}
      >
        <CustomDialogTitle onClose={handleExportChartOrMapHide}>
          {(exportChartFormat === DOWNLOAD_FORMAT_CHART_IMAGE || exportMapFormat === DOWNLOAD_FORMAT_MAP_IMAGE)
            ? t("components.exportButton.exportImage.title")
            : (exportChartFormat === DOWNLOAD_FORMAT_CHART_PDF || exportMapFormat === DOWNLOAD_FORMAT_MAP_PDF)
              ? t("components.exportButton.exportPdf.title")
              : ""
          }
        </CustomDialogTitle>
        <DialogContent style={{width: 640}}>
          <ExportForm
            settings={tempSettings}
            onSettingsSet={setTmpSettings}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportChartOrMapHide}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button autoFocus onClick={handleExportChartOrMapSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isExportExcelVisible}
        maxWidth="sm"
        fullWidth
        onClose={handleExportExcelHide}
      >
        <CustomDialogTitle onClose={handleExportExcelHide}>
          {t("scenes.dataViewer.dialogs.exportExcel.title")}
        </CustomDialogTitle>
        <DialogContent>
          {singleSheet === false && (getFilterCombinationCount(jsonStat, tableLayout?.filters) > exportConfig?.maxExcelSheets) && (
            <Alert severity="warning" className={classes.alert}>
              {t("scenes.dataViewer.dialogs.exportExcel.warning", {sheetsLimit: exportConfig?.maxExcelSheets})}
            </Alert>
          )}
          {(() => {
            const label = t("scenes.dataViewer.dialogs.exportExcel.export.label") + ":";

            const getTextWidthEl = $(`<span class="${classes.exportLabel}">`).css({visibility: 'hidden'}).appendTo('body').get(0);
            const labelWidth = getTextWidth(label, getTextWidthEl);
            $(getTextWidthEl).remove();

            return (
              <Fragment>
                <span className={classes.exportLabel}>
                  {label}
                </span>
                <Select
                  variant="outlined"
                  value={singleSheet}
                  style={{width: `calc(100% - ${labelWidth}px)`}}
                  onChange={ev => setSingleSheet(ev.target.value)}
                  SelectDisplayProps={{"aria-haspopup": true}}
                >
                  <MenuItem value={true}>{t("scenes.dataViewer.dialogs.exportExcel.export.values.singleSheet")}</MenuItem>
                  <MenuItem value={false}>{t("scenes.dataViewer.dialogs.exportExcel.export.values.multiSheet")}</MenuItem>
                </Select>
              </Fragment>
            )
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportExcelHide}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button onClick={handleExportExcelSubmit}>
            {t("commons.confirm.download")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isExportCsvVisible}
        maxWidth="sm"
        fullWidth
        onClose={handleExportCsvHide}
      >
        <CustomDialogTitle onClose={handleExportCsvHide}>
          {t("scenes.dataViewer.dialogs.exportCsv.title")}
        </CustomDialogTitle>
        <DialogContent>
          {(() => {
            const layoutForCsv = viewerIdx === 0
              ? tableLayout
              : viewerIdx === 1
                ? mapLayout
                : chartLayout;

            return jsonStat && (
              <Grid container spacing={2}>
                {(
                  getFilterCombinationCount(jsonStat, layoutForCsv?.filters) > 1 ||
                  (layoutForCsv?.primaryDim?.[0] !== undefined && layoutForCsv.primaryDimValues.length < jsonStat.size[jsonStat.id.indexOf(layoutForCsv.primaryDim[0])]) ||
                  (layoutForCsv?.secondaryDim?.[0] !== undefined && layoutForCsv.secondaryDimValues.length < jsonStat.size[jsonStat.id.indexOf(layoutForCsv.secondaryDim[0])])
                ) && (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.field}>
                      <TextField
                        select
                        label={t("scenes.dataViewer.dialogs.exportCsv.export.label")}
                        value={filteredDataset}
                        onChange={ev => setFilteredDataset(ev.target.value)}
                        variant="outlined"
                        SelectProps={{"aria-haspopup": true}}
                      >
                        <MenuItem value={true}>{t("scenes.dataViewer.dialogs.exportCsv.export.values.filtered")}</MenuItem>
                        <MenuItem value={false}>{t("scenes.dataViewer.dialogs.exportCsv.export.values.full")}</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      select
                      label={t("scenes.dataViewer.dialogs.exportCsv.labelFormat.label")}
                      value={csvParams?.labelFormat}
                      onChange={ev => setCsvParams({...csvParams, labelFormat: ev.target.value})}
                      variant="outlined"
                      SelectProps={{"aria-haspopup": true}}
                    >
                      <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH}>{t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.both")}</MenuItem>
                      <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME}>{t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.name")}</MenuItem>
                      <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID}>{t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.id")}</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth className={`${classes.field} ${classes.fieldLastRow}`}>
                    <TextField
                      select
                      label={t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.label")}
                      value={csvParams?.colsSeparator}
                      onChange={ev => setCsvParams({...csvParams, colsSeparator: ev.target.value})}
                      variant="outlined"
                      SelectProps={{"aria-haspopup": true}}
                    >
                      {getColsSeparators(t).map(({value, label}, idx) => (
                        <MenuItem key={idx} value={value}>{label}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth className={`${classes.field} ${classes.fieldLastRow}`}>
                    <TextField
                      label={t("scenes.dataViewer.dialogs.exportCsv.textQualifier.label")}
                      value={csvParams?.textQualifier || ""}
                      onChange={ev => (ev.target.value || "").length <= 1
                        ? setCsvParams({...csvParams, textQualifier: ev.target.value})
                        : null
                      }
                      variant="outlined"
                      error={csvParams?.colsSeparator === csvParams?.textQualifier}
                      helperText={csvParams?.colsSeparator === csvParams?.textQualifier
                        ? t("scenes.dataViewer.dialogs.exportCsv.textQualifier.helperText")
                        : null
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportCsvHide}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button onClick={handleExportCsvSubmit}>
            {t("commons.confirm.download")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(state => ({
    exportConfig: state.hub.hub.exportConfig,
    exportSettings: state.appConfig.exportSettings,
    modulesConfig: state.app.modulesConfig
  }))
)(ExportButton);