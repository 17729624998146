import React, {Fragment} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {ViewerMode} from "../../state/dataset/constants";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {viewersFactory} from "./constant";
import SingleViewerSidebar from "./single-viewer/Sidebar";
import MultiViewerSidebar from "./multi-viewer/Sidebar";
import {getFormattedValue} from "../../utils/formatters";

const styles = () => ({
  sidebar: {
    width: 80,
    height: "100%",
    overflow: "auto",
    padding: "8px 0",
    marginRight: 16
  }
});

function DataViewerSidebar({classes, ...props}) {

  const {
    nodeDecimalSeparator,
    viewerMode,
    isCriteriaAlertVisible,
    isEmptyData,
    isTooBigData,
    isTooLongQuery,
    tableColCount,
    maxTableColCount,
    mapPointCount,
    maxMapPointCount,
    onCriteriaShow,
    onCriteriaAlertHide,
    isIndicatorCreateVisible,
    isAdditionalDatasetCreateVisible
  } = props;

  const {t} = useTranslation();

  const viewers = viewersFactory(t);

  return (
    <Fragment>
      {(() => {
        switch (viewerMode) {
          case ViewerMode.SingleViewer: {
            return (
              <Card className={classes.sidebar}>
                <SingleViewerSidebar
                  {...props}
                  viewers={viewers}
                />
              </Card>
            );
          }
          case ViewerMode.MultiViewer: {
            return (
              <Card className={classes.sidebar}>
                <MultiViewerSidebar
                  {...props}
                  viewers={viewers}
                />
              </Card>
            );
          }
          default: {
            return null;
          }
        }
      })()}

      <Dialog
        open={isCriteriaAlertVisible}
        fullWidth
        maxWidth="sm"
        onClose={onCriteriaAlertHide}
      >
        {(() => {
          if (isEmptyData) {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.emptyData.title")}
              </CustomDialogTitle>
            )

          } else if (isTooBigData) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooBigData.title")}
                </CustomDialogTitle>
                <DialogContent>
                  {t("scenes.dataViewer.sidebar.warning.tooBigData.content")}
                </DialogContent>
              </Fragment>
            )

          } else if (isTooLongQuery) {
            return (
              <Fragment>
                <CustomDialogTitle onClose={onCriteriaAlertHide}>
                  {t("scenes.dataViewer.sidebar.warning.isTooLongQuery.title")}
                </CustomDialogTitle>
                <DialogContent>
                  {t("scenes.dataViewer.sidebar.warning.isTooLongQuery.content")}
                </DialogContent>
              </Fragment>
            )

          } else if (tableColCount !== null) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooMuchCols.title")}
                </CustomDialogTitle>
                <DialogContent>
                  {t("scenes.dataViewer.sidebar.warning.tooMuchCols.content", {
                    count: getFormattedValue(tableColCount, nodeDecimalSeparator),
                    max: getFormattedValue(maxTableColCount, nodeDecimalSeparator)
                  })}
                </DialogContent>
              </Fragment>
            )

          } else if (mapPointCount !== null) {
            return (
              <Fragment>
                <CustomDialogTitle
                  onClose={() => {
                    onCriteriaAlertHide();
                    onCriteriaShow();
                  }}
                >
                  {t("scenes.dataViewer.sidebar.warning.tooMuchPoints.title")}
                </CustomDialogTitle>
                <DialogContent>
                  {t("scenes.dataViewer.sidebar.warning.tooMuchPoints.content", {
                    count: getFormattedValue(mapPointCount, nodeDecimalSeparator),
                    max: getFormattedValue(maxMapPointCount, nodeDecimalSeparator)
                  })}
                </DialogContent>
              </Fragment>
            )

          } else {
            return (
              <CustomDialogTitle onClose={onCriteriaAlertHide}>
                {t("scenes.dataViewer.sidebar.warning.genericError.title")}
              </CustomDialogTitle>
            )
          }
        })()}
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              onCriteriaAlertHide();
              if (!isIndicatorCreateVisible && !isAdditionalDatasetCreateVisible) {
                onCriteriaShow();
              }
            }}
          >
            {(tableColCount || mapPointCount)
              ? t("commons.confirm.confirm")
              : t("commons.confirm.close")
            }
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  );
}

export default compose(
  withStyles(styles),
  connect(state => ({
    nodeDecimalSeparator: state.node.decimalSeparator
  }))
)(DataViewerSidebar);