import React, {Fragment, useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {getTextWidth} from "../../utils/style";
import InfiniteScrollTable from "../infinite-scroll-table";
import {
  getDimensionAttributeMap,
  getDimensionFilterValues,
  getDimensionLabel,
  getFormattedDimensionLabel,
  getFormattedDimensionValueLabel,
  getMarginalDimensions,
  MARGINAL_DIMENSION_KEY
} from "../../utils/jsonStat";
import {useTranslation} from "react-i18next";
import DatasetFilterAttributeIcon from "../dataset-filter-attribute-icon";
import {localizeI18nObj} from "../../utils/i18n";
import CustomDialogTitle from "../custom-dialog-title";
import Tooltip from '@material-ui/core/Tooltip';
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH} from "../label-format-selector/constants";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    maxWidth: "100%"
  },
  filter: {
    display: "inline-block",
    verticalAlign: "bottom",
    marginRight: 16,
    marginBottom: 8,
    maxWidth: "100%"
  },
  item: {
    fontSize: 14,
    maxWidth: "100%"
  },
  itemWrapper: {
    display: "inline-block",
    verticalAlign: "bottom",
    maxWidth: "100%"
  },
  itemDisabled: {
    pointerEvents: "none",
    '& > svg': {
      visibility: "hidden"
    }
  },
  dimensionTitle: {
    marginBottom: 4,
    fontSize: 13,
    color: theme.palette.primary.main
  },
  tooltip: {
    fontSize: 13
  },
  tooltipElement: {
    margin: "4px 0",
    fontWeight: "normal"
  }
});

const isMultiSelectDim = (dim, layout) => (layout?.primaryDim?.[0] === dim || layout?.secondaryDim?.[0] === dim);

function DatasetFilters(props) {
  const {
    classes,
    appLanguage,
    languages,
    hiddenDimensionValueLabels,
    jsonStat,
    layout,
    filterTree,
    labelFormat,
    onSelect,
    hideMultiSelectDims = false
  } = props;

  const {t} = useTranslation();

  const [timeDim, setTimeDim] = useState(null);

  const [visibleId, setVisibleId] = useState(null);
  const [data, setData] = useState(null);

  const [checkedKeys, setCheckedKeys] = useState(null);

  const [dimAttributeMap, setDimAttributeMap] = useState(null);

  useEffect(() => {
    setTimeDim(jsonStat.role?.time?.[0]);
  }, [jsonStat]);

  useEffect(() => {
    setDimAttributeMap(getDimensionAttributeMap(jsonStat, t));
  }, [jsonStat, t]);

  const filters = (layout?.primaryDim || []).concat((layout?.secondaryDim || [])).concat(layout?.filters || []);

  const handleFilterOpen = visibleIdx => {
    setVisibleId(visibleIdx);
    const data = getDimensionFilterValues(filters[visibleIdx], jsonStat, layout, filterTree, true);
    if (filters[visibleIdx] === timeDim) {
      data.reverse();
    }
    setData(data);

    const dim = filters[visibleIdx];
    if (isMultiSelectDim(dim, layout)) {
      if (checkedKeys === null && dim === layout?.primaryDim?.[0]) {
        setCheckedKeys(layout.primaryDimValues);
      } else if (checkedKeys === null && dim === layout?.secondaryDim?.[0]) {
        setCheckedKeys(layout.secondaryDimValues);
      }
    }
  };

  const handleFilterClose = () => {
    setVisibleId(null);
    setCheckedKeys(null);
    setData(null);
  };

  const handleFilterSubmit = (dimension, values, isArr) => {
    let data;
    if (isArr) {
      data = jsonStat.dimension[dimension].category.index.filter(dimVal => values.includes(dimVal));
    } else {
      data = values;
    }
    onSelect(dimension, data);
    handleFilterClose();
  };

  const getFormattedName = (dimension, value, labelFormat, withAttribute) => {
    let valueLabel;

    if (dimension !== MARGINAL_DIMENSION_KEY) {
      valueLabel = getFormattedDimensionValueLabel(jsonStat, null, dimension, value, labelFormat, t);

    } else {
      const localizedHiddenDimensionValueLabels = localizeI18nObj(hiddenDimensionValueLabels, appLanguage, languages) || [];
      const hiddenLabels = localizedHiddenDimensionValueLabels.map(label => label.toLowerCase());

      const marginal = jsonStat.extension.marginalvalues[value];
      valueLabel = marginal.label
        ? marginal.label
        : getMarginalDimensions(jsonStat, marginal.datasetid)
          .map(dim => getFormattedDimensionValueLabel(jsonStat, marginal.datasetid, dim, marginal.dimensionvalues[dim], labelFormat, t))
          .filter(label => !hiddenLabels.includes(label.toLowerCase()))
          .join(", ");
    }

    const datasetId = jsonStat.extension.datasets[0];

    return !withAttribute
      ? valueLabel
      : (
        <Fragment>
          {valueLabel}
          {withAttribute && (
            <DatasetFilterAttributeIcon
              datasetId={datasetId}
              jsonStat={jsonStat}
              dimension={dimension}
              dimensionValues={[value]}
              datasetDimAttributeMap={dimAttributeMap?.[datasetId]}
              labelFormat={labelFormat}
              disabled
            />
          )}
        </Fragment>
      );
  };

  const getFormattedTooltip = (dimension, value, labelFormat) => {
    return (
      <div className={classes.tooltip}>
        {(() => {
          if (dimension !== MARGINAL_DIMENSION_KEY) {
            return getFormattedDimensionValueLabel(jsonStat, null, dimension, value, labelFormat, t);

          } else {
            const marginal = jsonStat.extension.marginalvalues[value];
            if (marginal.label) {
              return marginal.label;
            } else {
              return getMarginalDimensions(jsonStat, marginal.datasetid).map((dim, idx) => {
                const dimLabel = getFormattedDimensionLabel(jsonStat, marginal.datasetid, dim, labelFormat, t);
                const valueLabel = getFormattedDimensionValueLabel(jsonStat, marginal.datasetid, dim, marginal.dimensionvalues[dim], labelFormat, t);
                return (
                  <div key={idx} className={classes.tooltipElement}>
                    <b>{dimLabel}</b>: <i>{valueLabel}</i>
                  </div>
                )
              });
            }
          }
        })()}
      </div>
    )
  };

  return (
    <Fragment>
      <div className={classes.root}>
        {(() => {
          const getTextWidthEl = $('<span>').css({
            visibility: 'hidden',
            fontSize: 16
          }).appendTo('body').get(0);

          const select = [];
          filters.forEach((filter, idx) => {

            const {
              filtersValue
            } = layout;

            if (
              (isMultiSelectDim(filter, layout) && !hideMultiSelectDims) ||
              (!isMultiSelectDim(filter, layout) && jsonStat.size[jsonStat.id.indexOf(filter)] !== 1)
            ) {

              const dimLabel = getDimensionLabel(jsonStat, null, filter, t);
              const minWidth = getTextWidth(dimLabel, getTextWidthEl) + 1;

              const value = isMultiSelectDim(filter, layout)
                ? layout.primaryDim[0] === filter
                  ? layout.primaryDimValues
                  : layout.secondaryDimValues
                : filtersValue[filter];

              const datasetId = !isMultiSelectDim(filter, layout) && filter === MARGINAL_DIMENSION_KEY && jsonStat.extension.marginalvalues[value]?.datasetid
                ? jsonStat.extension.marginalvalues[value].datasetid
                : jsonStat?.extension?.datasets?.[0];

              const getValueLabel = value => isMultiSelectDim(filter, layout)
                ? jsonStat.size[jsonStat.id.indexOf(filter)] !== 1
                  ? value.length === 1
                    ? t("components.datasetFilters.selectedCount", {selected: value.length})
                    : t("components.datasetFilters.selectedCount_plural", {selected: value.length})
                  : getFormattedName(filter, value[0], labelFormat)
                : getFormattedName(filter, value, labelFormat);

              select.push(
                <div key={idx} className={classes.filter}>
                  {isMultiSelectDim(filter, layout) && (
                    <InputLabel className={classes.dimensionTitle}>
                      {layout.primaryDim[0] === filter
                        ? t("components.datasetFilters.primaryDim")
                        : t("components.datasetFilters.secondaryDim")
                      }
                    </InputLabel>
                  )}
                  <FormControl className={classes.itemWrapper}>
                    <InputLabel>{dimLabel}</InputLabel>
                    <Tooltip title={getValueLabel(value)}>
                      <Select
                        open={false}
                        style={{minWidth: minWidth}}
                        value={value}
                        multiple={isMultiSelectDim(filter, layout)}
                        renderValue={value => getValueLabel(value)}
                        className={`${classes.item} ${jsonStat.size[jsonStat.id.indexOf(filter)] === 1 ? classes.itemDisabled : ""}`}
                        onOpen={() => jsonStat.size[jsonStat.id.indexOf(filter)] === 1
                          ? null
                          : handleFilterOpen(idx)
                        }
                        SelectDisplayProps={{
                          "aria-haspopup": true,
                          "aria-label": `${dimLabel}: ${getValueLabel(value)}`
                      }}
                      >
                        {jsonStat.dimension[filter].category.index.map((el, idx) => (
                          <MenuItem key={idx + el} value={el}>{el}</MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                  <div style={{display: "inline-block", marginBottom: 4}}>
                    <DatasetFilterAttributeIcon
                      datasetId={datasetId}
                      jsonStat={jsonStat}
                      dimension={filter}
                      dimensionValues={isMultiSelectDim(filter, layout) ? value : [value]}
                      datasetDimAttributeMap={dimAttributeMap?.[datasetId]}
                      labelFormat={labelFormat}
                    />
                  </div>
                </div>
              )
            }
          });

          $(getTextWidthEl).remove();

          return select
        })()}
      </div>
      <Dialog
        open={data !== null}
        onClose={handleFilterClose}
        fullWidth
        maxWidth="md"
      >
        <CustomDialogTitle onClose={handleFilterClose}>
          {visibleId !== null
            ? !isMultiSelectDim(filters[visibleId], layout)
              ? t("components.datasetFilters.modals.title", {dimension: getDimensionLabel(jsonStat, null, filters[visibleId], t)})
              : t("components.datasetFilters.modals.title_plural", {dimension: getDimensionLabel(jsonStat, null, filters[visibleId], t)})
            : ""
          }
        </CustomDialogTitle>
        <DialogContent>
          {!isMultiSelectDim(filters[visibleId], layout)
            ? (
              <InfiniteScrollTable
                data={data}
                getRowKey={({id}) => id}
                showHeader={false}
                columns={[
                  {
                    title: "",
                    dataIndex: 'label',
                    render: (_, {id}) => getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH, true),
                    renderText: (_, {id}) => getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                    renderTooltip: (_, {id}) => getFormattedTooltip(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                    minWidth: 100
                  }
                ]}
                onRowClick={rowData => handleFilterSubmit(filters[visibleId], rowData.id)}
                getRowStyle={rowData => ({
                  background: rowData.id === layout.filtersValue[filters[visibleId]]
                    ? "#fff9e5"
                    : undefined
                })}
                height={400}
              />
            )
            : (
              <InfiniteScrollTable
                data={data}
                getRowKey={({id}) => id}
                columns={[
                  {
                    title: t("components.datasetFilters.table.columns.label.title"),
                    dataIndex: 'label',
                    render: (_, {id}) => getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH, true),
                    renderText: (_, {id}) => getFormattedName(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                    renderTooltip: (_, {id}) => getFormattedTooltip(filters[visibleId], id, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH),
                    minWidth: 100,
                    noFilter: true,
                    noSort: true
                  }
                ]}
                rowSelection={{
                  selectedRowKeys: checkedKeys,
                  onChange: checkedKeys => setCheckedKeys(data.map(({id}) => id).filter(key => checkedKeys.includes(key)))
                }}
                height={400}
              />
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFilterClose} color="primary">
            {t("commons.confirm.close")}
          </Button>
          {isMultiSelectDim(filters[visibleId], layout) && (
            <Button
              onClick={() => handleFilterSubmit(filters[visibleId], checkedKeys, true)}
              color="primary"
              disabled={(checkedKeys || []).length < 1}
            >
              {t("commons.confirm.confirm")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default compose(
  connect(({app, appConfig}) => ({
    appLanguage: app.language,
    languages: app.languages,
    hiddenDimensionValueLabels: appConfig.hiddenDimensionValueLabels
  })),
  withStyles(styles)
)(DatasetFilters);