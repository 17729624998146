import React, {forwardRef, Fragment, useEffect, useImperativeHandle} from 'react';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {useForm} from 'react-hook-form'
import {compose} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import {localizeI18nObj} from "../../../utils/i18n";
import UserErrors from "../../user-errors";
import {clearUserEditUser, fetchUserEditUser, submitUserEditUser} from "../../../state/user/userActions";
import Call from "../../../hocs/call";

const Form = forwardRef(({
                           hub,
                           types,
                           language,
                           languages,
                           config,
                           onSubmit,
                           onCancel
                         }, ref) => {

  const {register, errors, handleSubmit, watch, setValue} = useForm({
    defaultValues: {
      type: types?.[0]?.id,
      organization: "",
      ...config
    }
  });

  const {t} = useTranslation();

  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(val => {
        const data = {
          ...val,
          isActive: config.isActive,
          type: val.type !== "null" ? val.type : null
        };

        onSubmit(data);
        f(data);
      })();
    },
    cancel(f) {
      onCancel();
      f();
    }
  }));

  useEffect(() => {
    register("type");
  }, [register]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            disabled
            name="email"
            label={t("scenes.usersSettings.userSettings.fields.email")}
            inputRef={register({required: t("commons.validation.required")})}
            error={!!errors.email}
            helperText={errors.email?.message}
            variant="outlined"
            required
          />
        </FormControl>
      </Grid>
      {types && types.length > 0 && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              name="type"
              select
              label={t("scenes.usersSettings.userSettings.fields.type.label")}
              variant="outlined"
              onChange={e => setValue('type', e.target.value)}
              value={watch("type") || ''}
              helperText={errors.type?.message}
              error={!!errors.type}
              required
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {types.map(({id, label}) =>
                <MenuItem value={id} key={id}>
                  {localizeI18nObj(label, language, languages)}
                </MenuItem>
              )}
            </TextField>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            name="firstName"
            label={t("scenes.usersSettings.userSettings.fields.firstName")}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
            variant="outlined"
            required
            inputRef={register({required: t("commons.validation.required")})}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            name="lastName"
            label={t("scenes.usersSettings.userSettings.fields.lastName")}
            inputRef={register({required: t("commons.validation.required")})}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
            variant="outlined"
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            name="organization"
            label={t("scenes.usersSettings.userSettings.fields.organization")}
            error={!!errors.organization}
            helperText={errors.organization?.message}
            variant="outlined"
            inputRef={register}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
});

const mapStateToProps = state => ({
  hub: state.hub,
  appConfig: state.appConfig,
  language: state.app.language,
  languages: state.app.languages,
  userId: state.user.userId,
  config: state.user.config,
  userErrors: state.user.userErrors
});

const mapDispatchToProps = dispatch => ({
  fetchConfig: userId => dispatch(fetchUserEditUser(userId)),
  submitConfig: (userId, config) => dispatch(submitUserEditUser(userId, config)),
  clearConfig: () => dispatch(clearUserEditUser())
});

const EditUserForm = ({
                        config,
                        userId,
                        fetchConfig,
                        submitConfig,
                        clearConfig,
                        userErrors,
                        hub,
                        appConfig,
                        languages,
                        language
                      }, ref) =>
  <Call cb={fetchConfig} cbParam={userId}>
    {config
      ? (
        <Fragment>
          <UserErrors errors={userErrors}/>
          <Form
            config={config}
            ref={ref}
            onSubmit={config => submitConfig(userId, config)}
            onCancel={clearConfig}
            hub={hub}
            types={appConfig.user.typeOptions}
            language={language}
            languages={languages}
          />
        </Fragment>
      )
      : <span/>
    }
  </Call>


export default compose(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}), forwardRef)(EditUserForm);
