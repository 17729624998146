import React, {Fragment} from "react";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ButtonSelect from "../button-select";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import ModulesPlaceholder from "../modules-placeholder";
import ModuleMenuItemPlaceholder from "../module-menu-item-placeholder";

const mapStateToProps = ({app}) => ({
  modulesConfig: app.modulesConfig
});

const TerritoryTools = ({
                          t,
                          modulesConfig
                        }) => {

  return (modulesConfig.placeholders?.["territory-tools-menu"] || []).length === 0
    ? null
    : (
      <Fragment>
        <ButtonSelect
          icon={<MenuBookIcon/>}
          ariaLabel={t("components.header.actions.territoryTools.ariaLabel")}
          tooltip={t("components.header.actions.territoryTools.title")}
        >
          {(modulesConfig.placeholders?.["territory-tools-menu"] || []).map((module, idx) => (
            <ModuleMenuItemPlaceholder
              isModulePlaceholder
              key={idx}
              module={module}
            />
          ))}
        </ButtonSelect>
        <ModulesPlaceholder
          id="territory-tools-dialog"
        />
      </Fragment>
    );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(TerritoryTools);