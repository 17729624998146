import React from 'react';
import {MenuItem, Select} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import {setDetailLevel} from "../../state/detailLevel/detailLevelActions";

const mapStateToProps = state => ({
  value: state.detailLevel.detailLevel,
  catalog: state.catalog
});

const mapDispatchToProps = dispatch => ({
  onSet: value => dispatch(setDetailLevel(value))
});

const DetailLevelSelect = ({value, catalog, onSet, onChange, t, variant}) =>
  <Select
    value={value !== null ? value : "all"}
    onChange={e => {
      const newVal = e.target.value;
      if (newVal !== value) {
        onSet(newVal !== "all" ? newVal : null);
        onChange && onChange(newVal !== "all" ? newVal : null);
      }
    }}
    variant={variant || undefined}
    fullWidth
    SelectDisplayProps={{"aria-haspopup": true}}
  >
    <MenuItem value={"all"}>
      {t('components.detailLevelSelect.options.all')}
    </MenuItem>
    {catalog && catalog.detailLevels.map(({id, label}) =>
      <MenuItem key={id} value={id}>
        {label}
      </MenuItem>
    )}
  </Select>;

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(DetailLevelSelect);