import React, {useEffect} from "react";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

const $ = window.jQuery;

const useStyles = makeStyles({
  root: {
    "& .MuiDialog-paperFullScreen": {
      maxWidth: ({maxWidth}) => `${maxWidth}px !important`
    }
  },
  paper: {
    width: "95%",
    height: "95%"
  }
});

const mapStateToProps = state => ({
  messages: state.spinner.messages
});

const FullscreenDialog = ({
                            messages,
                            dispatch,
                            children,
                            maxWidth,
                            ...props
                          }) => {

  const classes = useStyles({maxWidth});

  useEffect(() => {
    return () => {
      if ($(".MuiDialog-paperFullScreen").length <= 1) {
        document.body.classList.remove("body--hidden-scrollbar");
      }
    };
  }, []);

  return (
    <Dialog
      {...props}
      className={classes.root}
      classes={{
        paper: `${classes.paper} fullscreen-dialog`
      }}
      fullScreen
      disableEscapeKeyDown={(messages || []).length > 0}
      TransitionProps={{
        onEnter: () => {
          document.body.classList.add("body--hidden-scrollbar");
        },
        onExit: () => {
          if ($(".MuiDialog-paperFullScreen").length <= 1) {
            document.body.classList.remove("body--hidden-scrollbar");
          }
        }
      }}
    >
      {children}
    </Dialog>
  );
};

export default connect(mapStateToProps)(FullscreenDialog);