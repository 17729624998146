import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {getTextWidth} from "../../utils/style";

const $ = window.jQuery;

const CustomSelect = ({
                        label,
                        children,
                        style,
                        ...props
                      }) => {

  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    const getTextWidthEl = $("<span>").css({
      visibility: "hidden",
      fontSize: 16,
      whiteSpace: "nowrap"
    }).appendTo("body").get(0);

    const minWidth = getTextWidth(label, getTextWidthEl);
    setLabelWidth(minWidth);

    $(getTextWidthEl).remove();
  }, [label]);

  return (
    <TextField
      select
      label={label}
      style={{
        ...style,
        minWidth: labelWidth
      }}
      {...props}
    >
      {children}
    </TextField>
  );
};

export default CustomSelect;