import React, {Fragment, useState} from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import getDownloadButtonStyle from "./getDownloadButtonStyle";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  root: {
    "& > .MuiChip-root": {
      margin: 8
    },
    "& > .MuiChip-root:focus": {
      outline: "-webkit-focus-ring-color auto 1px"
    }
  },
  downloadChipDeleteIcon: {
    backgroundColor: "white"
  },
  counterAvatar: {
    width: 16,
    height: 16,
    marginLeft: -4,
    marginRight: 4
  }
});

const DatasetAttachments = ({
                              classes,
                              datasetTitle,
                              attachedDataFiles
                            }) => {

  const {t} = useTranslation();

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [downloadModal, setDownloadModal] = useState(null);

  const groupedAttachments = [];
  (attachedDataFiles || []).forEach(({format, url}) => {
    const group = groupedAttachments.find(({extension}) => extension === format.toLowerCase());
    if (!group) {
      groupedAttachments.push({
        extension: format.toLowerCase(),
        urls: [url]
      });
    } else {
      group.urls.push(url);
    }
  });

  return (
    <Fragment>
      <div className={classes.root}>
        {groupedAttachments.map((group, index) => (
          <Chip
            key={index}
            className={`download-btn--${group.extension.toLowerCase()}`}
            label={group.extension.toUpperCase()}
            deleteIcon={group.urls.length > 1
              ? (
                <Avatar style={{fontSize: 12, color: "black"}} classes={{root: classes.counterAvatar}}>
                  {group.urls.length}
                </Avatar>
              )
              : null
            }
            onDelete={group.urls.length > 1
              ? e => {
                e.stopPropagation();
                setIsDownloadModalOpen(true);
                setDownloadModal(group);
              }
              : null
            }
            size="small"
            onClick={e => {
              e.stopPropagation();
              if (group.urls.length > 1) {
                setIsDownloadModalOpen(true);
                setDownloadModal(group);
              } else {
                window.open(group.urls[0], "_blank");
              }
            }}
            style={{
              cursor: "pointer",
              ...getDownloadButtonStyle(group.extension)
            }}
            classes={{
              deleteIconSmall: classes.downloadChipDeleteIcon
            }}
            aria-label={
              t("scenes.results.datasetCard.downloadAria", {
                title: datasetTitle,
                extension: group.extension.toUpperCase()
              })
            }
          />
        ))}
      </div>
      {groupedAttachments.length > 0 && (
        <Dialog
          open={!!isDownloadModalOpen}
          onClose={() => setIsDownloadModalOpen(false)}
          TransitionProps={{
            onExited: () => {
              setDownloadModal(null);
            }
          }}
        >
          <DialogContent>
            <List>
              {downloadModal?.urls.map((url, key) =>
                <ListItem key={key}>
                  <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                </ListItem>
              )}
            </List>
            <DialogActions>
              <Button onClick={() => setIsDownloadModalOpen(false)}>
                {t("commons.confirm.close")}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(DatasetAttachments);