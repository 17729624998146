import React, {Component, Fragment} from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {withStyles} from "@material-ui/core";
import {getDatasetInternalUrl, getDatasetsInternalUrl} from "../../links";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import StorageIcon from '@material-ui/icons/Storage';
import FolderIcon from '@material-ui/icons/Folder';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import CatalogInfoButton from "../catalog-info-button";
import CatalogMetadataButton from "../catalog-metadata-button";
import CustomLink from "../custom-link";
import ListIcon from '@material-ui/icons/List';
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import HeightIcon from "@material-ui/icons/Height";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";

const styles = theme => ({
  treeViewRoot: {
    color: theme.palette.primary.main
  },
  treeItemRoot: {
    "&:focus > .MuiTreeItem-content .MuiTreeItem-label": {
      outline: "-webkit-focus-ring-color auto 1px",
      outlineOffset: -1
    }
  },
  treeItemContent: {
    cursor: "default"
  },
  treeItemContentUnselectable: {
    cursor: "default",
    "& > .MuiTreeItem-label": {
      background: "unset !important"
    }
  },
  treeItemLabel: {
    paddingLeft: 0
  },
  treeItemLabelCategory: {
    cursor: "pointer",
    backgroundColor: "inherit !important",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important"
    }
  },
  treeItemLabelCategorySelected: {
    cursor: "pointer",
    backgroundColor: "rgba(0, 41, 90, 0.2) !important",
    "&:hover": {
      backgroundColor: "rgba(0, 41, 90, 0.2) !important"
    }
  },
  treeItemLabelDataset: {
    width: "unset",
    backgroundColor: "inherit !important",
    "& .custom-link--enabled:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important"
    }
  },
  treeItemLabelDatasetSelected: {
    width: "unset",
    backgroundColor: "rgba(0, 41, 90, 0.2) !important",
    "& .custom-link--enabled:hover": {
      backgroundColor: "rgba(0, 41, 90, 0.2) !important"
    }
  },
  node: {
    display: "flex",
    alignItems: "center",
    padding: 8
  },
  nodeIcon: {
    marginRight: 8
  },
  nodeLabel: {},
  nodeAction: {
    marginLeft: 8,
    "& button": {
      padding: 4
    }
  },
  dataset: {
    display: "flex",
    alignItems: "center",
  },
  datasetIcon: {},
  datasetLabel: {},
  datasetAction: {
    marginLeft: 4,
    "& button": {
      padding: 4
    }
  },
  expandControls: {
    marginBottom: 2
  },
  expandControl: {
    padding: 8
  },
  datasetClickableLabel: {
    textTransform: "none",
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: 16
  }
});

const getNodeId = (catId, prevPath) => {
  let ret = "";

  if ((prevPath || []).length > 0) {
    ret += prevPath.join("+");
  }

  if (catId) {
    ret += "+" + catId
  }

  return ret;
};

const getSelectedNodeIds = categoryPath => {
  const ret = [];

  categoryPath.forEach((category, idx) => {
    ret.push(getNodeId(category, categoryPath.slice(0, idx)));
  });

  return ret
}

const getAllNodeIds = (tree, prevPath) => {

  const res = [];

  const recursive = (subTree, prevPath) => subTree
    ? (
      subTree.map(node => {
        res.push(getNodeId(node.id, prevPath));
        if (node.childrenCategories && node.childrenCategories.length) {
          recursive(node.childrenCategories, [...prevPath, node.id]);
        }
        return null;
      })
    )
    : [];

  recursive(tree, prevPath);

  return res;
};

class CategoriesTree extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: getSelectedNodeIds(props.selectedCategoryPath || []),
      selected: getSelectedNodeIds(props.selectedCategoryPath || [])
    };
    this.onExpand = this.onExpand.bind(this);
  }

  onExpand = expanded => this.setState({expanded});

  render() {

    const {
      t,
      classes,
      themeConfig,
      node,
      catalog,
      selectedDataset,
      onClick,
      onClose,
      initialPath = [],
      showDatasetList = false,
      showExpandControls = false,
      showCategoriesFirst = false,
      treeViewClasses = "",
      treeItemCategoryClasses = "",
      treeItemDatasetClasses = ""
    } = this.props;

    const {
      expanded,
      selected
    } = this.state;

    const getDataset = (datasetId, cat, prevPath) => {
      const nodeId = getNodeId(cat?.id, prevPath) + "-" + datasetId;

      const datasetLabel = catalog?.datasets?.[datasetId]
        ? catalog?.datasets?.[datasetId].title
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
          ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).title
          : datasetId;
      const datasetSource = catalog?.datasets?.[datasetId]
        ? catalog?.datasets?.[datasetId].source
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
          ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).source
          : null;
      const datasetDescription = catalog?.datasets?.[datasetId]
        ? catalog?.datasets?.[datasetId].description
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
          ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).description
          : null;
      const datasetAttachments = catalog?.datasets?.[datasetId]
        ? catalog?.datasets?.[datasetId].attachedDataFiles
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
          ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).attachedDataFiles
          : null;

      const isDatasetOnlyFile = catalog?.datasets?.[datasetId]
        ? catalog?.datasets?.[datasetId].catalogType === "ONLY_FILE"
        : catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId) !== undefined
          ? catalog.uncategorizedDatasets.find(({identifier}) => identifier === datasetId).catalogType === "ONLY_FILE"
          : false;

      return (
        <TreeItem
          key={nodeId}
          nodeId={nodeId}
          label={
            <div className={`${classes.dataset} categories-tree__tree-item__dataset`}>
              {onClick
                ? (
                  <Button
                    onClick={() => !isDatasetOnlyFile
                      ? onClick(datasetId)
                      : null
                    }
                    disabled={isDatasetOnlyFile}
                    classes={{label: classes.datasetClickableLabel}}
                    startIcon={<StorageIcon />}
                  >
                    {datasetLabel}
                  </Button>
                )
                : (
                  <CustomLink
                    to={getDatasetInternalUrl(node.code.toLowerCase(), [...prevPath, cat?.id], datasetId)}
                    text={
                      <i className={`${classes.datasetLabel} categories-tree__tree-item__dataset__label`}>
                        {datasetLabel}
                      </i>
                    }
                    icon={
                      <StorageIcon
                        fontSize="small"
                        className={`${classes.datasetIcon} categories-tree__tree-item__dataset__icon`}
                      />
                    }
                    onClick={onClose}
                    disabled={isDatasetOnlyFile}
                  />
                )
              }
              {(datasetSource || datasetDescription || datasetAttachments) && (
                <div className={`${classes.datasetAction} categories-tree__tree-item__dataset__action`}>
                  <CatalogInfoButton
                    title={datasetLabel}
                    source={datasetSource}
                    description={datasetDescription}
                    attachments={datasetAttachments}
                  />
                </div>
              )}
            </div>
          }
          classes={{
            root: `categories-tree__tree-item--dataset ${treeItemDatasetClasses}`,
            content: `${classes.treeItemContent} ${isDatasetOnlyFile ? classes.treeItemContentUnselectable : ""}`,
            label: `${classes.treeItemLabel} ${datasetId === selectedDataset ? classes.treeItemLabelDatasetSelected : classes.treeItemLabelDataset}`
          }}
        />
      );
    }

    const getTreeItems = (tree, prevPath) => tree.map(cat => {
      const nodeId = getNodeId(cat.id, prevPath);
      return (
        <TreeItem
          key={nodeId}
          nodeId={nodeId}
          label={
            <div className={`${classes.node} categories-tree__tree-item__node`}>
              {(expanded.includes(getNodeId(cat.id, prevPath)))
                ? (
                  <FolderOpenIcon
                    className={`${classes.nodeIcon} categories-tree__tree-item__node__icon`}
                  />
                )
                : (
                  <FolderIcon
                    className={`${classes.nodeIcon} categories-tree__tree-item__node__icon`}
                  />
                )
              }
              <span className={`${classes.nodeLabel} categories-tree__tree-item__node__label`}>
                {cat.label}
              </span>
              {cat.description && (
                <div className={`${classes.nodeAction} categories-tree__tree-item__node__action`}>
                  <CatalogInfoButton
                    title={cat.label}
                    description={cat.description}
                  />
                </div>
              )}
              {cat.metadataUrl && (
                <div className={`${classes.nodeAction} categories-tree__tree-item__node__action`}>
                  <CatalogMetadataButton
                    metadataUrl={cat.metadataUrl}
                    iconSize="small"
                  />
                </div>
              )}
            </div>
          }
          /* TODO: inefficente */
          classes={{
            root: `categories-tree__tree-item--category ${classes.treeItemRoot} ${treeItemCategoryClasses}`,
            content: classes.treeItemContent,
            label: `${classes.treeItemLabel} ${selected.includes(getNodeId(cat.id, prevPath)) ? classes.treeItemLabelCategorySelected : classes.treeItemLabelCategory}`
          }}
          tabIndex={0}
        >
          {((cat.datasetIdentifiers && cat.datasetIdentifiers.length > 0) || (cat.childrenCategories && cat.childrenCategories.length > 0)) && (
            <Fragment>
              {showCategoriesFirst && cat.childrenCategories && cat.childrenCategories.length > 0 && (
                getTreeItems(cat.childrenCategories, [...prevPath, cat.id])
              )}
              {(cat.datasetIdentifiers || []).length === 0
                ? null
                : (themeConfig.showDatasetListInCategoriesTree || showDatasetList)
                  ? cat.datasetIdentifiers.map(datasetId => getDataset(datasetId, cat, prevPath))
                  : (
                    <TreeItem
                      nodeId={getNodeId(cat.id, prevPath) + "-results"}
                      label={
                        <CustomLink
                          to={getDatasetsInternalUrl(node.code.toLowerCase(), [...prevPath, cat.id])}
                          text={
                            <i className={`${classes.datasetLabel} categories-tree__tree-item__dataset__label`}>
                              {t("components.categoriesTree.goToData", {datasetCount: cat.datasetIdentifiers.length})}
                            </i>
                          }
                          icon={<ListIcon className={`${classes.datasetIcon} categories-tree__tree-item__dataset__icon`}/>}
                          onClick={onClose}
                        />
                      }
                      classes={{
                        root: `categories-tree__tree-item--dataset ${treeItemDatasetClasses}`,
                        content: classes.treeItemContent,
                        label: `${classes.treeItemLabel} ${classes.treeItemLabelDataset}`
                      }}
                    />
                  )
              }
              {!showCategoriesFirst && cat.childrenCategories && cat.childrenCategories.length > 0 && (
                getTreeItems(cat.childrenCategories, [...prevPath, cat.id])
              )}
            </Fragment>
          )}
        </TreeItem>
      )
    });

    let tree = catalog.categoryGroups.length > 0
      ? catalog.hasCategorySchemes
        ? catalog.categoryGroups.map(({id, label, categories}) => ({id, label, childrenCategories: categories}))
        : catalog.categoryGroups[0].categories
      : null;

    if (catalog.uncategorizedDatasets && catalog.uncategorizedDatasets.length > 0) {
      tree = [
        ...(tree || []),
        {
          id: "uncategorized",
          label: t("commons.catalog.uncategorized"),
          childrenCategories: [],
          datasetIdentifiers: catalog.uncategorizedDatasets.map(({identifier}) => identifier)
        }
      ];
    }

    return (
      <Fragment>
        {showExpandControls && catalog.categoryGroups.length > 0 && (
          <Grid container spacing={1} className={classes.expandControls} justifyContent="flex-end">
            <Grid item>
              <Tooltip title={t("components.categoriesTree.expandAll.tooltip")}>
                <IconButton
                  aria-label={t("components.categoriesTree.expandAll.ariaLabel")}
                  className={classes.expandControl}
                  onClick={() => {
                    let nodeIds = [];
                    if (catalog.hasCategorySchemes) {
                      catalog.categoryGroups.forEach(({id, label, categories}) => {
                        nodeIds = [...nodeIds, ...getAllNodeIds([{id, label, childrenCategories: categories}], initialPath), "uncategorized"];
                      });
                    } else {
                      nodeIds = [...getAllNodeIds(catalog.categoryGroups[0].categories, initialPath), "uncategorized"];
                    }
                    this.onExpand(nodeIds);
                  }}
                >
                  <HeightIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t("components.categoriesTree.collapseAll.tooltip")}>
                <IconButton
                  aria-label={t("components.categoriesTree.collapseAll.ariaLabel")}
                  className={classes.expandControl}
                  onClick={() => this.onExpand([])}
                >
                  <VerticalAlignCenterIcon/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        {tree && (
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon/>}
            defaultExpandIcon={<ChevronRightIcon/>}
            expanded={expanded}
            onNodeToggle={(_, nodeIds) => this.onExpand(nodeIds)}
            classes={{
              root: `categories-tree__tree-view ${classes.treeViewRoot} ${treeViewClasses}`
            }}
          >
            {getTreeItems(tree, initialPath)}
            {(catalog?.rootDatasets || []).map(({identifier}) => getDataset(identifier, null, initialPath))}
          </TreeView>
        )}
      </Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(state => ({
    themeConfig: state.app.themeConfig
  }))
)(CategoriesTree);