import React, {Fragment, useCallback, useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import DatasetFilters from "../../dataset-filters";
import Table, {JSONSTAT_TABLE_FONT_SIZE_LG, JSONSTAT_TABLE_FONT_SIZE_MD, JSONSTAT_TABLE_FONT_SIZE_SM} from "../../table";
import Grid from "@material-ui/core/Grid";
import FontSizeSelector, {FONT_SIZE_SELECTOR_FONT_SIZE_MD, FONT_SIZE_SELECTOR_FONT_SIZE_SM} from "../../font-size-selector";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomDialogTitle from "../../custom-dialog-title";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CustomEmpty from "../../custom-empty";
import {
  setDatasetChartSettings,
  setDatasetHtmlGeneratingTime,
  setDatasetLabelFormat,
  setDatasetMapSettings,
  setDatasetTerritoryDetailLevel,
  setDatasetTemporalDimOrder,
  setDatasetVariation,
  submitDatasetChartLayout,
  submitDatasetDownload,
  submitDatasetMapLayout,
  submitDatasetTableLayout
} from "../../../state/dataset/datasetActions";
import Alert from "@material-ui/lab/Alert";
import {getUpdatedLayout} from "../../../utils/jsonStat";
import {numberFormatter} from "../../../utils/formatters";
import Map from "../../map";
import LabelFormatSelector from "../../label-format-selector";
import Chart, {CHART_TYPE_BAR, CHART_TYPE_LINE} from "../../chart";
import SettingsIcon from "@material-ui/icons/Settings";
import VariationSelector from "../../variation-selector";
import {getViewerTypeFromIdx, viewersFactory} from "../constant";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChartSettingsGeneral from "../../chart-settings-forms/General";
import ChartSettingsColors from "../../chart-settings-forms/Colors";
import GetAppIcon from "@material-ui/icons/GetApp";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID} from "../../label-format-selector/constants";
import {DOWNLOAD_FORMAT_CSV, downloadFormats} from "../../../utils/download";
import TemporalDimOrderSelector from "../../temporal-dim-order-selector";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../../temporal-dim-order-selector/constants";
import MapSettingsSettings from "../../map-settings-form/Settings";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  filtersAndActions: {
    width: "100%",
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  filters: {},
  actions: {
    marginBottom: 8
  },
  action: {
    height: 40,
    "& button": {
      padding: 8
    }
  },
  viewer: {
    width: "100%",
    position: "relative"
  },
  warningAlert: {
    width: "100%",
    marginBottom: 16
  },
  settingsTabs: {
    height: 48
  },
  settingsContent: {
    marginTop: 12,
    padding: 12,
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100% - 60px)",
    display: "flex"
  }
});

const mapStateToProps = ({app, appConfig, dataset}) => ({
  defaultLanguage: app.language,
  languages: app.languages,
  showSingleGeometry: appConfig.mapConfig.showSingleGeometry,
  dataset: dataset.dataset,
  timeDim: dataset.timeDim,
  criteria: dataset.criteria,
  viewerIdx: dataset.viewerIdx,
  tableLayout: dataset.tableLayout,
  mapLayout: dataset.mapLayout,
  chartLayout: dataset.chartLayout,
  tableFilterTree: dataset.tableFilterTree,
  mapFilterTree: dataset.mapFilterTree,
  chartFilterTree: dataset.chartFilterTree,
  timePeriodsByFreq: dataset.timePeriodsByFreq,
  labelFormat: dataset.labelFormat,
  temporalDimOrder: dataset.temporalDimOrder,
  showTrend: dataset.showTrend,
  showCyclical: dataset.showCyclical,
  decimalSeparator: dataset.decimalSeparator,
  decimalPlaces: dataset.decimalPlaces,
  tableEmptyChar: dataset.tableEmptyChar,
  chartSettings: dataset.chartSettings,
  mapSettings: dataset.mapSettings,
  enableVariation: dataset.enableVariation,
  isPartialData: dataset.isPartialData,
  isTooBigData: dataset.isTooBigData,
  detailLevelTree: dataset.detailLevelTree,
  detailLevel: dataset.detailLevel,
  isFullscreen: dataset.isFullscreen
});

const mapDispatchToProps = dispatch => ({
  onTableLayoutSet: layout => dispatch(submitDatasetTableLayout(layout)),
  onMapLayoutSet: layout => dispatch(submitDatasetMapLayout(layout)),
  onChartLayoutSet: layout => dispatch(submitDatasetChartLayout(layout)),
  onLabelFormatSet: labelFormat => dispatch(setDatasetLabelFormat(labelFormat)),
  onVariationSet: variation => dispatch(setDatasetVariation(variation)),
  onChartSettingsSet: chartSetting => dispatch(setDatasetChartSettings(chartSetting)),
  onMapSettingsSet: mapSettings => dispatch(setDatasetMapSettings(mapSettings)),
  onDetailLevelSet: detailLevel => dispatch(setDatasetTerritoryDetailLevel(detailLevel)),
  onTimeSet: time => dispatch(setDatasetHtmlGeneratingTime(time)),
  onDownloadSubmit: (nodeId, datasetId, datasetTitle, criteria, layout, format, extension, zipped, params, defaultLanguage, languages, t) =>
    dispatch(submitDatasetDownload(nodeId, datasetId, datasetTitle, criteria, layout, format, extension, zipped, params, defaultLanguage, languages, t)),
  onTemporalDimOrderSet: temporalDimOrder => dispatch(setDatasetTemporalDimOrder(temporalDimOrder)),
});

const handleStyle = mapId => {

  const $actions = $("#data-viewer__single-viewer__header__actions");
  const $filters = $("#data-viewer__single-viewer__header__filters");
  const $filtersAndActions = $("#data-viewer__single-viewer__header__filters-and-actions");

  const actionsWidth = $actions.outerWidth(true) || 0;
  const filtersWidth = $filters.outerWidth(true) || 0;
  const filtersAndActionsWidth = $filtersAndActions.width() || 0;

  if (filtersAndActionsWidth > (actionsWidth + filtersWidth)) {
    $filtersAndActions.css({flexDirection: "row"});
    $actions.css({alignSelf: "unset"});

  } else {
    $filtersAndActions.css({flexDirection: "column-reverse"});
    $actions.css({alignSelf: "end"});
  }

  const viewerHeaderHeight = $("#data-viewer__single-viewer__header").outerHeight(true) || 0;
  $("#data-viewer__single-viewer__viewer").height(`calc(100% - ${viewerHeaderHeight}px)`);

  if (mapId) {
    window.LMap.handleViewportChange(mapId);
  }

};

function SingleViewer(props) {
  const {
    classes,

    nodeId,
    nodeExtras,
    datasetId,
    datasetTitle,
    maxObservation,
    chartId,
    mapId,
    isFullscreen,

    defaultLanguage,
    languages,
    showSingleGeometry,
    dataset,
    timeDim,
    criteria,
    viewerIdx,
    tableLayout,
    mapLayout,
    chartLayout,
    tableFilterTree,
    mapFilterTree,
    chartFilterTree,
    timePeriodsByFreq,
    labelFormat,
    temporalDimOrder,
    showTrend,
    showCyclical,
    decimalSeparator,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    enableVariation,
    isPartialData,
    isTooBigData,
    detailLevel,

    onTableLayoutSet,
    onMapLayoutSet,
    onChartLayoutSet,
    onLabelFormatSet,
    onVariationSet,
    onChartSettingsSet,
    onMapSettingsSet,
    onDetailLevelSet,
    onTimeSet,
    onDownloadSubmit,
    onTemporalDimOrderSet
  } = props;

  const {t} = useTranslation();

  const [fontSize, setFontSize] = useState(FONT_SIZE_SELECTOR_FONT_SIZE_MD);

  const [isChartSettingVisible, setChartSettingsVisibility] = useState(false);
  const [chartSettingsTabId, setChartSettingsTabId] = useState(null);
  const [tmpChartSettings, setTmpChartSettings] = useState(null);

  const [isMapSettingVisible, setMapSettingsVisibility] = useState(false);
  const [tmpMapSettings, setTmpMapSettings] = useState(null);
  const [isMapSettingsValid, setMapSettingsValidity] = useState(true);

  const [hierarchyOnlyAttributes] = useState(nodeExtras?.HierarchyOnlyAttributes || []);
  const [hideHierarchyOnlyRows] = useState(nodeExtras?.HideHierarchyOnlyRows || false);

  useEffect(() => {
    const func = () => handleStyle(mapId);
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func)
  }, [mapId]);

  useEffect(() => {
    handleStyle(mapId);
  });

  useEffect(() => {
    setTmpChartSettings(chartSettings);
  }, [chartSettings]);

  useEffect(() => {
    setTmpMapSettings(mapSettings);
  }, [mapSettings]);

  const onTablePageGenerationComplete = useCallback(generatedTableStructure => {
    const timings = generatedTableStructure.timings;
    if (timings) {
      onTimeSet(Math.round((generatedTableStructure.timings) * 100) / 100);
    }
  }, [onTimeSet]);

  const handleFilterSelect = useCallback((dimension, value) => {
    if (viewerIdx === 0) {
      onTableLayoutSet(getUpdatedLayout(dimension, value, dataset, tableLayout, tableFilterTree));
    } else if (viewerIdx === 1) {
      onMapLayoutSet(getUpdatedLayout(dimension, value, dataset, mapLayout, mapFilterTree));
    } else if (viewerIdx >= 2) {
      onChartLayoutSet(getUpdatedLayout(dimension, value, dataset, chartLayout, chartFilterTree));
    }
  }, [dataset, viewerIdx, tableLayout, tableFilterTree, onTableLayoutSet, mapLayout, mapFilterTree, onMapLayoutSet, chartLayout, chartFilterTree, onChartLayoutSet]);

  const handleFontSizeSelect = size => {
    setFontSize(size);
  };

  const handleChartSettingsOpen = () => {
    setChartSettingsTabId("general");
    setChartSettingsVisibility(true);
  };

  const handleChartSettingsClose = () => {
    setChartSettingsVisibility(false);
    setTmpChartSettings(chartSettings);
  };

  const handleChartSettingsSubmit = () => {
    setChartSettingsVisibility(false);
    onChartSettingsSet(tmpChartSettings);
  };

  const handleMapSettingsOpen = settings => {
    setTmpMapSettings(settings);
    setMapSettingsValidity(true);
    setMapSettingsVisibility(true);
  };

  const handleMapSettingsClose = () => {
    setMapSettingsVisibility(false);
  };

  const handleMapSettingsSubmit = () => {
    setMapSettingsVisibility(false);
    onMapSettingsSet(tmpMapSettings);
  };

  return (
    <Fragment>
      <div id="data-viewer__single-viewer" className={`${classes.root} data-viewer__single-viewer`}>
        {dataset && viewerIdx !== null && (
          <div id="data-viewer__single-viewer__header">
            {dataset && isPartialData && (
              <Alert severity="warning" className={classes.warningAlert}>
                {t("scenes.dataViewer.warnings.maxObservation.label", {maxObservation: maxObservation ? numberFormatter(maxObservation) : ""})}
              </Alert>
            )}
            <div id="data-viewer__single-viewer__header__filters-and-actions" className={classes.filtersAndActions}>
              <div id="data-viewer__single-viewer__header__filters" className={classes.filters}>
                <DatasetFilters
                  jsonStat={dataset}
                  layout={viewerIdx === 0
                    ? tableLayout
                    : viewerIdx === 1
                      ? mapLayout
                      : chartLayout
                  }
                  filterTree={viewerIdx === 0
                    ? tableFilterTree
                    : viewerIdx === 1
                      ? mapFilterTree
                      : chartFilterTree
                  }
                  labelFormat={labelFormat}
                  onSelect={handleFilterSelect}
                />
              </div>
              <div id="data-viewer__single-viewer__header__actions" className={classes.actions}>
                <Grid container>
                  {(viewerIdx === 0 || viewerIdx >= 2) && (
                    <Grid item id="temporal-dim-order-selector" className={classes.action}>
                      <TemporalDimOrderSelector
                        temporalDimOrder={temporalDimOrder}
                        setTemporalDimOrder={onTemporalDimOrderSet}
                      />
                    </Grid>
                  )}
                  {enableVariation &&
                    (viewerIdx === 0 || getViewerTypeFromIdx(viewerIdx) === CHART_TYPE_BAR || getViewerTypeFromIdx(viewerIdx) === CHART_TYPE_LINE) && (
                      <Grid item id="variation-selector" className={classes.action}>
                        <VariationSelector
                          showTrend={showTrend}
                          showCyclical={showCyclical}
                          onVariationSet={onVariationSet}
                        />
                      </Grid>
                    )
                  }
                  <Grid item id="label-format-selector" className={classes.action}>
                    <LabelFormatSelector
                      labelFormat={labelFormat}
                      setLabelFormat={onLabelFormatSet}
                    />
                  </Grid>
                  <Grid item id="export-csv" className={classes.action}>
                    <Tooltip title={t("scenes.dataViewer.actions.exportCsv.tooltip")}>
                      <IconButton
                        aria-label={t("scenes.dataViewer.actions.exportCsv.ariaLabel")}
                        onClick={() => {
                          const exportParams = {
                            labelFormat: labelFormat,
                            customLabelFormat: {
                              [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
                            },
                            decimalNumber: decimalPlaces,
                            decimalSeparator: decimalSeparator,
                            emptyCellPlaceHolder: tableEmptyChar,
                            hasVariation: enableVariation,
                            showTrend: showTrend,
                            showCyclical: showCyclical,
                            temporalDimOrder: temporalDimOrder
                          };
                          const layout = viewerIdx === 0
                            ? tableLayout
                            : viewerIdx === 1
                              ? mapLayout
                              : chartLayout;

                          const newCriteria = {...criteria};
                          if (viewerIdx === 1 && layout?.territoryDim) {
                            const territoryDim = layout.territoryDim;
                            newCriteria[territoryDim] = {
                              id: territoryDim,
                              filterValues: window.LMap.getDataIds(mapId)
                            };
                          }

                          onDownloadSubmit(
                            nodeId,
                            datasetId,
                            datasetTitle,
                            newCriteria,
                            layout,
                            DOWNLOAD_FORMAT_CSV,
                            downloadFormats()[DOWNLOAD_FORMAT_CSV].extension,
                            false,
                            exportParams,
                            defaultLanguage,
                            languages,
                            t
                          );
                        }}
                      >
                        <GetAppIcon/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {viewerIdx >= 2 && (
                    <Grid item id="chart-settings" className={classes.action}>
                      <Tooltip title={t("scenes.dataViewer.actions.chartSettings.tooltip")}>
                        <IconButton
                          aria-label={t("scenes.dataViewer.actions.chartSettings.ariaLabel")}
                          onClick={handleChartSettingsOpen}
                        >
                          <SettingsIcon/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {false && viewerIdx === 1 && (
                    <Grid item id="map-settings" className={classes.action}>
                      <Tooltip title={t("scenes.dataViewer.actions.mapSettings.tooltip")}>
                        <IconButton
                          aria-label={t("scenes.dataViewer.actions.mapSettings.ariaLabel")}
                          onClick={handleMapSettingsOpen}
                        >
                          <SettingsIcon/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {viewerIdx === 0 && (
                    <Grid item  id="font-size-selector" className={classes.action}>
                      <FontSizeSelector
                        fontSize={fontSize}
                        setFontSize={handleFontSizeSelect}
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        )}
        <div id="data-viewer__single-viewer__viewer" className={classes.viewer}>
          {(() => {
            if (viewerIdx === 0 && tableLayout) {
              const isTimeDimInTable = !tableLayout.filters.includes(timeDim);
              return !isTooBigData
                ? (
                  <Table
                    jsonStat={dataset}
                    layout={tableLayout}
                    isFullscreen={isFullscreen}
                    labelFormat={labelFormat}
                    showTrend={showTrend}
                    showCyclical={showCyclical}
                    fontSize={fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_SM
                      ? JSONSTAT_TABLE_FONT_SIZE_SM
                      : fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_MD
                        ? JSONSTAT_TABLE_FONT_SIZE_MD
                        : JSONSTAT_TABLE_FONT_SIZE_LG
                    }
                    decimalSeparator={decimalSeparator}
                    decimalPlaces={decimalPlaces}
                    emptyChar={tableEmptyChar}
                    invertedDims={(temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && isTimeDimInTable)
                      ? [timeDim]
                      : null
                    }
                    hierarchyOnlyAttributes={hierarchyOnlyAttributes}
                    hideHierarchyOnlyRows={hideHierarchyOnlyRows}
                    onPageGenerationComplete={onTablePageGenerationComplete}
                    filterable
                    sortable
                  />
                )
                : (
                  <CustomEmpty
                    text={t("scenes.dataViewer.errors.tooBigData")}
                  />
                )

            } else if (viewerIdx === 1 && mapLayout) {
              return (
                <Map
                  mapId={mapId}
                  nodeId={nodeId}
                  jsonStat={dataset}
                  layout={mapLayout}
                  labelFormat={labelFormat}
                  decimalSeparator={decimalSeparator}
                  decimalPlaces={decimalPlaces}
                  defaultDetailLevel={detailLevel}
                  onDetailLevelChange={onDetailLevelSet}
                  initialBaseLayer={mapSettings.baseLayer}
                  defaultSettings={{
                    isLegendCollapsed: mapSettings.isLegendCollapsed,
                    opacity: mapSettings.opacity
                  }}
                  settings={{
                    classificationMethod: mapSettings.classificationMethod,
                    paletteStartColor: mapSettings.paletteStartColor,
                    paletteEndColor: mapSettings.paletteEndColor,
                    paletteCardinality: mapSettings.paletteCardinality,
                    customIntervals: mapSettings.customIntervals
                  }}
                  setSettings={onMapSettingsSet}
                  isFullscreen={isFullscreen}
                  showSelection
                  disableSettings
                  onSettingsShow={handleMapSettingsOpen}
                  showSingleGeometry={showSingleGeometry}
                />
              )

            } else if (viewerIdx >= 2 && chartLayout) {
              const isTimeDimInChart = !chartLayout.filters.includes(timeDim);
              return (
                <Chart
                  chartId={chartId}
                  type={viewersFactory(t)[viewerIdx].chartType}
                  jsonStat={dataset}
                  layout={chartLayout}
                  timePeriodsByFreq={timePeriodsByFreq}
                  labelFormat={labelFormat}
                  showTrend={showTrend}
                  showCyclical={showCyclical}
                  decimalSeparator={decimalSeparator}
                  decimalPlaces={decimalPlaces}
                  chartSettings={chartSettings}
                  invertedDims={(temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && isTimeDimInChart)
                    ? [timeDim]
                    : null
                  }
                />
              )

            } else {
              return (
                <CustomEmpty
                  text={t("scenes.dataViewer.errors.noViewerSelected")}
                />
              )
            }
          })()}
        </div>
      </div>

      <Dialog
        open={isChartSettingVisible}
        maxWidth="md"
        fullWidth
        onClose={handleChartSettingsClose}
      >
        <CustomDialogTitle onClose={handleChartSettingsClose}>
          {t("scenes.dataViewer.singleViewer.dialogs.chartSettings.title")}
        </CustomDialogTitle>
        <DialogContent style={{height: 480}}>
          <Box className={classes.settingsTabs}>
            <Tabs
              value={chartSettingsTabId}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(event, newValue) => setChartSettingsTabId(newValue)}
            >
              <Tab value={"general"} label={t("scenes.dataViewer.singleViewer.dialogs.chartSettings.tabs.general")}/>
              <Tab value={"colors"} label={t("scenes.dataViewer.singleViewer.dialogs.chartSettings.tabs.colors")}/>
            </Tabs>
          </Box>
          <div className={classes.settingsContent}>
            {chartSettingsTabId === "general" && (
              <ChartSettingsGeneral
                settings={tmpChartSettings}
                onSettingsSet={setTmpChartSettings}
              />
            )}
            {chartSettingsTabId === "colors" && (
              <ChartSettingsColors
                jsonStat={dataset}
                settings={tmpChartSettings}
                onSettingsSet={setTmpChartSettings}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChartSettingsClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button autoFocus onClick={handleChartSettingsSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isMapSettingVisible}
        maxWidth="md"
        fullWidth
        onClose={handleMapSettingsClose}
      >
        <CustomDialogTitle onClose={handleMapSettingsClose}>
          {t("scenes.dataViewer.singleViewer.dialogs.mapSettings.title")}
        </CustomDialogTitle>
        <DialogContent>
          <MapSettingsSettings
            mapId={mapId}
            settings={tmpMapSettings}
            onSettingsSet={setTmpMapSettings}
            setSettingsValidity={setMapSettingsValidity}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleMapSettingsClose}
          >
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            color="primary"
            onClick={handleMapSettingsSubmit}
            disabled={!isMapSettingsValid}
          >
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SingleViewer)