import React, {Fragment, useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import BarChartIcon from "@material-ui/icons/BarChart";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import SettingsIcon from "@material-ui/icons/Settings";
import EditIcon from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomDialogTitle from "../../custom-dialog-title";
import Button from "@material-ui/core/Button";
import {
  enableDatasetFetch,
  hideDatasetCriteria,
  setDatasetStructureCriteria,
  setDatasetViewer,
  showDatasetCriteria,
  submitDatasetChartFilterTree,
  submitDatasetChartLayout,
  submitDatasetTableFilterTree,
  submitDatasetTableLayout
} from "../../../state/dataset/datasetActions";
import Criteria from "../../criteria";
import TableLayout from "../../table-layout";
import ChartLayout from "../../chart-layout";
import {getDimensionFilterValues, getFilterTreeFromJsonStat, getInitialFiltersValue} from "../../../utils/jsonStat";
import MetadataIcon from "../../custom-icons/MetadataIcon";
import FullscreenDialog from "../../fullscreen-dialog";
import {ViewerMode} from "../../../state/dataset/constants";

const styles = theme => ({
  root: {
    "& .MuiBottomNavigation-root": {
      height: "unset !important",
    },
    "& .MuiBottomNavigationAction-label": {
      fontSize: "13px !important"
    }
  },
  floatingMenuOption: {
    color: "gray"
  },
  floatingMenuOptionSelected: {
    color: theme.palette.primary.main
  },
  divider: {
    margin: "4px 0"
  },
  navigationActionDisabled: {
    color: "rgba(0, 0, 0, 0.26) !important",
    "& svg": {
      color: "rgba(0, 0, 0, 0.26)"
    }
  }
});

const mapStateToProps = ({dataset}) => ({
  nodeCode: dataset.nodeCode,
  dataset: dataset.dataset,
  viewerIdx: dataset.viewerIdx,
  isCriteriaVisible: dataset.isCriteriaVisible,
  dimensions: dataset.dimensions,
  timeDim: dataset.timeDim,
  freqDim: dataset.freqDim,
  codelists: dataset.codelists,
  codelistsLength: dataset.codelistsLength,
  tableLayout: dataset.tableLayout,
  mapLayout: dataset.mapLayout,
  chartLayout: dataset.chartLayout,
  criteria: dataset.criteria,
  labelFormat: dataset.labelFormat,
  enableCriteria: dataset.enableCriteria,
  enableLayout: dataset.enableLayout,
  isTableEnabled: dataset.isTableEnabled,
  isMapEnabled: dataset.isMapEnabled,
  isChartEnabled: dataset.isChartEnabled,
  templateLayouts: dataset.template?.layouts,
  tableLockedDimensions: dataset.tableLockedDimensions,
  graphLockedDimensions: dataset.graphLockedDimensions
});

const mapDispatchToProps = dispatch => ({
  onFetchDatasetEnable: maxObservation => dispatch(enableDatasetFetch(maxObservation)),
  onViewerSet: viewerIdx => dispatch(setDatasetViewer(viewerIdx)),
  onTableLayoutSet: layout => dispatch(submitDatasetTableLayout(layout)),
  onTableFilterTreeSet: filterTree => dispatch(submitDatasetTableFilterTree(filterTree)),
  onChartLayoutSet: layout => dispatch(submitDatasetChartLayout(layout)),
  onChartFilterTreeSet: filterTree => dispatch(submitDatasetChartFilterTree(filterTree)),
  onCriteriaShow: () => dispatch(showDatasetCriteria()),
  onCriteriaHide: () => dispatch(hideDatasetCriteria()),
  onSetCriteria: criteria => dispatch(setDatasetStructureCriteria(criteria)),
});

function SingleViewerSidebar(props) {

  const {
    classes,

    datasetMap,
    nodeCode,
    dataset,
    viewerIdx,
    enableCriteria,
    enableLayout,
    isCriteriaVisible,
    dimensions,
    timeDim,
    freqDim,
    codelists,
    codelistsLength,
    tableLayout,
    mapLayout,
    chartLayout,
    criteria,
    labelFormat,

    onFetchDatasetEnable,
    onViewerSet,
    onTableLayoutSet,
    onTableFilterTreeSet,
    onChartLayoutSet,
    onChartFilterTreeSet,
    onCriteriaShow,
    onCriteriaHide,
    onSetCriteria,

    nodeId,
    datasetId,
    viewers,
    onMetadataShow,
    maxObservation,
    nodeExtras,

    isTableEnabled,
    isMapEnabled,
    isChartEnabled,
    templateLayouts,
    tableLockedDimensions,
    graphLockedDimensions
  } = props;

  const {t} = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const [tmpTableLayout, setTmpTableLayout] = useState(null);
  const [tmpChartLayout, setTmpChartLayout] = useState(null);

  const [isLayoutVisible, setLayoutVisibility] = useState(false);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const defaultLastNPeriods = nodeExtras?.DefaultLastNPeriods;

  useEffect(() => {
    setTmpTableLayout(tableLayout);
  }, [tableLayout]);

  useEffect(() => {
    setTmpChartLayout(chartLayout);
  }, [chartLayout]);

  const handleViewerSelect = index => {
    onViewerSet(index);
    setAnchorEl(null);
  };

  const handleLayoutOpen = () => {
    setLayoutVisibility(true);
  };

  const handleLayoutClose = () => {
    setLayoutVisibility(false);
    setTmpTableLayout(tableLayout);
    setTmpChartLayout(chartLayout);
  };

  const handleLayoutSubmit = () => {
    setLayoutVisibility(false);

    if (viewerIdx === 0) {
      let filtersValue = tableLayout.filtersValue;

      if (tableLayout.filters.join("+") !== tmpTableLayout.filters.join("+")) {
        const newTableFilterTree = getFilterTreeFromJsonStat(tmpTableLayout.filters, dataset);
        onTableFilterTreeSet(newTableFilterTree);
        filtersValue = getInitialFiltersValue(dataset, tmpTableLayout, newTableFilterTree);
      }

      onTableLayoutSet({
        ...tmpTableLayout,
        filtersValue: filtersValue
      });

    } else if (viewerIdx >= 2) {
      const newPrimaryDim = tmpChartLayout.primaryDim[0];
      const newSecondaryDim = tmpChartLayout.secondaryDim[0];
      const prevPrimaryDim = chartLayout.primaryDim[0];
      const prevSecondaryDim = chartLayout.secondaryDim[0];

      const isSecondaryDimChanged = (
        (!prevSecondaryDim && !!newSecondaryDim) ||
        (!!prevSecondaryDim && !newSecondaryDim) ||
        (!!prevSecondaryDim && !!newSecondaryDim && prevSecondaryDim !== newSecondaryDim)
      );

      const filters = [...tmpChartLayout.primaryDim, ...tmpChartLayout.secondaryDim, ...tmpChartLayout.filters];
      const newChartFilterTree = getFilterTreeFromJsonStat(filters, dataset);
      if (newPrimaryDim !== prevPrimaryDim) {
        tmpChartLayout.primaryDimValues = dataset.dimension[newPrimaryDim].category.index;
        tmpChartLayout.secondaryDimValues = newSecondaryDim
          ? getDimensionFilterValues(newSecondaryDim, dataset, tmpChartLayout, newChartFilterTree)
          : []
      } else if (isSecondaryDimChanged) {
        tmpChartLayout.secondaryDimValues = newSecondaryDim
          ? getDimensionFilterValues(newSecondaryDim, dataset, tmpChartLayout, newChartFilterTree)
          : []
      }
      onChartLayoutSet({
        ...tmpChartLayout,
        filtersValue: getInitialFiltersValue(dataset, tmpChartLayout, newChartFilterTree)
      });
      onChartFilterTreeSet(newChartFilterTree);
    }
  };

  const handleCriteriaOpen = () => {
    onCriteriaShow();
  }

  const handleCriteriaClose = () => {
    onCriteriaHide();
    setCriteriaValidity(true);
  }

  const handleCriteriaSubmit = (maxObservation) => {
    onFetchDatasetEnable(maxObservation);
  }

  const isCriteriaDisabled = !enableCriteria;
  const isLayoutDisabled = !enableLayout || !dataset || !(!!tableLayout || !!mapLayout || !!chartLayout);
  const isTableDisabled = !dataset || !tableLayout;
  const isChartDisabled = !dataset || !chartLayout;
  const isMapDisabled = !dataset || !mapLayout;

  const metadataUrl = datasetMap[datasetId]?.referenceMetadata || null;

  return (
    <div className={classes.root} role="menu">

      <BottomNavigation
        showLabels
        onChange={handleCriteriaOpen}
      >
        <BottomNavigationAction
          label={t("scenes.dataViewer.singleViewer.sidebar.criteria")}
          icon={<SettingsIcon/>}
          className={isCriteriaDisabled ? classes.navigationActionDisabled : ""}
          disabled={isCriteriaDisabled}
          role="menuitem"
        />
      </BottomNavigation>
      <BottomNavigation
        showLabels
        onChange={handleLayoutOpen}
      >
        <BottomNavigationAction
          label={t("scenes.dataViewer.singleViewer.sidebar.layout")}
          icon={<EditIcon/>}
          className={(isLayoutDisabled || viewerIdx === 1) ? classes.navigationActionDisabled : ""}
          disabled={isLayoutDisabled || viewerIdx === 1}
          role="menuitem"
        />
      </BottomNavigation>
      <Divider className={classes.divider}/>

      {metadataUrl && (
        <Fragment>
          <BottomNavigation
            showLabels
            onChange={() => onMetadataShow(metadataUrl)}
            style={{marginBottom: 8}}
          >
            <BottomNavigationAction
              label={t("scenes.dataViewer.singleViewer.sidebar.metadata")}
              icon={<MetadataIcon/>}
              role="menuitem"
            />
          </BottomNavigation>
          <Divider className={classes.divider}/>
        </Fragment>
      )}

      {isTableEnabled && !templateLayouts?.disableTable && (
        <BottomNavigation
          showLabels
          value={viewerIdx === 0 ? 0 : null}
          onChange={() => handleViewerSelect(0)}
        >
          <BottomNavigationAction
            label={viewers[0].title}
            icon={viewers[0].icon}
            className={isTableDisabled ? classes.navigationActionDisabled : ""}
            disabled={isTableDisabled}
            role="menuitem"
          />
        </BottomNavigation>
      )}

      {isChartEnabled&& !templateLayouts?.disableChart && (
        <BottomNavigation
          showLabels
          value={viewerIdx >= 2 ? 0 : null}
          onChange={({currentTarget}) => setAnchorEl(currentTarget)}
        >
          <BottomNavigationAction
            label={viewerIdx >= 2 ? viewers[viewerIdx].title : t("scenes.dataViewer.singleViewer.sidebar.chart")}
            icon={viewerIdx >= 2 ? viewers[viewerIdx].icon : <BarChartIcon/>}
            className={isChartDisabled ? classes.navigationActionDisabled : ""}
            disabled={isChartDisabled}
            role="menuitem"
          />
        </BottomNavigation>
      )}
      
      {isMapEnabled && !templateLayouts?.disableMap && !isMapDisabled && (
        <BottomNavigation
          showLabels
          value={viewerIdx === 1 ? 0 : null}
          onChange={() => handleViewerSelect(1)}
        >
          <BottomNavigationAction
            label={viewers[1].title}
            icon={viewers[1].icon}
            className={isMapDisabled ? classes.navigationActionDisabled : ""}
            role="menuitem"
          />
        </BottomNavigation>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {viewers
          .slice(2)
          .filter(({hidden}) => hidden !== true)
          .map(option => (
            <MenuItem
              className={`${classes.floatingMenuOption} ${viewerIdx === option.key ? classes.floatingMenuOptionSelected : ''}`}
              key={option.key}
              selected={viewerIdx === option.key}
              onClick={() => handleViewerSelect(option.key)}
            >
              {option.icon}
              <Typography style={{marginLeft: 8}}>{option.title}</Typography>
            </MenuItem>
          ))}
      </Menu>

      <FullscreenDialog
        open={isCriteriaVisible}
        onClose={handleCriteriaClose}
      >
        <CustomDialogTitle onClose={handleCriteriaClose}>
          {t("scenes.dataViewer.singleViewer.dialogs.criteria.title")}
        </CustomDialogTitle>
        <DialogContent className={classes.criteriaContent}>
          <Criteria
            viewerMode={ViewerMode.SingleViewer}
            nodeId={nodeId}
            nodeCode={nodeCode}
            datasetId={datasetId}
            dimensions={dimensions}
            timeDim={timeDim}
            freqDim={freqDim}
            codelists={codelists}
            codelistsLength={codelistsLength}
            criteria={criteria}
            onSetCriteria={onSetCriteria}
            isCriteriaValid={isCriteriaValid}
            setCriteriaValidity={setCriteriaValidity}
            onSubmit={handleCriteriaSubmit}
            defaultLastNPeriods={defaultLastNPeriods}
            showCodelistInfo
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCriteriaClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={() => handleCriteriaSubmit(maxObservation)}
            disabled={!isCriteriaValid}
          >
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </FullscreenDialog>

      <Dialog
        open={isLayoutVisible && viewerIdx === 0}
        fullWidth
        maxWidth="md"
        onClose={handleLayoutClose}
      >
        <CustomDialogTitle onClose={handleLayoutClose}>
          {t("scenes.dataViewer.dialogs.tableLayout.title")}
        </CustomDialogTitle>
        <DialogContent>
          <TableLayout
            jsonStat={dataset}
            layout={tmpTableLayout}
            labelFormat={labelFormat}
            setLayout={setTmpTableLayout}
            lockedDimensions={tableLockedDimensions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLayoutClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button autoFocus onClick={handleLayoutSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isLayoutVisible && viewerIdx >= 2}
        fullWidth
        maxWidth="md"
        onClose={handleLayoutClose}
      >
        <CustomDialogTitle onClose={handleLayoutClose}>
          {t("scenes.dataViewer.dialogs.chartLayout.title")}
        </CustomDialogTitle>
        <DialogContent>
          <ChartLayout
            jsonStat={dataset}
            layout={tmpChartLayout}
            setLayout={setTmpChartLayout}
            lockedDimensions={graphLockedDimensions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLayoutClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button autoFocus onClick={handleLayoutSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SingleViewerSidebar)