import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {withTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import "./style.css";
import {getFormattedValue} from "../../utils/formatters";
import Call from "../../hocs/call/index";
import {
  getAttributeLabel,
  getAttributeValueLabel,
  getFilterTreeFromJsonStat,
  VARIATION_DIMENSION_KEY
} from "../../utils/jsonStat";
import {v4 as uuidv4} from "uuid";
import {connect} from "react-redux";
import CustomEmpty from "../custom-empty";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import {
  clearMapGeometry,
  fetchMapGeometry,
  hideMapNoGeometryWarning,
  setMapGeometry,
  setMapGeometryChangedStatus
} from "../../state/maps/mapsActions";
import {
  MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS,
  MAP_LAYERS_ALL,
  MAP_LAYERS_NONE
} from "./constants";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@material-ui/icons/Error";
import {localizeI18nObj} from "../../utils/i18n";
import {
  getDetailLevelsFromGeometries,
  getLayoutGeometries,
  getTerritoriesWithValue,
  getTimeDimValueFromLayout
} from "./utils";
import {isValidIntegerInInclusiveRange} from "../../utils/validator";
import CustomSelect from "../custom-select";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  map: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 0,
    filter: "blur(0)"
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 2,
    visibility: "hidden"
  },
  attributesGrid: {
    display: "table",
    width: "calc(100% + 24px)",
    margin: "-8px -12px"
  },
  attributesTitle: {
    fontSize: 16,
    fontWeight: "bold"
  },
  detailLevelSelectorWrapper: {
    position: "absolute",
    zIndex: 1,
    top: 8,
    left: 8,
    padding: 8,
    background: "rgba(255, 255, 255, 0.7)"
  },
  detailLevelSelector: {}
});

const getOverlayStyle = (theme, solidOverlay) => ({
  position: "absolute",
  zIndex: 3,
  color: "white",
  background: solidOverlay
    ? "#F5F5F5"
    : "rgba(0, 0, 0, 0.1)"
});

const getOverlayTextStyle = (theme, solidOverlay) => ({
  fontWeight: solidOverlay
    ? "normal"
    : "bold",
  color: solidOverlay
    ? theme.palette.primary.main
    : "white"
});

const getOverlayImageStyle = (theme, solidOverlay) => ({
  color: solidOverlay
    ? theme.palette.primary.main
    : "white"
});

const mapStateToProps = state => {
  const hubExtras = JSON.parse(state.hub?.hub?.extras || "{}");
  return {
    isMultiViewerTheme: state.app.themeConfig.isMultiViewer,
    defaultLanguage: state.app.language,
    languages: state.app.languages,
    defaultExtent: state.appConfig.mapConfig.defaultExtent,
    geometryBorderColor: state.appConfig.mapConfig.geometryBorderColor,
    levelsOrder: state.appConfig.mapConfig.levelsOrder,
    baseMap: hubExtras?.BaseMap || null,
    mapCopyright: hubExtras?.MapCopyright || null,
    mapLayer: hubExtras?.MapLayer || null,
    mapLayersConfig: state.maps.mapLayersConfig,
    maps: state.maps.maps
  };
};

const mapDispatchToProps = dispatch => ({
  onGeometryFetch: (mapStateUuid, nodeId, territoryDimValues, format, detailLevel, timeDimValue, territoryDimCodelist) =>
    dispatch(fetchMapGeometry(mapStateUuid, nodeId, territoryDimValues, format, detailLevel, timeDimValue, territoryDimCodelist)),
  onGeometrySet: (mapStateUuid, geometries) => dispatch(setMapGeometry(mapStateUuid, geometries)),
  onGeometryChangedStatusSet: (mapStateUuid, changed) => dispatch(setMapGeometryChangedStatus(mapStateUuid, changed)),
  onGeometryClear: mapStateUuid => dispatch(clearMapGeometry(mapStateUuid)),
  onWarningHide: mapStateUuid => dispatch(hideMapNoGeometryWarning(mapStateUuid))
});

const isSettingsChanged = (newSettings, prevSettings) => {
  let isChanged = false;

  if (!prevSettings) {
    return true;
  }

  for (let key in newSettings) {
    if (newSettings.hasOwnProperty(key)) {
      if (!_.isEqual((newSettings?.[key] || null), (prevSettings?.[key] || null))) {
        isChanged = true;
      }
    }
  }

  return isChanged;
};

const callSetSettings = (settings, setSettings) => {

  const newSettings = {
    ..._.cloneDeep(settings),
    customIntervals: settings?.classificationMethod !== undefined
      ? settings.classificationMethod === MAP_CLASSIFICATION_METHOD_CUSTOM_INTERVALS
        ? settings.customIntervals
        : null
      : undefined
  };

  Object.keys(newSettings).forEach(key => {
    if (newSettings[key] === undefined || newSettings[key] === null) {
      delete newSettings[key];
    }
  });

  setSettings(newSettings);
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Map(props) {
  const {
    classes,
    theme,

    isMultiViewerTheme,
    defaultLanguage,
    languages,
    defaultExtent,
    geometryBorderColor,
    levelsOrder,
    baseMap,
    mapCopyright,
    mapLayer,
    mapLayersConfig,
    maps,

    onGeometryFetch,
    onGeometrySet,
    onGeometryChangedStatusSet,
    onGeometryClear,
    onWarningHide,

    isMultiViewer = false,
    mapId: externalMapId,
    nodeId,
    jsonStat,
    layout,
    labelFormat,
    decimalSeparator,
    decimalPlaces,
    isFullscreen,
    detailLevel,
    defaultDetailLevel = null,
    onDetailLevelChange,
    disableDetailLevelSelector,
    territoryDimCodelist,
    initialBaseLayer,
    defaultSettings,
    settings,
    setSettings,
    onDataClick,
    disableZoomToClickedData = false,
    onDataSelect,
    selectedTerritoryId,
    filteredTerritoryIds,
    onSettingsShow,
    showSelection = false,
    disableBaseLayer = false,
    disableSettings = false,
    showSingleGeometry = false,
    disableWheelZoom = false,
    isPointData = false,
    latAttributeId,
    longAttributeId,
    srid,
    onRenderStart,
    onRenderFinish,
    geometriesYear = null
  } = props;

  const {t} = useTranslation();
  const [mapId, setMapId] = useState(null);

  const [legendTitle] = useState(null);

  const prevJsonStat = usePrevious(jsonStat);
  const prevLayout = usePrevious(layout);

  const [isFetchEnabled, setIsFetchEnabled] = useState(true);
  const [fetchedGeometries, setFetchedGeometries] = useState(null);

  const [internalDetailLevels, setInternalDetailLevels] = useState(null);
  const [internalDetailLevel, setInternalDetailLevel] = useState(!isMultiViewer ? defaultDetailLevel : null);

  const [geometries, setGeometries] = useState(null);
  const [geometryMap, setGeometryMap] = useState(null);
  const [territoryMap, setTerritoryMap] = useState(null);

  const [filteredGeometries, setFilteredGeometries] = useState(null);

  const [mapSettings, setMapSettings] = useState(null);
  const [mapDefaultSettings, setMapDefaultSettings] = useState(null);

  const [isLayerRendered, setIsLayerRendered] = useState(false);

  const [geometryId, setGeometryId] = useState(null);

  const [dimensionValueTree, setDimensionValueTree] = useState(null);

  const showSpinner = useCallback(() => {
    $(`#${mapId}__wrapper > .map__overlay > div`).addClass("map__overlay--visible");
    if (onRenderStart) {
      onRenderStart();
    }
  }, [mapId, onRenderStart]);

  const hideSpinner = useCallback(() => {
    $(`#${mapId}__wrapper > .map__overlay > div`).removeClass("map__overlay--visible");
    if (onRenderFinish) {
      onRenderFinish();
    }
  }, [mapId, onRenderFinish]);

  const fetchGeometry = useCallback((cbParam) => {
    const {
      nodeId,
      detailLevel,
      territoryDimCodelist,
      jsonStat,
      layout,
      mapStateUuid
    } = cbParam;

    const territoryDim = layout.territoryDim;
    const territoryDimValues = jsonStat.dimension[territoryDim].category.index;
    const timeDimValue = geometriesYear !== null
      ? geometriesYear
      : getTimeDimValueFromLayout(jsonStat, layout);

    const format = isMultiViewerTheme ? "geojson" : "wkt";

    if (isPointData) {
      const geometries = territoryDimValues.map((value, idx) => ({
        id: value,
        uniqueId: (idx + 1)
      }));
      onGeometrySet(mapStateUuid, geometries);

    } else if (isMultiViewer) {
      onGeometryFetch(mapStateUuid, nodeId, territoryDimValues, format, detailLevel, timeDimValue, territoryDimCodelist);

    } else {
      const filteredTerritoryDimValues = getTerritoriesWithValue(jsonStat, territoryDim);
      onGeometryFetch(mapStateUuid, nodeId, filteredTerritoryDimValues, format);
    }
  }, [isMultiViewerTheme, isPointData, isMultiViewer, onGeometryFetch, onGeometrySet, geometriesYear]);

  // handle mapId and destroy map on component unmount
  useEffect(() => {
    const mapId = (externalMapId || uuidv4());
    setMapId(mapId);

    window[`${mapId}_isFirstRender`] = true;
    window[`${mapId}_areGeometriesUpdated`] = false;
    window[`${mapId}_areGeometriesChanged`] = true;

    return () => {
      if (window.document.getElementById(mapId) && window.LMap.isInitialized(mapId)) {
        window.LMap.destroyMap(mapId);
      }
      onGeometryClear(mapId);
      setIsFetchEnabled(true);

      window[`${mapId}_isFirstRender`] = true;
      window[`${mapId}_areGeometriesUpdated`] = false;
      window[`${mapId}_areGeometriesChanged`] = true;
    };
  }, [externalMapId, onGeometryClear]);

  // init map
  useEffect(() => {
    if (mapId && !window.LMap.isInitialized(mapId)) {

      let baseLayer, layers;

      if (mapLayer === MAP_LAYERS_NONE) {
        baseLayer = null;
        layers = null;
      } else if (mapLayer === MAP_LAYERS_ALL) {
        baseLayer = initialBaseLayer;
        layers = mapLayersConfig;
      } else {
        baseLayer = mapLayer;
        layers = mapLayersConfig.filter(({id}) => id === mapLayer);
      }

      if (layers) {
        layers = layers.map(({name, credits, ...layer}) => ({
          ...layer,
          name: localizeI18nObj(name, defaultLanguage, languages),
          credits: localizeI18nObj(credits, defaultLanguage, languages)
        }));
      }

      const mapOptions = {
        showZoom: true,
        showScale: isMultiViewer,
        baseLayer: baseLayer,
        layers: layers,
        defaultExtent: defaultExtent,
        showBaseLayerRadio: (!disableBaseLayer && mapLayer === MAP_LAYERS_ALL && (layers || []).length > 1),
        showSelection: showSelection,
        disableWheelZoom: disableWheelZoom,
        onSettingsChange: settings => {
          if (setSettings) {
            callSetSettings(settings, setSettings);
          }
        },
        onLegendCollapsedChange: isCollapsed => {
          if (setSettings) {
            callSetSettings(({isLegendCollapsed: isCollapsed}), setSettings);
          }
        },
        onBaseLayerChange: baseLayer => {
          if (setSettings) {
            callSetSettings(({baseLayer: baseLayer}), setSettings);
          }
        },
        onSettingsShow: settings => {
          if (onSettingsShow) {
            onSettingsShow(settings);
          }
        },
        legendFontFamily: "Roboto",
        primaryColor: theme.palette.primary.main,
        isBaseLayerRadioPopupClosable: true
      };

      const mapTranslations = {
        configure: t("components.map.translation.configure"),
        opacity: t("components.map.translation.opacity"),
        from: t("components.map.translation.from"),
        to: t("components.map.translation.to"),
        cancel: t("components.map.translation.cancel"),
        apply: t("components.map.translation.apply"),
        classification: t("components.map.translation.classification"),
        equalInterval: t("components.map.translation.equalInterval"),
        naturalBreaks: t("components.map.translation.naturalBreaks"),
        quantile: t("components.map.translation.quantile"),
        customIntervals: t("components.map.translation.customIntervals"),
        customIntervalsFrom: t("components.map.translation.customIntervalsFrom"),
        customIntervalsTo: t("components.map.translation.customIntervalsTo"),
        numberOfClasses: t("components.map.translation.numberOfClasses"),
        startColor: t("components.map.translation.startColor"),
        endColor: t("components.map.translation.endColor"),
        preview: t("components.map.translation.preview"),
        collapse: t("components.map.translation.collapse"),
        uncollapse: t("components.map.translation.uncollapse"),
        allowedPaletteCardinality: ({min, max}) =>
          t("components.map.translation.allowedPaletteCardinality", {min: min, max: max}),
        invalidCustomIntervals: t("components.map.translation.invalidCustomIntervals"),
        linearSelectionTooltip: step => {
          if (step === 0) {
            return t("components.map.translation.linearSelectionTooltip.step0");
          } else {
            return t("components.map.translation.linearSelectionTooltip.steps");
          }
        },
        polygonalSelectionTooltip: step => {
          if (step === 0) {
            return t("components.map.translation.polygonalSelectionTooltip.step0");
          } else if (step === 1) {
            return t("components.map.translation.polygonalSelectionTooltip.step1");
          } else {
            return t("components.map.translation.polygonalSelectionTooltip.steps");
          }
        },
        rectangularSelectionTooltip: step => {
          if (step === 0) {
            return t("components.map.translation.rectangularSelectionTooltip.step0");
          } else {
            return t("components.map.translation.rectangularSelectionTooltip.steps");
          }
        },
        circularSelectionTooltip: (step, infos) => {
          if (step === 0) {
            return t("components.map.translation.circularSelectionTooltip.step0");
          } else {
            if (infos && infos.diameter) {
              const formatter = diameter => {
                if (diameter < 1000) {
                  return Math.round(diameter) + " m";
                } else if (diameter < 10000) {
                  return (diameter / 1000).toFixed(1) + " km";
                } else {
                  return Math.round(diameter / 1000) + " km";
                }
              };
              return t("components.map.translation.circularSelectionTooltip.stepsWithDiameter", {diameter: formatter(infos.diameter)});
            } else {
              return t("components.map.translation.circularSelectionTooltip.steps");
            }
          }
        },
        zoomIn: t("components.map.translation.zoomIn"),
        zoomOut: t("components.map.translation.zoomOut"),
        zoomHome: t("components.map.translation.zoomHome"),
        linearSelection: t("components.map.translation.linearSelection"),
        polygonalSelection: t("components.map.translation.polygonalSelection"),
        rectangularSelection: t("components.map.translation.rectangularSelection"),
        circularSelection: t("components.map.translation.circularSelection"),
        clearSelection: t("components.map.translation.clearSelection"),
        configureLayers: t("components.map.translation.configureLayers"),
        changeBaseLayer: t("components.map.translation.changeBaseLayer"),
        abortSelection: t("components.map.translation.abortSelection"),
        expandSelectionButtons: t("components.map.translation.expandSelectionButtons"),
        collapseSelectionButtons: t("components.map.translation.collapseSelectionButtons"),
        noBaseLayer: t("components.map.translation.noBaseLayer")
      };

      window.LMap.initMap(mapId, mapOptions, mapTranslations);
    }
  }, [mapId, defaultLanguage, languages, defaultExtent, isMultiViewerTheme, isMultiViewer, baseMap, mapCopyright, mapLayer, mapLayersConfig, initialBaseLayer, disableWheelZoom, disableBaseLayer, showSelection, setSettings, onSettingsShow, t, theme.palette.primary.main]);

  // handle fullscreen
  useEffect(() => {
    if (mapId) {
      window.LMap.handleViewportChange(mapId);
    }
  }, [mapId, isFullscreen]);

  // handle resize
  useEffect(() => {
    if (mapId) {
      const func = () => window.LMap.handleViewportChange(mapId);
      window.addEventListener("resize", func);
      return () => {
        window.removeEventListener("resize", func);
      };
    }
  }, [mapId]);

  // handle jsonStat or time dimension value changes
  useEffect(() => {
    if (mapId) {

      showSpinner();

      const isJsonStatChanged = !_.isEqual(prevJsonStat, jsonStat);

      const isTimeDimValueChanged = (isMultiViewer && geometriesYear === null)
        ? getTimeDimValueFromLayout(jsonStat, prevLayout) !== getTimeDimValueFromLayout(jsonStat, layout)
        : false;

      if (isJsonStatChanged || isTimeDimValueChanged) {
        setGeometries(null);
        setGeometryMap(null);
        setTerritoryMap(null);

        setFilteredGeometries(null);

        setIsFetchEnabled(true);

        window[`${mapId}_areGeometriesUpdated`] = false;
      }

      const dims = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);
      const layoutDims = [layout.territoryDim, ...layout.filters];

      if (dims.length !== layoutDims.length && (isJsonStatChanged || !dimensionValueTree)) {
        const orderedDims = [...layoutDims, ...dims.filter(dim => !layoutDims.includes(dim))];
        setDimensionValueTree(getFilterTreeFromJsonStat(orderedDims, jsonStat));
      }
    }
  }, [mapId, prevJsonStat, jsonStat, prevLayout, layout, dimensionValueTree, isPointData, isMultiViewer, geometriesYear, showSpinner]);

  // handle new fetched geometries
  useEffect(() => {
    if (mapId && maps?.[mapId]?.changed === true) {
      const geometries = maps[mapId].geometries;

      setFetchedGeometries(geometries);
      onGeometryChangedStatusSet(mapId, false);

      setIsFetchEnabled(false);

      window[`${mapId}_areGeometriesUpdated`] = true;
    }
  }, [mapId, maps, onGeometryChangedStatusSet]);

  // filtering geometries by layout
  useEffect(() => {
    if (window[`${mapId}_areGeometriesUpdated`] && fetchedGeometries !== null) {

      showSpinner();

      const {
        layoutGeometries,
        geometryMap,
        territoryMap
      } = getLayoutGeometries(fetchedGeometries, jsonStat, layout, dimensionValueTree, labelFormat, isPointData, latAttributeId, longAttributeId, t);

      setGeometries(layoutGeometries);
      setGeometryMap(geometryMap);
      setTerritoryMap(territoryMap);

      setFilteredGeometries(null);

      if (!isMultiViewer) {
        const newDetailLevels = getDetailLevelsFromGeometries(layoutGeometries, showSingleGeometry, levelsOrder, t);

        setInternalDetailLevels(newDetailLevels);
        setInternalDetailLevel(prevDetailLevel => (prevDetailLevel !== null && newDetailLevels.map(({value}) => value).includes(prevDetailLevel))
          ? prevDetailLevel
          : newDetailLevels[0]?.value >= 0
            ? newDetailLevels[0].value
            : null
        );
      }

      if (onDataSelect && !window[`${mapId}_isFirstRender`]) {
        onDataSelect(null);
      }
    }
  }, [mapId, isMultiViewer, fetchedGeometries, jsonStat, layout, dimensionValueTree, labelFormat, isPointData, latAttributeId, longAttributeId, t, showSingleGeometry, onDataSelect, showSpinner, levelsOrder]);

  // filtering geometries (already filtered by layout) by detail level
  useEffect(() => {
    if (mapId && (geometries || []).length > 0) {

      showSpinner();

      const newFilteredGeometries = geometries.filter(({level}) => isMultiViewer || level === internalDetailLevel)
      setFilteredGeometries(newFilteredGeometries);

      window[`${mapId}_areGeometriesChanged`] = true;
    }
  }, [mapId, isMultiViewer, geometries, internalDetailLevel, showSpinner]);

  // handle external geometries filter
  useEffect(() => {
    if (mapId && territoryMap && isLayerRendered && filteredTerritoryIds !== undefined) {
      let geometryIds;
      if (filteredTerritoryIds === null) {
        geometryIds = null;
      } else {
        geometryIds = (filteredTerritoryIds || []).map(territoryId => territoryMap[territoryId]).filter(el => el);
      }
      window.LMap.setDataFilter(mapId, geometryIds);
    }
  }, [mapId, territoryMap, isLayerRendered, filteredTerritoryIds]);

  // handle map default settings
  useEffect(() => {
    setMapDefaultSettings(prevDefaultSettings => prevDefaultSettings === null
      ? (defaultSettings || {})
      : prevDefaultSettings
    );
  }, [defaultSettings]);

  // handle map settings
  useEffect(() => {
    setMapSettings(prevSettings => isSettingsChanged(settings, prevSettings)
      ? settings
      : prevSettings
    );
  }, [settings]);

  // add or update layer with filtered geometries
  useEffect(() => {
    if (filteredGeometries !== null && mapSettings !== null && mapDefaultSettings !== null) {

      let newSettings = {};
      if (window[`${mapId}_isFirstRender`]) {
        newSettings = {...newSettings, ...mapDefaultSettings};
      }
      newSettings = {...newSettings, ...mapSettings};

      setTimeout(
        () => {
          if (!window[`${mapId}_areGeometriesChanged`]) {
            window.LMap.updateLayerSettings(mapId, newSettings);

          } else {
            setIsLayerRendered(false);
            const layerOptions = {
              srid: `EPSG:${srid || 4326}`,
              useGeoJSON: isMultiViewerTheme,
              settings: newSettings,
              onDataRender: () => {
                setTimeout(() => {
                  window[`${mapId}_isFirstRender`] = false;
                  hideSpinner();
                  setIsLayerRendered(true);
                }, 500);
              },
              borderColor: geometryBorderColor,
              valueFormatter: val => getFormattedValue(val, decimalSeparator, decimalPlaces, ""),
              disableSettings: disableSettings,
              dataInfoDuration: 1000,
              onDataClick: (id, isActive, isSelected) => {
                if (id) {
                  if (onDataClick) {
                    onDataClick(geometryMap[id], isActive, isSelected);
                  }
                } else {
                  if (onDataClick) {
                    onDataClick(null, isActive, isSelected);
                  }
                }
              },
              zoomToClickedData: !disableZoomToClickedData,
              onDataSelect: selected => {
                if (onDataSelect) {
                  const territoryDimValues = [];
                  (selected || []).forEach(geomId => territoryDimValues.push(geometryMap[geomId].territoryId));
                  if (territoryDimValues.length > 0) {
                    onDataSelect(territoryDimValues);
                  } else {
                    onDataSelect(null);
                  }
                }
              },
              onDataTooltipClick: id => {
                if (geometryMap[id].hasAttributes) {
                  setGeometryId(id);
                }
              },
              onLocationChanged: (extent, zoomLevel) => {
                // console.log("onLocationChanged") ;
                // console.log('extent : ', extent);
                // console.log('zoomLevel : ', zoomLevel);
              }
            };
            window.LMap.updateLayer(mapId, filteredGeometries, legendTitle, layerOptions);

            if (setSettings) {
              const settings = window.LMap.getSettings(mapId);
              if (isSettingsChanged(newSettings, settings)) {
                callSetSettings(settings, setSettings);
              }
            }

            window[`${mapId}_areGeometriesChanged`] = false;
          }
        },
        0
      );
    }
  }, [mapId, isMultiViewerTheme, srid, filteredGeometries, geometryMap, mapSettings, mapDefaultSettings, geometryBorderColor, decimalPlaces, decimalSeparator, disableZoomToClickedData, onDataClick, onDataSelect, disableSettings, legendTitle, setSettings, hideSpinner]);

  // handle zoom to data
  useEffect(() => {
    if (isLayerRendered && filteredGeometries !== null) {
      const geometryToZoom = (filteredGeometries.find(({id}) => id === selectedTerritoryId) || {}).identifier || null;
      window.LMap.zoomToData(mapId, geometryToZoom);
    }
  }, [mapId, isLayerRendered, filteredGeometries, selectedTerritoryId]);

  if (!mapId) {
    return <span/>;
  }

  const territoryDim = layout?.territoryDim;

  const territoryDimMissing = !territoryDim || !jsonStat.dimension[territoryDim];
  const fetchingGeometriesError = maps[mapId]?.error;
  const geometriesMissing = filteredGeometries !== null && filteredGeometries.length === 0;
  const detailLevelMissing = isPointData
    ? false
    : isMultiViewer
      ? !isValidIntegerInInclusiveRange(detailLevel)
      : (internalDetailLevels !== null && internalDetailLevels.length === 0);

  const isMapUnavailable = territoryDimMissing || fetchingGeometriesError || geometriesMissing || detailLevelMissing;

  const isDetailLevelSelectorVisible = !isMultiViewer &&
    (internalDetailLevels || []).length > 0 &&
    internalDetailLevel !== null &&
    internalDetailLevel >= 0;

  let unavailableMapErrorText;
  if (isMapUnavailable) {
    showSpinner();
    if (territoryDimMissing) {
      console.debug("missing territory dimension");
      unavailableMapErrorText = t("components.map.noTerritoryDimension");
    } else if (fetchingGeometriesError) {
      unavailableMapErrorText = t("components.map.fetchingGeometriesError");
    } else if (geometriesMissing) {
      unavailableMapErrorText = t("components.map.noDataToDisplay");
    } else if (detailLevelMissing) {
      console.debug("missing detail level");
      unavailableMapErrorText = t("components.map.noDetailLevel");
    } else {
      unavailableMapErrorText = null;
    }
  }

  return (
    <Fragment>

      <Call
        cb={fetchGeometry}
        cbParam={{
          nodeId: nodeId,
          detailLevel: detailLevel,
          territoryDimCodelist: territoryDimCodelist,
          jsonStat: jsonStat,
          layout: layout,
          mapStateUuid: mapId
        }}
        disabled={territoryDimMissing || !isFetchEnabled}
      >
        <div id={`${mapId}__wrapper`} className={`${classes.root} map`} aria-hidden={true}>
          <div className={`${classes.overlay} map__overlay ${(isFetchEnabled || window[`${mapId}_isFirstRender`]) ? "map__overlay--solid" : ""}`}>
            <div className="map__overlay--visible">
              <CustomEmpty
                text={isMapUnavailable
                  ? unavailableMapErrorText
                  : isFetchEnabled
                    ? t("components.map.spinners.loading") + "..."
                    : t("components.map.spinners.rendering") + "..."
                }
                style={getOverlayStyle(theme, (isFetchEnabled || window[`${mapId}_isFirstRender`]))}
                textStyle={getOverlayTextStyle(theme, (isFetchEnabled || window[`${mapId}_isFirstRender`]))}
                imageStyle={getOverlayImageStyle(theme, (isFetchEnabled || window[`${mapId}_isFirstRender`]))}
                image={isMapUnavailable
                  ? <ErrorIcon/>
                  : isFetchEnabled
                    ? <CircularProgress/>
                    : null
                }
              />
            </div>
          </div>
          <div
            className={`${classes.detailLevelSelectorWrapper} map__detail-level-selector ${!isDetailLevelSelectorVisible ? "map__detail-level-selector--hidden" : ""}`}
          >
            {(internalDetailLevels || []).length > 0 && (
              <FormControl className={classes.detailLevelSelector}>
                <CustomSelect
                  label={t("components.map.detailLevelSelector.label")}
                  value={internalDetailLevel}
                  onChange={ev => {
                    const dl = ev.target.value;
                    setInternalDetailLevel(dl);
                    if (onDetailLevelChange) {
                      onDetailLevelChange(dl);
                    }
                  }}
                  disabled={disableDetailLevelSelector || (internalDetailLevels || []).length === 1}
                >
                  {(internalDetailLevels || []).map((val, idx) =>
                    <MenuItem key={idx} value={val.value}>{val.label}</MenuItem>
                  )}
                </CustomSelect>
              </FormControl>
            )}
          </div>
          <div
            id={mapId}
            className={`map__lmap-container ${classes.map} ${isDetailLevelSelectorVisible ? "map--has-detail-level-selector" : ""}`}
          />
        </div>
      </Call>

      <Dialog
        open={geometryId !== null}
        onClose={() => setGeometryId(null)}
      >
        <CustomDialogTitle onClose={() => setGeometryId(null)}>
          {t("components.map.dialogs.attributes.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={3} className={classes.attributesGrid}>
            {geometryMap?.[geometryId]?.obsAttributes && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className={classes.attributesTitle}>
                    {t("components.map.dialogs.attributes.content.obsAttributes.title")}
                  </Grid>
                  {geometryMap[geometryId].obsAttributes.map((attribute, idx) =>
                    <Grid item key={idx} xs={12}>
                      <b>{getAttributeLabel(attribute, labelFormat)}</b>: {getAttributeValueLabel(attribute, labelFormat)}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {geometryMap?.[geometryId]?.dimAttributes && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className={classes.attributesTitle}>
                    {t("components.map.dialogs.attributes.content.dimAttributes.title")}
                  </Grid>
                  {geometryMap[geometryId].dimAttributes.map((attribute, idx) =>
                    <Grid item key={idx} xs={12}>
                      <b>{getAttributeLabel(attribute, labelFormat)}</b>: {getAttributeValueLabel(attribute, labelFormat)}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGeometryId(null)}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={maps[mapId]?.isNoGeometryWarningVisible === true}
        onClose={() => onWarningHide(mapId)}
      >
        <DialogContent onClose={() => onWarningHide(mapId)}>
          {t("components.map.dialogs.noGeometry.title")}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onWarningHide(mapId)}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  );
}

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(Map);
