import _ from "lodash";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../components/label-format-selector/constants";

export const FREQ_DIMENSION_KEY = "FREQ";

export const VARIATION_DIMENSION_KEY = "AUTO.VARIATION";
export const VARIATION_VALUE_VALUE_KEY = "V";
export const VARIATION_VALUE_TREND_KEY = "T";
export const VARIATION_VALUE_CYCLICAL_KEY = "C";

export const getVariationValueLabels = t => ({
  [VARIATION_VALUE_VALUE_KEY]: t ? t("customDimension.variation.options.value") : VARIATION_VALUE_VALUE_KEY,
  [VARIATION_VALUE_TREND_KEY]: t ? t("customDimension.variation.options.trend") : VARIATION_VALUE_TREND_KEY,
  [VARIATION_VALUE_CYCLICAL_KEY]: t ? t("customDimension.variation.options.cyclical") : VARIATION_VALUE_CYCLICAL_KEY
});

export const MARGINAL_DIMENSION_KEY = "MARGINAL";

export const MARGINAL_ATTRIBUTE_KEY = "MARGINAL";

export const NOTES_LABEL = "NOTES_LABEL";
export const NO_ISTAT_DATA_LABEL = "NO_ISTAT_DATA_LABEL";

const MAX_COL_COUNT = 10000;
const MAX_ROW_COUNT = 10000;

const toUpperCaseFirst = string => (string !== null && string !== undefined && string.length > 0)
  ? string[0].toUpperCase() + string.slice(1)
  : string;

export const getDimensionLabel = (jsonStat, datasetId, dimensionId, t) => {
  let dimensionLabel;

  if (dimensionId === VARIATION_DIMENSION_KEY) {
    return t ? t("customDimension.variation.label") : dimensionId;
  }

  if (dimensionId === MARGINAL_DIMENSION_KEY) {
    return t ? t("customDimension.indicator.label") : dimensionId;
  }

  if (jsonStat.dimension[dimensionId]) {
    dimensionLabel = jsonStat.dimension[dimensionId].label;

  } else if (datasetId) {
    dimensionLabel = jsonStat.extension.marginaldimensions[datasetId]?.[dimensionId]?.dimensionlabel || null;
  }

  return dimensionLabel
    ? toUpperCaseFirst(dimensionLabel)
    : dimensionId;
}

export const getFormattedDimensionLabel = (jsonStat, datasetId, dimensionId, format, t) => {

  return getDimensionLabel(jsonStat, datasetId, dimensionId, t);
};

export const getDimensionValueLabel = (jsonStat, datasetId, dimensionId, valueId, t) => {
  let valueLabel;

  if (dimensionId === VARIATION_DIMENSION_KEY) {
    return getVariationValueLabels(t)[valueId];
  }

  if (jsonStat.dimension[dimensionId]) {
    if (dimensionId !== MARGINAL_DIMENSION_KEY) {
      valueLabel = jsonStat.dimension[dimensionId].category.label[valueId];
    } else {
      valueLabel = jsonStat.extension.marginalvalues[valueId].label || valueId;
    }

  } else if (datasetId) {
    valueLabel = jsonStat.extension.marginaldimensions[datasetId]?.[dimensionId]?.values?.[valueId] || null;
  }

  return valueLabel
    ? toUpperCaseFirst(valueLabel)
    : valueId;
}

export const getFormattedDimensionValueLabel = (jsonStat, datasetId, dimensionId, valueId, format, t) => {

  const valueLabel = getDimensionValueLabel(jsonStat, datasetId, dimensionId, valueId, t);

  if (dimensionId === jsonStat.role?.time?.[0]) {
    return valueLabel;
  }

  switch (format) {
    case LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME:
      return valueLabel || valueId;
    case LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID:
      return valueId;
    case LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH:
      return `[${valueId}] ${valueLabel}`;
    default:
      return valueLabel || valueId;
  }
};

export const getDataIdxFromCoordinatesArray = (dimensions, size) => {
  let dataIdx = 0;
  let multiplier = 1;

  // row-major order algorithm
  for (let i = 0; i < dimensions.length; i++) {
    multiplier *= (i > 0) ? size[dimensions.length - i] : 1;
    dataIdx += multiplier * dimensions[dimensions.length - i - 1];
  }

  return dataIdx;
};

export const getCoordinatesArrayFromDataIdx = (dataIdx, size) => {
  const coordinates = new Array(size.length);

  // reverse row-major order algorithm
  let offset = dataIdx;
  for (let i = size.length - 1; i >= 0; i--) {
    const dimensionSize = size[i];
    coordinates[i] = dimensionSize > 0 ? (offset % dimensionSize) : 0;
    offset = dimensionSize > 0 ? Math.floor(offset / dimensionSize) : 0;
  }

  return coordinates;
};

export const getJsonStatTableSize = (jsonStat, layout) => {
  if (jsonStat) {
    let count = 1;

    if (!layout) {
      jsonStat.size.forEach(el => count *= el);

    } else {
      let colCount = 1;
      layout.cols.forEach(col => colCount *= jsonStat.size[jsonStat.id.indexOf(col)]);
      let rowCount = 1;
      layout.rows.forEach(row => rowCount *= jsonStat.size[jsonStat.id.indexOf(row)]);
      layout.sections.forEach(section => rowCount *= jsonStat.size[jsonStat.id.indexOf(section)]);
      count = colCount * rowCount;
    }

    return count;

  } else {
    return 0
  }
};

export const getFilterTreeFromJsonStat = (filters, jsonStat) => {
  const tree = {};

  Object.keys(jsonStat.value).forEach(key => {
    const coordinates = getCoordinatesArrayFromDataIdx(key, jsonStat.size);

    const treeRoute = filters.map(dim => {
      const dimIdx = jsonStat.id.indexOf(dim);
      const dimValueIdx = coordinates[dimIdx];
      return jsonStat.dimension[dim].category.index[dimValueIdx];
    });

    let currentLevel = tree;
    treeRoute.forEach(elem => {
      if (!currentLevel[elem]) {
        currentLevel[elem] = {};
      }
      currentLevel = currentLevel[elem];
    });
  });

  return tree
};

export const isFilterValid = (filter, filterTree) => {
  let isValid = true;

  let iter = filterTree;
  filter.forEach(val => {
    if (isValid && iter[val]) {
      iter = iter[val];
    } else {
      isValid = false;
    }
  });

  return isValid;
};

export const getInitialFiltersValue = (jsonStat, layout, filterTree) => {

  const timeDim = jsonStat.role?.time?.[0];

  const primaryDim = layout.primaryDim?.[0] || null;
  const secondaryDim = layout.secondaryDim?.[0] || null;
  const filters = layout.filters || [];

  let selectedPrimaryDim = null;
  let selectedSecondaryDim = null;

  if (filters.length === 0) {
    return {}
  }

  let root = filterTree;
  if (primaryDim) {
    selectedPrimaryDim = Object.keys(root).find(primDimKey =>
      layout.primaryDimValues.includes(primDimKey) &&
      (!secondaryDim || Object.keys(root[primDimKey]).find(secDimKey => layout.secondaryDimValues.includes(secDimKey)))
    );
    root = root[selectedPrimaryDim];
    if (secondaryDim) {
      selectedSecondaryDim = Object.keys(root).find(secDimKey => layout.secondaryDimValues.includes(secDimKey));
      root = root[selectedSecondaryDim];
    }
  }

  const filtersValue = {};

  let iter = root;
  layout.filters.forEach(dim => {
    const selectableValues = Object.keys(iter);
    const allValues = [...jsonStat.dimension[dim].category.index];
    if (dim === timeDim) {
      allValues.reverse();
    }
    const selectedVal = allValues.find(dimVal => selectableValues.includes(dimVal));
    filtersValue[dim] = selectedVal;
    iter = iter[selectedVal];
  });

  return filtersValue;
};

export const getInitialTableLayout = (jsonStat, territoryDim, timeDim) => {
  if (!jsonStat) {
    return null;
  }

  let rows = [];
  let cols = [];
  let filters = [];

  let dimensions = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);

  filters = dimensions.filter(dim => jsonStat.size[jsonStat.id.indexOf(dim)] === 1);
  dimensions = dimensions.filter(dim => !filters.includes(dim));

  if (dimensions.includes(FREQ_DIMENSION_KEY)) {
    rows.push(FREQ_DIMENSION_KEY);
  }
  if (dimensions.includes(timeDim)) {
    rows.push(timeDim);
  }
  dimensions = dimensions.filter(dim => !rows.includes(dim));

  cols = (territoryDim && dimensions.includes(territoryDim)) ? [territoryDim] : [];
  dimensions = dimensions.filter(dim => !cols.includes(dim));

  if (rows.length === 1 && jsonStat.size[jsonStat.id.indexOf(rows[0])] === 1 && cols.length === 1) {
    filters = filters.concat(rows);
    rows = [...cols];
    cols = [];
  }

  let colCount = 1;
  cols.forEach(col => colCount *= jsonStat.size[jsonStat.id.indexOf(col)]);
  let rowCount = 1;
  rows.forEach(row => rowCount *= jsonStat.size[jsonStat.id.indexOf(row)]);

  dimensions.forEach(dim => {
    const newColCount = colCount * jsonStat.size[jsonStat.id.indexOf(dim)];

    if (newColCount > MAX_COL_COUNT) {
      const newRowCount = rowCount * jsonStat.size[jsonStat.id.indexOf(dim)];

      if (newRowCount > MAX_ROW_COUNT) {
        filters.push(dim);

      } else {
        rows = [dim].concat(rows);
        rowCount = newRowCount;
      }

    } else {
      cols.push(dim);
      colCount = newColCount;
    }
  });

  return {
    rows: rows,
    cols: cols,
    sections: [],
    filters: filters
  }
};

export const getMultiViewerInitialTableLayout = (jsonStat, territoryDim, timeDim) => {
  if (!jsonStat) {
    return null;
  }

  let rows = [];
  let cols = [];

  let dimensions = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);

  rows.push(territoryDim);
  dimensions = dimensions.filter(dim => !rows.includes(dim));

  cols.push(timeDim);
  dimensions = dimensions.filter(dim => !cols.includes(dim));

  cols = cols.concat(dimensions);

  return {
    rows: rows,
    cols: cols,
    sections: [],
    filters: []
  }
};

export const getMultiViewerTableColCount = (jsonStat, layout) => {
  let colCount = 1;

  layout.cols.forEach(dim => {
    colCount *= jsonStat.size[jsonStat.id.indexOf(dim)];
  });

  return colCount;
};

export const getPointDataInitialTableLayout = (jsonStat, pointDim, territoryDim, timeDim, measureDim) => {
  if (!jsonStat) {
    return null;
  }

  let rows = [];
  let cols = [];

  let dimensions = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);

  rows.push(pointDim);
  if (territoryDim) {
    rows.push(territoryDim);
  }
  dimensions = dimensions.filter(dim => !rows.includes(dim));

  cols.push(timeDim);
  if (measureDim) {
    cols.push(measureDim);
  }
  dimensions = dimensions.filter(dim => !cols.includes(dim));

  rows = rows.concat(dimensions);

  return {
    rows: rows,
    cols: cols,
    sections: [],
    filters: []
  }
};

export const getInitialChartLayout = (jsonStat, territoryDim) => {
  if (!jsonStat) {
    return null
  }
  let primaryDim = [];
  let primaryDimValues = [];
  let secondaryDim = [];
  let secondaryDimValues = [];

  let dimensions = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);

  const timeDim = jsonStat.role?.time?.[0];

  if (dimensions.includes(timeDim)) {
    primaryDim.push(timeDim);
  } else {
    primaryDim.push(dimensions[0]);
  }
  primaryDimValues = jsonStat.dimension[primaryDim].category.index;

  const filters = dimensions.filter(dim => !primaryDim.includes(dim) && !secondaryDim.includes(dim));

  return {
    primaryDim: primaryDim,
    primaryDimValues: primaryDimValues,
    secondaryDim: secondaryDim,
    secondaryDimValues: secondaryDimValues,
    filters: filters
  }
};

export const getMultiViewerInitialChartLayout = (jsonStat, territoryDim, timeDim, maxTerritoryValueCount) => {
  if (!jsonStat) {
    return null
  }

  const primaryDim = [];
  const secondaryDim = [];
  const filters = [];

  let dimensions = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);

  primaryDim.push(territoryDim);

  secondaryDim.push(timeDim);

  dimensions.forEach(dim => {
    if (!primaryDim.includes(dim) && !secondaryDim.includes(dim)) {
      filters.push(dim);
    }
  });

  return {
    primaryDim: primaryDim,
    // removed usage of maxTerritoryValueCount for incompatibility with getFilterTreeFromJsonStat
    primaryDimValues: jsonStat.dimension[primaryDim].category.index, //.slice(0, maxTerritoryValueCount)
    secondaryDim: secondaryDim,
    secondaryDimValues: jsonStat.dimension[secondaryDim].category.index,
    filters: filters
  }
};

export const getPointDataInitialChartLayout = (jsonStat, geoDim, timeDim, measureDim, maxTerritoryValueCount) => {
  if (!jsonStat) {
    return null
  }
  const primaryDim = [];
  const secondaryDim = [];
  const filters = [];

  primaryDim.push(geoDim);

  secondaryDim.push(timeDim);

  if (measureDim) {
    filters.push(measureDim);
  }

  return {
    primaryDim: primaryDim,
    // removed usage of maxTerritoryValueCount for incompatibility with getFilterTreeFromJsonStat
    primaryDimValues: jsonStat.dimension[primaryDim].category.index, //.slice(0, maxTerritoryValueCount)
    secondaryDim: secondaryDim,
    secondaryDimValues: jsonStat.dimension[secondaryDim].category.index,
    filters: filters
  }
};

export const getInitialMapLayout = (jsonStat, territoryDim) => {
  if (!jsonStat) {
    return null
  }

  let dimensions = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);

  if (!territoryDim || !jsonStat.id.includes(territoryDim)) {
    return null
  }

  return {
    territoryDim: territoryDim,
    filters: dimensions.filter(dim => dim !== territoryDim)
  }
};

export const getPointDataInitialMapLayout = (jsonStat, geoDim, timeDim, measureDim) => {
  if (!jsonStat) {
    return null
  }

  if (!geoDim || !jsonStat.id.includes(geoDim)) {
    return null
  }

  let filters = [];

  filters.push(timeDim);
  if (measureDim) {
    filters.push(measureDim);
  }

  return {
    territoryDim: geoDim,
    filters: filters
  }
};

const getFilteredLayout = (initialLayout, jsonStat, isMultiViewerTable = false) => {
  if (!initialLayout) {
    return null
  }

  const layout = _.cloneDeep(initialLayout);
  const dimArraysKey = ["rows", "cols", "filters", "sections", "primaryDim", "secondaryDim", "territoryDim"];

  if (layout.territoryDim) {
    layout.territoryDim = [layout.territoryDim];
  }

  let dimensions = jsonStat.id.filter(dim => dim !== VARIATION_DIMENSION_KEY);

  // removing dimensions from layout not present in jsonStat
  for (let key in layout) {
    if (layout.hasOwnProperty(key)) {
      if (dimArraysKey.includes(key)) {
        layout[key] = (layout[key] || []).filter(dim => dimensions.includes(dim));
      }
    }
  }

  // adding missing dimensions to layout
  dimensions.forEach(dim => {
    let found = false;
    for (let key in layout) {
      if (layout.hasOwnProperty(key)) {
        if (!found && dimArraysKey.includes(key)) {
          found = layout[key].includes(dim);
        }
      }
    }
    if (!found) {
      if (isMultiViewerTable) {
        layout.cols.push(dim);

      } else {
        layout.filters.push(dim);
        if (layout.filtersValue) {
          layout.filtersValue[dim] = jsonStat.dimension[dim].category.index[0];
        }
      }
    }
  });

  // checking selected filtersValue
  if (layout.filtersValue) {
    for (let dim in layout.filtersValue) {
      if (layout.filtersValue.hasOwnProperty(dim)) {
        if (!layout.filters.includes(dim)) {
          layout.filtersValue[dim] = undefined;
        } else if (!jsonStat.dimension[dim].category.index.includes(layout.filtersValue[dim])) {
          layout.filtersValue[dim] = jsonStat.dimension[dim].category.index[0];
        }
      }
    }
  }

  // checking selected primaryDimValues
  if (layout.primaryDim) {
    if (layout.primaryDim.length === 1) {
      const primaryDim = layout?.primaryDim?.[0] || null;
      if (layout.primaryDimValues.length === 0) {
        layout.primaryDimValues = jsonStat.dimension[primaryDim].category.index;
      }
      layout.primaryDimValues = layout.primaryDimValues.filter(value =>
        jsonStat.dimension[primaryDim].category.index.includes(value));
    } else {
      return getInitialChartLayout(jsonStat)
    }
  }

  // checking selected secondaryDimValues
  const secondaryDim = layout?.secondaryDim?.[0] || null;
  if (secondaryDim) {
    if (layout.secondaryDimValues.length === 0) {
      layout.secondaryDimValues = jsonStat.dimension[secondaryDim].category.index;
    }
    layout.secondaryDimValues = layout.secondaryDimValues.filter(value =>
      jsonStat.dimension[secondaryDim].category.index.includes(value));
  }

  if (layout.territoryDim) {
    layout.territoryDim = layout.territoryDim[0];
  }

  return layout
};

export const getFilteredTableLayout = (initialLayout, jsonStat, isMultiViewerTable) => {
  if (!initialLayout) {
    return null
  }
  const layout = {
    rows: [],
    cols: [],
    sections: [],
    filters: [],
    ...initialLayout,
  };

  return getFilteredLayout(layout, jsonStat, isMultiViewerTable);
};

export const getFilteredMapLayout = (initialLayout, jsonStat) => {
  if (!initialLayout) {
    return null
  }
  const layout = {
    territoryDim: "",
    filters: [],
    ...initialLayout,
  };

  return getFilteredLayout(layout, jsonStat);
};

export const getFilteredChartLayout = (initialLayout, jsonStat) => {
  if (!initialLayout) {
    return null
  }
  const layout = {
    primaryDim: [],
    primaryDimValues: [],
    secondaryDim: [],
    secondaryDimValues: [],
    filters: [],
    ...initialLayout,
  };

  return getFilteredLayout(layout, jsonStat);
};

export const getUpdatedLayout = (dimension, value, jsonStat, layout, filterTree) => {
  let newLayout = _.cloneDeep(layout);
  const primaryDim = newLayout.primaryDim?.[0] || null;
  const secondaryDim = newLayout.secondaryDim?.[0] || null;

  const timeDim = jsonStat.role?.time?.[0];

  let tmpLayout = _.cloneDeep(layout);
  if (primaryDim && dimension === primaryDim) {
    tmpLayout.primaryDimValues = value;
  } else if (secondaryDim && dimension === secondaryDim) {
    tmpLayout.secondaryDimValues = value;
  } else {
    tmpLayout.filtersValue[dimension] = value;
  }
  const filters = (tmpLayout?.primaryDim || []).concat((tmpLayout?.secondaryDim || [])).concat(tmpLayout?.filters || []);
  let isLayoutValid = false;
  let iter = filterTree;
  if (primaryDim && !isLayoutValid) {
    (tmpLayout.primaryDimValues || []).forEach(primDimVal => {
      if (secondaryDim && !isLayoutValid) {
        (tmpLayout.secondaryDimValues || []).forEach(secDimVal => {
          if (filterTree[primDimVal][secDimVal] !== null && filterTree[primDimVal][secDimVal] !== undefined) {
            iter = filterTree[primDimVal][secDimVal];
            let isFilterValid = true;
            filters.slice(2).forEach(val => {
              if (isFilterValid && iter[tmpLayout.filtersValue[val]] !== null && iter[tmpLayout.filtersValue[val]] !== undefined) {
                iter = iter[tmpLayout.filtersValue[val]];
              } else {
                isFilterValid = false;
              }
            });
            if (isFilterValid) {
              isLayoutValid = true;
            }
          }
        });
      } else {
        iter = filterTree[primDimVal];
        let isFilterValid = true;
        filters.slice(1).forEach(val => {
          if (isFilterValid && iter[tmpLayout.filtersValue[val]] !== null && iter[tmpLayout.filtersValue[val]] !== undefined) {
            iter = iter[tmpLayout.filtersValue[val]];
          } else {
            isFilterValid = false;
          }
        });
        if (isFilterValid) {
          isLayoutValid = true;
        }
      }
    });
  } else {
    let isFilterValid = true;
    filters.forEach(val => {
      if (isFilterValid && iter[tmpLayout.filtersValue[val]]) {
        iter = iter[tmpLayout.filtersValue[val]];
      } else {
        isFilterValid = false;
      }
    });
    if (isFilterValid) {
      isLayoutValid = true;
    }
  }

  if (isLayoutValid) {
    return tmpLayout

  } else {

    if (primaryDim && dimension === primaryDim) {
      newLayout.primaryDimValues = value;
      if (secondaryDim) {
        const allSecondaryDimValues = getDimensionFilterValues(secondaryDim, jsonStat, newLayout, filterTree);
        newLayout.secondaryDimValues = newLayout.secondaryDimValues.filter(val => allSecondaryDimValues.includes(val));
      }
      newLayout.filtersValue = getInitialFiltersValue(jsonStat, newLayout, filterTree);

    } else if (secondaryDim && dimension === secondaryDim) {
      newLayout.secondaryDimValues = value;
      newLayout.filtersValue = getInitialFiltersValue(jsonStat, newLayout, filterTree);

    } else {
      newLayout.filtersValue[dimension] = value;

      const filters = newLayout.filters || [];
      const selectedIdx = filters.indexOf(dimension);
      if (selectedIdx < (filters.length - 1)) {
        newLayout.filters.slice(selectedIdx + 1).forEach(dim => {
          const selectableValues = getDimensionFilterValues(dim, jsonStat, newLayout, filterTree);
          const allValues = [...jsonStat.dimension[dim].category.index];
          if (dim === timeDim) {
            allValues.reverse();
          }
          newLayout.filtersValue[dim] = allValues.find(dimVal => selectableValues.includes(dimVal));
        });
      }
    }

    return newLayout
  }
};

export const getDimensionFilterValues = (dimension, jsonStat, layout, filterTree, withLabels = false) => {
  const primaryDim = layout.primaryDim?.[0] || null;
  const secondaryDim = layout.secondaryDim?.[0] || null;
  const filters = (layout?.primaryDim || []).concat((layout?.secondaryDim || [])).concat(layout?.filters || []);
  const dimensionIdx = filters.indexOf(dimension);
  const prevFilters = filters.slice(0, dimensionIdx);

  let data = {};
  if (dimensionIdx > 0) {
    let iter = filterTree;
    if (primaryDim) {
      (layout.primaryDimValues || []).forEach(primDimVal => {
        if (secondaryDim && dimension !== secondaryDim) {
          (layout.secondaryDimValues || []).forEach(secDimVal => {
            iter = filterTree[primDimVal][secDimVal];
            prevFilters.slice(2).forEach(val => {
              iter = (iter || {})[layout.filtersValue[val]];
            });
            data = {...data, ...iter};
          });
        } else {
          iter = filterTree[primDimVal];
          prevFilters.slice(1).forEach(val => {
            iter = (iter || {})[layout.filtersValue[val]];
          });
          data = {...data, ...iter};
        }
      });
    } else {
      prevFilters.forEach(val => {
        iter = iter[layout.filtersValue[val]];
      });
      data = {...data, ...iter};
    }
  } else {
    data = filterTree;
  }

  const availableValue = Object.keys(data);
  const ret = [];
  jsonStat.dimension[dimension].category.index.forEach(val => {
    if (availableValue.includes(val)) {
      ret.push(withLabels
        ? {
          id: val,
          label: jsonStat.dimension[dimension].category.label[val]
        }
        : val
      );
    }
  });

  return ret;
};

export const getDimensionValuesIndexesMap = jsonStat => {
  const indexesMap = {};

  jsonStat.id.forEach(dim => {
    indexesMap[dim] = {};
    jsonStat.dimension[dim].category.index.forEach((dimValue, idx) => indexesMap[dim][dimValue] = idx);
  });

  return indexesMap;
};

export const getCompleteValueDims = (incompleteValueDims, dims, orderedDims, orderedDimValueTree) => {
  const ret = [...incompleteValueDims];

  try {
    let iter = orderedDimValueTree;
    orderedDims.forEach(dim => {
      const dimIdx = dims.indexOf(dim);
      if (!ret[dimIdx]) {
        ret[dimIdx] = Object.keys(iter)[0];
      }
      iter = iter[ret[dimIdx]];
    });
    return ret;

  } catch (e) {
    return null;
  }
};

const getAttributeObject = (attributes, attributeIdx, attributeValueIdx, t) => {
  const attribute = attributes[attributeIdx];
  const attributeValue = attribute.values[attributeValueIdx];

  let id = attribute.id;
  let label = (attribute.name !== NOTES_LABEL || !t) ? attribute.name : t("customAttributes.notes.label");
  let valueId = attributeValue.id;
  let valueLabel = (attributeValue.name !== NO_ISTAT_DATA_LABEL || !t) ? attributeValue.name : t("customAttributes.notes.values.noIstatData");

  return {
    id: id,
    label: label,
    valueId: valueId,
    valueLabel: valueLabel
  };
};

const getAttributeObjects = (attributeCoordinates, attributeObjList, t) => {
  const attributes = [];

  try {
    (attributeCoordinates || []).forEach((coordinate, idx) => {
      if (coordinate !== null) {
        const attribute = getAttributeObject(attributeObjList, idx, coordinate, t);
        attributes.push(attribute);
      }
    });

    return (attributes.length > 0)
      ? attributes
      : null

  } catch (error) {
    return -1
  }
};

export const getDimensionAttributeMap = (jsonStat, t) => {
  if (!jsonStat) {
    return null;
  }

  const dimAttributeMap = {};

  const datasetIds = (jsonStat?.extension?.datasets || []).concat(MARGINAL_ATTRIBUTE_KEY);

  datasetIds.forEach(datasetId => {

    const datasetDimAttributeMap = {};

    const series = (jsonStat?.extension?.attributes?.[datasetId]?.series || []);
    const seriesIndexes = (jsonStat?.extension?.attributes?.[datasetId]?.index?.series || []);

    jsonStat.id.forEach(dim => datasetDimAttributeMap[dim] = {});
    getMarginalDimensions(jsonStat, datasetId).forEach(dim => datasetDimAttributeMap[dim] = datasetDimAttributeMap[dim] || {});

    if (series && series.length > 0 && seriesIndexes && seriesIndexes.length > 0) {
      seriesIndexes
        .filter(({coordinates}) => (coordinates || []).filter(c => c !== null).length === 1)
        .forEach(index => {
          const dimIdx = index.coordinates.findIndex(el => el !== null);
          const dimValueIdx = index.coordinates.find(el => el !== null);

          let dim, dimValue;
          if (datasetId === MARGINAL_DIMENSION_KEY || !jsonStat.id.includes(MARGINAL_DIMENSION_KEY)) {
            dim = jsonStat.id[dimIdx];
            dimValue = jsonStat.dimension[dim].category.index[dimValueIdx];
          } else {
            dim = jsonStat.extension.originalid[datasetId][dimIdx];
            dimValue = jsonStat.dimension[dim]
              ? jsonStat.dimension[dim].category.index[dimValueIdx]
              : jsonStat.extension.marginaldimensions[datasetId][dim].index[dimValueIdx];
          }

          const attributeObjects = getAttributeObjects(index.attributes, series, t);

          if (attributeObjects !== null && attributeObjects !== -1) {
            if (!datasetDimAttributeMap[dim][dimValue]) {
              datasetDimAttributeMap[dim][dimValue] = attributeObjects;
            }
          }
        });
    }

    dimAttributeMap[datasetId] = datasetDimAttributeMap;
  });

  return dimAttributeMap;
};

export const getObservationAttributeMap = (jsonStat) => {
  const obsAttributeMap = {};
  let hasError = false;

  (jsonStat?.extension?.datasets || []).forEach(datasetId => {

    const observations = (jsonStat?.extension?.attributes?.[datasetId]?.observation || []);
    const observationIndexes = (jsonStat?.extension?.attributes?.[datasetId]?.index?.observation || {});
    for (let key in observationIndexes) {
      if (observationIndexes.hasOwnProperty(key)) {
        const attributeObjects = getAttributeObjects(observationIndexes[key], observations);
        if (attributeObjects === -1) {
          hasError = true;
        } else {
          obsAttributeMap[key] = attributeObjects;
        }
      }
    }
  });

  return !hasError
    ? obsAttributeMap
    : null;
};

export const getDatasetAttributeMap = (jsonStat) => {
  const datasetAttributeMap = {};

  (jsonStat?.extension?.datasets || []).forEach(datasetId => {
    const datasetAttributes = [];

    const dataset = (jsonStat?.extension?.attributes?.[datasetId]?.dataSet || []);
    const datasetIndexes = (jsonStat?.extension?.attributes?.[datasetId]?.index?.dataSet || []);

    if (dataset && dataset.length > 0 && datasetIndexes && datasetIndexes.length > 0) {
      (datasetIndexes || []).forEach((el, idx) => {
        if (el !== null) {
          datasetAttributes.push(getAttributeObject(dataset, idx, el));
        }
      });
    }

    datasetAttributeMap[datasetId] = datasetAttributes;
  });

  return datasetAttributeMap;
};

export const getSeriesAttributeMap = (jsonStat, labelFormat) => {
  const seriesAttributeMap = {};

  const datasetIds = (jsonStat?.extension?.datasets || []).concat(MARGINAL_ATTRIBUTE_KEY);

  datasetIds.forEach(datasetId => {
    const seriesAttributes = [];

    const series = (jsonStat?.extension?.attributes?.[datasetId]?.series || []);
    const seriesIndexes = (jsonStat?.extension?.attributes?.[datasetId]?.index?.series || []);

    if (series && series.length > 0 && seriesIndexes && seriesIndexes.length > 0) {
      seriesIndexes
        .filter(({coordinates}) => (coordinates || []).filter(c => c !== null).length > 1)
        .forEach(({coordinates, attributes}) => {
          const dimsId = [];
          const dimsValueId = [];
          coordinates.forEach((dimValueIdx, dimIdx) => {
            if (dimValueIdx !== null) {
              let dim, dimValue;
              if (datasetId === MARGINAL_DIMENSION_KEY || !jsonStat.id.includes(MARGINAL_DIMENSION_KEY)) {
                dim = jsonStat.id[dimIdx];
                dimValue = jsonStat.dimension[dim].category.index[dimValueIdx];
              } else {
                dim = jsonStat.extension.originalid[datasetId][dimIdx];
                dimValue = jsonStat.dimension[dim]
                  ? jsonStat.dimension[dim].category.index[dimValueIdx]
                  : jsonStat.extension.marginaldimensions[datasetId][dim].index[dimValueIdx];
              }
              dimsId.push(dim);
              dimsValueId.push(dimValue);
            }
          });
          const dims = dimsId.map((dim, idx) => ({
            id: getFormattedDimensionLabel(jsonStat, null, dim, labelFormat),
            value: getFormattedDimensionValueLabel(jsonStat, null, dim, dimsValueId[idx], labelFormat)
          }));
          const attrs = [];
          attributes.forEach((attr, idx) => {
            if (attr !== null && attr !== undefined) {
              attrs.push(getAttributeObject(series, idx, attr));
            }
          });
          if (dims.length > 0 && attrs.length > 0) {
            seriesAttributes.push({
              dimensions: dims,
              attributes: attrs
            });
          }
        });
    }

    seriesAttributeMap[datasetId] = seriesAttributes;
  });

  return seriesAttributeMap;
};

export const getAttributeLabel = (attribute, format) => {
  const id = attribute.id;
  const name = attribute.label;

  return name || id;
};

export const getAttributeValueLabel = (attribute, format) => {
  const id = attribute.valueId;
  const name = attribute.valueLabel;

  switch (format) {
    case LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME:
      return name || id;
    case LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID:
      return id;
    case LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH:
      if (id && name && id !== name) {
        return `[${id}] ${name}`;
      } else {
        return name || id;
      }
    default:
      return name || id;
  }
};

export const getMarginalDimensions = (jsonStat, datasetId) => (datasetId && jsonStat?.extension?.marginaldimensions?.[datasetId])
  ? Object.keys(jsonStat.extension.marginaldimensions[datasetId])
    .sort((a, b) =>
      jsonStat.extension.marginaldimensions[datasetId][a].position - jsonStat.extension.marginaldimensions[datasetId][b].position)
  : [];

export const getFiltersValueInfo = (filters, filtersValue, jsonStat, labelFormat, t) => {
  let datasetId;
  if (jsonStat.id.includes(MARGINAL_DIMENSION_KEY)) {
    const marginal = jsonStat.extension.marginalvalues[filtersValue[MARGINAL_DIMENSION_KEY]];
    datasetId = marginal.label
      ? MARGINAL_ATTRIBUTE_KEY
      : marginal.datasetid;
  } else {
    datasetId = jsonStat.extension.datasets[0];
  }

  let values = [];
  filters.forEach(dim => {
    const filterValue = filtersValue[dim];
    if (dim !== MARGINAL_DIMENSION_KEY) {
      const datasetId = jsonStat?.extension?.datasets?.[0];
      values.push({
        dim: dim,
        dimLabel: getFormattedDimensionLabel(jsonStat, datasetId, dim, labelFormat, t),
        value: filterValue,
        valueLabel: getFormattedDimensionValueLabel(jsonStat, datasetId, dim, filterValue, labelFormat, t)
      });
    } else {
      const marginal = jsonStat.extension.marginalvalues[filterValue];
      if (marginal.label) {
        values.push({
          dim: dim,
          dimLabel: getFormattedDimensionLabel(jsonStat, null, dim, labelFormat, t),
          value: filterValue,
          valueLabel: marginal.label
        });
      } else {
        getMarginalDimensions(jsonStat, marginal.datasetid).forEach(dim => {
          values.push({
            dim: dim,
            dimLabel: getFormattedDimensionLabel(jsonStat, marginal.datasetid, dim, labelFormat, t),
            value: marginal.dimensionvalues[dim],
            valueLabel: getFormattedDimensionValueLabel(jsonStat, marginal.datasetid, dim, marginal.dimensionvalues[dim], labelFormat, t)
          });
        });
      }
    }
  });

  return {
    values,
    datasetId
  };
};