import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {compose} from "redux";
import {Visibility} from "@material-ui/icons";
import {clearOtherConfigViews, deleteOtherConfigView, fetchOtherConfigViews} from "../../../state/otherConfig/otherConfigActions";
import {goToDataset} from "../../../links";
import {useTranslation} from "react-i18next";
import CustomMaterialTable from "../../custom-material-table";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../../utils/i18n";

const styles = theme => ({
  tableToolbar: {
    marginBottom: theme.spacing(1)
  }
});

const mapStateToProps = state => ({
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  views: state.otherConfig.views
});

const mapDispatchToProps = dispatch => ({
  fetchViews: () => dispatch(fetchOtherConfigViews()),
  clearViews: () => dispatch(clearOtherConfigViews()),
  deleteView: (nodeId, id) => dispatch(deleteOtherConfigView(nodeId, id))
});

const UserViewsSettingsForm = ({
                                 classes,
                                 defaultLanguage,
                                 languages,
                                 views,
                                 fetchViews,
                                 clearViews,
                                 deleteView,
                                 nodes,
                                 onViewsClose
                               }, ref) => {

  const [needViews, setNeedViews] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {

    if (needViews) {
      setNeedViews(false);
      fetchViews();
    }
  }, [views, needViews, setNeedViews, fetchViews]);

  useImperativeHandle(ref, () => ({
    cancel(f) {
      clearViews();
      f();
    }
  }));

  const [deleteViewId, setDeleteViewId] = useState(null);
  const [deleteViewNodeId, setDeleteViewNodeId] = useState(null);

  return nodes && views && (
    <Fragment>
      <CustomMaterialTable
        columns={[
          {
            field: "nodeId",
            title: t("scenes.viewsSettings.table.columns.viewNodeId"),
            render: ({nodeId}) => nodes.find(node => node.nodeId === nodeId).code,
            customFilterAndSearch: (str, {nodeId}) =>
              nodes.find(node => node.nodeId === nodeId).code.toLowerCase()
                .includes(str.toLowerCase())
          },
          {field: "datasetId", title: t("scenes.viewsSettings.table.columns.viewDatasetId")},
          {
            field: "title",
            title: t("scenes.viewsSettings.table.columns.viewTitle"),
            render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
            customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
          },
        ]}
        data={(views || []).filter(({nodeId}) => nodes.find(node => node.nodeId === nodeId))}
        actions={[
          {
            icon: Visibility,
            tooltip: t("scenes.viewsSettings.table.actions.viewView"),
            onClick: (_, {nodeId, datasetId, viewTemplateId}) => {
              if (!window.location.href.toLowerCase().includes(`view=${viewTemplateId}`)) {
                goToDataset(nodes.find(node => node.nodeId === nodeId).code, [], datasetId, viewTemplateId);
              }
              onViewsClose();
            }
          },
          {
            icon: DeleteIcon,
            tooltip: t("scenes.viewsSettings.table.actions.deleteView"),
            onClick: (_, {nodeId, viewTemplateId}) => {
              setDeleteViewId(viewTemplateId);
              setDeleteViewNodeId(nodeId);
            }
          }
        ]}
        options={{
          actionsColumnIndex: 3
        }}
      />

      <Dialog
        maxWidth="xs"
        open={deleteViewId !== null}
        onClose={() => {
          setDeleteViewId(null);
          setDeleteViewNodeId(null);
        }}
      >
        <CustomDialogTitle
          onClose={() => {
            setDeleteViewId(null);
            setDeleteViewNodeId(null);
          }}
        >
          {t("scenes.viewsSettings.modals.deleteView.title")}
        </CustomDialogTitle>
        <DialogContent>
          {t("scenes.viewsSettings.modals.deleteView.content")}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setDeleteViewId(null);
              setDeleteViewNodeId(null);
            }}
          >
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            onClick={() => {
              deleteView(deleteViewNodeId, deleteViewId);
              setDeleteViewId(null);
              setDeleteViewNodeId(null);
            }}
          >
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true}), forwardRef)(UserViewsSettingsForm);