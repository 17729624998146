import React, {Fragment, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import {withRouter} from "react-router";
import {getDatasetsInternalUrl, getHomeInternalUrl, getNodeInternalUrl, goToDatasets} from "../../links";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CategoriesLists from "../categories-lists";
import CardsGrid from "../cards-grid";
import Page from "../page";
import {getPageTitle} from "../../utils/other";
import NodeHeader from "../node-header";
import Footer from "../footer";
import Container from "@material-ui/core/Container";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {HashLink} from 'react-router-hash-link';
import CustomLink from "../custom-link";
import DetailLevelSelect from "../detail-level-select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";

const styles = theme => ({
  fullWidthContainer: {
    width: "100%",
    minHeight: "100%",
    backgroundColor: "#f5f5f5",
    display: "flex",
    flexDirection: "column"
  },
  breadcrumbs: {
    minHeight: 48,
    padding: "8px 0",
    marginTop: theme.spacing(3),
    display: "flex"
  },
  breadcrumbsSeparator: {
    margin: "0 4px"
  },
  breadcrumbsLastElem: {
    color: theme.palette.primary.main,
    padding: 4
  },
  title: {
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0,
    paddingBottom: 8
  },
  detailLevels: {
    padding: "8px 4px"
  },
  container: {
    paddingTop: 8
  },
  categorySchemesTabs: {
    marginBottom: theme.spacing(2)
  }
});


const mapStateToProps = state => ({
  baseURL: state.config.baseURL,
  filteredCatalog: state.detailLevel.filteredCatalog
});

const Categories = ({
                      t,
                      classes,
                      location,
                      baseURL,
                      filteredCatalog,
                      hub,
                      nodeCode,
                      node,
                      catalog,
                      isDefault
                    }) => {

  const [isListMode] = useState(hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase()).showCategoryLevels === 5);

  const [categorySchemeTab, setCategorySchemeTab] = useState(0);

  const nodeMinimalInfo = hub.nodes.find(({code}) => code.toLowerCase() === nodeCode.toLowerCase());
  const nodeExtras = nodeMinimalInfo?.extras || [];
  const hideLabelOnCategoryWithImage = JSON.parse(nodeExtras.find(({key}) => key === "HideLabelOnCategoryWithImage")?.value || "[]");

  const uncategorizedCategory = {
    id: "uncategorized",
    label: t("commons.catalog.uncategorized"),
    childrenCategories: [],
    datasetIdentifiers: filteredCatalog?.uncategorizedDatasets.map(({identifier}) => identifier)
  };

  const categories = filteredCatalog
    ? filteredCatalog.categoryGroups.length > 1
      ? categorySchemeTab === "uncategorized"
        ? [uncategorizedCategory]
        : (
          filteredCatalog.categoryGroups[categorySchemeTab].categories.map(c => ({
            ...c,
            image: c.image ? baseURL + c.image : undefined
          }))
        )
      : filteredCatalog.uncategorizedDatasets.length > 0
        ? (
          filteredCatalog.categoryGroups[0]?.categories.map(c => ({
            ...c,
            image: c.image ? baseURL + c.image : undefined
          })).concat([uncategorizedCategory]) || [uncategorizedCategory]
        )
        : (
          filteredCatalog.categoryGroups[0]?.categories.map(c => ({
            ...c,
            image: c.image ? baseURL + c.image : undefined
          })) || []
        )
    : [];

  const categoriesWithImages = (categories || []).find(({image}) => !!image) !== undefined;

  return (categories || []).length > 0 && (
    <Fragment>
      <HashLink
        to={{hash: "#main", search: location.search}}
        className="skip-link sr-only"
      >
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink
        to={{hash: "#footer", search: location.search}}
        className="skip-link sr-only"
      >
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page
        title={getPageTitle([t("scenes.categories.title"), nodeMinimalInfo?.name, hub?.hub?.name], t)}
      >
        <NodeHeader
          hub={hub.hub}
          nodes={hub.nodes}
          catalog={catalog}
          title={nodeMinimalInfo.name}
          node={node}
          nodeId={nodeMinimalInfo.nodeId}
          isDefault={isDefault}
        />
        <main id="main" className={`${classes.fullWidthContainer} categories`}>
          <Container>
            <Breadcrumbs
              aria-label={t("components.results.breadcrumbs.ariaLabel")}
              classes={{
                separator: classes.breadcrumbsSeparator
              }}
              className={classes.breadcrumbs}
            >
              <CustomLink
                to={getHomeInternalUrl()}
                text={t("components.results.breadcrumbs.links.home")}
                textStyle={{padding: 4}}
              />
              {(hub.nodes || []).length > 1 && (
                <CustomLink
                  to={getNodeInternalUrl(node.code)}
                  text={node?.name || node.code}
                  textStyle={{padding: 4}}
                />
              )}
              <span className={classes.breadcrumbsLastElem}>
                {t("scenes.categories.title")}
              </span>
            </Breadcrumbs>
            <Typography variant="h1" className={classes.title}>
              {t("scenes.categories.title")}
            </Typography>
            {catalog.detailLevels.length > 0 && (
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2} className={classes.detailLevels}>
                <Grid item>
                  <Typography>
                    {t("components.results.detailLevelSelectTitle")}
                  </Typography>
                </Grid>
                <Grid item>
                  <DetailLevelSelect onChange={() => setCategorySchemeTab(0)}/>
                </Grid>
              </Grid>
            )}
            <nav
              className={classes.container}
              aria-label={t("scenes.categories.ariaLabel")}
            >
              {filteredCatalog.hasCategorySchemes && (
                <Tabs
                  value={categorySchemeTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={(_, tab) => setCategorySchemeTab(tab)}
                  className={classes.categorySchemesTabs}
                >
                  {filteredCatalog.categoryGroups.map(({label}, index) =>
                    <Tab
                      key={index}
                      value={index}
                      label={label}
                      tabIndex={0}
                    />
                  )}
                  {filteredCatalog.uncategorizedDatasets?.length > 0 && (
                    <Tab
                      key="uncategorized"
                      value="uncategorized"
                      label={t("commons.catalog.uncategorized")}
                      tabIndex={0}
                    />
                  )}
                </Tabs>
              )}
              {isListMode
                ? (
                  <CategoriesLists
                    categories={categories}
                    onClick={categoryPath => goToDatasets(
                      node.code,
                      categoryPath[0] === "uncategorized"
                        ? ["uncategorized"]
                        : filteredCatalog.hasCategorySchemes ? [filteredCatalog.categoryGroups[categorySchemeTab].id, ...categoryPath] : categoryPath
                    )}
                  />
                )
                : (
                  <CardsGrid
                    list={categories}
                    getHref={category => getDatasetsInternalUrl(
                      node?.code,
                      category.id === "uncategorized"
                        ? ["uncategorized"]
                        : filteredCatalog.hasCategorySchemes
                          ? [filteredCatalog.categoryGroups[categorySchemeTab].id, category.id]
                          : [category.id]
                    )}
                    imageStyle={{
                      maxHeight: undefined
                    }}
                    hideLabelOnElemWithImage={hideLabelOnCategoryWithImage}
                    icon={<FolderIcon/>}
                    xs={categoriesWithImages ? 4 : 12}
                    sm={categoriesWithImages ? 4 : 12}
                    md={categoriesWithImages ? 3 : 6}
                    lg={categoriesWithImages ? 2 : 4}
                    xl={categoriesWithImages ? 2 : 4}
                  />
                )
              }
            </nav>
          </Container>
          <Footer/>
        </main>
      </Page>
    </Fragment>
  )
};

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps),
  withRouter
)(Categories);