import React, {Fragment, useMemo, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import {getDimensionAttributeMap, getFiltersValueInfo, isFilterValid} from "../../utils/jsonStat";
import DatasetFilterAttributeIcon from "../dataset-filter-attribute-icon";
import {localizeI18nObj} from "../../utils/i18n";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InfiniteScrollTable from "../infinite-scroll-table";
import Button from "@material-ui/core/Button";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME} from "../label-format-selector/constants";
import {getCombinationArrays} from "../../utils/other";
import CustomEmpty from "../custom-empty";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  filtersWrapper: {
    cursor: "pointer",
    padding: 4,
    "&:hover": {
      background: "#f5f5f5"
    },
    display: "flex",
    alignItems: "center"
  },
  filters: {
    fontSize: 14,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  filter: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  filterElement: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  tooltipTitle: {
    fontSize: 15,
    marginBottom: 8
  },
  tooltipElement: {
    margin: "4px 0"
  },
  filtersClickableIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    float: "right",
    color: "rgba(0, 0, 0, 0.54)"
  }
});

const mapStateToProps = ({app, appConfig}) => ({
  hiddenDimensionValueLabels: localizeI18nObj(appConfig.hiddenDimensionValueLabels, app.language, app.languages)
});

const getKeyFromFilter = filter => filter.map(({value}) => value).join("+");

const DatasetMergedFilters = ({
                                t,
                                classes,
                                hiddenDimensionValueLabels,
                                jsonStat,
                                layout,
                                filterTree,
                                timeDim,
                                labelFormat = LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME,
                                isDimensionAllowed,
                                onChange
                              }) => {

  const [visible, setVisible] = useState(false);

  const filterDims = useMemo(() => {
    let orderedFilters = (layout?.filters || []).filter(dim => !isDimensionAllowed || isDimensionAllowed(dim));
    if (orderedFilters.includes(timeDim)) {
      orderedFilters = [timeDim, ...orderedFilters.filter(dim => dim !== timeDim)];
    }
    return orderedFilters;
  }, [layout.filters, timeDim, isDimensionAllowed]);

  const dimAttributeMap = useMemo(() => getDimensionAttributeMap(jsonStat, t), [jsonStat, t]);

  const rows = useMemo(() => {
    const rows = [];

    if (filterDims) {
      const dimensionsValues = filterDims.map(filter => {
        const dimValues = [...jsonStat.dimension[filter].category.index];
        return filter !== timeDim
          ? dimValues
          : dimValues.reverse();
      });
      getCombinationArrays(dimensionsValues).forEach(combination => {
        const orderedCombination = (layout?.filters || []).map(dim => combination[filterDims.indexOf(dim)]);
        if (isFilterValid(orderedCombination, filterTree)) {
          const filter = filterDims.reduce((o, dim, dimIdx) => ({...o, [dim]: combination[dimIdx]}), {});
          const {values, datasetId} = getFiltersValueInfo(filterDims, filter, jsonStat, labelFormat, t);

          rows.push({
            filter: filter,
            values: values,
            datasetId: datasetId
          });
        }
      });
    }

    return rows;
  }, [filterDims, jsonStat, layout.filters, filterTree, timeDim, labelFormat, t]);

  const {
    datasetId: currentDatasetId,
    values: currentValues
  } = getFiltersValueInfo(filterDims, layout.filtersValue, jsonStat, labelFormat, t);

  return (
    <Fragment>
      <Tooltip
        title={
          <div className={classes.tooltip}>
            {(jsonStat?.extension?.datasets || []).length > 1 && currentDatasetId && (
              <div className={classes.tooltipTitle}>
                <b>{jsonStat.extension.datasetlabels[currentDatasetId]}</b>
              </div>
            )}
            {currentValues.map(({dimLabel, valueLabel}, idx) => (
              <div key={idx} className={classes.tooltipElement}>
                <b>{dimLabel}</b>: <i>{valueLabel}</i>
              </div>
            ))}
          </div>
        }
      >
        <div className={classes.filtersWrapper} role="button" onClick={() => setVisible(true)}>
          <div className={classes.filters}>
            {currentValues
              .filter(({valueLabel}) => !(hiddenDimensionValueLabels || []).map(label => label.toLowerCase()).includes(valueLabel.toLowerCase()))
              .map(({dim, dimLabel, value, valueLabel}, idx, arr) =>
                <span key={idx} className={classes.filter}>
                  <span className={classes.filterElement}>
                    <i>{valueLabel}</i>
                  </span>
                  <span className={classes.filterElement}>
                    <DatasetFilterAttributeIcon
                      datasetId={currentDatasetId}
                      jsonStat={jsonStat}
                      dimension={dim}
                      dimensionValues={[value]}
                      datasetDimAttributeMap={dimAttributeMap?.[currentDatasetId]}
                      labelFormat={labelFormat}
                      disabled
                      disabledTooltip
                    />
                  </span>
                  <span className={classes.filterElement}>
                    {idx < arr.length - 1 ? "," : ""}&nbsp;
                  </span>
                </span>
              )
            }
          </div>
          <ArrowDropDownIcon className={classes.filtersClickableIcon} />
        </div>
      </Tooltip>
      <Dialog
        open={visible}
        onClose={() => setVisible(false)}
        fullWidth
        maxWidth="md"
      >
        <CustomDialogTitle onClose={() => setVisible(false)}>
          {t("components.datasetMergedFilters.dialog.title")}
        </CustomDialogTitle>
        <DialogContent>
          {!rows
            ? (
              <CustomEmpty
                text={t("components.datasetMergedFilters.dialog.content.loading") + "..."}
                image={<CircularProgress />}
                style={{height: 400}}
              />
            )
            : (
              <InfiniteScrollTable
                data={rows}
                getRowKey={({filter}) => getKeyFromFilter(filter)}
                showHeader={false}
                columns={[
                  {
                    title: "",
                    dataIndex: "values",
                    render: (values, {datasetId}) => values
                      .map(({dim, value, valueLabel}, idx, arr) => (
                        <span key={idx} className={classes.filter}>
                          <span className={classes.filterElement}>
                            <i>{valueLabel}</i>
                          </span>
                          <span className={classes.filterElement}>
                            <DatasetFilterAttributeIcon
                              datasetId={datasetId}
                              jsonStat={jsonStat}
                              dimension={dim}
                              dimensionValues={[value]}
                              datasetDimAttributeMap={dimAttributeMap?.[datasetId]}
                              labelFormat={labelFormat}
                              disabled
                            />
                          </span>
                          <span className={classes.filterElement}>
                            {idx < arr.length - 1 ? "," : ""}&nbsp;
                          </span>
                        </span>
                      )),
                    renderText: values => values.map(({valueLabel}) => valueLabel).join(", "),
                    renderTooltip: (values, {datasetId}) => (
                      <div className={classes.tooltip}>
                        {(jsonStat?.extension?.datasets || []).length > 1 && datasetId && (
                          <div className={classes.tooltipTitle}>
                            <b>{jsonStat.extension.datasetlabels[datasetId]}</b>
                          </div>
                        )}
                        {values.map(({dimLabel, valueLabel}, idx) => (
                          <div key={idx} className={classes.tooltipElement}>
                            <b>{dimLabel}</b>: <i>{valueLabel}</i>
                          </div>
                        ))}
                      </div>
                    ),
                    minWidth: 100
                  }
                ]}
                onRowClick={({filter}) => {
                  onChange({
                    ...layout,
                    filtersValue: filter
                  });
                  setVisible(false);
                }}
                getRowStyle={({values}) => ({
                  background: getKeyFromFilter(values) === getKeyFromFilter(currentValues)
                    ? "#fff9e5"
                    : undefined
                })}
                height={400}
              />
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVisible(false)} color="primary">
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default compose(
  connect(mapStateToProps),
  withTranslation(),
  withStyles(styles)
)(DatasetMergedFilters);