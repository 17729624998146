import React, {Fragment, useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import Call from "../../hocs/call";
import EnhancedTree from "../enhanced-tree";
import {getFilteredTreeWithPaths, getMappedTree, getMaxTreeDepth, getNode, getNodes} from "../../utils/tree";
import InfiniteScrollTable from "../infinite-scroll-table";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import {fetchDatasetTerritoryLastYear, fetchDatasetTerritoryTerritories} from "../../state/dataset/datasetActions";
import Grid from "@material-ui/core/Grid";
import MapPreview from "./MapPreview";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  }
});

const mapStateToProps = ({appConfig, dataset}) => ({
  maxMapPolygonCount: appConfig.mapConfig.maxPolygonCount,
  territoryDimCodelist: dataset.territoryDimCodelist,
  timeDim: dataset.timeDim,
  criteria: dataset.criteria,
  territoryTree: dataset.territoryTree,
  lastTerritoryYear: dataset.lastTerritoryYear
});

const mapDispatchToProps = dispatch => ({
  fetchLastYear: ({nodeId, datasetId, timeDim, criteria}) => dispatch(fetchDatasetTerritoryLastYear(nodeId, datasetId, timeDim, criteria)),
  fetchTerritories: ({nodeId, datasetId, territoryDim, detailLevel}) => dispatch(fetchDatasetTerritoryTerritories(nodeId, datasetId, territoryDim, detailLevel))
});

function TerritoriesSelector(props) {
  const {
    t,
    classes,
    maxMapPolygonCount,
    territoryDimCodelist,
    timeDim,
    criteria,
    territoryTree,
    lastTerritoryYear,
    fetchLastYear,
    fetchTerritories,
    nodeId,
    datasetId,
    territoryDim,
    detailLevelTree,
    detailLevel,
    territories,
    setTerritories,
    isFetchEnabled
  } = props;

  const [tree, setTree] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState(null);
  const [mapTerritories, setMapTerritories] = useState(null);
  const [changedMapTerritories, setMapChangedTerritories] = useState(null);

  const [detailLevelSuffix] = useState(() => getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.detailLevelSuffix || "");
  const [referenceYear] = useState(() => getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.referenceYear || null);

  useEffect(() => {
    if (territoryTree) {
      const tree = getMappedTree(territoryTree, "children", node => ({...node, label: `[${(node.id || "").replace(detailLevelSuffix, "")}] ${node.name}`}))
      setTree(tree);
    }
    return () => {
      setTree(null);
    }
  }, [territoryTree, detailLevelSuffix]);

  useEffect(() => {
    setTree(null);
  }, [detailLevel]);

  useEffect(() => {
    if (tree && territories && expandedKeys === null && changedMapTerritories === null) {
      const newExpandedKeys = [];

      const recursive = subTree => subTree
        ? (
          subTree.map(node => {
            if (territories.includes(node.id)) {
              if (node.parentId) {
                newExpandedKeys.push(node.parentId);
              }
            } else if ((node.children || []).length > 0) {
              recursive(node.children);
            }
            return null;
          })
        )
        : [];
      recursive(tree);

      setExpandedKeys(newExpandedKeys.concat(tree.map(({id}) => id)));
      setMapChangedTerritories((territories || []).map(id => ({id: id, selected: true})));
    }
  }, [territories, tree, expandedKeys, changedMapTerritories]);

  useEffect(() => {
    if (tree) {
      const territoryValues = getNodes(tree, "children", ({children}) => (children || []).length === 0);
      setMapTerritories(territoryValues.map(({id}) => id));
    }
  }, [tree]);

  const handleCheck = (checkedKeys, checkedNode, checked) => {
    let newChangedTerritories;

    if (!checkedNode) {
      if (checkedKeys.length > 0) {
        newChangedTerritories = mapTerritories.map(id => ({id: id, selected: true}));
      } else {
        newChangedTerritories = mapTerritories.map(id => ({id: id, selected: false}));
      }

    } else {
      if (checked) {
        const keysToAdd = getNodes([checkedNode], "children", () => true).map(({id}) => id);
        newChangedTerritories = keysToAdd.map(id => ({id: id, selected: true}));

      } else {
        const parentTree = getFilteredTreeWithPaths(tree, "children", ({id}) => id === checkedNode.id);
        const keysToRemove = getNodes(parentTree, "children", () => true).map(({id}) => id)
          .concat(getNodes((checkedNode.children || []), "children", () => true).map(({id}) => id));
        newChangedTerritories = keysToRemove.map(id => ({id: id, selected: false}));
      }
    }

    setTerritories(checkedKeys);
    setMapChangedTerritories(newChangedTerritories);
  };

  const isMapVisible = mapTerritories && (mapTerritories.length < maxMapPolygonCount);

  return (
    <div className={classes.root}>
      <Call
        cb={fetchLastYear}
        cbParam={{
          nodeId,
          datasetId,
          timeDim,
          criteria
        }}
        // disabled fetch of time dimension due to performance issues
        // disabled={!isFetchEnabled || lastTerritoryYear !== null}
        disabled={true}
      >
        <Call
          cb={fetchTerritories}
          cbParam={{
            nodeId,
            datasetId,
            territoryDim,
            detailLevel
          }}
          // disabled fetch of time dimension due to performance issues
          // disabled={!isFetchEnabled || lastTerritoryYear === null || territoryDim === null || detailLevel === null}
          disabled={!isFetchEnabled || territoryDim === null || detailLevel === null}
        >
          <Grid container spacing={1} style={{height: "100%"}}>
            <Grid item xs={isMapVisible ? 6 : 12} style={{height: "100%"}}>
              {tree
                ? getMaxTreeDepth(tree, "children") > 1
                  ? expandedKeys
                    ? (
                      <EnhancedTree
                        tree={tree}
                        idKey="id"
                        labelKey="label"
                        childrenKey="children"
                        defaultExpandedKeys={expandedKeys}
                        selectable
                        defaultSelectedKeys={territories}
                        onSelect={handleCheck}
                        hierarchicalSelect
                        hideExpandControls
                        treeActionsDirection="column-reverse"
                      />
                    )
                    : <span />
                  : (
                    <InfiniteScrollTable
                      data={tree}
                      getRowKey={({id}) => id}
                      showHeader={false}
                      columns={[
                        {
                          title: "",
                          dataIndex: 'name',
                          render: (_, {id, name}) => `[${id.replace(detailLevelSuffix, "")}] ${name}`,
                          renderText: (_, {id, name}) => `[${id.replace(detailLevelSuffix, "")}] ${name}`,
                          minWidthToContent: true,
                          noFilter: true
                        }
                      ]}
                      rowSelection={{
                        selectedRowKeys: (territories || []),
                        onChange: setTerritories,
                      }}
                      leftActions={
                        <Fragment>
                          <Tooltip title={t("components.criteria.table.actions.selectAll.tooltip")}>
                            <span>
                              <IconButton
                                onClick={() => setTerritories(tree.map(({id}) => id))}
                                style={{padding: 8}}
                              >
                                <LibraryAddCheckIcon/>
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title={t("components.criteria.table.actions.deselectAll.tooltip")}>
                            <span>
                              <IconButton
                                onClick={() => setTerritories([])}
                                style={{padding: 8}}
                              >
                                <FilterNoneIcon style={{padding: 1}}/>
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Fragment>
                      }
                    />
                  )
                : <span/>
              }
            </Grid>
            {isMapVisible && (
              <Grid item xs={6} style={{height: "100%"}}>
                <MapPreview
                  nodeId={nodeId}
                  detailLevel={detailLevel}
                  territoryDimCodelist={territoryDimCodelist}
                  territories={mapTerritories}
                  timeDimValue={referenceYear || lastTerritoryYear}
                  selectedTerritories={territories}
                  changedTerritories={changedMapTerritories}
                />
              </Grid>
            )}
          </Grid>
        </Call>
      </Call>
    </div>
  );
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation()
)(TerritoriesSelector);