import React, {Fragment, useEffect} from 'react';
import {withTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';
import ShareIcon from '@material-ui/icons/Share';
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ButtonSelect from "../../button-select";
import SaveIcon from '@material-ui/icons/Save';
import {
  canSaveAsView,
  canSaveTemplate,
  canShare,
  canUseDatasetTools,
  canViewTemplateOrAnnotationIcon,
  canViewTimesLog
} from "../../../utils/user";
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import AttributeIcon from "../../custom-icons/AttributeIcon";
import ExportButton from "../../export-button";
import DatasetStaticFilters from "../../dataset-static-filters";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID} from "../../label-format-selector/constants";
import {DOWNLOAD_FORMAT_EXCEL} from "../../../utils/download";
import Typography from "@material-ui/core/Typography";
import DatasetViewerTools from '../DatasetViewerTools';
import {toggleFullScreen} from "../../../utils/other";
import {setFullScreen} from "../../../state/dataset/datasetActions";
import {compose} from "redux";
import {connect} from "react-redux";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    color: theme.palette.primary.main
  },
  titleContainer: {
    paddingTop: 7,
    minHeight: 48
  },
  title: {
    display: "unset",
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0,
    marginRight: 4
  },
  titleAction: {
    marginTop: -10
  },
  layoutIcon: {
    width: 48,
    height: 48,
    padding: 12,
    color: "rgba(0, 0, 0, 0.54)"
  },
  action: {
    height: 40,
    "& button": {
      padding: 8
    }
  }
});

const mapStateToProps = state => ({
  isFullscreen: state.dataset.isFullscreen,
});

const mapDispatchToProps = dispatch => ({
  onSetFullscreen: (isFullscreen) => dispatch(setFullScreen(isFullscreen))
});

function SingleViewerHeader(props) {

  const {
    t,
    classes,

    exportConfig,
    user,
    defaultLanguage,
    languages,
    nodeId,
    nodeCode,
    datasetMap,
    datasetId,
    nodeExtras,
    datasetTitle,
    viewId,
    attachedFiles,
    chartId,
    mapId,
    jsonStat,
    viewerIdx,
    timeDim,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    hasViewLayout,
    hasTemplateLayout,
    hasAnnotationLayout,
    tableLayout,
    mapLayout,
    chartLayout,
    criteria,
    labelFormat,
    temporalDimOrder,
    enableVariation,
    showTrend,
    showCyclical,
    decimalSeparator,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapSettings,
    timings,
    onDownloadSubmit,
    onQueryShow,

    datasetAttributeMap,
    seriesAttributeMap,
    handleNotesShow,
    handleAttributesShow,
    handleTimingsShow,
    handleViewOpen,
    handleTemplateOpen,

    isFullscreen,
    onSetFullscreen
  } = props;

  const downloadFormats = nodeExtras?.DownloadFormats;
  const showQueryInfo = nodeExtras?.QueryInfo;

  useEffect(() => {
    const actionsWidth = $("#data-viewer__header__header__single-viewer__actions").outerWidth(true);
    $("#data-viewer__header__header__single-viewer__title").width(`calc(100% - ${actionsWidth}px - 8px)`);
  });

  const notes = datasetMap[datasetId]?.notes || [];
  const datasetAttributes = datasetAttributeMap?.[`${nodeCode},${datasetId}`] || [];
  const seriesAttributes = seriesAttributeMap?.[`${nodeCode},${datasetId}`] || [];

  const handleFullscreen = () => {
    toggleFullScreen(
      "data-viewer",
      "data-viewer__wrapper",
      ["data-viewer__page__sidebar", "data-viewer__footer"],
      !isFullscreen
    );
    onSetFullscreen(!isFullscreen);
  };

  return (
    <Fragment>
      <Grid container className={classes.root} justifyContent="space-between" alignItems="flex-start">
        <Grid item id="data-viewer__header__header__single-viewer__title" className={classes.titleContainer}>
          <Typography variant={"h1"} className={classes.title}>
            {datasetTitle}
          </Typography>
          {notes.length > 0 && (
            <Tooltip title={t("scenes.dataViewer.header.action.information.tooltip")}>
              <IconButton
                id="dataset-notes-btn"
                aria-label={t("scenes.dataViewer.header.action.information.label")}
                onClick={() => handleNotesShow(notes)}
                className={classes.titleAction}
              >
                <InfoIcon/>
              </IconButton>
            </Tooltip>
          )}
          {datasetAttributes.concat(seriesAttributes).length > 0 && (
            <Tooltip title={t("scenes.dataViewer.header.action.attributes.tooltip")}>
              <IconButton
                id="dataset-attributes-btn"
                aria-label={t("scenes.dataViewer.header.action.attributes.label")}
                onClick={() => handleAttributesShow({
                  datasetAttributes: datasetAttributes,
                  seriesAttributes: seriesAttributes
                })}
                className={classes.titleAction}
              >
                <AttributeIcon/>
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item id="data-viewer__header__header__single-viewer__actions">
          <Grid container alignItems="center">
            {(() => {
              if (canViewTemplateOrAnnotationIcon(user, nodeId)) {

                let title = null;
                if (hasViewLayout) {
                  title = t("scenes.dataViewer.header.action.hasViewLayout.tooltip");
                } else if (hasTemplateLayout) {
                  title = t("scenes.dataViewer.header.action.hasTemplateLayout.tooltip");
                } else if (hasAnnotationLayout) {
                  title = t("scenes.dataViewer.header.action.hasAnnotationLayout.tooltip");
                }

                if (title) {
                  return (
                    <Grid item id="dataset-layout-info-btn" className={classes.layoutIcon} tabIndex={0} aria-label={title}>
                      <Tooltip title={title}>
                        <InfoIcon/>
                      </Tooltip>
                    </Grid>
                  )
                }
              }
            })()}
            {canViewTimesLog(user, nodeId) && (
              <Grid item id="dataset-times-log-btn">
                <Tooltip title={t("scenes.dataViewer.header.action.timesLog.tooltip")}>
                  <div>
                    <IconButton
                      aria-label={t("scenes.dataViewer.header.action.timesLog.label")}
                      onClick={handleTimingsShow}
                      disabled={timings === null}
                    >
                      <AccessTimeIcon/>
                    </IconButton>
                  </div>
                </Tooltip>
              </Grid>
            )}
            {canUseDatasetTools(user) && (
              <Grid item id="dataset-cache-template-btn">
                <DatasetViewerTools/>
              </Grid>
            )}
            {(canSaveAsView(user) || canSaveTemplate(user, nodeId)) && (
              <Grid item id="dataset-save-btn">
                <ButtonSelect
                  icon={<SaveIcon/>}
                  ariaLabel={t("scenes.dataViewer.header.action.save.label")}
                  tooltip={t("scenes.dataViewer.header.action.save.tooltip")}
                  color="default"
                  disabled={!tableLayout && !chartLayout && !mapLayout}
                >
                  {canSaveAsView(user) && (
                    <div onClick={() => handleViewOpen(false)}>
                      {t("scenes.dataViewer.header.action.save.values.createView")}
                    </div>
                  )}
                  {canSaveAsView(user) && hasViewLayout && viewId && (
                    <div onClick={() => handleViewOpen(true)}>
                      {t("scenes.dataViewer.header.action.save.values.updateView")}
                    </div>
                  )}
                  {canSaveTemplate(user, nodeId) && (
                    <div onClick={() => handleTemplateOpen(hasTemplateLayout)}>
                      {hasTemplateLayout
                        ? t("scenes.dataViewer.header.action.save.values.updateTemplate")
                        : t("scenes.dataViewer.header.action.save.values.createTemplate")
                      }
                    </div>
                  )}
                </ButtonSelect>
              </Grid>
            )}
            {canShare(user) && (
              <Grid item id="dataset-share-btn">
                <Tooltip title={t("scenes.dataViewer.header.action.share.tooltip")}>
                  <div>
                    <IconButton
                      aria-label={t("scenes.dataViewer.header.action.share.label")}
                      onClick={window.notImplemented.show}
                    >
                      <ShareIcon/>
                    </IconButton>
                  </div>
                </Tooltip>
              </Grid>
            )}
            {(attachedFiles || []).length > 0 && (
              <Grid item id="dataset-attachments-btn">
                <ButtonSelect
                  icon={<AttachFileIcon/>}
                  ariaLabel={t("scenes.dataViewer.header.action.attachments.label")}
                  tooltip={t("scenes.dataViewer.header.action.attachments.tooltip")}
                  color="default"
                  onChange={({attachedFile}) => window.open(attachedFile.url)}
                >
                  {attachedFiles.map((attachedFile, idx) => {
                    const fileName = attachedFile.url.split("/").pop();
                    return (
                      <div key={idx} data-value={{attachedFile}}>
                        {`${fileName} (${attachedFile.format})`}
                      </div>
                    )
                  })}
                </ButtonSelect>
              </Grid>
            )}
            {showQueryInfo && (
              <Grid item id="dataset-query-btn">
                <Tooltip title={t("scenes.dataViewer.header.action.query.tooltip")}>
                  <div>
                    <IconButton
                      aria-label={t("scenes.dataViewer.header.action.query.label")}
                      onClick={onQueryShow}
                    >
                      <DeveloperModeIcon/>
                    </IconButton>
                  </div>
                </Tooltip>
              </Grid>
            )}
            <Grid item id="dataset-export-btn">
              <ExportButton
                formats={downloadFormats}
                jsonStat={jsonStat}
                viewerIdx={viewerIdx}
                isTableVisible={viewerIdx === 0 || (viewerIdx === null && isTableVisible)}
                isMapVisible={viewerIdx === 1 || isMapVisible}
                isChartVisible={viewerIdx >= 2 || isChartVisible}
                tableLayout={tableLayout}
                mapId={mapId}
                mapContainerId="data-viewer__single-viewer__viewer"
                mapLayout={mapLayout}
                mapSettings={mapSettings}
                chartId={chartId}
                chartContainerId="data-viewer__single-viewer__viewer"
                chartLayout={chartLayout}
                chartSettings={chartSettings}
                labelFormat={labelFormat}
                datasetTitle={datasetTitle}
                submitDownload={(format, extension, additionalParams) => {
                  const exportParams = {
                    labelFormat: labelFormat,
                    customLabelFormat: {
                      [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
                    },
                    decimalNumber: decimalPlaces,
                    decimalSeparator: decimalSeparator,
                    emptyCellPlaceHolder: tableEmptyChar,
                    hasVariation: enableVariation,
                    showTrend: showTrend,
                    showCyclical: showCyclical,
                    temporalDimOrder: temporalDimOrder,
                    exportConfig: exportConfig,
                    ...additionalParams
                  };
                  const layout = (format === DOWNLOAD_FORMAT_EXCEL || viewerIdx === 0)
                    ? tableLayout
                    : viewerIdx === 1
                      ? mapLayout
                      : chartLayout;

                  const newCriteria = {...criteria};
                  if (viewerIdx === 1 && layout?.territoryDim && additionalParams?.exportOnlyCurrentView === true) {
                    const territoryDim = layout.territoryDim;
                    newCriteria[territoryDim] = {
                      id: territoryDim,
                      filterValues: window.LMap.getDataIds(mapId)
                    };
                  }

                  onDownloadSubmit(
                    nodeId,
                    datasetId,
                    datasetTitle,
                    newCriteria,
                    layout,
                    format,
                    extension,
                    false,
                    exportParams,
                    defaultLanguage,
                    languages,
                    t
                  );
                }}
              />
            </Grid>
            <Grid item id="fullscreen-btn">
              <Tooltip
                title={isFullscreen
                  ? t("scenes.dataViewer.actions.fullscreen.exit")
                  : t("scenes.dataViewer.actions.fullscreen.enter")
                }
              >
                <IconButton onClick={handleFullscreen}>
                  {isFullscreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DatasetStaticFilters
        jsonStat={jsonStat}
        layout={viewerIdx === 0
          ? tableLayout
          : viewerIdx === 1
            ? mapLayout
            : chartLayout
        }
        labelFormat={labelFormat}
        isDimensionAllowed={dim => jsonStat.size[jsonStat.id.indexOf(dim)] === 1}
      />
    </Fragment>
  )
}

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SingleViewerHeader);