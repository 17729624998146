import React, {Fragment, useState} from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import SanitizedHTML from "../sanitized-html";
import Grid from "@material-ui/core/Grid";
import DatasetAttachments from "../dataset-attachments";

const styles = theme => ({
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: 8
  },
  sectionContent: {
    "& p:first-child": {
      marginTop: 0
    },
    "& p:last-child": {
      marginBottom: 0
    },
    "& .MuiChip-root": {
      margin: "0 8px 0 0"
    }
  }
})

const CatalogInfoButton = ({
                             t,
                             classes,
                             title,
                             source,
                             description,
                             note,
                             attachments
                           }) => {

  const [isVisible, setVisibility] = useState(false);

  const onOpen = () => {
    setVisibility(true);
  };

  const onClose = ev => {
    ev.stopPropagation();
    setVisibility(false);
  };

  if (!source && !description && !note && !attachments) {
    return <span/>;
  }

  return (
    <Fragment>
      <Tooltip title={t("components.catalogInfoButton.action.title")}>
        <IconButton
          size="small"
          onClick={ev => {
            ev.stopPropagation();
            onOpen();
          }}
          aria-label={t("components.catalogInfoButton.action.ariaLabel")}
        >
          <InfoOutlinedIcon/>
        </IconButton>
      </Tooltip>

      <Dialog
        open={isVisible}
        maxWidth="sm"
        fullWidth
        onClose={onClose}
      >
        <CustomDialogTitle onClose={onClose}>
          {t("components.catalogInfoButton.dialog.title", {datasetTitle: title})}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {source && (
              <Grid item container>
                <Grid item xs={12} className={classes.sectionTitle}>
                  {t("components.catalogInfoButton.dialog.content.source")}:
                </Grid>
                <Grid item xs={12} className={classes.sectionContent}>
                  {source}
                </Grid>
              </Grid>
            )}
            {description && (
              <Grid item container>
                <Grid item xs={12} className={classes.sectionTitle}>
                  {t("components.catalogInfoButton.dialog.content.description")}:
                </Grid>
                <Grid item xs={12} className={classes.sectionContent}>
                  <SanitizedHTML html={description} allowTarget/>
                </Grid>
              </Grid>
            )}
            {note && (
              <Grid item container>
                <Grid item xs={12} className={classes.sectionTitle}>
                  {t("components.catalogInfoButton.dialog.content.notes")}:
                </Grid>
                <Grid item xs={12} className={classes.sectionContent}>
                  <SanitizedHTML html={note} allowTarget/>
                </Grid>
              </Grid>
            )}
            {attachments && (
              <Grid item container>
                <Grid item xs={12} className={classes.sectionTitle}>
                  {t("components.catalogInfoButton.dialog.content.attachments")}:
                </Grid>
                <Grid item xs={12} className={classes.sectionContent}>
                  <DatasetAttachments
                    title={title}
                    attachedDataFiles={attachments}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  );
};

export default compose(
  withTranslation(),
  withStyles(styles)
)(CatalogInfoButton);