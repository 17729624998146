import React, {Fragment} from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {ViewerMode} from "../../state/dataset/constants";
import SingleViewer from "./single-viewer/Viewer";
import MultiViewer from "./multi-viewer/Viewer";
import CustomEmpty from "../custom-empty";
import ErrorIcon from '@material-ui/icons/Error';

const styles = () => ({
  viewer: {
    width: "100%",
    height: "100%",
    padding: 8
  },
  singleViewer: {
    padding: 16
  },
  multiViewer: {},
  error: {}
});

function DataViewerViewer({t, classes, ...props}) {

  const {
    viewerMode,
    dataset,
    datasetFetchStart,
    datasetFetchError,
    dimensions,
    isEmptyData
  } = props;

  return (
    <Fragment>
      {(() => {
        if (viewerMode === ViewerMode.Error) {
          return (
            <Card className={`${classes.viewer} ${classes.error}`}>
              <CustomEmpty
                text={t("scenes.dataViewer.viewer.dataViewingError")}
                image={<ErrorIcon/>}
              />
            </Card>
          )

        }

        if (!dataset) {
          let text = "";
          if (datasetFetchStart) {
            text = "";
          } else if (datasetFetchError) {
            text = t("scenes.dataViewer.errors.fetchData");
          } else if (isEmptyData) {
            text = t("scenes.dataViewer.errors.emptyData");
          } else if (dimensions) {
            text = t("scenes.dataViewer.errors.applyCriteria");
          }
          return (
            <Card className={classes.viewer}>
              <CustomEmpty text={text}/>
            </Card>
          )
        }

        switch (viewerMode) {
          case ViewerMode.SingleViewer: {
            return (
              <Card
                id="data-viewer__single-viewer__wrapper"
                className={`${classes.viewer} ${classes.singleViewer}`}
              >
                <SingleViewer
                  {...props}
                />
              </Card>
            );
          }
          case ViewerMode.MultiViewer: {
            return (
              <Card className={`${classes.viewer} ${classes.multiViewer}`}>
                <MultiViewer
                  {...props}
                />
              </Card>
            );
          }
          default: {
            return null;
          }
        }
      })()}
    </Fragment>
  );
}

export default compose(
  withStyles(styles),
  withTranslation()
)(DataViewerViewer);