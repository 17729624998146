import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./state/rootReducer";
import userMiddlewareFactory from "./middlewares/user/userMiddlewareFactory";
import requestMiddlewareFactory from "./middlewares/request/requestMiddlewareFactory";
import {showTranslatedGenericErrorFactory} from "./utils/other";
import configMiddleware from "./middlewares/config/configMiddleware";
import fileSaveMiddleware from "./middlewares/file-save/middleware";
import persistenceMiddleware from "./middlewares/persistence/middleware";
import i18nMiddleware from "./middlewares/i18n-middleware/middleware";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {init as initAction} from "./state/rootActions";
import requestSpinnerMiddlewareFactory from "./middlewares/request-spinner/requestSpinnerMiddlewareFactory";
import {
  getAppConfigUrl,
  getCustomTranslationUrl,
  getDashboardConfigUrl,
  getFooterHtmlUrl,
  getHubLanguagesUrl,
  getInitConfigUrl,
  getMapLayersConfigUrl,
  getModulesConfigUrl
} from "./serverApi/urls";
import feedbackMiddlewareFactory from "./middlewares/feedback/feedbackMiddleware";
import fetchDatasetAsyncHandlerMiddlewareFactory from "./middlewares/fetch-dataset-async-handler/middlewareFactory";
import fetchDashboardDatasetAsyncHandlerMiddlewareFactory from "./middlewares/fetch-dashboard-dataset-async-handler/middlewareFactory";
import a11yMiddleware from "./middlewares/a11y-middleware/middleware";
import nodeMiddleware from "./middlewares/node/middleware";
import detailLevelMiddleware from "./middlewares/detail-level-middleware/middleware";
import _ from "lodash";
import actionDecoratorMiddlewareFactory from "./middlewares/action-decorator/actionDecoratorMiddlewareFactory";
import {isValidIntegerInInclusiveRange} from "./utils/validator";
import actionTransformerMiddleware from "./middlewares/action-transformer/middleware";

import themeConfig from "./config/theme/config.json";

const getThemeAppConfig = appConfig => {
  const newAppConfig = {};

  Object.keys(themeConfig.appConfig).forEach(key => {
    if (themeConfig.configsToMerge.includes(key)) {
      newAppConfig[key] = {
        ...themeConfig.appConfig[key],
        ...(appConfig?.[key] || {})
      };

    } else if (typeof themeConfig.appConfig[key] === "object" && !Array.isArray(themeConfig.appConfig[key]) && themeConfig.appConfig[key] !== null) {
      newAppConfig[key] = {};
      Object.keys(themeConfig.appConfig[key]).forEach(subKey => {
        if (themeConfig.configsToMerge.includes(subKey)) {
          newAppConfig[key][subKey] = {
            ...themeConfig.appConfig[key][subKey],
            ...(appConfig?.[key]?.[subKey] || {})
          };
        } else {
          newAppConfig[key][subKey] = appConfig?.[key]?.[subKey] !== undefined
            ? appConfig[key][subKey]
            : themeConfig.appConfig[key][subKey];
        }
      });

    } else {
      newAppConfig[key] = appConfig?.[key] !== undefined
        ? appConfig[key]
        : themeConfig.appConfig[key];
    }
  });

  return newAppConfig;
};

const $ = window.jQuery;

const getRandomParam = () => "?random=" + Math.floor(Math.random() * 16777215).toString(16);

const configFiles = [
  {
    url: getAppConfigUrl(),
    param: "appConfig"
  },
  {
    url: getDashboardConfigUrl(),
    param: "dashboardFilterConfig"
  },
  {
    url: getMapLayersConfigUrl(),
    param: "mapLayersConfig"
  },
  {
    url: getModulesConfigUrl(),
    param: "modulesConfig"
  }
];

const showError = text => {
  $(".preloader__spinner").hide();

  if (text) {
    $(".preloader__error-text").text(text);
  }

  $(".preloader__error").show();
};

const getUrlWithFinalSlash = url => url.endsWith("/") ? url : (url + "/");

const init = cb => {
  const configs = {
    themeConfig: {...themeConfig, appConfig: undefined}
  };

  fetch(`./${getInitConfigUrl()}${getRandomParam()}`)
    .then(response => response.json())
    .then(({baseURL: origBaseURL, externalServices}) => {
      const baseURL = getUrlWithFinalSlash(origBaseURL);
      configs.externalServices = _.mapValues(externalServices, getUrlWithFinalSlash);
      Promise.all(configFiles.map(config =>
        fetch(`./${config.url}${getRandomParam()}`)
          .then(response => (response.ok && response.headers.get("content-type").indexOf("application/json") !== -1)
            ? response.json()
            : null
          )
          .catch(() => showError())
      ))
        .then(responses => {
          responses.forEach((config, idx) => {
            configs[configFiles[idx].param] = config;
          });
        })
        .then(() => {
          fetch(`${baseURL}${getHubLanguagesUrl()}`)
            .then(response => response.json())
            .then(supportedLanguages => {
              Promise.all(supportedLanguages.map(code =>
                fetch(`./i18n/${code}.json${getRandomParam()}`)
                  .then(response => (response.ok && response.headers.get("content-type").indexOf("application/json") !== -1)
                    ? response.json()
                    : null
                  )
                  .catch(() => showError())
              ))
                .then(translations => {

                  let resources = {};
                  supportedLanguages.forEach((code, idx) => {
                    if (translations[idx]) {
                      resources[code] = {
                        translation: translations[idx]
                      };
                    }
                  });

                  const supportedAndTranslatedLanguages = Object.keys(resources);

                  if (supportedAndTranslatedLanguages.length === 0) {
                    console.error("Unable to find translation file for at least one configured language. Please check app configuration and translations file.");
                    showError("Error initializing application.");

                  } else {
                    const enabledModules = (configs?.modulesConfig?.modules || [])
                      .filter(({enabled}) => enabled)
                      .map(({id}) => id);

                    Promise.all(enabledModules.map(moduleId => import(`./modules/${moduleId}/config.js`)))
                      .then(moduleConfigList => {

                        const modulesConfig = {
                          modules: [],
                          configs: {},
                          placeholders: {},
                          hubRoutes: [],
                          nodeRoutes: [],
                          permissions: []
                        };

                        enabledModules.forEach((moduleId, key) => {
                          modulesConfig.modules.push(moduleId);
                          modulesConfig.configs[moduleId] = configs.modulesConfig.modules.find(({id}) => id === moduleId).config || null;
                          (moduleConfigList[key]?.placeholders || []).forEach(({placeholder, component, fallback}) => {
                            if (!modulesConfig.placeholders[placeholder]) {
                              modulesConfig.placeholders[placeholder] = [];
                            }
                            modulesConfig.placeholders[placeholder].push({
                              id: moduleId,
                              component: component,
                              fallback: fallback
                            });
                          });
                          (moduleConfigList[key]?.hubRoutes || []).forEach(({route, component, fallback}) => {
                            modulesConfig.hubRoutes.push({
                              id: moduleId,
                              route: route,
                              component: component,
                              fallback: fallback
                            });
                          });
                          (moduleConfigList[key]?.nodeRoutes || []).forEach(({route, component, fallback}) => {
                            modulesConfig.nodeRoutes.push({
                              id: moduleId,
                              route: route,
                              component: component,
                              fallback: fallback
                            });
                          });
                          (moduleConfigList[key]?.permissions || []).forEach(permission => {
                            modulesConfig.permissions.push(permission);
                          });
                        });

                        configs.modulesConfig = modulesConfig;

                        Promise.all(supportedAndTranslatedLanguages.map(code =>
                          fetch(`./${getCustomTranslationUrl(code)}${getRandomParam()}`)
                            .then(response => (response.ok && response.headers.get("content-type").indexOf("application/json") !== -1)
                              ? response.json()
                              : null
                            )
                            .catch(() => console.error(`Error in custom translation file for language "${code}".`))
                        ))
                          .then((customTranslations) => {

                            supportedAndTranslatedLanguages.forEach((code, idx) => {
                              if (customTranslations[idx]) {
                                _.merge(resources[code].translation, customTranslations[idx]);
                              }
                            });

                            const urlLang = window.location.hash.split("/")[1];

                            const defaultLanguage = (urlLang && supportedAndTranslatedLanguages.includes(urlLang))
                              ? urlLang
                              : supportedAndTranslatedLanguages[0] || "en";

                            configs.footers = {};
                            Promise.all(["default", ...supportedAndTranslatedLanguages].map(code =>
                              fetch(`${getFooterHtmlUrl(code)}${getRandomParam()}`)
                                .then(response => response.ok
                                  ? response.text()
                                  : null
                                )
                                .then(footer => {
                                  if (footer && !footer.includes("react-client__root")) {
                                    configs.footers[code] = footer;
                                  }
                                })
                                .catch(() => showError())
                            ))
                              .then(() => {

                                i18n
                                  .use(initReactI18next)
                                  .init({
                                    lng: defaultLanguage,
                                    resources,
                                    returnEmptyString: false,
                                    interpolation: {
                                      escapeValue: false
                                    }
                                  });

                                Promise.all(enabledModules.map(moduleId => import(`./modules/${moduleId}/state/reducer`)))
                                  .then(moduleReducerFiles => moduleReducerFiles.map(moduleReducerFile => moduleReducerFile.default))
                                  .then(moduleReducerList => {

                                    const moduleReducerMap = {};
                                    enabledModules.forEach((module, idx) => {
                                      moduleReducerMap[module] = moduleReducerList[idx];
                                    });

                                    const store = configureStore({
                                      reducer: {
                                        ...moduleReducerMap,
                                        ...rootReducer
                                      },
                                      middleware: [
                                        actionDecoratorMiddlewareFactory(i18n.t.bind(i18n)),
                                        actionTransformerMiddleware,
                                        userMiddlewareFactory(i18n.t.bind(i18n)),
                                        requestMiddlewareFactory({
                                          onGenericError: showTranslatedGenericErrorFactory(i18n.t.bind(i18n))
                                        }),
                                        requestSpinnerMiddlewareFactory(i18n.t.bind(i18n)),
                                        fileSaveMiddleware,
                                        persistenceMiddleware,
                                        i18nMiddleware,
                                        a11yMiddleware,
                                        detailLevelMiddleware,
                                        configMiddleware,
                                        nodeMiddleware,
                                        feedbackMiddlewareFactory(i18n.t.bind(i18n)),
                                        fetchDatasetAsyncHandlerMiddlewareFactory(i18n.t.bind(i18n)),
                                        fetchDashboardDatasetAsyncHandlerMiddlewareFactory(i18n.t.bind(i18n))
                                      ]
                                    });

                                    let detailLevelParam;
                                    const split = window.location.hash.split("t=");
                                    if (split && split.length === 2 && split[1].length > 0) {
                                      const split2 = split[1].split("&");
                                      if (split2[0] && split2[0].length > 0) {
                                        detailLevelParam = decodeURIComponent(split2[0]);
                                      }
                                    }
                                    if (isValidIntegerInInclusiveRange(detailLevelParam)) {
                                      detailLevelParam = parseInt(detailLevelParam, 10);
                                    } else {
                                      detailLevelParam = null;
                                    }

                                    configs.appConfig = getThemeAppConfig(configs.appConfig);

                                    if ((configs.appConfig.pwaManifestPath || "").length > 0) {
                                      document.getElementById("manifest").href = configs.appConfig.pwaManifestPath;
                                    }

                                    store.dispatch(initAction(baseURL, supportedAndTranslatedLanguages, defaultLanguage, detailLevelParam, configs));
                                    cb(store);
                                  })
                                  .catch(() => showError());
                              })
                              .catch(() => showError());
                          })
                          .catch(() => showError());
                      })
                      .catch(() => showError());
                  }
                })
                .catch(() => showError());
            })
            .catch(() => showError());
        })
        .catch(() => showError());
    })
    .catch(() => showError());
};

export default init;