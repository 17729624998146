import React, {useCallback, useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import EnhancedTree from "../enhanced-tree";
import {getFilteredTreeWithPaths, getNode} from "../../utils/tree";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  }
});

const mapStateToProps = ({catalog}) => ({
  datasetMap: catalog.datasets
});

function DetailLevelSelector(props) {
  const {
    classes,
    datasetMap,
    detailLevelTree,
    detailLevel,
    setDetailLevel,
    additionalDatasets
  } = props;

  const [filteredDetailLevelTree, setFilteredDetailLevelTree] = useState(null);

  const [checkedKeys, setCheckedKeys] = useState(null);

  const getTreeNodeIsSelectable = useCallback(({layers}) => (layers || []).length === 0, []);

  useEffect(() => {
    if (detailLevelTree) {
      if (additionalDatasets.length === 0) {
        setFilteredDetailLevelTree(detailLevelTree);

      } else {

        const additionalDatasetsDLs = additionalDatasets
          .map(({detailLevels}) => detailLevels)
          .sort((a, b) => a.length - b.length);

        const commonAdditionalDatasetsDLs = additionalDatasetsDLs
          .shift()
          .filter(dl => additionalDatasetsDLs.every(dls => dls.indexOf(dl) !== -1));

        const filteredDetailLevelTree = getFilteredTreeWithPaths(detailLevelTree, "layers", ({id}) => commonAdditionalDatasetsDLs.includes(id));

        setFilteredDetailLevelTree(filteredDetailLevelTree);
      }
    }
  }, [detailLevelTree, datasetMap, additionalDatasets]);

  useEffect(() => {
    if (filteredDetailLevelTree) {
      const checkedNode = getNode(filteredDetailLevelTree, "layers", ({id}) => id === detailLevel);
      if (checkedNode) {
        setCheckedKeys([checkedNode.key]);
      } else {
        setCheckedKeys([]);
      }
    }
  }, [filteredDetailLevelTree, detailLevel]);

  const handleDetailLevelCheck = newCheckedKeys => {
    let checkedNodeKey;

    if (newCheckedKeys.length === 1) {
      checkedNodeKey = newCheckedKeys[0];

    } else if (newCheckedKeys.length === 2) {
      checkedNodeKey = newCheckedKeys.find(key => !(checkedKeys || []).includes(key));
    }

    const checkedNode = getNode(filteredDetailLevelTree, "layers", ({key}) => key === checkedNodeKey);
    if (checkedNode) {
      setDetailLevel(checkedNode.id);
    }
  };

  return checkedKeys && (
    <div className={classes.root}>
      <EnhancedTree
        tree={filteredDetailLevelTree}
        idKey="key"
        labelKey="label"
        childrenKey="layers"
        defaultExpandedKeys={checkedKeys}
        selectable
        singleSelect
        selectOnNodeClick
        getIsNodeSelectable={getTreeNodeIsSelectable}
        defaultSelectedKeys={checkedKeys}
        onSelect={handleDetailLevelCheck}
        hideCheckControls
        hideExpandControls
        hideDisabledCheckbox
        checkboxCheckedIcon={<RadioButtonCheckedIcon />}
        checkboxUncheckedIcon={<RadioButtonUncheckedIcon />}
        treeActionsDirection="row-reverse"
      />
    </div>
  );
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(DetailLevelSelector);