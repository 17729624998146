import {
  getCompleteValueDims,
  getCoordinatesArrayFromDataIdx,
  getDataIdxFromCoordinatesArray,
  getDimensionAttributeMap,
  getDimensionValuesIndexesMap,
  getFormattedDimensionValueLabel,
  getObservationAttributeMap,
  VARIATION_DIMENSION_KEY,
  VARIATION_VALUE_VALUE_KEY
} from "../../utils/jsonStat";
import moment from "moment";
import {isNumeric} from "../../utils/validator";
import {getNutsLevelTranslations} from "../../constants/getNutsLevelTranslations";

export const getDetailLevelsFromGeometries = (geometries, showSingleGeometry, levelsOrder, t) =>
  geometries
    .reduce((acc, geom) => {
      const level = geom.level;
      const levelOrder = (levelsOrder || []).indexOf(level);
      if (!acc[level]) {
        acc[level] = {
          value: level,
          label: getNutsLevelTranslations(t)[level],
          order: levelOrder > -1 ? levelOrder : Number.MAX_SAFE_INTEGER,
          counter: 0
        };
      }
      acc[level].counter++;
      return acc;
    }, [])
    .filter(val => showSingleGeometry || val.counter > 1)
    .sort((a, b) => a.order - b.order);

export const getLayoutGeometries = (
  geometries,
  jsonStat,
  layout,
  dimensionValueTree,
  labelFormat,
  isPointData,
  latAttributeId,
  longAttributeId,
  t,
  enableLog = false
) => {
  const layoutGeometries = [];
  const geometryMap = {};
  const territoryMap = {};

  const missingGeometryList = [];

  const geometryMapByTerritoryId = {};
  (geometries || []).forEach(g => geometryMapByTerritoryId[g.id] = g);

  const datasetId = jsonStat?.extension?.datasets?.[0];

  const territoryDim = layout.territoryDim;
  const territoryDimValues = jsonStat.dimension[territoryDim].category.index;
  const indexesMap = getDimensionValuesIndexesMap(jsonStat);
  const obsAttributeMap = getObservationAttributeMap(jsonStat);
  const dimAttributeMap = getDimensionAttributeMap(jsonStat, t)[datasetId];

  let filtersValue = {...layout.filtersValue};
  if (jsonStat.id.includes(VARIATION_DIMENSION_KEY)) {
    filtersValue = {...filtersValue, [VARIATION_DIMENSION_KEY]: VARIATION_VALUE_VALUE_KEY};
  }

  const orderedDims = [layout.territoryDim, ...layout.filters, ...jsonStat.id.filter(dim => dim !== layout.territoryDim && !layout.filters.includes(dim))];

  territoryDimValues.forEach(territoryDimValue => {
    const geometry = geometryMapByTerritoryId[territoryDimValue];

    if (!geometry) {
      const missingEntry = {
        id: territoryDimValue,
        label: jsonStat.dimension[territoryDim].category.label[territoryDimValue]
      };
      missingGeometryList.push(missingEntry);

    } else {
      let dimValueArray = jsonStat.id.map(dim => {
        if (dim === territoryDim) {
          return territoryDimValue;
        } else if (filtersValue[dim]) {
          return filtersValue[dim];
        } else {
          return null;
        }
      });

      if (dimValueArray.findIndex(val => val === null) > -1 && dimensionValueTree) {
        dimValueArray = getCompleteValueDims(dimValueArray, jsonStat.id, orderedDims, dimensionValueTree);
      }

      if (dimValueArray) {
        const dimValueIndexArray = dimValueArray.map((value, idx) => indexesMap[jsonStat.id[idx]][value]);
        const valueIdx = getDataIdxFromCoordinatesArray(dimValueIndexArray, jsonStat.size);

        const jsonStatValue = jsonStat.value[valueIdx];
        const value = (jsonStatValue !== null && jsonStatValue !== "" && !isNaN(jsonStatValue))
          ? Number(jsonStatValue)
          : null;

        if (value !== null) {

          const obsAttributes = obsAttributeMap?.[valueIdx] || null;
          const dimAttributes = dimAttributeMap[territoryDim][territoryDimValue] || null;
          const hasAttributes = (obsAttributes || []).concat(dimAttributes || []).length > 0;

          let isGeomValid = true;
          let geom;
          if (!isPointData) {
            geom = geometry.geoJson || geometry.wkt;
          } else {
            const long = (dimAttributes || []).find(({id}) => id === longAttributeId)?.valueId;
            const lat = (dimAttributes || []).find(({id}) => id === latAttributeId)?.valueId;
            if (!isNumeric(long) || !isNumeric(lat)) {
              isGeomValid = false;
            }
            geom = {
              type: "Point",
              coordinates: [Number(long), Number(lat)]
            };
          }

          if (isGeomValid) {
            layoutGeometries.push({
              value: value,
              id: geometry.id,
              identifier: geometry.uniqueId,
              level: geometry.nutsLevel,
              geometry: geom,
              label: getFormattedDimensionValueLabel(jsonStat, null, territoryDim, territoryDimValue, labelFormat, t),
              suffix: hasAttributes
                ? t("components.map.tooltip.attributes.suffix")
                : undefined,
              isTooltipClickable: hasAttributes
            });

            geometryMap[geometry.uniqueId] = {
              hasAttributes: hasAttributes,
              obsAttributes: obsAttributes,
              dimAttributes: dimAttributes,
              territoryId: territoryDimValue
            };

            territoryMap[geometry.id] = geometry.uniqueId;
          }
        }
      }
    }
  });

  if (enableLog && missingGeometryList.length > 0) {
    console.log("missing ids:", missingGeometryList);
  }

  return {
    layoutGeometries,
    geometryMap,
    territoryMap
  };
};

export const getTimeDimValueFromLayout = (jsonStat, layout) => {
  const timeDim = jsonStat.role?.time?.[0];
  const timeDimValue = (timeDim && layout.filtersValue[timeDim]) ? layout.filtersValue[timeDim] : null;

  const timeDimValueYear = moment(timeDimValue);

  return timeDimValueYear.isValid()
    ? timeDimValueYear.format("YYYY")
    : null;
};

export const getTerritoriesWithValue = (jsonStat, territoryDim) => {
  const map = {};
  const territoryDimIdx = jsonStat.id.indexOf(territoryDim);

  Object.keys(jsonStat.value).forEach(key => {
    const val = jsonStat.value[key];
    if (val !== undefined && val !== null && val !== "") {
      const coordinates = getCoordinatesArrayFromDataIdx(key, jsonStat.size);
      const territoryDimValue = jsonStat.dimension[territoryDim].category.index[coordinates[territoryDimIdx]]
      map[territoryDimValue] = true;
    }
  });

  return Object.keys(map);
};