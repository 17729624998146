import React, {Fragment, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ButtonSelect from "../../button-select";
import SaveIcon from '@material-ui/icons/Save';
import {
  canSaveAsView,
  canSaveTemplate,
  canUseDatasetTools,
  canViewTemplateOrAnnotationIcon,
  canViewTimesLog
} from "../../../utils/user";
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import AttributeIcon from "../../custom-icons/AttributeIcon";
import MetadataIcon from "../../custom-icons/MetadataIcon";
import DatasetViewerTools from '../DatasetViewerTools';
import Typography from "@material-ui/core/Typography";
import {NodeDataAccessibility, NodeVisibility} from "../../../model/IHubMinimalNode.d.ts";
import {MERGED_DATASETS_TITLE_SEPARATOR} from "../../../utils/constants";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    color: theme.palette.primary.main
  },
  titleContainer: {
    paddingTop: 7,
    minHeight: 48,
    "& > h1:first-child": {
      marginLeft: 0
    },
    "& > h1:last-child": {
      marginRight: 0
    }
  },
  title: {
    display: "unset",
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0,
    margin: "0 4px"
  },
  titleAction: {
    marginTop: -10
  },
  layoutIcon: {
    width: 48,
    height: 48,
    padding: 12,
    color: "rgba(0, 0, 0, 0.54)"
  }
});

const areNodesAllowedForSaveViewTemplate = (datasets, nodes) => {
  const datasetNodes = datasets.map(({nodeCode}) => nodeCode);
  return nodes
    .filter(({code}) => datasetNodes.includes(code))
    .findIndex(({visible, dataAccess}) => visible === NodeVisibility.No && dataAccess === NodeDataAccessibility.Profiled) === -1;
};

function MultiViewerHeader(props) {

  const {
    classes,

    user,
    nodes,
    nodeId,
    nodeCode,
    datasetMap,
    datasetId,
    nodeExtras,
    datasetTitle,
    viewId,
    attachedFiles,
    onMetadataShow,
    hasViewLayout,
    hasTemplateLayout,
    hasAnnotationLayout,
    tableLayout,
    mapLayout,
    chartLayout,
    timings,
    onQueryShow,
    indicators,
    additionalDatasets,

    datasetAttributeMap,
    seriesAttributeMap,
    handleNotesShow,
    handleAttributesShow,
    handleTimingsShow,
    handleViewOpen,
    handleTemplateOpen
  } = props;

  const {t} = useTranslation();

  const showQueryInfo = nodeExtras?.QueryInfo;

  useEffect(() => {
    const actionsWidth = $("#data-viewer__header__header__multi-viewer__actions").outerWidth(true);
    $("#data-viewer__header__header__multi-viewer__title").width(`calc(100% - ${actionsWidth}px - 8px)`);
  });

  const datasets = [
    {
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle
    },
    ...additionalDatasets
  ];

  return (
    <Grid container className={classes.root} justifyContent="space-between" alignItems="flex-start">
      <Grid item id="data-viewer__header__header__multi-viewer__title" className={classes.titleContainer}>
        {datasets.map(({datasetId, datasetTitle, nodeCode}, idx, arr) => {

          const notes = datasetMap[datasetId]?.notes || [];
          const datasetAttributes = datasetAttributeMap?.[`${nodeCode},${datasetId}`] || [];
          const seriesAttributes = seriesAttributeMap?.[`${nodeCode},${datasetId}`] || [];
          const metadataUrl = datasetMap[datasetId]?.referenceMetadata || null;

          return (
            <Fragment key={idx}>
              <Typography variant={"h1"} className={classes.title}>
                {datasetTitle}
              </Typography>
              {notes.length > 0 && (
                <Tooltip title={t("scenes.dataViewer.header.action.information.tooltip")}>
                  <IconButton
                    id="dataset-notes-btn"
                    aria-label={t("scenes.dataViewer.header.action.information.label")}
                    onClick={() => handleNotesShow(notes)}
                    className={classes.titleAction}
                  >
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
              {datasetAttributes.concat(seriesAttributes).length > 0 && (
                <Tooltip title={t("scenes.dataViewer.header.action.attributes.tooltip")}>
                  <IconButton
                    id="dataset-attributes-btn"
                    aria-label={t("scenes.dataViewer.header.action.attributes.label")}
                    onClick={() => handleAttributesShow({
                      datasetAttributes: datasetAttributes,
                      seriesAttributes: seriesAttributes
                    })}
                    className={classes.titleAction}
                  >
                    <AttributeIcon/>
                  </IconButton>
                </Tooltip>
              )}
              {metadataUrl && (
                <Tooltip title={t("scenes.dataViewer.header.action.metadata.tooltip")}>
                  <IconButton
                    id="dataset-metadata-btn"
                    aria-label={t("scenes.dataViewer.header.action.metadata.label")}
                    onClick={() => onMetadataShow(metadataUrl)}
                    className={classes.titleAction}
                  >
                    <MetadataIcon/>
                  </IconButton>
                </Tooltip>
              )}
              {idx < (arr.length - 1) && (
                <Typography variant={"h1"} className={classes.title}>{MERGED_DATASETS_TITLE_SEPARATOR}</Typography>
              )}
            </Fragment>
          );
        })}
      </Grid>
      <Grid item id="data-viewer__header__header__multi-viewer__actions">
        <Grid container alignItems="center">
          {(() => {
            if (canViewTemplateOrAnnotationIcon(user, nodeId)) {

              let title = null;
              if (hasViewLayout) {
                title = t("scenes.dataViewer.header.action.hasViewLayout.tooltip");
              } else if (hasTemplateLayout) {
                title = t("scenes.dataViewer.header.action.hasTemplateLayout.tooltip");
              } else if (hasAnnotationLayout) {
                title = t("scenes.dataViewer.header.action.hasAnnotationLayout.tooltip");
              }

              if (title) {
                return (
                  <Grid item id="dataset-layout-info-btn" className={classes.layoutIcon}>
                    <Tooltip title={title}>
                      <InfoIcon/>
                    </Tooltip>
                  </Grid>
                )
              }
            }
          })()}
          {canViewTimesLog(user, nodeId) && (
            <Grid item id="dataset-times-log-btn">
              <Tooltip title={t("scenes.dataViewer.header.action.timesLog.tooltip")}>
                <div>
                  <IconButton
                    aria-label={t("scenes.dataViewer.header.action.timesLog.label")}
                    onClick={handleTimingsShow}
                    disabled={timings === null}
                  >
                    <AccessTimeIcon/>
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
          )}
          {additionalDatasets.length === 0 && canUseDatasetTools(user) && (
            <Grid item id="dataset-cache-template-btn">
              <DatasetViewerTools/>
            </Grid>
          )}
          {(canSaveAsView(user) || canSaveTemplate(user, nodeId)) && areNodesAllowedForSaveViewTemplate(datasets, nodes) && (
            <Grid item id="dataset-save-btn">
              <ButtonSelect
                icon={<SaveIcon/>}
                ariaLabel={t("scenes.dataViewer.header.action.save.label")}
                tooltip={t("scenes.dataViewer.header.action.save.tooltip")}
                color="default"
                disabled={!tableLayout && !chartLayout && !mapLayout}
              >
                {canSaveAsView(user) && (
                  <div onClick={() => handleViewOpen(false)}>
                    {t("scenes.dataViewer.header.action.save.values.createView")}
                  </div>
                )}
                {canSaveAsView(user) && hasViewLayout && viewId && (
                  <div onClick={() => handleViewOpen(true)}>
                    {t("scenes.dataViewer.header.action.save.values.updateView")}
                  </div>
                )}
                {indicators.length === 0 && additionalDatasets.length === 0 && canSaveTemplate(user, nodeId) && (
                  <div onClick={() => handleTemplateOpen(hasTemplateLayout)}>
                    {hasTemplateLayout
                      ? t("scenes.dataViewer.header.action.save.values.updateTemplate")
                      : t("scenes.dataViewer.header.action.save.values.createTemplate")
                    }
                  </div>
                )}
              </ButtonSelect>
            </Grid>
          )}
          {(attachedFiles || []).length > 0 && (
            <Grid item id="dataset-attachments-btn">
              <ButtonSelect
                icon={<AttachFileIcon/>}
                ariaLabel={t("scenes.dataViewer.header.action.attachments.label")}
                tooltip={t("scenes.dataViewer.header.action.attachments.tooltip")}
                color="default"
                onChange={({attachedFile}) => window.open(attachedFile.url)}
              >
                {attachedFiles.map((attachedFile, idx) => {
                  const fileName = attachedFile.url.split("/").pop();
                  return (
                    <div key={idx} data-value={{attachedFile}}>
                      {`${fileName} (${attachedFile.format})`}
                    </div>
                  )
                })}
              </ButtonSelect>
            </Grid>
          )}
          {showQueryInfo && (
            <Grid item id="dataset-query-btn">
              <Tooltip title={t("scenes.dataViewer.header.action.query.tooltip")}>
                <div>
                  <IconButton
                    aria-label={t("scenes.dataViewer.header.action.query.label")}
                    onClick={onQueryShow}
                  >
                    <DeveloperModeIcon/>
                  </IconButton>
                </div>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(MultiViewerHeader);