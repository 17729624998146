import {Reducer} from "redux";
import {MAP_GEOMETRY_CHANGED_STATUS_SET, MAP_GEOMETRY_CLEAR, MAP_GEOMETRY_FETCH, MAP_GEOMETRY_SET, MAP_NO_GEOMETRY_WARNING_HIDE} from "./mapsActions";
import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";
import {INIT} from "../rootActions";

type Geometry = {
  country: string | undefined,
  id: string,
  label: string,
  nutsLevel: number,
  uniqueId: number,
  wkt: string | undefined
  geoJson: any | undefined
};

type MapState = {
  geometries: Geometry[] | null,
  changed: boolean,
  error: boolean,
  isNoGeometryWarningVisible: boolean
};

type MapsState = {
  mapLayersConfig: any,
  maps: { [key: string]: MapState }
};

const mapsReducer: Reducer<MapsState> = (
  state = {
    mapLayersConfig: null,
    maps: {}
  },
  action
) => {
  switch (action.type) {
    case INIT: {
      return {
        ...state,
        mapLayersConfig: action.payload.mapLayersConfig
      };
    }
    case MAP_GEOMETRY_CLEAR: {
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapStateUuid]: undefined
        }
      };
    }
    case MAP_NO_GEOMETRY_WARNING_HIDE: {
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapStateUuid]: {
            ...state.maps[action.payload.mapStateUuid],
            isNoGeometryWarningVisible: false
          }
        }
      };
    }
    case MAP_GEOMETRY_CHANGED_STATUS_SET: {
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapStateUuid]: {
            ...state.maps[action.payload.mapStateUuid],
            changed: action.payload.changed
          }
        }
      };
    }
    case MAP_GEOMETRY_SET: {
      return {
        ...state,
        maps: {
          ...state.maps,
          [action.payload.mapStateUuid]: {
            geometries: action.payload.geometries,
            changed: true,
            error: false
          }
        }
      };
    }
    case REQUEST_INIT: {
      switch (action.payload.label) {
        case MAP_GEOMETRY_FETCH: {
          return {
            ...state,
            maps: {
              ...state.maps,
              [action.payload.extra.mapStateUuid]: {
                geometries: null,
                changed: false,
                error: false
              }
            }
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case MAP_GEOMETRY_FETCH: {
          return {
            ...state,
            maps: {
              ...state.maps,
              [action.payload.extra.mapStateUuid]: {
                geometries: action.payload.response,
                changed: true,
                error: false
              }
            }
          };
        }
        default:
          return state;
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case MAP_GEOMETRY_FETCH: {
          return {
            ...state,
            maps: {
              ...state.maps,
              [action.payload.extra.mapStateUuid]: {
                geometries: [],
                changed: true,
                error: true,
                isNoGeometryWarningVisible: true
              }
            }
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default mapsReducer;