import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {getFormattedDimensionLabel, getFormattedDimensionValueLabel, getMarginalDimensions, MARGINAL_DIMENSION_KEY, VARIATION_DIMENSION_KEY} from "./jsonStat";
import {getCriteriaArrayFromObject} from "./criteria";
import {getIndicatorsDefinition} from "../state/dataset/constants";
import {localizeI18nObj} from "./i18n";
import moment from "moment";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../components/temporal-dim-order-selector/constants";
import {PDF_ORIENTATION_HORIZONTAL} from "../components/export-button/ExportForm";

const $ = window.jQuery;

export const DOWNLOAD_VIEWER_TABLE = "table";
export const DOWNLOAD_VIEWER_CHART = "chart";
export const DOWNLOAD_VIEWER_MAP = "map";

export const DOWNLOAD_FORMAT_CHART_IMAGE = "chart-image";
export const DOWNLOAD_FORMAT_MAP_IMAGE = "map-image";
export const DOWNLOAD_FORMAT_CHART_PDF = "chart-pdf";
export const DOWNLOAD_FORMAT_MAP_PDF = "map-pdf";
export const DOWNLOAD_FORMAT_SHAPEFILES = "shapefiles";
export const DOWNLOAD_FORMAT_EXCEL = "excel";
export const DOWNLOAD_FORMAT_CSV = "csv";
export const DOWNLOAD_REFERENCE_METADATA = "referenceMetadata";

export const downloadFormats = t => ({
  "genericdata": {
    label: t ? t("commons.downloadFormat.genericdata") : "genericdata",
    extension: "xml"
  },
  "genericdata20": {
    label: t ? t("commons.downloadFormat.genericdata20") : "genericdata20",
    extension: "xml"
  },
  "compactdata": {
    label: t ? t("commons.downloadFormat.compactdata") : "compactdata",
    extension: "xml"
  },
  "structurespecificdata": {
    label: t ? t("commons.downloadFormat.structurespecificdata") : "structurespecificdata",
    extension: "xml"
  },
  "structure": {
    label: t ? t("commons.downloadFormat.structure") : "structure",
    extension: "xml"
  },
  "jsondata": {
    label: t ? t("commons.downloadFormat.jsondata") : "jsondata",
    extension: "json"
  },
  "sdmx-csv": {
    label: t ? t("commons.downloadFormat.sdmxCsv") : "sdmx-csv",
    extension: "csv",
    param: "csv"
  },
  [DOWNLOAD_FORMAT_CHART_IMAGE]: {
    label: t ? t("commons.downloadFormat.chartImage") : "chart-image",
    extension: "jpeg",
    viewers: [DOWNLOAD_VIEWER_CHART]
  },
  [DOWNLOAD_FORMAT_MAP_IMAGE]: {
    label: t ? t("commons.downloadFormat.mapImage") : "map-image",
    extension: "jpeg",
    viewers: [DOWNLOAD_VIEWER_MAP]
  },
  [DOWNLOAD_FORMAT_CHART_PDF]: {
    label: t ? t("commons.downloadFormat.chartPdf") : "chart-pdf",
    extension: "pdf",
    viewers: [DOWNLOAD_VIEWER_CHART]
  },
  [DOWNLOAD_FORMAT_MAP_PDF]: {
    label: t ? t("commons.downloadFormat.mapPdf") : "map-pdf",
    extension: "pdf",
    viewers: [DOWNLOAD_VIEWER_MAP]
  },
  [DOWNLOAD_FORMAT_EXCEL]: {
    label: t ? t("commons.downloadFormat.excel") : "excel",
    extension: "xlsx",
    viewers: [DOWNLOAD_VIEWER_TABLE]
  },
  [DOWNLOAD_FORMAT_CSV]: {
    label: t ? t("commons.downloadFormat.csv") : "csv",
    extension: "csv"
  }
});

export const isFormatAvailableForMergedData = format => (
  format === DOWNLOAD_FORMAT_EXCEL ||
  format === DOWNLOAD_FORMAT_CSV ||
  format === DOWNLOAD_FORMAT_CHART_IMAGE ||
  format === DOWNLOAD_FORMAT_MAP_IMAGE ||
  format === DOWNLOAD_FORMAT_CHART_PDF ||
  format === DOWNLOAD_FORMAT_MAP_PDF
);

export const isDownloadFormatValid = format => downloadFormats()?.[format] !== undefined;

export const exportViewerCanvas = (
  canvas,
  canvasWidthPixel,
  canvasHeightPixel,
  format,
  fileName,
  title,
  layout,
  jsonStat,
  showDate,
  sheetOrientation,
  logoURL,
  labelFormat,
  t,
  onExportComplete
) => {

  let exportedContentW = canvasWidthPixel + 16;

  const imageContent = $("<div>")
    .css({
      position: "absolute",
      left: -(exportedContentW + 1)
    })
    .appendTo("body")
    .get(0);

  let pdfContent = [];

  const hasLogo = !!logoURL;
  const hasTitle = title && title.length > 0;
  const hasFilters = jsonStat && layout && layout.filters && layout.filters.length > 0;
  let hasIndicatorNote = false;

  // adding title
  if (hasTitle) {

    $(`<div>${title}</div>`)
      .css({
        width: exportedContentW,
        height: "100%",
        margin: "8px 0",
        padding: "0 8px",
        fontSize: 20
      })
      .appendTo(imageContent);

    pdfContent.push({
      text: title,
      style: ["text", "title"]
    });
  }

  // adding filters
  if (hasFilters) {

    const imageFilters = $(`<div/>`)
      .css({
        width: exportedContentW,
        height: "100%",
        margin: "8px 0",
        padding: "0 8px",
        fontSize: 14
      });

    const pdfFilters = [];

    layout.filters.forEach((filter, dimIdx) => {
      const filterValue = layout.filtersValue[filter];

      const addFilter = (dim, value, isLast) => {

        $(`<span><b>${dim}: </b><i>${value}</i>${!isLast ? ", " : ""} </span>`).appendTo(imageFilters);

        pdfFilters.push({text: dim + ": ", style: "bold"});
        pdfFilters.push({text: value, style: "italic"});
        if (!isLast) {
          pdfFilters.push({text: ", "});
        }
      };

      if (filter !== MARGINAL_DIMENSION_KEY) {
        const dimLabel = getFormattedDimensionLabel(jsonStat, null, filter, labelFormat, t);
        const valueLabel = getFormattedDimensionValueLabel(jsonStat, null, filter, filterValue, labelFormat, t);
        addFilter(dimLabel, valueLabel, dimIdx === layout.filters.length - 1);

      } else {
        const marginal = jsonStat.extension.marginalvalues[filterValue];
        if (marginal.label) {
          hasIndicatorNote = true;

          const dimLabel = getFormattedDimensionLabel(jsonStat, null, filter, labelFormat, t);
          const valueLabel = marginal.label + "*";
          addFilter(dimLabel, valueLabel, dimIdx === layout.filters.length - 1);

        } else {
          getMarginalDimensions(jsonStat, marginal.datasetid).forEach((dim, marginalDimIdx, arr) => {
            const dimLabel = getFormattedDimensionLabel(jsonStat, marginal.datasetid, dim, labelFormat, t);
            const valueLabel = getFormattedDimensionValueLabel(jsonStat, marginal.datasetid, dim, marginal.dimensionvalues[dim], labelFormat, t);
            addFilter(dimLabel, valueLabel, (dimIdx === (layout.filters.length - 1) && marginalDimIdx === (arr.length - 1)));
          });
        }
      }
    });

    imageFilters.appendTo(imageContent);

    pdfContent.push({
      text: pdfFilters,
      style: "text"
    });

    if (hasIndicatorNote) {
      const noteLabel = t("customAttributes.notes.label");
      const noteValueLabel = t("customAttributes.notes.values.noIstatData");

      $(`<div style="font-size: 12px; margin-top: 8px"><b>${noteLabel}: </b><i>${noteValueLabel}</i></div>`).appendTo(imageFilters);

      pdfContent.push({
        text: [
          {text: noteLabel + ": ", style: "bold"},
          {text: noteValueLabel, style: "italic"}
        ],
        style: ["text", "note"]
      });
    }
  }

  if (format === DOWNLOAD_FORMAT_CHART_IMAGE || format === DOWNLOAD_FORMAT_MAP_IMAGE) {

    // saving image file
    html2canvas(imageContent)
      .then(imageContentCanvas => {

        const mergedCanvas = document.createElement("canvas");
        mergedCanvas.width = canvas.width + 16;
        mergedCanvas.height = imageContentCanvas.height + canvas.height + 16;

        const context = mergedCanvas.getContext("2d");
        context.fillStyle = "white";
        context.fillRect(0, 0, mergedCanvas.width, mergedCanvas.height);
        if (imageContentCanvas.height > 0) {
          context.drawImage(imageContentCanvas, 0, 0);
        }
        if (canvas.height > 0) {
          context.drawImage(canvas, 8, imageContentCanvas.height + 8);
        }

        if (onExportComplete) {
          onExportComplete();
        }

        const link = document.createElement("a");
        link.download = fileName;
        link.target = "_blank";
        link.href = mergedCanvas.toDataURL("image/jpeg");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

  } else {

    pdfContent.push({
      text: "",
      margin: [0, 10, 0, 0]
    });

    const pageShortSize = 595.28, pageLongSize = 841.89 // default size from: https://github.com/bpampuch/pdfmake/blob/master/src/standardPageSizes.js
    const pageMargin = 50;

    const titleRowCount = 2;
    const filterRowCount = 3;

    const headerHeight =
      (hasLogo ? (40 + 20) : 0) +
      (hasTitle ? ((19 * titleRowCount) + 10) : 0) +
      (hasFilters ? ((13 * filterRowCount) + 10) : 0) +
      (hasIndicatorNote ? (10 + 10) : 0)
      + 10;

    let imgHeight, imgWidth, imgMargins;

    const canvasWHRatio = canvas.width / canvas.height;

    if (sheetOrientation !== PDF_ORIENTATION_HORIZONTAL) {
      imgWidth = pageShortSize - pageMargin - pageMargin;
      imgHeight = imgWidth / canvasWHRatio;

      const freeSpaceHeight = pageLongSize - pageMargin - pageMargin - headerHeight;
      if (imgHeight > freeSpaceHeight) {
        const scale = freeSpaceHeight / imgHeight;
        imgHeight *= scale;
        imgWidth *= scale;
      }

      imgMargins = [((pageShortSize - pageMargin - pageMargin - imgWidth) / 2), 0, 0, 0];

    } else {
      imgHeight = pageShortSize - pageMargin - pageMargin - headerHeight;
      imgWidth = imgHeight * canvasWHRatio;

      const freeSpaceWidth = pageLongSize - pageMargin - pageMargin;
      if (imgWidth > freeSpaceWidth) {
        const scale = freeSpaceWidth / imgWidth;
        imgHeight *= scale;
        imgWidth *= scale;
      }

      imgMargins = [((pageLongSize - pageMargin - pageMargin - imgWidth) / 2), 0, 0, 0];
    }

    // adding map or chart canvas
    pdfContent.push({
      image: canvas.toDataURL("image/png"),
      width: imgWidth,
      height: imgHeight,
      margin: imgMargins
    });

    const addLogoFromUrl = (url, callback) => {
      const img = new Image();
      // img.crossOrigin = 'Anonymous';
      img.onload = function () {
        let canvas = document.createElement('canvas');
        let dataURL;
        canvas.height = this.height;
        canvas.width = this.width;
        canvas.getContext('2d').drawImage(this, 0, 0);
        dataURL = canvas.toDataURL("image/png");
        pdfContent = [
          {
            image: dataURL,
            fit: [240, 40],
            margin: [0, 0, 0, 20]
          },
          ...pdfContent
        ]
        callback();
        canvas = null;
      };
      img.onerror = function () {
        callback();
      }
      img.src = url;
    };

    const printPdf = () => {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      const pdf = pdfMake.createPdf({
        pageSize: "A4",
        pageMargins: pageMargin,
        content: pdfContent,
        pageOrientation: sheetOrientation === PDF_ORIENTATION_HORIZONTAL
          ? "landscape"
          : "portrait",
        footer: showDate
          ? {
            columns: [{
              text: t('utils.download.pdf.date') + ': ' + moment().format("DD/MM/YYYY HH:mm:ss"),
              alignment: 'right',
              fontSize: 10,
            }],
            margin: [0, 5, 50, 0]
          }
          : undefined,
        defaultStyle: {
          fontSize: 11
        },
        styles: {
          text: {
            margin: [0, 0, 0, 10]
          },
          title: {
            fontSize: 16
          },
          note: {
            fontSize: 8
          },
          bold: {
            bold: true
          },
          italic: {
            italics: true
          }
        }
      });
      pdf.download(fileName);

      if (onExportComplete) {
        onExportComplete();
      }
    };

    if (hasLogo) {
      // adding logo and saving pdf file
      addLogoFromUrl(logoURL, printPdf);

    } else {
      // saving pdf file
      printPdf();
    }

  }

  $(imageContent).remove();
};

const getCustomExportCommonParams = (origLayout, params, defaultLanguage, languages, t) => {
  const layout = Object.assign({}, origLayout);

  if (params?.exportOnlyCurrentView === false) {
    layout.filtersValue = null;
    layout.primaryDimValues = null;
    layout.secondaryDimValues = null;
  }

  return {
    layout: layout,
    parameters: {
      decimalNumber: params?.decimalNumber || null,
      decimalSeparator: params?.decimalSeparator || null,
      emptyCellPlaceHolder: params?.emptyCellPlaceHolder || null,
      labelFormat: params?.labelFormat || null,
      customLabelFormat: params?.customLabelFormat || null,
      hiddenDimensionValueLabels: localizeI18nObj(params.hiddenDimensionValueLabels, defaultLanguage, languages),
      suffixToRemove: params?.suffixToRemove || null,
      csvSeparator: params?.colsSeparator || null,
      csvTextQualifier: params?.textQualifier || null,
      customDimensionLabels: params?.customDimensionLabels || null,
      invertTimeDimension: params?.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC,
      datasetAttributesLabel: t("commons.export.custom.attributes.values.dataset"),
      seriesAttributesLabel: t("commons.export.custom.attributes.values.series"),
      indicatorNote: t("commons.export.custom.indicatorNote.label"),
      excelLimitMessage: t("commons.export.custom.messages.excel.colsAndRowsLimitsExceeded"),
      excelRowsLimitMessage: t("commons.export.custom.messages.excel.rowsLimitsExceeded", {rowsLimit: params?.exportConfig?.maxRowsPerSheet}),
      excelColumnsLimitMessage: t("commons.export.custom.messages.excel.colsLimitsExceeded", {colsLimit: params?.exportConfig?.maxColsPerSheet}),
      observationValueLabel: t("commons.export.custom.messages.csv.observationLabel")
    },
    variation: {
      hasVariation: params?.hasVariation || false,
      showTrend: params?.showTrend || null,
      showCyclical: params?.showCyclical || null,
      variationLabel: t("commons.export.custom.variation.title"),
      valueLabel: t("commons.export.custom.variation.values.value"),
      trendLabel: t("commons.export.custom.variation.values.trend"),
      cyclicalLabel: t("commons.export.custom.variation.values.cyclical")
    }
  };
};

export const getCustomExportRequestBody = (datasetId, datasetTitle, criteria, origLayout, params, defaultLanguage, languages, t) => {
  const layout = Object.assign({}, origLayout);

  if (params?.hasVariation && (params?.showTrend || params?.showCyclical) && origLayout.cols) {
    layout.cols = [...origLayout.cols, VARIATION_DIMENSION_KEY];
  }

  return {
    data: {
      datasetId: datasetId,
      datasetLabel: datasetTitle,
      dataCriterias: getCriteriaArrayFromObject(criteria)
    },
    ...getCustomExportCommonParams(layout, params, defaultLanguage, languages, t)
  };
};

export const getCustomExportRequestBodyWithMarginal = (datasets, indicators, timeDim, origLayout, params, defaultLanguage, languages, t) => {
  const layout = Object.assign({}, origLayout);

  let marginalDefinition, indicatorDefinitions;

  if (indicators.length === 0) {
    marginalDefinition = {
      dimensions: [datasets.map(({territoryDim}) => territoryDim), datasets.map(({timeDim}) => timeDim)],
      dataflowItems: datasets.map(dataset => {

        const newCriteria = {
          ...dataset.criteria,
          [dataset.territoryDim]: {
            ...datasets[0].criteria[datasets[0].territoryDim],
            id: dataset.territoryDim
          }
        };

        return {
          nodeId: dataset.nodeId,
          dataflowId: dataset.datasetId,
          dataCriterias: getCriteriaArrayFromObject(newCriteria)
        };
      })
    };
    layout.cols = [timeDim, MARGINAL_DIMENSION_KEY];

  } else {
    indicatorDefinitions = getIndicatorsDefinition(indicators, datasets, timeDim, defaultLanguage, languages);
  }

  return {
    marginalDefinition: marginalDefinition,
    indicatorDefinitions: indicatorDefinitions,
    ...getCustomExportCommonParams(layout, params, defaultLanguage, languages, t)
  };
};