import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {compose} from "redux";
import {Box, FormControl, Grid, MenuItem, TextField, Tooltip, withStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import I18nTextField from "../../i18n-text-field";
import { validateI18nObj } from "../../../utils/i18n";
import { INDICATOR_AVERAGE_ANNUAL_GROWTH_RATE, INDICATOR_AVERAGE_ANNUAL_PERCENTAGE_VARIATION, INDICATOR_PERCENTAGE_VARIATION, YEAR_SELECT_WIDTH } from "./constants";
import { getIndicatorDefinition } from "./utils";
import { getDimensionLabel, getFormattedDimensionValueLabel } from "../../../utils/jsonStat";
import { LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME } from "../../label-format-selector/constants";
import CustomEmpty from "../../custom-empty";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  tooltip: {
    fontSize: 13,
  },
  tooltipElement: {
    marginBottom: 4,
    fontWeight: "normal",
  },
});

const VariationAndGrowthRates = (
  {
    classes,
    dataset,
    datasetId,
    years,
    nodeCode,
    timeDim,
    combinations,
    variables,
    variablesDataset
  },
  ref
) => {

  const {t} = useTranslation();

  const {register, errors, handleSubmit, watch, setValue, getValues} = useForm({
    defaultValues: {
      title: {},
      year: "",
      indicator:"",
      statistic:"",
      startYear: "",
      endYear: ""
    },
  });  

  const [reversedYearValues, setReversedYearValues] = useState(null);
  const [statistics, setStatistics] = useState([]);
  const [indicators, setIndicators] = useState(null);
  const [startYearValues, setStartYearValues] = useState([]);
  const [endYearValues, setEndYearValues] = useState([]);

  const handleStatisticChange = useCallback((selectedStatistic, statistics) => {
    if(selectedStatistic && statistics) {
      setValue("statistic", selectedStatistic);
      const values = selectedStatistic.split("@");
      const datasetId = values[0];
      const statisticKey = values[1];      
      const statistic = statistics[datasetId][statisticKey];
      setStartYearValues(statistic.years);
      setEndYearValues(statistic.years);
      const l = statistic.years.length;
      setValue("startYear", statistic.years[l - 2]);
      setValue("endYear", statistic.years[l -1]);
    }      
  }, [setValue]);

  useEffect(() => {
    if (variablesDataset && variables) {

      const datasets = [...new Set(Object.values(variablesDataset))];

      const allStatistics = {};
      datasets.forEach(datasetId => {
        allStatistics[datasetId] = {};
      });

      Object.keys(variables || {})
        .forEach(key => {
          const variable = variables[key];
          const timeValue = variable[timeDim];
          const datasetId = variablesDataset[key];
          const noTimeCombinationArray = [];
          const combinationLabelArray = [];
          const dimArray = [];

          Object.keys(variable).filter(dim => dim !== timeDim).forEach(dim => {
            dimArray.push(dim);
            noTimeCombinationArray.push(variable[dim]);
            const label = getFormattedDimensionValueLabel(dataset, datasetId, dim, variable[dim], LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME, t);
            combinationLabelArray.push(label);
          })
         
          const noTimeCombinationStr = noTimeCombinationArray.join(",");         
          const datasetStatistics = allStatistics[datasetId];
          if (!(noTimeCombinationStr in datasetStatistics)) {
            datasetStatistics[noTimeCombinationStr] = {
              dimensionsLabels: combinationLabelArray,
              dimensions: dimArray,
              years: [timeValue],
              variables: [key]
            };
          } else {
            datasetStatistics[noTimeCombinationStr].years.push(timeValue);
            datasetStatistics[noTimeCombinationStr].variables.push(key);
          }
	      });

      setStatistics(allStatistics);
    }
  }, [variablesDataset, variables, timeDim, dataset, setStatistics, t]);

  useEffect(() => {
    if ((Object.keys(statistics) || []).length > 0) {
      const datasetId = Object.keys(statistics)[0];
      const combination = Object.keys(statistics[datasetId])[0];
      handleStatisticChange(`${datasetId}@${combination}`, statistics);   
    }
  }, [statistics, handleStatisticChange]);

  useEffect(() => {
    setReversedYearValues(years.slice().reverse());
  }, [years, setReversedYearValues]);

  useEffect(() => {
    if ((reversedYearValues || []).length > 0) {
      setValue("year", reversedYearValues[0]);
    }
  }, [reversedYearValues, setValue]);

  useEffect(() => {
    const indicators = [
      {
        value:INDICATOR_PERCENTAGE_VARIATION,
        label:t("components.indicatorDialogs.create.form.fields.indicator.percentageVariation.label")
      },
      {
        value:INDICATOR_AVERAGE_ANNUAL_PERCENTAGE_VARIATION,
        label:t("components.indicatorDialogs.create.form.fields.indicator.avgAnnualPercentageVariation.label")
      },
      {
        value:INDICATOR_AVERAGE_ANNUAL_GROWTH_RATE,
        label:t("components.indicatorDialogs.create.form.fields.indicator.avgAnnualGrowthRate.label")
      },
    ];
    setIndicators(indicators);
  }, [setIndicators, t]);

  useEffect(() => {
    if ((indicators || []).length > 0) {
      setValue("indicator", indicators[0].value);
    }
  }, [indicators, setValue]);


  useEffect(() => {
    register(
      {
        name: "title",
      },
      {
        validate: val =>
          validateI18nObj(val) || t("commons.validation.requiredAnyLanguage"),
      }
    );
    register(
      {
        name: "year",
      },
      {
        required: t("commons.validation.required"),
      }
    );
    register(
      {
        name: "indicator",
      },
      {
        required: t("commons.validation.required"),
      }
    );
    register(
      {
        name: "statistic",
      },
      {
        required: t("commons.validation.required"),
      }
    );  
    register(
      {
        name: "startYear",
      },
      {
        required: t("commons.validation.required"),
      }
      
    );
    register(
      {
        name: "endYear",
      },
      {
        required: t("commons.validation.required"),
      }
    );  
    
  }, [register,  t]);


  useImperativeHandle(ref, () => ({
    submit(f) {
      handleSubmit(variationAndGrowthRatesFormData => {
        const indicator = getIndicatorDefinition(variationAndGrowthRatesFormData, statistics);    
        f(indicator);
      })();
    },
    cancel(f) {
      f();
    }
  }));

  if (!combinations) {
    return (
      <CustomEmpty
        text={t("components.indicatorDialogs.create.form.loading") + "..."}
        image={<CircularProgress/>}
      />
    )
  }

  return (
    <Box style={{height: "calc(100% - 148px)"}}>
      <Grid container spacing={2} style={{marginBottom: 16}}>
        <Grid item style={{width: `calc(100% - ${YEAR_SELECT_WIDTH}px)`}}>
          <FormControl fullWidth>
            <I18nTextField
              name="title"
              label={t("components.indicatorDialogs.create.form.fields.title.label")}
              value={watch("title") || {}}
              onChange={value => setValue("title", value)}
              error={!!errors.title}
              helperText={errors.title?.message}
              required
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item style={{width: YEAR_SELECT_WIDTH}}>
          <FormControl fullWidth>
            <TextField
              select
              name="year"
              label={t("components.indicatorDialogs.create.form.fields.year.label")}
              value={watch("year")}
              onChange={ev => setValue("year", ev.target.value)}
              error={!!errors.year}
              helperText={errors.year?.message}
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(reversedYearValues || []).map(v =>
              
                <MenuItem value={v} key={v}>{v}</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginBottom: 16}}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              select
              name="indicator"
              label={t("components.indicatorDialogs.create.form.fields.indicator.label")}
              value={watch("indicator")}
              onChange={ev => setValue("indicator", ev.target.value)}
              error={!!errors.indicator}
              helperText={errors.indicator?.message}
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(indicators || []).map((indicator, idx) => 
                <MenuItem key={idx} value={indicator.value}>{indicator.label}</MenuItem>
              )}        
            </TextField>
          </FormControl>
        </Grid>        
      </Grid>
      <Grid container spacing={2} style={{marginBottom: 16}}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              select
              name="statistic"
              label={t("components.indicatorDialogs.create.form.fields.statistic.label")}
              value={watch("statistic")}
              onChange={ev => handleStatisticChange(ev.target.value, statistics)}
              error={!!errors.statistic}
              helperText={errors.statistic?.message}
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(Object.keys(statistics) || []).map(datasetId => {
                const combinations = statistics[datasetId];
                return (
                  (Object.keys(combinations) || []).map(c => {
                    const key = `${datasetId}@${c}`
                    return (
                      <MenuItem 
                        key={key} 
                        value={key}
                      > 
                        <Tooltip
                          key={`Tooltip_${key}`}
                          title={
                            <div className={classes.tooltip}>
                              {combinations[c].dimensions.map((dim, idx) =>(
                                  <div key={idx} className={classes.tooltipElement}>
                                    <b>{getDimensionLabel(dataset, datasetId, dim)}</b>: <i>{combinations[c].dimensionsLabels[idx]}</i>
                                  </div>
                                )
                                )
                              }                           
                            </div>
                          }
                        >     
                          <div>
                            {combinations[c].dimensionsLabels.join(", ")}
                          </div>              
                        </Tooltip>
                      </MenuItem>
                    );
                  }));  
                }                                  
              )}  
            </TextField>
          </FormControl>
        </Grid>        
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              select
              name="startYear"
              label={t("components.indicatorDialogs.create.form.fields.startYear.label")}
              value={watch("startYear")}
              onChange={ev => setValue("startYear", ev.target.value)}
              error= { getValues("endYear") <= getValues("startYear")}
              helperText={getValues("endYear") <= getValues("startYear")
                ? t("components.indicatorDialogs.create.form.fields.indicator.variation.years.helperText")
                : null
              }
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(startYearValues || []).map(v =>
                <MenuItem value={v} key={v}>{v}</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              select
              name="endYear"
              label={t("components.indicatorDialogs.create.form.fields.endYear.label")}
              value={watch("endYear")}
              onChange={ev => setValue("endYear", ev.target.value)}
              required
              variant="outlined"
              fullWidth
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {(endYearValues || []).map(v =>
                <MenuItem value={v} key={v}>{v}</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default compose(
  withStyles(styles),
  forwardRef
  )(VariationAndGrowthRates);
