import {Reducer} from "redux";
import {REQUEST_ERROR, REQUEST_SUCCESS} from "../../middlewares/request/requestActions";
import {DASHBOARDS_DASHBOARD_FETCH, DASHBOARDS_DASHBOARDS_CLEAR, DASHBOARDS_DATASET_FILTER_APPLY, DASHBOARDS_DATASET_FILTER_SET} from "./dashboardActions";
import {DASHBOARD_ELEM_FILTER_DIMENSION_KEY, DASHBOARD_ELEM_TYPE_KEY, DASHBOARD_ELEM_TYPE_VALUE_VIEW, getViewIdxFromRowAndCol} from "../../utils/dashboards";
import {getCriteriaObjectFromArray} from "../../utils/criteria";
import {
  FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_ERROR,
  FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_INIT,
  FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_RESET,
  FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_SUCCESS
} from "../../middlewares/fetch-dashboard-dataset-async-handler/actions";
import _ from "lodash";
import {getUpdatedLayout} from "../../utils/jsonStat";

export const DASHBOARD_VIEW_STATE_APPLY_FILTERS = "DASHBOARD_VIEW_STATE_APPLY_FILTERS"
export const DASHBOARD_VIEW_STATE_FETCHING = "DASHBOARD_VIEW_STATE_FETCHING"
export const DASHBOARD_VIEW_STATE_ERROR = "DASHBOARD_VIEW_STATE_ERROR"

type DashboardState = {
  dashboards: any | null,
  fetchedDashboards: { [key: string]: { [key: string]: boolean } },
  jsonStats: any | null,
  layouts: any | null,
  filterTrees: any | null,
  timePeriodsByFreq: any | null,
  dynamicViewPendingRequests: string[]
}

const initialState = {
  dashboards: null,
  fetchedDashboards: {},
  jsonStats: null,
  layouts: null,
  filterTrees: null,
  timePeriodsByFreq: null,
  dynamicViewPendingRequests: []
};

const dashboardReducer: Reducer<DashboardState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DASHBOARDS_DASHBOARDS_CLEAR: {
      return {
        ...initialState
      }
    }
    case DASHBOARDS_DATASET_FILTER_APPLY: {
      const newFetchedDashboards = _.cloneDeep(state.fetchedDashboards);
      Object.keys(newFetchedDashboards).forEach(id => newFetchedDashboards[id] = {
        ...newFetchedDashboards[id],
        dynamic: false
      });

      return {
        ...state,
        fetchedDashboards: newFetchedDashboards
      }
    }
    case FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_INIT: {
      let currentDashboardJsonStats = _.cloneDeep(state.jsonStats?.[action.payload.dashboardId]);
      let currentDashboardLayouts = _.cloneDeep(state.layouts?.[action.payload.dashboardId]);
      let currentDashboardFilterTrees = _.cloneDeep(state.filterTrees?.[action.payload.dashboardId]);
      let currentDashboardTimePeriodsByFreq = _.cloneDeep(state.timePeriodsByFreq?.[action.payload.dashboardId]);

      (state.dashboards?.[action.payload.dashboardId]?.dashboardConfig || []).forEach((row: any, rowIdx: number) => {
        row.forEach((col: any, colIdx: number) => {
          if (col[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW) {
            const viewIdx = getViewIdxFromRowAndCol(rowIdx, colIdx);
            const isStaticView = (col[DASHBOARD_ELEM_FILTER_DIMENSION_KEY] || "").length === 0;

            const jsonStat = currentDashboardJsonStats?.[viewIdx];
            currentDashboardJsonStats = {
              ...currentDashboardJsonStats,
              [viewIdx]: (isStaticView && jsonStat)
                ? jsonStat
                : DASHBOARD_VIEW_STATE_FETCHING
            }

            const layout = currentDashboardLayouts?.[viewIdx];
            currentDashboardLayouts = {
              ...currentDashboardLayouts,
              [viewIdx]: (isStaticView && jsonStat)
                ? layout
                : null
            }

            const filterTree = currentDashboardFilterTrees?.[viewIdx];
            currentDashboardFilterTrees = {
              ...currentDashboardFilterTrees,
              [viewIdx]: (isStaticView && jsonStat)
                ? filterTree
                : null
            }

            const timePeriodsByFreq = currentDashboardTimePeriodsByFreq?.[viewIdx];
            currentDashboardTimePeriodsByFreq = {
              ...currentDashboardTimePeriodsByFreq,
              [viewIdx]: timePeriodsByFreq || null
            }
          }
        });
      });

      const dynamicViewPendingRequests = [...state.dynamicViewPendingRequests];
      if (action.payload.isFetchingDynamicView) {
        dynamicViewPendingRequests.push(action.payload.requestUuid);
      }

      return {
        ...state,
        jsonStats: {
          ...state.jsonStats,
          [action.payload.dashboardId]: currentDashboardJsonStats
        },
        layouts: {
          ...state.layouts,
          [action.payload.dashboardId]: currentDashboardLayouts
        },
        filterTrees: {
          ...state.filterTrees,
          [action.payload.dashboardId]: currentDashboardFilterTrees
        },
        timePeriodsByFreq: {
          ...state.timePeriodsByFreq,
          [action.payload.dashboardId]: currentDashboardTimePeriodsByFreq
        },
        dynamicViewPendingRequests: dynamicViewPendingRequests
      }
    }
    case FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_SUCCESS: {

      const fetchedDashboard = state.fetchedDashboards[action.payload.dashboardId] || {};

      return (state.jsonStats && state.layouts && state.filterTrees && state.timePeriodsByFreq)
        ? {
          ...state,
          fetchedDashboards: {
            ...state.fetchedDashboards,
            [action.payload.dashboardId]: {
              static: !action.payload.isFetchingDynamicView ? true : fetchedDashboard.static,
              dynamic: action.payload.isFetchingDynamicView ? true : fetchedDashboard.dynamic
            }
          },
          jsonStats: {
            ...state.jsonStats,
            [action.payload.dashboardId]: {
              ...state.jsonStats[action.payload.dashboardId],
              ...action.payload.dashboardJsonStats
            }
          },
          layouts: {
            ...state.layouts,
            [action.payload.dashboardId]: {
              ...state.layouts[action.payload.dashboardId],
              ...action.payload.dashboardLayouts
            }
          },
          filterTrees: {
            ...state.filterTrees,
            [action.payload.dashboardId]: {
              ...state.filterTrees[action.payload.dashboardId],
              ...action.payload.dashboardFilterTrees
            }
          },
          timePeriodsByFreq: {
            ...state.timePeriodsByFreq,
            [action.payload.dashboardId]: {
              ...state.timePeriodsByFreq[action.payload.dashboardId],
              ...action.payload.dashboardTimePeriodsByFreq
            }
          }
        }
        : {
          ...state
        }
    }
    case FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_ERROR: {
      const jsonStats = _.cloneDeep(state.jsonStats);
      const layouts = _.cloneDeep(state.layouts);
      const filterTrees = _.cloneDeep(state.filterTrees);
      const timePeriodsByFreq = _.cloneDeep(state.timePeriodsByFreq);

      (action.payload.requestIds || []).forEach((id: string) => {
        if (jsonStats) {
          jsonStats[action.payload.dashboardId] = {
            ...jsonStats[action.payload.dashboardId],
            [id]: DASHBOARD_VIEW_STATE_ERROR
          }
        }
        if (layouts) {
          layouts[action.payload.dashboardId] = {
            ...layouts[action.payload.dashboardId],
            [id]: null
          }
        }
        if (filterTrees) {
          filterTrees[action.payload.dashboardId] = {
            ...filterTrees[action.payload.dashboardId],
            [id]: null
          }
        }
        if (timePeriodsByFreq) {
          timePeriodsByFreq[action.payload.dashboardId] = {
            ...timePeriodsByFreq[action.payload.dashboardId],
            [id]: null
          }
        }
      });

      return {
        ...state,
        jsonStats: jsonStats,
        layouts: layouts,
        filterTrees: filterTrees,
        timePeriodsByFreq: timePeriodsByFreq
      }
    }
    case FETCH_DASHBOARD_DATASET_ASYNC_HANDLER_RESET: {
      const jsonStats = _.cloneDeep(state.jsonStats);
      const layouts = _.cloneDeep(state.layouts);
      const filterTrees = _.cloneDeep(state.filterTrees);
      const timePeriodsByFreq = _.cloneDeep(state.timePeriodsByFreq);

      (action.payload.requestIds || []).forEach((id: string) => {
        if (jsonStats) {
          jsonStats[action.payload.dashboardId] = {
            ...jsonStats[action.payload.dashboardId],
            [id]: DASHBOARD_VIEW_STATE_FETCHING
          }
        }
        if (layouts) {
          layouts[action.payload.dashboardId] = {
            ...layouts[action.payload.dashboardId],
            [id]: null
          }
        }
        if (filterTrees) {
          filterTrees[action.payload.dashboardId] = {
            ...filterTrees[action.payload.dashboardId],
            [id]: null
          }
        }
        if (timePeriodsByFreq) {
          timePeriodsByFreq[action.payload.dashboardId] = {
            ...timePeriodsByFreq[action.payload.dashboardId],
            [id]: null
          }
        }
      });

      return {
        ...state,
        jsonStats: jsonStats,
        layouts: layouts,
        filterTrees: filterTrees,
        timePeriodsByFreq: timePeriodsByFreq
      }
    }
    case DASHBOARDS_DATASET_FILTER_SET: {
      return {
        ...state,
        layouts: {
          ...state.layouts,
          [action.payload.dashboardId]: {
            ...state.layouts[action.payload.dashboardId],
            [action.payload.viewIdx]: {
              ...state.layouts[action.payload.dashboardId][action.payload.viewIdx],
              layout: getUpdatedLayout(
                action.payload.dimension,
                action.payload.value,
                state.jsonStats[action.payload.dashboardId][action.payload.viewIdx],
                state.layouts[action.payload.dashboardId][action.payload.viewIdx].layout,
                state.filterTrees[action.payload.dashboardId][action.payload.viewIdx]
              )
            }
          }
        }
      }
    }
    case REQUEST_SUCCESS: {
      switch (action.payload.label) {
        case DASHBOARDS_DASHBOARD_FETCH: {
          const views = _.cloneDeep(action.payload.response.views);
          for (let key in views) {
            if (views.hasOwnProperty(key)) {
              views[key] = {
                ...views[key],
                datasetId: views[key].datasetId ? views[key].datasetId.split("+").join(",") : undefined,
                criteria: getCriteriaObjectFromArray(views[key].criteria),
                layouts: JSON.parse(views[key].layouts)
              }
            }
          }

          return {
            ...state,
            dashboards: {
              ...state.dashboards,
              [action.payload.extra.dashboardId]: {
                ...action.payload.response,
                dashboardConfig: action.payload.response?.dashboardConfig
                  ? JSON.parse(action.payload.response.dashboardConfig)
                  : {},
                filterLevels: action.payload.response?.filterLevels
                  ? JSON.parse(action.payload.response.filterLevels)
                  : {},
                views: views
              }
            }
          }
        }
        default:
          return state
      }
    }
    case REQUEST_ERROR: {
      switch (action.payload.label) {
        case DASHBOARDS_DASHBOARD_FETCH: {
          return {
            ...state,
            dashboards: {
              ...state.dashboards,
              [action.payload.extra.dashboardId]: null
            }
          }
        }
        default:
          return state
      }
    }
    default:
      return state
  }
};

export default dashboardReducer;