import {getViewerTypeFromIdx} from "../../components/data-viewer/constant";
import {getCriteriaArrayFromObject} from "../../utils/criteria";
import {getViewTemplateLayoutsFromChartSettings, getViewTemplateLayoutsFromMapSettings} from "../../utils/viewTemplate";
import {localizeI18nObj} from "../../utils/i18n";
import {ICategoryProvider} from "../../model/ICategoryProvider";

export enum ViewerMode {
  SingleViewer = "SINGLE_VIEWER",
  MultiViewer = "MULTI_VIEWER",
  Error = "VIEWER_ERROR"
}

export type Criteria = {
  id: string;
  type: string;
  filterValues: string[] | null | undefined;
  period: number | null | undefined;
  from: string | null | undefined;
  to: string | null | undefined;
};

export type TimePeriod = {
  selectorType: string;
  freq: string | null;
  minDate: string | null;
  maxDate: string | null;
  fromDate: string | null;
  toDate: string | null;
  periods: number | null;
  missingRange: boolean;
};

type ChartSettings = {
  stacked?: boolean;
  legendPosition?: string;
  showAxesLabel?: boolean;
  dataLabelType?: string;
  colors?: any;
  customizeCategoryAxis?: boolean;
  categoryAxisLabel?: {[key: string]: string};
  valueAxisLabel?: {[key: string]: string};
};

export type MapSettings = {
  baseLayer?: string | null;
  classificationMethod?: string;
  paletteStartColor?: string;
  paletteEndColor?: string;
  paletteCardinality?: number;
  opacity?: number;
  isLegendCollapsed?: boolean;
  customIntervals?: any[] | null;
};

export type IndicatorVariable = {[key: string]: string};

export type Indicator = {
  title: {[key: string]: string};
  year: string;
  formula: string[];
  variables: {[key: string]: IndicatorVariable};
  variablesDataset: {[key: string]: string};
};

export type AdditionalDataset = {
  nodeId: number;
  nodeCode: string;
  datasetId: string;
  datasetTitle: string;
  dimensions: any[] | null;
  timeDim: string | null;
  freqDim: string | null;
  territoryDim: string | null;
  codelists: {[key: string]: any[]} | null;
  codelistsLength: (number | null)[] | null;
  criteria: {[key: string]: Criteria};
  detailLevels: number[];
};

export type DatasetState = {
  datasetId: string | null;
  nodeCode: string | null;
  categoryPath: string[] | null;
  viewId: string | null;
  dataset: any | null;
  isFirstFetch: boolean;
  datasetFetchStart: boolean;
  datasetFetchError: boolean;
  viewerMode: ViewerMode | null;
  viewerModeBackup: ViewerMode | null;
  isPartialData: boolean;
  isEmptyData: boolean;
  isTooBigData: boolean;
  isPointData: boolean;
  latAttributeId: string | null;
  longAttributeId: string | null;
  srid: string | null;
  isTooLongQuery: boolean;
  tableColCount: number | null;
  mapPointCount: number | null;
  isCriteriaVisible: boolean;
  isCriteriaAlertVisible: boolean;
  isObsCountWarningVisible: boolean;
  dimensions: any[] | null;
  territoryDimCodelist: string | null;
  territoryDim: string | null;
  timeDim: string | null;
  freqDim: string | null;
  pointDim: string | null;
  measureDim: string | null;
  mode: string | null;
  type: string | null;
  viewerIdx: number | null;
  isTableVisible: boolean | null;
  isMapVisible: boolean | null;
  isChartVisible: boolean | null;
  chartType: string;
  view: any | null;
  template: any | null;
  hasViewLayout: boolean;
  hasTemplateLayout: boolean;
  hasAnnotationLayout: boolean;
  tableLayout: any | null;
  mapLayout: any | null;
  chartLayout: any | null;
  tableFilterTree: any | null;
  mapFilterTree: any | null;
  chartFilterTree: any | null;
  timePeriodsByFreq: any | null;
  labelFormat: string | null;
  temporalDimOrder: string | null;
  showTrend: boolean;
  showCyclical: boolean;
  criteria: {[key: string]: Criteria};
  initialCriteria: {[key: string]: Criteria};
  decimalSeparator: string | null;
  decimalPlaces: number | null;
  tableEmptyChar: string;
  chartSettings: ChartSettings;
  mapSettings: MapSettings;
  enableCriteria: boolean;
  enableLayout: boolean;
  enableVariation: boolean;
  maxAllowedCells: number;
  codelists: {[key: string]: any[]} | null;
  codelistsLength: (number | null)[] | null;
  codelistFetchError: boolean;
  criteriaObsCount: number | null;
  timings: any | null;
  isFetchStructureDisabled: boolean;
  isFetchDatasetDisabled: boolean;
  isDownloadWarningVisible: boolean;
  isUnavailableViewWarningVisible: boolean;
  backup: boolean;
  isViewVisible: boolean;
  isViewErrorVisible: boolean;
  viewErrorMessage: string | null;
  isTemplateVisible: boolean;
  isQueryVisible: boolean;
  structureQuery: string | null;
  dataQuery: string | null;
  isTerritoryVisible: boolean;
  detailLevelTree: any | null;
  detailLevel: number | null;
  territoryTree: any[] | null;
  territories: string[] | null;
  lastTerritoryYear: string | null;
  isIndicatorCreateVisible: boolean;
  isIndicatorListVisible: boolean;
  indicators: Indicator[];
  indicatorPreview: any;
  missingIndicators: string[] | null;
  showArithmeticMean: boolean;
  arithmeticMeanDims: string[] | null;
  arithmeticMeans: {[key: string]: number} | null;
  showStandardDeviation: boolean;
  showCoefficientOfVariation: boolean;
  isAdditionalDatasetCreateVisible: boolean;
  isAdditionalDatasetListVisible: boolean;
  additionalDatasets: AdditionalDataset[];
  additionalDatasetCatalog: ICategoryProvider | null;
  additionalDataset: AdditionalDataset | null;
  datasetCacheInfo: any | null;
  isFullscreen: boolean;
  isTableEnabled: boolean;
  isMapEnabled: boolean;
  isChartEnabled: boolean;
  tableLockedDimensions: string[] | null;
  graphLockedDimensions: string[] | null;
  missingFilterValues: any[] | null;
  geometriesYear: string | null;
};

export const getViewTemplateBackupFromState = (state: DatasetState) => ({
  mode: state.viewerMode,
  defaultView: getViewerTypeFromIdx(state.viewerIdx),
  criteria: getCriteriaArrayFromObject(state.criteria),
  layouts: JSON.stringify({
    detailLevel: state.detailLevel,
    territories: state.territories,
    labelFormat: state.labelFormat,
    temporalDimOrder: state.temporalDimOrder,
    showTrend: state.showTrend,
    showCyclical: state.showCyclical,
    tableLayout: state.tableLayout,
    tableEmptyChar: state.tableEmptyChar,
    mapLayout: state.mapLayout,
    ...getViewTemplateLayoutsFromMapSettings(state.mapSettings),
    chartLayout: state.chartLayout,
    ...getViewTemplateLayoutsFromChartSettings(state.chartSettings),
    isTableVisible: state.isTableVisible,
    isMapVisible: state.isMapVisible,
    isChartVisible: state.isChartVisible,
    chartType: state.chartType,
    showArithmeticMean: state.showArithmeticMean,
    showStandardDeviation: state.showStandardDeviation,
    showCoefficientOfVariation: state.showCoefficientOfVariation
  }),
  indicatorsDefinition: JSON.stringify(state.indicators),
  additionalDatasets: JSON.stringify(state.additionalDatasets)
});

export const getIndicatorsDefinition = (
  indicators: Indicator[],
  datasets: any[],
  timeDim: string,
  defaultLanguage: string,
  languages: string[]
) => {

  return indicators.map((indicator: Indicator) => {

    const {
      title,
      year,
      formula,
      variables,
      variablesDataset
    } = indicator;

    return {
      name: localizeI18nObj(title, defaultLanguage, languages),
      formula: formula.map(el => el.length > 1 ? "[" + el + "]" : el).join(""),
      indicatorJoinDimensions: [datasets.map(({territoryDim}) => territoryDim)],
      targetValue: year,
      targetDimension: timeDim,
      dataflowFormulaItems: datasets.map(dataset => {
        const filteredVariables: {[key: string]: IndicatorVariable} = {};
        Object.keys(variables).forEach(variable => {
          if (variablesDataset[variable] === `${dataset.nodeCode},${dataset.datasetId}`) {
            filteredVariables[variable] = variables[variable];
          }
        });

        const newCriteria = {
          ...dataset.criteria,
          [dataset.territoryDim]: {
            ...datasets[0].criteria[datasets[0].territoryDim],
            id: dataset.territoryDim
          }
        };

        return {
          nodeId: dataset.nodeId,
          dataflowId: dataset.datasetId,
          formulaItems: Object.keys(filteredVariables).map(itemName => ({
            itemName: itemName,
            dimensionValues: Object.keys(filteredVariables[itemName]).map(id => ({
              id: id,
              value: filteredVariables[itemName][id]
            }))
          })),
          dataCriterias: getCriteriaArrayFromObject(newCriteria),
          dataflowJoinDimensions: [dataset.territoryDim]
        };
      })
    };
  });
};

export const getMarginalDefinition = (datasets: any[]) => {
  const territoryDims: string[] = [];
  const timeDims: string[] = [];

  const dataflowItems: any[] = [];

  datasets.forEach(dataset => {
    territoryDims.push(dataset.territoryDim);
    timeDims.push(dataset.timeDim);

    const newCriteria = {
      ...dataset.criteria,
      [dataset.territoryDim]: {
        ...datasets[0].criteria[datasets[0].territoryDim],
        id: dataset.territoryDim
      }
    };

    dataflowItems.push({
      nodeId: dataset.nodeId,
      dataflowId: dataset.datasetId,
      dataCriterias: getCriteriaArrayFromObject(newCriteria)
    });
  });

  return {
    dimensions: [territoryDims, timeDims],
    dataflowItems: dataflowItems
  };
};