import React from 'react';
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {CHART_TYPE_PYRAMID} from "../chart";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import {viewersFactory} from "../data-viewer/constant";
import {getDimensionLabel} from "../../utils/jsonStat";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%"
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    "& :last-child": {
      marginBottom: 0
    }
  },
  field: {
    marginBottom: theme.spacing(3)
  },
  dimTitle: {
    fontSize: 16,
    fontWeight: "bold",
    width: "calc(50% - 36px)"
  },
  dimTitleSpacer: {
    width: 72
  },
  dimValue: {
    width: "calc(50% - 32px)",
    fontSize: 16,
    padding: 16
  },
  dimSwapper: {
    margin: "0 8px",
    height: "56px",
    lineHeight: "56px"
  }
});

function ChartSettingsLayout(props) {
  const {
    t,
    classes,
    jsonStat,
    layout,
    onLayoutSet,
    type,
    onTypeSet
  } = props;

  return (
    <div className={classes.root}>
      <FormControl fullWidth className={classes.field}>
        <TextField
          select
          label={t("components.chartSettings.layout.chartType.label")}
          variant="outlined"
          value={type}
          onChange={ev => onTypeSet(ev.target.value)}
          SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
        >
          {viewersFactory(t).slice(2).filter(({type}) => type !== CHART_TYPE_PYRAMID).map(({type, title}, idx) =>
            <MenuItem key={idx} value={type}>{title}</MenuItem>
          )}
        </TextField>
      </FormControl>
      {layout.primaryDim[0] && layout.secondaryDim[0] && (
        <Paper variant="outlined" className={classes.paper}>
          <Grid container className={classes.field}>
            <div className={classes.dimTitle}>
              {t("components.chartSettings.layout.primaryDim.label")}
            </div>
            <div className={classes.dimTitleSpacer}/>
            <div className={classes.dimTitle}>
              {t("components.chartSettings.layout.secondaryDim.label")}
            </div>
          </Grid>
          <Grid container className={classes.field}>
            <Paper variant="outlined" className={classes.dimValue}>
              {getDimensionLabel(jsonStat, null, layout.primaryDim[0])}
            </Paper>
            <div className={classes.dimSwapper}>
              <IconButton
                onClick={() => onLayoutSet({
                  ...layout,
                  primaryDim: layout.secondaryDim,
                  primaryDimValues: layout.secondaryDimValues,
                  secondaryDim: layout.primaryDim,
                  secondaryDimValues: layout.primaryDimValues
                })}
              >
                <SwapHorizIcon/>
              </IconButton>
            </div>
            <Paper variant="outlined" className={classes.dimValue}>
              {getDimensionLabel(jsonStat, null, layout.secondaryDim[0])}
            </Paper>
          </Grid>
        </Paper>
      )}
    </div>
  )
}

export default compose(
  withStyles(styles),
  withTranslation()
)(ChartSettingsLayout);