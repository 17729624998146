import React from 'react';
import {withTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import I18nTextField from "../i18n-text-field";
import Alert from "@material-ui/lab/Alert";
import {CRITERIA_FILTER_TYPE_PERIODS} from "../../utils/criteria";

const ViewBuilder = ({
                       t,
                       view,
                       onChange,
                       criteria,
                       timeDim
                     }) => {

  if (!view) {
    return <span/>;
  }

  return (
    <Grid container spacing={2}>
      {criteria?.[timeDim]?.type === CRITERIA_FILTER_TYPE_PERIODS && (
        <Grid item xs={12}>
          <Alert severity="warning">{t("scenes.dataViewer.warnings.viewTemplateLastNPeriods.label")}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <I18nTextField
            label={t("scenes.dataViewer.viewBuilder.form.title.label")}
            required
            variant="outlined"
            value={view.title}
            onChange={value => onChange({...view, title: value})}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(ViewBuilder);