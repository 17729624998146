const ACTION_EXTRAS = 'ACTION_EXTRAS';
const CURRENT_NODE_CONFIG = 'CURRENT_NODE_CONFIG';

export const getActionExtras = action => action[ACTION_EXTRAS];
export const getCurrentNodeConfig = action => action[CURRENT_NODE_CONFIG];

const actionDecoratorMiddlewareFactory = t => ({getState}) => next => action => {

  const state = getState();

  let decoratedAction = {
    ...action,
    [ACTION_EXTRAS]: {
      language: state?.app?.language,
      languages: state?.app?.languages,
      t: t,
      mapDefaultSettings: state?.appConfig?.mapDefaultSettings,
      chartDefaultSettings: state?.appConfig?.chartDefaultSettings
    },
    [CURRENT_NODE_CONFIG]: state?.node
  }

  return next(decoratedAction);

};

export default actionDecoratorMiddlewareFactory;
