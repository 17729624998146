import {Reducer} from "redux";
import {APP_IS_A11Y_SET, APP_LANGUAGE_SET} from "./appActions";
import {INIT} from '../rootActions';
import _ from "lodash";

export type ThemeConfig = {
  isMultiViewer: boolean;
  showDatasetListInCategoriesTree: boolean;
  defaultFreq: string | undefined;
  componentsVariant: {[key: string]: string} | undefined;
  appConfig: {[key: string]: any} | undefined;
}

export type AppState = {
  language: string,
  languages: string[],
  isA11y: boolean,
  footers: string,
  modulesConfig: any,
  themeConfig: ThemeConfig
} | null;

const appReducer: Reducer<AppState> = (
  state = null,
  action
) => {
  switch (action.type) {
    case INIT:
      const initialState = {
        language: action.payload.defaultLanguage,
        languages: action.payload.supportedLanguages,
        isA11y: false,
        footers: action.payload.footers,
        modulesConfig: action.payload.modulesConfig,
        themeConfig: action.payload.themeConfig
      };
      return _.merge(initialState) || initialState;
    case APP_LANGUAGE_SET: {
      return {
        ...state,
        language: action.payload.language
      };
    }
    case APP_IS_A11Y_SET: {
      return {
        ...state,
        isA11y: action.payload.isA11y
      };
    }
    default:
      return state;
  }
};

export default appReducer;