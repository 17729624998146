import React, {Fragment} from "react";
import {ViewerMode} from "../../state/dataset/constants";
import SingleViewerFooter from "./single-viewer/Footer";

function DataViewerFooter(props) {

  const {viewerMode} = props;

  return (
    <Fragment>
      {viewerMode === ViewerMode.SingleViewer && (
        <SingleViewerFooter
          {...props}
        />
      )}
    </Fragment>
  );
}

export default DataViewerFooter;