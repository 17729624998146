import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import DashboardRow from "./DashboardRow";
import CustomEmpty from "../custom-empty";

function Dashboard(props) {
  const {
    dashboardId,
    dashboard: externalDashboard,
    filterValue,
    jsonStats,
    layouts,
    filterTrees,
    timePeriodsByFreq,
    onFilterSet,
    onDownloadSubmit
  } = props;

  const {t} = useTranslation();
  const [dashboard, setDashboard] = useState(externalDashboard);

  useEffect(() => {
    setDashboard(prevDashboard => {
      if (_.isEqual(prevDashboard, externalDashboard)) {
        return prevDashboard
      } else {
        return externalDashboard
      }
    });
  }, [externalDashboard]);

  return (
    <Fragment>
      {dashboard
        ? (
          <Fragment>
            {(dashboard.dashboardConfig || []).map((row, idx) =>
              <DashboardRow
                key={idx}
                dashboardId={dashboardId}
                dashboard={dashboard}
                filterValue={filterValue}
                row={row}
                rowIdx={idx}
                jsonStats={jsonStats}
                layouts={layouts}
                filterTrees={filterTrees}
                timePeriodsByFreq={timePeriodsByFreq}
                onSelect={onFilterSet}
                onDownloadSubmit={onDownloadSubmit}
              />
            )}
          </Fragment>
        )
        : (
          <CustomEmpty
            text={t("components.dashboard.errors.fetchDashboard")}
            style={{height: 400}}
          />
        )}
    </Fragment>
  )
}

export default Dashboard;