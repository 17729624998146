import React, {Component} from "react";
import Data from "../components/data";
import {connect} from "react-redux";
import {
  enableDatasetStructureFetch,
  fetchDatasetStructure,
  fetchDatasetTerritoryDetailLevels,
  resetDatasetState,
  setDatasetTerritoryDetailLevel,
  setDatasetTerritoryDetailLevelTree
} from "../state/dataset/datasetActions";
import Call from "../hocs/call";
import {getFilteredTreeWithPaths} from "../utils/tree";
import {getDetailLevelFromTree} from "../utils/catalog";

const mapStateToProps = ({app, dataset, detailLevel, catalog}) => ({
  isMultiViewerTheme: app.themeConfig.isMultiViewer,
  dsDatasetId: dataset.datasetId,
  dsNodeCode: dataset.nodeCode,
  dsCategoryPath: dataset.categoryPath,
  dsViewId: dataset.viewId,
  detailLevel: detailLevel.detailLevel,
  datasetDetailLevelTree: dataset.detailLevelTree,
  catalogDetailLevelTree: catalog?.detailLevelTree,
  isFetchStructureDisabled: dataset.isFetchStructureDisabled
});

const mapDispatchToProps = dispatch => ({
  onDetailLevelSet: detailLevel => dispatch(setDatasetTerritoryDetailLevel(detailLevel)),
  onDetailLevelTreeSet: detailLevelTree => dispatch(setDatasetTerritoryDetailLevelTree(detailLevelTree)),
  fetchDetailLevels: (nodeId, datasetId, detailLevel) => dispatch(fetchDatasetTerritoryDetailLevels(nodeId, datasetId, detailLevel)),
  onFetchStructureEnable: (nodeCode, categoryPath, datasetId, viewId) => dispatch(enableDatasetStructureFetch(nodeCode, categoryPath, datasetId, viewId)),
  fetchStructure: ({nodeId, datasetId, viewId, isMultiViewerTheme}) => dispatch(fetchDatasetStructure(nodeId, datasetId, viewId, isMultiViewerTheme)),
  onDatasetReset: () => dispatch(resetDatasetState())
});

class DatasetDomain extends Component {

  componentDidMount() {

    const {
      node,
      datasetId,
      datasetDetailLevels,
      catalogDetailLevelTree,
      detailLevel,
      onDetailLevelTreeSet,
      onDetailLevelSet,
      fetchDetailLevels,
      isMultiViewerTheme,
      nodeCode,
      categoryPath,
      viewId,
      onFetchStructureEnable
    } = this.props;

    if (!isMultiViewerTheme) {
      onFetchStructureEnable(nodeCode, categoryPath, datasetId, viewId);

    } else {
      if (catalogDetailLevelTree && datasetDetailLevels) {
        const datasetDetailLevelTree = getFilteredTreeWithPaths(catalogDetailLevelTree, "layers", ({id}) => (datasetDetailLevels || []).includes(id));
        onDetailLevelTreeSet(datasetDetailLevelTree);
        onDetailLevelSet(getDetailLevelFromTree(datasetDetailLevelTree, detailLevel));

      } else {
        fetchDetailLevels(node.nodeId, datasetId, detailLevel);
      }
    }
  };

  componentDidUpdate() {

    const {
      isMultiViewerTheme,
      dsDatasetId,
      dsNodeCode,
      dsCategoryPath,
      dsViewId,
      datasetDetailLevelTree,
      nodeCode,
      categoryPath,
      datasetId,
      viewId,
      onFetchStructureEnable
    } = this.props;

    if (!isMultiViewerTheme || datasetDetailLevelTree !== null) {

      const isStateAndUrlSynced = (
        nodeCode === dsNodeCode &&
        (categoryPath || []).join() === (dsCategoryPath || []).join() &&
        datasetId === dsDatasetId &&
        (viewId || "") === (dsViewId || "")
      );

      if (!isStateAndUrlSynced) {
        onFetchStructureEnable(nodeCode, categoryPath, datasetId, viewId);
      }
    }
  };

  componentWillUnmount() {

    const {
      onDatasetReset
    } = this.props;

    onDatasetReset();
  };

  render() {

    const {
      isMultiViewerTheme,
      isFetchStructureDisabled,
      hub,
      node,
      isDefault,
      catalog,
      categoryPath,
      isAccessible,
      datasetId,
      datasetTitle,
      attachedFiles,
      viewId,
      fetchStructure
    } = this.props;

    return (
      <Call
        cb={fetchStructure}
        cbParam={{
          nodeId: node.nodeId,
          datasetId: datasetId,
          viewId: viewId,
          isMultiViewerTheme: isMultiViewerTheme
        }}
        disabled={isFetchStructureDisabled}
      >
        <Data
          hub={hub}
          node={node}
          isDefault={isDefault}
          catalog={catalog}
          categoryPath={categoryPath}
          isAccessible={isAccessible}
          datasetId={datasetId}
          datasetTitle={datasetTitle}
          viewId={viewId}
          attachedFiles={attachedFiles}
        />
      </Call>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetDomain);