import {getGenericPermissionStr, UserChecker} from "../../utils/user";
import {UserRoles, UserState} from "../../state/user/userReducer";

/* urls */
export const getNodeLatestNewsUrl = (nodeId: number, count?: number) => `news/latest/${nodeId}` + (count ? `?n=${count}` : "");
export const getAllNewsUrl = () => `news/all`;
export const getDeleteNewsUrl = (id: number) => `news/${id}`;
export const getNewsUrl = (id: number) => `news/${id}`;
export const getSubmitNewsUrl = (nodes: string) => `news?nodeIds=${nodes}`;
export const getExportNewsUrl = (nodeId: number) => `news/export/${nodeId}`;

/* permissions */
export const canDisplayNewsManagerForm: UserChecker = (user: UserState, nodeId?: number) =>
  user && user.isAuthenticated && (
    user.roles.includes(UserRoles.Administrator) ||
    (nodeId !== undefined && user.permissions.includes(getGenericPermissionStr("ManageNews", nodeId))) ||
    (nodeId === undefined && !!user.permissions.find(p => p.startsWith("ManageNews_SingleNode")))
  );

/* news types */
export const NEWS_TYPE_DATA_UPDATES = "data-updates";
export const NEWS_TYPE_NEW_FUNCTIONALITY = "new-functionality";
export const NEWS_TYPE_NEW_NODE = "new-atlantes";
export const NEWS_TYPE_EVENT = "event";

/* utils */
export const getFormattedDate = (dateStr: string, lang?: string) => {
  const format: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric"
  };

  return new Date(dateStr).toLocaleDateString(`${lang === "it" ? "it-IT" : "en-GB"}`, format);
};
