import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import "./style.css";

import "ckeditor5-custom-build/build/translations/it";
import "ckeditor5-custom-build/build/translations/fr";
import "ckeditor5-custom-build/build/translations/es";
import "ckeditor5-custom-build/build/translations/pt";
import "ckeditor5-custom-build/build/translations/de";

const styles = theme => ({
  preToolbar: {
    marginBottom: theme.spacing(1)
  }
});

class HtmlEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = HtmlEditor.getInitState(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  static getInitState(props) {
    return {
      value: props ? (props.value || "") : ""
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.value !== null && props.value !== undefined) {
      return {
        value: props.value
      };

    } else {
      return HtmlEditor.getInitState(props);
    }
  }

  handleEditorChange(event, editor) {
    const data = editor.getData();
    if (this.props.onChange) {
      this.props.onChange(data);
    }
  }

  render() {

    const {
      value
    } = this.state;

    const {
      defaultLanguage,
      disabled,
      minimalToolbar
    } = this.props;

    const customConfig = {
      link: {
        defaultProtocol: "http://",
        decorators: [
          {
            mode: "manual",
            label: "Open in a new tab",
            defaultValue: true,
            attributes: {
              target: "_blank",
              rel: "noopener noreferrer"
            }
          }
        ]
      }
    };

    if (minimalToolbar) {
      customConfig.toolbar = {
        items: [
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "removeFormat",
          "|",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "link"
        ]
      };
    }

    return (
      <CKEditor
        data={value}
        editor={Editor}
        config={{
          language: defaultLanguage,
          ...customConfig
        }}
        disabled={disabled}
        onChange={this.handleEditorChange}
      />
    );
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({
    defaultLanguage: state.app.language
  }))
)(HtmlEditor);
