export const DETAIL_LEVEL_SET = "detailLevel/set";
export const DETAIL_LEVEL_FILTERED_CATALOG_SET = "detailLevel/setFilteredCatalog";

export const setDetailLevel = (detailLevel: number) => ({
    type: DETAIL_LEVEL_SET,
    payload: {
        detailLevel
    }
});

export const setDetailLevelFilteredCatalog = (filteredCatalog: any) => ({
    type: DETAIL_LEVEL_FILTERED_CATALOG_SET,
    payload: {
        filteredCatalog
    }
});