import React from 'react';
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {withTranslation} from "react-i18next";

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    fontStyle: "italic"
  },
  image: {
    paddingBottom: 16
  }
});

const CustomEmpty = ({
                       t,
                       classes,
                       style,
                       text,
                       textStyle,
                       image,
                       imageStyle
                     }) =>
  <div className={`${classes.root} custom-empty`} style={{...style}}>
    {image && (
      <div className={`${classes.image} custom-empty__image`} style={{...imageStyle}}>
        {image}
      </div>
    )}
    <div className={`${classes.text} custom-empty__text`} style={{...textStyle}}>
      {(text !== null && text !== undefined)
        ? text
        : t("components.customEmpty.placeholder")
      }
    </div>
  </div>;

export default compose(
  withStyles(styles),
  withTranslation()
)(CustomEmpty);
