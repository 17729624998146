import {initRequest} from '../../middlewares/request/requestActions';
import {getNodeUrl} from '../../serverApi/urls';

export const NODE_FETCH = "node/fetch";
export const NODE_CLEAR = "node/clear";

export const fetchNode = (nodeId: number, nodeCode: string) =>
  initRequest(
    NODE_FETCH,
    getNodeUrl(nodeId),
    undefined,
    undefined,
    t => ({
      onStart: t("domains.node.messages.fetch.start")
    }),
    {
      nodeId,
      nodeCode
    }
  );

export const clearNode = () => ({
  type: NODE_CLEAR
})

