import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from 'react';
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {compose} from "redux";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/Delete";
import {useTranslation} from "react-i18next";
import CustomMaterialTable from "../custom-material-table";
import {getI18nObjCustomFilterAndSearch, localizeI18nObj} from "../../utils/i18n";
import "./style.css";
import FullscreenDialog from "../fullscreen-dialog";

const styles = theme => ({
  tableToolbar: {
    marginBottom: theme.spacing(1)
  },
  buttonAction: {
    ...theme.typography.button
  }
});

const mapStateToProps = state => ({
  languages: state.app.languages,
  defaultLanguage: state.app.language
});

const DashboardsManager = ({
                             classes,
                             defaultLanguage,
                             languages,
                             dashboards,
                             allDashboards,
                             fetchDashboards,
                             fetchAllDashboards,
                             addDashboard,
                             removeDashboard,
                             sendDashboardsOrders,
                             clearDashboards,
                             clearAllDashboards
                           }, ref) => {

  const [needFetch, setNeedFetch] = useState(true);
  const {t} = useTranslation();

  useEffect(() => {
    if (needFetch) {
      setNeedFetch(false);
      fetchDashboards();
    }
  }, [dashboards, needFetch, setNeedFetch, fetchDashboards]);

  useImperativeHandle(ref, () => ({
    destroy(f) {
      clearDashboards();
      if (f) {
        f();
      }
    }
  }));

  const orderedDashboardsIds = (dashboards || []).map(({dashboardId}) => dashboardId);

  const moveUp = rowIndex =>
    sendDashboardsOrders([
      ...orderedDashboardsIds.slice(0, rowIndex - 1),
      orderedDashboardsIds[rowIndex],
      orderedDashboardsIds[rowIndex - 1],
      ...orderedDashboardsIds.slice(rowIndex + 1)
    ]);

  const moveDown = rowIndex =>
    sendDashboardsOrders([
      ...orderedDashboardsIds.slice(0, rowIndex),
      orderedDashboardsIds[rowIndex + 1],
      orderedDashboardsIds[rowIndex],
      ...orderedDashboardsIds.slice(rowIndex + 2)
    ]);

  return dashboards !== null && (
    <Fragment>
      <FullscreenDialog
        open={allDashboards !== null}
        onClose={() => clearAllDashboards()}
      >
        <CustomDialogTitle onClose={() => clearAllDashboards()}>
          {t("components.dashboardsManager.modals.addDashboard.title")}
        </CustomDialogTitle>
        <DialogContent>
          {allDashboards !== null && (
            <CustomMaterialTable
              columns={[
                {
                  field: "title",
                  title: t("components.dashboardsManager.modals.addDashboard.table.columns.dashboardTitle"),
                  render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
                  customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
                }
              ]}
              data={allDashboards}
              actions={[
                ({dashboardId}) => {
                  const assigned = !!dashboards.find(dashboard => dashboard.dashboardId === dashboardId)
                  return ({
                    icon: AddIcon,
                    tooltip:
                      assigned
                        ? t("components.dashboardsManager.modals.addDashboard.table.actions.addDashboard.tooltip.assigned")
                        : t("components.dashboardsManager.modals.addDashboard.table.actions.addDashboard.tooltip.notAssigned"),
                    onClick: (_, {dashboardId}) => addDashboard(dashboardId),
                    disabled: assigned
                  });
                }
              ]}
              options={{
                actionsColumnIndex: 2
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => clearAllDashboards()}>
            {t("commons.confirm.cancel")}
          </Button>
        </DialogActions>
      </FullscreenDialog>

      <Box className="dashboards-manager__table" style={{height: "100%"}}>
        <CustomMaterialTable
          title=""
          rightActions={
            <Button
              size="small"
              startIcon={<AddIcon/>}
              onClick={() => fetchAllDashboards()}
            >
              {t("components.dashboardsManager.addDashboard")}
            </Button>
          }
          columns={[
            {
              field: "title",
              title: t("components.dashboardsManager.table.columns.dashboardTitle"),
              render: ({title}) => localizeI18nObj(title, defaultLanguage, languages),
              customFilterAndSearch: getI18nObjCustomFilterAndSearch(defaultLanguage, languages)
            }
          ]}
          data={dashboards}
          actions={[
            ({tableData}) => ({
              icon: ArrowDropUpIcon,
              tooltip: t("components.dashboardsManager.table.actions.dashboardOrderMoveUp"),
              onClick: (_, {tableData}) => moveUp(tableData.id), // tableData.id is the rowIndex
              disabled: tableData.id === 0
            }),
            ({tableData}) => ({
              icon: ArrowDropDownIcon,
              tooltip: t("components.dashboardsManager.table.actions.dashboardOrderMoveDown"),
              onClick: (_, {tableData}) => moveDown(tableData.id), // tableData.id is the rowIndex
              disabled: tableData.id === dashboards.length - 1
            }),
            {
              icon: DeleteIcon,
              tooltip: t("components.dashboardsManager.table.actions.removeDashboard"),
              onClick: (_, {dashboardId}) => removeDashboard(dashboardId)
            }
          ]}
          options={{
            actionsColumnIndex: 2
          }}
        />
      </Box>
    </Fragment>
  );
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null, null, {forwardRef: true}),
  forwardRef
)(DashboardsManager);