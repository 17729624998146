import {Reducer} from "redux";
import {
    DETAIL_LEVEL_FILTERED_CATALOG_SET,
    DETAIL_LEVEL_SET
} from './detailLevelActions';

const detailLevelReducer: Reducer = (
    state = {
        detailLevel: null,
        filteredCatalog: null
    },
    action
) => {
    switch (action.type) {
        case DETAIL_LEVEL_SET:
            return {
                ...state,
                detailLevel: action.payload.detailLevel
            }
        case DETAIL_LEVEL_FILTERED_CATALOG_SET:
            return {
                ...state,
                filteredCatalog: action.payload.filteredCatalog
            };
        default:
            return state;
    }
};

export default detailLevelReducer;