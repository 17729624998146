import React, {Fragment, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import BarChartIcon from "@material-ui/icons/BarChart";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomDialogTitle from "../../custom-dialog-title";
import Button from "@material-ui/core/Button";
import {
  enableDatasetFetch,
  fetchDatasetAdditionalDatasetCatalog,
  hideDatasetCriteria,
  hideDatasetTerritory,
  setDatasetAdditionalDatasetCreateVisibility,
  setDatasetAdditionalDatasetCriteria,
  setDatasetAdditionalDatasetListVisibility,
  setDatasetIndicatorArithmeticMeanVisibility,
  setDatasetIndicatorCoefficientOfVariationVisibility,
  setDatasetIndicatorCreateVisibility,
  setDatasetIndicatorListVisibility,
  setDatasetIndicatorStandardDeviationVisibility,
  setDatasetStructureCriteria,
  setDatasetTerritoryDetailLevel,
  setDatasetTerritoryTerritories,
  setDatasetViewerChartVisibility,
  setDatasetViewerMapVisibility,
  setDatasetViewerTableVisibility,
  showDatasetCriteria,
  showDatasetTerritory,
  submitDatasetTerritoryTerritories,
  submitDatasetWithMarginalDownload,
  submitReferenceMetadataDownload
} from "../../../state/dataset/datasetActions";
import Criteria from "../../criteria";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Grid from "@material-ui/core/Grid";
import FullscreenDialog from "../../fullscreen-dialog";
import DetailLevelSelector from "../../territory-selectors/DetailLevelSelector";
import TerritoriesSelector from "../../territory-selectors/TerritorySelector";
import FilterAltIcon from "../../custom-icons/FIlterAltIcon";
import FlagIcon from "../../custom-icons/FlagIcon";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CalculateIcon from "../../custom-icons/CalculateIcon";
import ExportButton from "../../export-button";
import FileDownloadIcon from "../../custom-icons/FileDownloadIcon";
import {getMappedTree, getNode, getNodes} from "../../../utils/tree";
import IndicatorCreateDialog from "../../indicator-dialogs/create";
import IndicatorListDialog from "../../indicator-dialogs/list";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH, LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID} from "../../label-format-selector/constants";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../../temporal-dim-order-selector/constants";
import AdditionalDatasetCreateDialog from "../../additional-datasets-dialogs/create";
import AdditionalDatasetListDialog from "../../additional-datasets-dialogs/list";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {isFormatAvailableForMergedData} from "../../../utils/download";
import CheckIcon from "@material-ui/icons/Check";
import {ViewerMode} from "../../../state/dataset/constants";

const styles = theme => ({
  root: {
    "& .MuiBottomNavigation-root": {
      height: "unset !important",
    },
    "& .MuiBottomNavigation-root button": {
      color: theme.palette.primary.main
    },
    "& .MuiBottomNavigationAction-label": {
      fontSize: "13px !important"
    }
  },
  divider: {
    margin: "4px 0"
  },
  actionGroup: {
    marginBottom: 8,
    "& button:hover": {
      background: "rgba(0, 0, 0, 0.1)",
      color: `${theme.palette.primary.main} !important`,
      "& svg": {
        color: theme.palette.primary.main
      }
    }
  },
  actionGroupBackground: {
    "& button": {
      background: "rgba(0, 0, 0, 0.07)"
    }
  },
  actionDisabled: {
    color: "rgba(0, 0, 0, 0.54) !important",
    "& svg": {
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  criteriaContent: {
    height: "100%"
  },
  datasetSelector: {
    marginBottom: 8
  },
  territoryContent: {
    height: "100%"
  },
  steps: {},
  stepContent: {
    height: "calc(100% - 108px)",
    overflow: "hidden"
  },
  stepContentFullHeight: {
    height: "100%"
  },
  menuItemTitle: {
    width: "100%",
    color: "gray",
    fontStyle: "italic",
    fontSize: 14,
  }
});

const mapStateToProps = ({config, hub, app, appConfig, dataset}) => ({
  indicatorsBaseUrl: config.externalServices?.indicator,
  exportConfig: hub.hub.exportConfig,
  defaultLanguage: app.language,
  languages: app.languages,
  shapefilesUrl: appConfig.shapefilesUrl,
  maxMapPolygonCount: appConfig.mapConfig.maxPolygonCount,
  hiddenDimensionValueLabels: appConfig.hiddenDimensionValueLabels,
  dataset: dataset.dataset,
  enableCriteria: dataset.enableCriteria,
  enableVariation: dataset.enableVariation,
  showTrend: dataset.showTrend,
  showCyclical: dataset.showCyclical,
  isCriteriaVisible: dataset.isCriteriaVisible,
  isTerritoryVisible: dataset.isTerritoryVisible,
  dimensions: dataset.dimensions,
  territoryDim: dataset.territoryDim,
  timeDim: dataset.timeDim,
  freqDim: dataset.freqDim,
  codelists: dataset.codelists,
  codelistsLength: dataset.codelistsLength,
  tableLayout: dataset.tableLayout,
  mapLayout: dataset.mapLayout,
  chartLayout: dataset.chartLayout,
  chartSettings: dataset.chartSettings,
  mapSettings: dataset.mapSettings,
  isTableVisible: dataset.isTableVisible,
  isMapVisible: dataset.isMapVisible,
  isChartVisible: dataset.isChartVisible,
  criteria: dataset.criteria,
  labelFormat: dataset.labelFormat,
  decimalSeparator: dataset.decimalSeparator,
  decimalPlaces: dataset.decimalPlaces,
  tableEmptyChar: dataset.tableEmptyChar,
  detailLevelTree: dataset.detailLevelTree,
  detailLevel: dataset.detailLevel,
  territoryTree: dataset.territoryTree,
  territories: dataset.territories,
  isPointData: dataset.isPointData,
  isIndicatorCreateVisible: dataset.isIndicatorCreateVisible,
  isIndicatorListVisible: dataset.isIndicatorListVisible,
  indicators: dataset.indicators,
  showArithmeticMean: dataset.showArithmeticMean,
  showStandardDeviation: dataset.showStandardDeviation,
  showCoefficientOfVariation: dataset.showCoefficientOfVariation,
  isAdditionalDatasetCreateVisible: dataset.isAdditionalDatasetCreateVisible,
  isAdditionalDatasetListVisible: dataset.isAdditionalDatasetListVisible,
  additionalDatasetCatalog: dataset.additionalDatasetCatalog,
  additionalDatasets: dataset.additionalDatasets
});

const mapDispatchToProps = dispatch => ({
  onFetchDatasetEnable: maxObservation => dispatch(enableDatasetFetch(maxObservation)),
  onCriteriaShow: () => dispatch(showDatasetCriteria()),
  onCriteriaHide: () => dispatch(hideDatasetCriteria()),
  onSetCriteria: criteria => dispatch(setDatasetStructureCriteria(criteria)),
  onSetADCriteria: (datasetIdx, criteria) => dispatch(setDatasetAdditionalDatasetCriteria(datasetIdx, criteria)),
  onTerritoryShow: () => dispatch(showDatasetTerritory()),
  onTerritoryHide: (detailLevel, territories) => dispatch(hideDatasetTerritory(detailLevel, territories)),
  onTableVisibilitySet: isVisible => dispatch(setDatasetViewerTableVisibility(isVisible)),
  onMapVisibilitySet: isVisible => dispatch(setDatasetViewerMapVisibility(isVisible)),
  onChartVisibilitySet: isVisible => dispatch(setDatasetViewerChartVisibility(isVisible)),
  onDetailLevelSet: detailLevel => dispatch(setDatasetTerritoryDetailLevel(detailLevel)),
  onTerritoriesSet: territories => dispatch(setDatasetTerritoryTerritories(territories)),
  onTerritoriesSubmit: criteria => dispatch(submitDatasetTerritoryTerritories(criteria)),
  onDownloadSubmit: (nodeId, nodeCode, datasetId, datasetTitle, criteria, timeDim, territoryDim, layout, indicators, additionalDatasets, format, extension, zipped, params, defaultLanguage, languages, t, indicatorsBaseUrl) =>
    dispatch(submitDatasetWithMarginalDownload(nodeId, nodeCode, datasetId, datasetTitle, criteria, timeDim, territoryDim, layout, indicators, additionalDatasets, format, extension, zipped, params, defaultLanguage, languages, t, indicatorsBaseUrl)),
  onIndicatorCreateVisibilitySet: isVisible => dispatch(setDatasetIndicatorCreateVisibility(isVisible)),
  onIndicatorListVisibilitySet: isVisible => dispatch(setDatasetIndicatorListVisibility(isVisible)),
  onArithmeticMeanVisibilitySet: isVisible => dispatch(setDatasetIndicatorArithmeticMeanVisibility(isVisible)),
  onStandardDeviationVisibilitySet: isVisible => dispatch(setDatasetIndicatorStandardDeviationVisibility(isVisible)),
  onCoefficientOfVariationVisibilitySet: isVisible => dispatch(setDatasetIndicatorCoefficientOfVariationVisibility(isVisible)),
  onAdditionalDatasetCreateVisibilitySet: isVisible => dispatch(setDatasetAdditionalDatasetCreateVisibility(isVisible)),
  onAdditionalDatasetListVisibilitySet: isVisible => dispatch(setDatasetAdditionalDatasetListVisibility(isVisible)),
  fetchAdditionalDatasetCatalog: nodeId => dispatch(fetchDatasetAdditionalDatasetCatalog(nodeId)),
  onDownloadReferenceMetadataSubmit: (nodeId, datasetId, datasetTitle) => dispatch(submitReferenceMetadataDownload(nodeId, datasetId, datasetTitle)),
});

function MultiViewerSidebar(props) {

  const {
    classes,

    nodeId,
    nodeCode,
    datasetId,
    datasetTitle,
    maxObservation,
    chartId,
    mapId,
    nodeExtras,
    datasetMap,

    indicatorsBaseUrl,
    exportConfig,
    defaultLanguage,
    languages,
    shapefilesUrl,
    maxMapPolygonCount,
    hiddenDimensionValueLabels,
    dataset,
    enableCriteria,
    enableVariation,
    showTrend,
    showCyclical,
    isCriteriaVisible,
    isTerritoryVisible,
    dimensions,
    territoryDim,
    timeDim,
    freqDim,
    codelists,
    codelistsLength,
    tableLayout,
    mapLayout,
    chartLayout,
    chartSettings,
    mapSettings,
    isTableVisible,
    isMapVisible,
    isChartVisible,
    criteria,
    labelFormat,
    decimalSeparator,
    decimalPlaces,
    tableEmptyChar,
    detailLevelTree,
    detailLevel,
    territoryTree,
    territories,
    isPointData,
    isIndicatorCreateVisible,
    isIndicatorListVisible,
    indicators,
    showArithmeticMean,
    showStandardDeviation,
    showCoefficientOfVariation,
    isAdditionalDatasetCreateVisible,
    isAdditionalDatasetListVisible,
    additionalDatasetCatalog,
    additionalDatasets,

    onFetchDatasetEnable,
    onCriteriaShow,
    onCriteriaHide,
    onSetCriteria,
    onSetADCriteria,
    onTerritoryShow,
    onTerritoryHide,
    onTableVisibilitySet,
    onMapVisibilitySet,
    onChartVisibilitySet,
    onDetailLevelSet,
    onTerritoriesSet,
    onTerritoriesSubmit,
    onDownloadSubmit,
    onIndicatorCreateVisibilitySet,
    onIndicatorListVisibilitySet,
    onArithmeticMeanVisibilitySet,
    onStandardDeviationVisibilitySet,
    onCoefficientOfVariationVisibilitySet,
    onAdditionalDatasetCreateVisibilitySet,
    onAdditionalDatasetListVisibilitySet,
    fetchAdditionalDatasetCatalog,
    onDownloadReferenceMetadataSubmit
  } = props;

  const {t} = useTranslation();

  const [datasetIdx, setDatasetIdx] = useState(0);

  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const [tmpDetailLevel, setTmpDetailLevel] = useState(null);
  const [tmpTerritories, setTmpTerritories] = useState(null);

  const [step, setStep] = useState(isPointData ? 1 : 0);

  const [isTerritoryWarningVisible, setTerritoryWarningVisibility] = useState(false);

  const [indicatorsAnchorEl, setIndicatorsAnchorEl] = useState(null);
  const [additionalDatasetsAnchorEl, setAdditionalDatasetsAnchorEl] = useState(null);

  const [datasetToExportIdx, setDatasetToExportIdx] = useState(null);

  const downloadFormats = additionalDatasets.length === 0
    ? nodeExtras?.DownloadFormats
    : nodeExtras?.DownloadFormats.filter(format => isFormatAvailableForMergedData(format));

  const defaultLastNPeriods = nodeExtras?.DefaultLastNPeriods;

  const handleCriteriaOpen = () => {
    setDatasetIdx(0);
    onCriteriaShow();
  };

  const handleCriteriaClose = () => {
    onCriteriaHide();
    setCriteriaValidity(true);
  };

  const handleCriteriaSubmit = maxObservation => {
    onFetchDatasetEnable(maxObservation);
  };

  const handleTerritoryOpen = () => {
    setStep(isPointData ? 1 : 0);

    setTmpDetailLevel(detailLevel);
    setTmpTerritories(territories);

    onTerritoryShow();
  };

  const handleTerritoryClose = () => {
    onTerritoryHide(tmpDetailLevel, tmpTerritories);
  };

  const handleTerritorySubmit = () => {

    const markedTree = getMappedTree(
      [{children: territoryTree}],
      "children",
      node => ({
        ...node,
        children: (node.children || []).map(child => ({
          ...child,
          marked: (node.marked || territories.includes(child.id))
        }))
      })
    )[0].children;
    const territoryFilterValues = getNodes(markedTree, "children", ({marked, children}) => (marked && (children || []).length === 0));

    if (territoryFilterValues.length < maxMapPolygonCount) {
      let newCriteria = {
        ...criteria,
        [territoryDim]: {
          id: territoryDim,
          filterValues: territoryFilterValues.map(({id}) => id)
        }
      }
      onTerritoriesSubmit(newCriteria);

    } else {
      setTerritoryWarningVisibility(true);
    }
  };

  const visibleViewerCount = [isTableVisible, isChartVisible, isMapVisible].filter(el => el).length;
  const isTableDisabled = !dataset || !tableLayout;
  const isChartDisabled = !dataset || !chartLayout;
  const isMapDisabled = !dataset || !mapLayout;

  const datasets = [
    {
      nodeId: nodeId,
      nodeCode: nodeCode,
      datasetId: datasetId,
      datasetTitle: datasetTitle,
      dimensions: dimensions,
      timeDim: timeDim,
      freqDim: freqDim,
      territoryDim: territoryDim,
      codelists: codelists,
      codelistsLength: codelistsLength,
      criteria: criteria
    },
    ...additionalDatasets
  ];

  const datasetsWithMetadata = datasets.filter(({datasetId}) => datasetMap[datasetId]?.referenceMetadata);

  return (
    <div className={classes.root} role="menu">

      <div className={classes.actionGroup}>
        {territoryDim && (
          <BottomNavigation
            showLabels
            onChange={handleTerritoryOpen}
          >
            <BottomNavigationAction
              label={t("scenes.dataViewer.multiViewer.sidebar.territory")}
              icon={<FlagIcon/>}
              role="menuitem"
            />
          </BottomNavigation>
        )}
        <BottomNavigation
          showLabels
          onChange={handleCriteriaOpen}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.criteria")}
            icon={<FilterAltIcon/>}
            className={!enableCriteria ? classes.actionDisabled : ""}
            disabled={!enableCriteria}
            role="menuitem"
          />
        </BottomNavigation>
      </div>

      <div className={`${classes.actionGroup} ${classes.actionGroupBackground}`}>
        <BottomNavigation
          showLabels
          value={isTableVisible ? 0 : null}
          onChange={() => onTableVisibilitySet(!isTableVisible)}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.table")}
            icon={<TableChartOutlinedIcon/>}
            className={!isTableVisible ? classes.actionDisabled : ""}
            disabled={isTableDisabled || (isTableVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation
          showLabels
          value={isChartVisible ? 0 : null}
          onChange={() => onChartVisibilitySet(!isChartVisible)}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.chart")}
            icon={<BarChartIcon/>}
            className={!isChartVisible ? classes.actionDisabled : ""}
            disabled={isChartDisabled || (isChartVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
        <BottomNavigation
          showLabels
          value={isMapVisible ? 0 : null}
          onChange={() => onMapVisibilitySet(!isMapVisible)}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.map")}
            icon={<MapOutlinedIcon/>}
            className={!isMapVisible ? classes.actionDisabled : ""}
            disabled={isMapDisabled || (isMapVisible && visibleViewerCount <= 1)}
            role="menuitem"
          />
        </BottomNavigation>
      </div>

      <div className={classes.actionGroup}>
        {!isPointData && (
          <BottomNavigation
            showLabels
            onChange={({currentTarget}) => setAdditionalDatasetsAnchorEl(currentTarget)}
          >
            <BottomNavigationAction
              label={t("scenes.dataViewer.multiViewer.sidebar.addData")}
              icon={<AddCircleOutlineOutlinedIcon />}
              className={(!dataset && additionalDatasets.length === 0) ? classes.actionDisabled : ""}
              disabled={!dataset && additionalDatasets.length === 0}
              role="menuitem"
            />
          </BottomNavigation>
        )}
        <BottomNavigation
          showLabels
          onChange={({currentTarget}) => setIndicatorsAnchorEl(currentTarget)}
        >
          <BottomNavigationAction
            label={t("scenes.dataViewer.multiViewer.sidebar.calculateIndicator")}
            icon={<CalculateIcon />}
            className={(!dataset && indicators.length === 0) ? classes.actionDisabled : ""}
            disabled={!dataset && indicators.length === 0}
            role="menuitem"
          />
        </BottomNavigation>
      </div>

      <div className={classes.actionGroup}>
        <ExportButton
          showAsBottomNavigation
          icon={<FileDownloadIcon/>}
          formats={downloadFormats}
          jsonStat={dataset}
          viewerIdx={0}
          isTableVisible={isTableVisible}
          isMapVisible={isMapVisible}
          isChartVisible={isChartVisible}
          tableLayout={tableLayout}
          mapId={mapId}
          mapContainerId="data-viewer__multi-viewer__viewers__map__viewer"
          mapLayout={mapLayout}
          mapSettings={mapSettings}
          chartId={chartId}
          chartContainerId="data-viewer__multi-viewer__viewers__chart__viewer"
          chartLayout={chartLayout}
          chartSettings={chartSettings}
          labelFormat={labelFormat}
          datasetTitle={datasetTitle}
          submitDownload={(format, extension, additionalParams) => {
            const exportParams = {
              labelFormat: labelFormat,
              customLabelFormat: {
                [timeDim]:LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
                [territoryDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH
              },
              decimalNumber: decimalPlaces,
              decimalSeparator: decimalSeparator,
              emptyCellPlaceHolder: tableEmptyChar,
              hiddenDimensionValueLabels: hiddenDimensionValueLabels,
              hasVariation: enableVariation,
              showTrend: showTrend,
              showCyclical: showCyclical,
              temporalDimOrder: TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC,
              exportConfig: exportConfig,
              suffixToRemove: {
                [territoryDim]: getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.detailLevelSuffix || ""
              },
              customDimensionLabels: {
                [territoryDim]: (getNode(detailLevelTree, "layers", ({id}) => id === detailLevel)?.label || null)
              },
              ...additionalParams
            };

            onDownloadSubmit(
              nodeId,
              nodeCode,
              datasetId,
              datasetTitle,
              criteria,
              timeDim,
              territoryDim,
              tableLayout,
              indicators,
              additionalDatasets,
              format,
              extension,
              false,
              exportParams,
              defaultLanguage,
              languages,
              t,
              indicatorsBaseUrl
            );
          }}
          shapefilesUrl={shapefilesUrl}
          role="menuitem"
          submitMetadataDownload={additionalDatasets.length === 0
            ? datasetMap[datasetId]?.referenceMetadata
              ? () => onDownloadReferenceMetadataSubmit(nodeId, datasetId, datasetTitle)
              : null
            : datasetsWithMetadata.length > 0
              ? () => setDatasetToExportIdx(0)
              : null
          }
          className={!dataset ? classes.actionDisabled : ""}
          disabled={!dataset}
        />
      </div>

      <FullscreenDialog
        open={isCriteriaVisible}
        onClose={handleCriteriaClose}
      >
        <CustomDialogTitle onClose={handleCriteriaClose}>
          {t("scenes.dataViewer.multiViewer.dialogs.criteria.title")}
        </CustomDialogTitle>
        <DialogContent className={classes.criteriaContent}>
          {datasets.length > 1 && (
            <FormControl
              id="criteria__dataset-selector"
              className={classes.datasetSelector}
              fullWidth
            >
              <TextField
                select
                value={datasetIdx}
                onChange={ev => setDatasetIdx(ev.target.value)}
                variant="outlined"
                SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
              >
                {datasets.map(({datasetTitle}, idx) => (
                  <MenuItem key={idx} value={idx}>{datasetTitle}</MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
          <div
            style={{
              height: `calc(100% - ${(datasets.length > 1) ? 64 : 0}px)`
            }}
          >
            {datasets[datasetIdx] && (
              <Criteria
                viewerMode={ViewerMode.MultiViewer}
                key={`${datasets[datasetIdx].nodeCode}+${datasets[datasetIdx].datasetId}`}
                nodeId={datasets[datasetIdx].nodeId}
                nodeCode={datasets[datasetIdx].nodeCode}
                datasetId={datasets[datasetIdx].datasetId}
                dimensions={datasets[datasetIdx].dimensions}
                timeDim={datasets[datasetIdx].timeDim}
                freqDim={datasets[datasetIdx].freqDim}
                territoryDim={datasets[datasetIdx].territoryDim}
                hideTerritoryDim={!datasets[datasetIdx].isPointData}
                codelists={datasets[datasetIdx].codelists}
                codelistsLength={datasets[datasetIdx].codelistsLength}
                criteria={datasets[datasetIdx].criteria}
                territoryDimCriteria={(datasetIdx > 0 && criteria?.[territoryDim])
                  ? criteria[territoryDim]
                  : null
                }
                onSetCriteria={criteria => datasetIdx === 0
                  ? onSetCriteria(criteria)
                  : onSetADCriteria((datasetIdx - 1), criteria)
                }
                isCriteriaValid={isCriteriaValid}
                setCriteriaValidity={setCriteriaValidity}
                onSubmit={handleCriteriaSubmit}
                defaultLastNPeriods={defaultLastNPeriods}
                preserveFiltersWithDynamic
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCriteriaClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={() => handleCriteriaSubmit(maxObservation)}
            disabled={!isCriteriaValid}
          >
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </FullscreenDialog>

      <FullscreenDialog
        open={isTerritoryVisible}
        onClose={handleTerritoryClose}
        maxWidth={1280}
      >
        <CustomDialogTitle onClose={handleTerritoryClose}>
          {datasetTitle}
        </CustomDialogTitle>
        <DialogContent className={classes.territoryContent}>
          {!isPointData && (
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Stepper activeStep={step} nonLinear alternativeLabel className={classes.steps}>
                  <Step key={0}>
                    <StepButton onClick={() => setStep(0)}>
                      {t("components.territory.steps.detailLevel.title")}
                    </StepButton>
                  </Step>
                  <Step key={1}>
                    <StepButton onClick={() => setStep(1)} disabled={detailLevel === null}>
                      {t("components.territory.steps.territory.title")}
                    </StepButton>
                  </Step>
                </Stepper>
              </Grid>
            </Grid>
          )}
          <div key={step} className={`${classes.stepContent} ${isPointData && classes.stepContentFullHeight}`}>
            {step === 0 && (
              <DetailLevelSelector
                detailLevelTree={detailLevelTree}
                detailLevel={detailLevel}
                setDetailLevel={onDetailLevelSet}
                additionalDatasets={additionalDatasets}
              />
            )}
            {step === 1 && (
              <TerritoriesSelector
                nodeId={nodeId}
                datasetId={datasetId}
                territoryDim={territoryDim}
                detailLevelTree={detailLevelTree}
                detailLevel={detailLevel}
                territories={territories}
                setTerritories={onTerritoriesSet}
                isFetchEnabled={isTerritoryVisible}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {step === 0 && (
            <Fragment>
              <Button onClick={handleTerritoryClose}>
                {t("commons.confirm.cancel")}
              </Button>
              <Button
                autoFocus
                color="primary"
                onClick={() => setStep(1)}
                disabled={detailLevel === null}
              >
                {t("commons.confirm.next")}
              </Button>
            </Fragment>
          )}
          {step === 1 && (
            <Grid container justifyContent="space-between">
              <Grid item>
                {!isPointData && (
                  <Button
                    color="primary"
                    onClick={() => setStep(0)}
                  >
                    {t("commons.confirm.back")}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button onClick={handleTerritoryClose}>
                  {t("commons.confirm.cancel")}
                </Button>
                <Button
                  autoFocus
                  color="primary"
                  onClick={handleTerritorySubmit}
                  disabled={(territories || []).length === 0}
                >
                  {t("commons.confirm.apply")}
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </FullscreenDialog>

      <Dialog
        open={datasetToExportIdx !== null}
        maxWidth="sm"
        fullWidth
        onClose={() => setDatasetToExportIdx(null)}
      >
        <CustomDialogTitle onClose={() => setDatasetToExportIdx(null)}>
          {t("scenes.dataViewer.multiViewer.dialogs.exportMetadata.title")}
        </CustomDialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              select
              value={datasetToExportIdx !== null ? datasetToExportIdx : ""}
              onChange={ev => setDatasetToExportIdx(ev.target.value)}
              variant="outlined"
              SelectProps={{SelectDisplayProps: {"aria-haspopup": true}}}
            >
              {datasetsWithMetadata.map(({datasetTitle}, idx) => (
                <MenuItem key={idx} value={idx}>{datasetTitle}</MenuItem>
              ))}
            </TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDatasetToExportIdx(null)}>
            {t("commons.confirm.close")}
          </Button>
          <Button
            onClick={() => {
              const dataToExport = datasetsWithMetadata[datasetToExportIdx];
              onDownloadReferenceMetadataSubmit(dataToExport.nodeId, dataToExport.datasetId, dataToExport.datasetTitle);
            }}
          >
            {t("commons.confirm.download")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isTerritoryWarningVisible}
        maxWidth="sm"
        fullWidth
        onClose={() => setTerritoryWarningVisibility(false)}
      >
        <CustomDialogTitle onClose={() => setTerritoryWarningVisibility(false)}>
          {t("scenes.dataViewer.multiViewer.dialogs.toMuchPolygon.title")}
        </CustomDialogTitle>
        <DialogContent>
          {t("scenes.dataViewer.multiViewer.dialogs.toMuchPolygon.content")}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTerritoryWarningVisibility(false)}>
            {t("commons.confirm.close")}
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        anchorEl={indicatorsAnchorEl}
        keepMounted
        open={Boolean(indicatorsAnchorEl)}
        onClose={() => setIndicatorsAnchorEl(null)}
      >
        {!isPointData && (
          <MenuItem
            disabled
            className={classes.menuItemTitle}
          >
            {t("scenes.dataViewer.multiViewer.indicators.title")}
          </MenuItem>
        )}
        {!isPointData && (
          <MenuItem
            onClick={() => {
              onIndicatorCreateVisibilitySet(true);
              setIndicatorsAnchorEl(null);
            }}
            disabled={!dataset || (indicators || []).length >= 5}
          >
            {t("scenes.dataViewer.multiViewer.indicators.values.create")}
          </MenuItem>
        )}
        {!isPointData && (
          <MenuItem
            onClick={() => {
              onIndicatorListVisibilitySet(true);
              setIndicatorsAnchorEl(null);
            }}
            disabled={(indicators || []).length === 0}
          >
            {t("scenes.dataViewer.multiViewer.indicators.values.list")}
          </MenuItem>
        )}
        <MenuItem
          disabled
          className={classes.menuItemTitle}
        >
          {t("scenes.dataViewer.multiViewer.measuresOfSynthesisAndVariability.title")}
        </MenuItem>
        <MenuItem
          onClick={() => onArithmeticMeanVisibilitySet(!showArithmeticMean)}
          disabled={!dataset}
        >
          {t("commons.measuresOfSynthesisAndVariability.values.arithmeticMean")}
          {showArithmeticMean && <CheckIcon style={{marginLeft: 8}}/>}
        </MenuItem>
        <MenuItem
          onClick={() => onStandardDeviationVisibilitySet(!showStandardDeviation)}
          disabled={!dataset}
        >
          {t("commons.measuresOfSynthesisAndVariability.values.standardDeviation")}
          {showStandardDeviation && <CheckIcon style={{marginLeft: 8}}/>}
        </MenuItem>
        <MenuItem
          onClick={() => onCoefficientOfVariationVisibilitySet(!showCoefficientOfVariation)}
          disabled={!dataset}
        >
          {t("commons.measuresOfSynthesisAndVariability.values.coefficientOfVariation")}
          {showCoefficientOfVariation && <CheckIcon style={{marginLeft: 8}}/>}
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={additionalDatasetsAnchorEl}
        keepMounted
        open={Boolean(additionalDatasetsAnchorEl)}
        onClose={() => setAdditionalDatasetsAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onAdditionalDatasetCreateVisibilitySet(true);
            setAdditionalDatasetsAnchorEl(null);
          }}
          disabled={!dataset || additionalDatasets.length >= 2}
        >
          {t("scenes.dataViewer.multiViewer.additionalDataset.create.title")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onAdditionalDatasetListVisibilitySet(true);
            setAdditionalDatasetsAnchorEl(null);
          }}
          disabled={additionalDatasets.length === 0}
        >
          {t("scenes.dataViewer.multiViewer.additionalDataset.list.title")}
        </MenuItem>
      </Menu>

      {isIndicatorCreateVisible && (
        <IndicatorCreateDialog
          isOpen={isIndicatorCreateVisible}
          onClose={() => onIndicatorCreateVisibilitySet(false)}
        />
      )}

      {isIndicatorListVisible && (
        <IndicatorListDialog
          isOpen={isIndicatorListVisible}
          onClose={() => onIndicatorListVisibilitySet(false)}
        />
      )}

      {isAdditionalDatasetCreateVisible && (
        <AdditionalDatasetCreateDialog
          isOpen={isAdditionalDatasetCreateVisible}
          onClose={() => onAdditionalDatasetCreateVisibilitySet(false)}
          defaultLastNPeriods={defaultLastNPeriods}
          catalog={additionalDatasetCatalog}
          fetchCatalog={fetchAdditionalDatasetCatalog}
        />
      )}

      {isAdditionalDatasetListVisible && (
        <AdditionalDatasetListDialog
          isOpen={isAdditionalDatasetListVisible}
          onClose={() => onAdditionalDatasetListVisibilitySet(false)}
        />
      )}

    </div>
  );
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(MultiViewerSidebar);