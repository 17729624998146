import React, {lazy, Suspense, useEffect, useState} from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import ErrorBoundary from "./ErrorBoundary";
import CustomEmpty from "../custom-empty";

const DefaultFallback = ({t}) => (
  <CustomEmpty
    text={t("components.componentVariantLoader.suspense.text") + "..."}
  />
);

const ComponentVariantLoader = ({
                                  t,
                                  themeConfig,
                                  componentId,
                                  fallback = <DefaultFallback t={t}/>,
                                  ...props
                                }) => {

  const [variant] = useState(themeConfig.componentsVariant?.[componentId] || "default");
  const [ComponentVariant, setComponentVariant] = useState(null);

  useEffect(() => {
    const ComponentVariant = lazy(() => import(`../../components-lazy/${componentId}/${variant}`));
    setComponentVariant(ComponentVariant);
  }, [variant, componentId]);

  return ComponentVariant && (
    <ErrorBoundary
      variant={variant}
      component={componentId}
    >
      <Suspense fallback={fallback}>
        <ComponentVariant {...props}/>
      </Suspense>
    </ErrorBoundary>
  )
};

export default compose(
  withTranslation(),
  connect(state => ({
    themeConfig: state.app.themeConfig
  }))
)(ComponentVariantLoader);