import {REQUEST_ERROR, REQUEST_INIT, REQUEST_SUCCESS} from "../request/requestActions";
import {DATASET_FETCH} from "../../state/dataset/datasetActions";
import {errorFetchDatasetAsyncHandler, initFetchDatasetAsyncHandler, successFetchDatasetAsyncHandler} from "./actions";
import {addSpinnerMessage, markSpinnerMessage} from "../../state/spinner/spinnerActions";
import {v4 as uuidv4} from "uuid";
import {
  getFilteredChartLayout,
  getFilteredMapLayout,
  getFilteredTableLayout,
  getInitialChartLayout,
  getInitialMapLayout,
  getInitialTableLayout,
  getJsonStatTableSize,
  getMultiViewerInitialChartLayout,
  getMultiViewerInitialTableLayout,
  getMultiViewerTableColCount,
  getPointDataInitialChartLayout,
  getPointDataInitialMapLayout,
  getPointDataInitialTableLayout
} from "../../utils/jsonStat";
import {ViewerMode} from "../../state/dataset/constants";
import {getNode} from "../../utils/tree";

const fetchDatasetAsyncHandlerMiddlewareFactory = t => ({dispatch, getState}) => next => action => {

  const result = next(action);

  if (action?.payload?.label === DATASET_FETCH) {

    if (action.type === REQUEST_INIT) {
      dispatch(initFetchDatasetAsyncHandler());

    } else if (action.type === REQUEST_SUCCESS) {

      const state = getState();

      const spinnerUuid = uuidv4();
      dispatch(addSpinnerMessage(spinnerUuid, t("middlewares.fetchDatasetAsyncHandlerMiddlewareFactory.spinners.layoutHandling")));

      const {response, extra} = action.payload;
      const parsedResponse = {
        ...response,
        extension: {
          ...(response?.extension || {}),
          datasets: response?.extension?.datasets || [extra.datasetId]
        }
      };

      const cellCount = getJsonStatTableSize(parsedResponse);

      let isTooBigData = cellCount > state.dataset.maxAllowedCells; // TODO: consider empty cells in this count
      const isEmptyData = (extra.status === 204 || (parsedResponse?.id || "").length === 0);
      const isPartialData = extra.status === 206;
      let tableColCount = null;
      let mapPointCount = null;

      let isResponseNotValid = (!parsedResponse || isTooBigData || isEmptyData);

      let tableLayout = null;
      let mapLayout = null;
      let chartLayout = null;

      let territoryDim = state.dataset.territoryDim;
      let timeDim = state.dataset.timeDim;
      let measureDim = state.dataset.measureDim;

      let isPointData = state.dataset.isPointData;
      let pointDim = state.dataset.pointDim;

      if (isPointData) {
        const datasetId = parsedResponse?.extension?.datasets?.[0] || null;

        const latitudeId = state.dataset.latAttributeId;
        const longitudeId = state.dataset.longAttributeId;
        const latAttribute = (parsedResponse?.extension?.attributes?.[datasetId]?.series || []).find(({id}) => id === latitudeId) || null;
        const longAttribute = (parsedResponse?.extension?.attributes?.[datasetId]?.series || []).find(({id}) => id === longitudeId) || null;
        const latAttachedDims = latAttribute?.relationship?.dimensions || [];
        const longAttachedDims = longAttribute?.relationship?.dimensions || [];
        if (latAttachedDims.length === 1 && longAttachedDims.length === 1 && latAttachedDims[0] === longAttachedDims[0]) {
          pointDim = latAttachedDims[0];
        } else {
          isPointData = false;
          pointDim = null;
        }

      } else {
        pointDim = null;
      }

      const territoryValueCount = (!isResponseNotValid && territoryDim && parsedResponse.id.includes(territoryDim))
        ? parsedResponse.size[parsedResponse.id.indexOf(territoryDim)]
        : null;

      if (!isResponseNotValid) {
        if (state.dataset.viewerMode === ViewerMode.MultiViewer) {
          if (state.dataset.tableLayout) {
            tableLayout = isPointData
              ? state.dataset.tableLayout // TODO
              : getFilteredTableLayout(state.dataset.tableLayout, parsedResponse, true);
          } else {
            tableLayout = isPointData
              ? getPointDataInitialTableLayout(parsedResponse, pointDim, territoryDim, timeDim, measureDim)
              : getMultiViewerInitialTableLayout(parsedResponse, territoryDim, timeDim);
          }

          const colCount = getMultiViewerTableColCount(parsedResponse, tableLayout);
          if (colCount > state.appConfig.tableConfig.maxColCount) {
            tableColCount = colCount;
            isResponseNotValid = true;
            tableLayout = null;
          }

        } else {
          if (state.dataset.tableLayout) {
            tableLayout = getFilteredTableLayout(state.dataset.tableLayout, parsedResponse, false);
          } else {
            tableLayout = getInitialTableLayout(parsedResponse, territoryDim, timeDim);
          }
        }
      }

      if (!isResponseNotValid && ((territoryDim && parsedResponse.id.includes(territoryDim)) || (pointDim && parsedResponse.id.includes(pointDim)))) {
        if (
          isPointData ||
          (state.dataset.viewerMode === ViewerMode.MultiViewer && (state.dataset.detailLevelTree || []).length > 0) ||
          (state.dataset.viewerMode === ViewerMode.SingleViewer && parsedResponse.dimension[territoryDim].category.index.length > 1)
        ) {

          if (isPointData && territoryValueCount > state.appConfig.mapConfig.maxPointCount) {
            mapPointCount = territoryValueCount;
            isResponseNotValid = true;
            mapLayout = null;

          } else {
            if (state.dataset.mapLayout) {
              mapLayout = isPointData
                ? state.dataset.mapLayout // TODO
                : getFilteredMapLayout(state.dataset.mapLayout, parsedResponse);
            } else {
              mapLayout = isPointData
                ? getPointDataInitialMapLayout(parsedResponse, pointDim, timeDim, measureDim)
                : getInitialMapLayout(parsedResponse, territoryDim);
            }
          }
        }
      }

      if (!isResponseNotValid) {
        if (state.dataset.chartLayout) {
          chartLayout = isPointData
            ? state.dataset.chartLayout // TODO
            : getFilteredChartLayout(state.dataset.chartLayout, parsedResponse);
        } else if (state.dataset.viewerMode === ViewerMode.MultiViewer) {
          chartLayout = isPointData
            ? getPointDataInitialChartLayout(parsedResponse, pointDim, timeDim, measureDim, state.appConfig.chartConfig.maxTerritoryValueCount)
            : getMultiViewerInitialChartLayout(parsedResponse, territoryDim, timeDim, state.appConfig.chartConfig.maxTerritoryValueCount);
        } else {
          chartLayout = getInitialChartLayout(parsedResponse, territoryDim);
        }
      }

      if (!isResponseNotValid && !isPointData) {
        const territoryDimLabel = getNode(state.dataset.detailLevelTree, "layers", ({id}) => id === state.dataset.detailLevel)?.label || null;
        if (territoryDimLabel) {
          parsedResponse.dimension[territoryDim].label = territoryDimLabel;
        }
      }

      const myWorker = new Worker("./workers/fetchDatasetAsyncHandlerWorker.js");
      myWorker.onmessage = event => {
        const {
          tableLayout,
          tableFilterTree,
          mapLayout,
          mapFilterTree,
          chartLayout,
          chartFilterTree,
          timePeriodsByFreq
        } = event.data;
        dispatch(successFetchDatasetAsyncHandler(
          parsedResponse,
          extra.status,
          extra.responseHeaders,
          isPointData,
          pointDim,
          cellCount,
          isTooBigData,
          isEmptyData,
          isPartialData,
          tableColCount,
          mapPointCount,
          isResponseNotValid,
          tableLayout,
          tableFilterTree,
          mapLayout,
          mapFilterTree,
          chartLayout,
          chartFilterTree,
          timePeriodsByFreq,
          myWorker
        ));
        dispatch(markSpinnerMessage(spinnerUuid));
      };
      myWorker.onerror = () => {
        dispatch(errorFetchDatasetAsyncHandler());
        dispatch(markSpinnerMessage(spinnerUuid, true));
        window.error.show(t("middlewares.fetchDatasetAsyncHandlerMiddlewareFactory.feedback.layoutHandlingError"));
      };
      myWorker.postMessage({
        response: parsedResponse,
        tableLayout: tableLayout,
        mapLayout: mapLayout,
        chartLayout: chartLayout,
        criteria: state.dataset.criteria,
        isFirstFetch: state.dataset.isFirstFetch,
        isMultiViewer: state.dataset.viewerMode === ViewerMode.MultiViewer
      });

    } else if (action.type === REQUEST_ERROR) {
      dispatch(errorFetchDatasetAsyncHandler(action.payload.statusCode));
    }
  }

  return result;
};

export default fetchDatasetAsyncHandlerMiddlewareFactory;
