export const INIT = "INIT";

export const init = (baseURL: string, supportedLanguages: string[], defaultLanguage: string, detailLevel: number | null, configs: any) => ({
  type: INIT,
  payload: {
    baseURL,
    supportedLanguages,
    defaultLanguage,
    detailLevel,
    appConfig: configs?.appConfig || null,
    footers: configs?.footers || null,
    dashboardFilterConfig: configs?.dashboardFilterConfig || null,
    mapLayersConfig: configs?.mapLayersConfig || null,
    modulesConfig: configs?.modulesConfig || null,
    externalServices: configs?.externalServices || null,
    themeConfig: configs.themeConfig
  }
});
