import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import {
  DASHBOARD_ELEM_ENABLE_FILTERS_KEY,
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_SHOW_TITLE_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_TEXT,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  DASHBOARD_ELEM_VALUE_KEY,
  DASHBOARD_ELEM_WIDTH_KEY,
  getViewIdxFromRowAndCol
} from "../../utils/dashboards";
import {getViewerIdxFromType} from "../data-viewer/constant";
import {localizeI18nObj} from "../../utils/i18n";
import SanitizedHTML from "../sanitized-html";
import CustomEmpty from "../custom-empty";
import {DASHBOARD_VIEW_STATE_APPLY_FILTERS, DASHBOARD_VIEW_STATE_ERROR, DASHBOARD_VIEW_STATE_FETCHING} from "../../state/dashboard/dashboardReducer";
import {getDatasetAttributeMap, getSeriesAttributeMap, VARIATION_DIMENSION_KEY} from "../../utils/jsonStat";
import DatasetFilters from "../dataset-filters";
import Table from "../table";
import Map from "../map";
import Chart from "../chart";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import {v4 as uuidv4} from "uuid";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomDialogTitle from "../custom-dialog-title";
import Button from "@material-ui/core/Button";
import AttributeList from "../attribute-list";
import AttributeIcon from "../custom-icons/AttributeIcon";
import ExportButton from "../export-button";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID} from "../label-format-selector/constants";
import {toggleFullScreen} from "../../utils/other";
import {TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC} from "../temporal-dim-order-selector/constants";
import DatasetStaticFilters from "../dataset-static-filters";
import {DOWNLOAD_FORMAT_CSV, DOWNLOAD_FORMAT_EXCEL} from "../../utils/download";

const $ = window.jQuery;

const VIEW_FIXED_HEIGHT = 580;

const styles = theme => ({
  col: {
    display: "inline-block",
    verticalAlign: "top",
    padding: 8
  },
  viewContainer: {
    position: "relative",
    padding: 16,
    background: "#FFFFFF !important"
  },
  viewContainerHeader: {},
  viewContainerHeaderTitle: {
    minHeight: 48,
    paddingBottom: 8,
    display: "flex",
    alignItems: "center",
    fontSize: 18,
    "& > button": {
      marginLeft: 4
    }
  },
  viewContainerHeaderTitleAttributeIcon: {
    "& text > tspan": {
      fontWeight: "normal !important"
    }
  },
  viewContainerHeaderControllers: {
    position: "absolute",
    top: 16,
    right: 24,
    display: "flex",
    "& > *": {
      marginLeft: 4
    }
  },
  viewContainerHeaderStaticFilters: {
    paddingBottom: 8,
  },
  viewContainerHeaderActiveFilters: {
    paddingBottom: 8,
  },
  viewContainerHeaderPlaceholder: {
    paddingBottom: 8
  },
  viewContainerView: {
    width: "100%",
    position: "relative"
  },
  attributeId: {
    cursor: "default",
    fontSize: 13,
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(108, 108, 108)",
    borderRadius: 3,
    padding: "0 4px",
    marginLeft: 4
  },
  attributeAst: {
    cursor: "default",
    fontSize: 15,
    color: theme.palette.primary.main,
    fontFamily: "Do Hyeon",
    marginLeft: 4
  }
});

const handleStyle = (dashboardId, viewIdx, dashboardElem, minDashboardWidth) => {
  const $viewContainer = $(`#dashboard__${dashboardId}__view-container__${viewIdx}`);

  if ($viewContainer.is(":visible")) {

    const $col = $(`#dashboard__${dashboardId}__col__${viewIdx}`);
    const $header = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header`);
    const $title = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__title`);
    const $controllers = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__controllers`);
    const $placeHolder = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__placeholder`);
    const $staticFilters = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__static-filters`);
    const $activeFilters = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__active-filters`);
    const $view = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__view`);

    $col.css({
      width: `${window.innerWidth > minDashboardWidth ? dashboardElem[DASHBOARD_ELEM_WIDTH_KEY] : 100}%`
    });

    if ($title.length) {
      $title.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight()
      });
      $staticFilters.css({
        width: "100%"
      });
      $activeFilters.css({
        width: "100%"
      });

    } else if ($staticFilters.length) {
      $staticFilters.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight(),
        paddingTop: 12
      });
      $activeFilters.css({
        width: "100%"
      });

    } else if ($activeFilters.length) {
      $activeFilters.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight(),
        paddingTop: 12
      });

    } else {
      $placeHolder.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight()
      });
    }

    $view.css({
      height: `calc(100% - ${$header.outerHeight() || 0}px)`
    });
  }
};

function DashboardCol(props) {

  const {
    t,
    classes,
    theme,
    hub,
    defaultLanguage,
    languages,
    minDashboardWidth,
    dashboardId,
    dashboard,
    filterValue,
    rowIdx,
    colIdx,
    dashboardElem,
    jsonStat,
    layout: layoutObj,
    filterTree,
    timePeriodsByFreq,
    rowWithView,
    onSelect,
    onDownloadSubmit
  } = props;

  const [chartId] = useState("chart__" + uuidv4());
  const [mapId] = useState("map__" + uuidv4());

  const [nodeExtras, setNodeExtras] = useState({});

  const [view] = useState(_.cloneDeep((dashboard.views || {})[dashboardElem[DASHBOARD_ELEM_VALUE_KEY]]));
  const [viewIdx] = useState(getViewIdxFromRowAndCol(rowIdx, colIdx));
  const [viewerIdx] = useState(view ? getViewerIdxFromType(view.defaultView) : null);

  const [isDetailLevelFilterEnabled] = useState(Object.values(dashboard?.filterLevels || {}).filter(val => val).length > 0);

  const [isFullscreen, setFullscreen] = useState(false);
  const [isAttributesVisible, setAttributesVisibility] = useState(false);

  const [hierarchyOnlyAttributes] = useState(nodeExtras?.HierarchyOnlyAttributes || []);
  const [hideHierarchyOnlyRows] = useState(nodeExtras?.HideHierarchyOnlyRows || false);

  const datasetAttributes = useMemo(() => {
    return (jsonStat && layoutObj && view?.datasetId)
      ? getDatasetAttributeMap(jsonStat)?.[view.datasetId] || []
      : [];
  }, [jsonStat, layoutObj, view?.datasetId]);

  const seriesAttributes = useMemo(() => {
    return (jsonStat && layoutObj && view?.datasetId)
      ? getSeriesAttributeMap(jsonStat, layoutObj.labelFormat)[view.datasetId] || []
      : [];
  }, [jsonStat, layoutObj, view?.datasetId]);

  useEffect(() => {
    const nodeMinimalInfo = view ? hub.nodes.find(({nodeId}) => nodeId === view.nodeId) : {};
    if (nodeMinimalInfo?.extras) {
      const nodeExtras = {};
      nodeMinimalInfo.extras.forEach(({key, value}) => {
        try {
          nodeExtras[key] = JSON.parse(value);
        } catch (e) {
          nodeExtras[key] = value;
        }
      });
      setNodeExtras(nodeExtras);
    }
  }, [hub, view]);

  useEffect(() => {
    const func = () => handleStyle(dashboardId, viewIdx, dashboardElem, minDashboardWidth);
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func)
  }, [dashboardId, viewIdx, dashboardElem, minDashboardWidth]);

  useEffect(() => {
    handleStyle(dashboardId, viewIdx, dashboardElem, minDashboardWidth);
  });

  const handleFullscreen = useCallback(() => {
    toggleFullScreen(
      `dashboard__${dashboardId}__view-container__${viewIdx}`,
      `dashboard__${dashboardId}__col__${viewIdx}`,
      [],
      !isFullscreen
    );
    handleStyle(dashboardId, viewIdx, dashboardElem, minDashboardWidth);
    setFullscreen(!isFullscreen);
  }, [dashboardId, dashboardElem, viewIdx, isFullscreen, minDashboardWidth]);

  const handleDownload = useCallback((format, extension, additionalParams) => {
    const timeDim = jsonStat.role?.time?.[0];
    const newCriteria = {...view.criteria};

    if (format !== DOWNLOAD_FORMAT_CSV && format !== DOWNLOAD_FORMAT_EXCEL) {
      Object.keys(layoutObj.layout.filtersValue).forEach(key => {
        if (key !== timeDim) {
          newCriteria[key] = {
            id: key,
            filterValues: [layoutObj.layout.filtersValue[key]]
          };
        }
      });
      const primaryDim = layoutObj.layout?.primaryDim?.[0] || null;
      if (primaryDim && primaryDim !== timeDim) {
        newCriteria[primaryDim] = {
          id: primaryDim,
          filterValues: layoutObj.layout.primaryDimValues
        };
      }
      const secondaryDim = layoutObj.layout?.secondaryDim?.[0] || null;
      if (secondaryDim && secondaryDim !== timeDim) {
        newCriteria[secondaryDim] = {
          id: secondaryDim,
          filterValues: layoutObj.layout.secondaryDimValues
        };
      }
    }

    if (viewerIdx === 1 && layoutObj.layout?.territoryDim) {
      if ((format !== DOWNLOAD_FORMAT_CSV && format !== DOWNLOAD_FORMAT_EXCEL) || additionalParams?.exportOnlyCurrentView === true) {
        const territoryDim = layoutObj.layout.territoryDim;
        newCriteria[territoryDim] = {
          id: territoryDim,
          filterValues: window.LMap.getDataIds(mapId)
        };
      }
    }

    const exportParams = {
      decimalNumber: view.decimalNumber,
      decimalSeparator: localizeI18nObj(view.decimalSeparator, defaultLanguage, languages),
      emptyCellPlaceHolder: layoutObj.tableEmptyChar,
      labelFormat: layoutObj.labelFormat,
      customLabelFormat: {
        [timeDim]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
      },
      hasVariation: jsonStat.id.includes(VARIATION_DIMENSION_KEY),
      showTrend: layoutObj?.showTrend,
      showCyclical: layoutObj?.showCyclical,
      temporalDimOrder: layoutObj?.temporalDimOrder,
      exportConfig: hub.hub.exportConfig,
      ...additionalParams
    };

    onDownloadSubmit(
      view.nodeId,
      view.datasetId,
      localizeI18nObj(view.title, defaultLanguage, languages),
      newCriteria,
      layoutObj.layout,
      format,
      extension,
      false,
      exportParams,
      defaultLanguage,
      languages,
      t
    );
  }, [hub, view, jsonStat, layoutObj, mapId, viewerIdx, onDownloadSubmit, defaultLanguage, languages, t]);

  const showAttributeIcon = datasetAttributes.concat(seriesAttributes).length > 0;

  return (
    <div
      id={`dashboard__${dashboardId}__col__${viewIdx}`}
      className={`${classes.col} dashboard__col`}
      style={{
        width: `${window.innerWidth > minDashboardWidth ? dashboardElem[DASHBOARD_ELEM_WIDTH_KEY] : 100}%`
      }}
    >
      <Card
        id={`dashboard__${dashboardId}__view-container__${viewIdx}`}
        className={`${classes.viewContainer} dashboard__view-container`}
        style={{
          width: "100%",
          height: rowWithView ? VIEW_FIXED_HEIGHT : "100%",
          overflowY: dashboardElem[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW
            ? "hidden"
            : "auto",
          overflowX: "hidden"
        }}
      >
        {(() => {
          if (dashboardElem[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_TEXT) {
            return (
              <SanitizedHTML
                html={localizeI18nObj(dashboardElem[DASHBOARD_ELEM_VALUE_KEY], defaultLanguage, languages)}
                allowTarget
              />
            )

          } else if (view === null || view === undefined) {
            return (
              <CustomEmpty
                text={t("components.dashboard.missingView")}
              />
            )

          } else if (jsonStat !== null && jsonStat !== undefined) {

            if (jsonStat === DASHBOARD_VIEW_STATE_APPLY_FILTERS) {
              return (
                <CustomEmpty
                  text={t("components.dashboard.applyFilters")}
                />
              )

            } else if (jsonStat === DASHBOARD_VIEW_STATE_FETCHING) {
              return (
                <CustomEmpty
                  text={t("components.dashboard.fetching") + "..."}
                  image={<CircularProgress/>}
                />
              )

            } else if (jsonStat === DASHBOARD_VIEW_STATE_ERROR) {
              return (
                <CustomEmpty
                  text={t("components.dashboard.fetchingDatasetError")}
                />
              )

            } else if (jsonStat === "") {
              return (
                <CustomEmpty
                  text={t("components.dashboard.emptyView")}
                />
              )

            } else if (layoutObj) {
              try {

                let layout = {...layoutObj.layout};

                const filterDim = dashboardElem[DASHBOARD_ELEM_FILTER_DIMENSION_KEY];
                if (filterDim && filterValue && (jsonStat.dimension[filterDim]?.category?.index || []).includes(filterValue)) {
                  if ((layout.filters || []).includes(filterDim)) {
                    layout.filtersValue = {
                      ...layout.filtersValue,
                      [filterDim]: filterValue
                    };
                  } else if ((layout.primaryDim || []).includes(filterDim)) {
                    layout.primaryDimValues = [filterValue];
                  } else if ((layout.secondaryDim || []).includes(filterDim)) {
                    layout.secondaryDimValues = [filterValue];
                  }
                }

                let staticFilters = [];
                layout.filters.forEach(dim => {
                  if (!dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] || jsonStat.size[jsonStat.id.indexOf(dim)] === 1) {
                    staticFilters.push(dim);
                  }
                });

                const TitleComponent = (
                  <div
                    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__title`}
                    className={`${classes.viewContainerHeaderTitle} dashboard__view-container__header__title`}
                  >
                    {localizeI18nObj(view.title, defaultLanguage, languages)}
                    {showAttributeIcon && (
                      <Tooltip title={t("components.dashboard.actions.attributes")}>
                        <IconButton
                          aria-label={t("components.dashboard.actions.attributes")}
                          color="primary"
                          onClick={() => setAttributesVisibility(true)}
                          className={classes.viewContainerHeaderTitleAttributeIcon}
                        >
                          <AttributeIcon/>
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                );

                const ControllersComponent = (
                  <div
                    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__controllers`}
                    className={`${classes.viewContainerHeaderControllers} dashboard__view-container__header__controllers`}
                  >
                    {showAttributeIcon && !dashboardElem[DASHBOARD_ELEM_SHOW_TITLE_KEY] && (
                      <Tooltip title={t("components.dashboard.actions.attributes")}>
                        <IconButton
                          color="primary"
                          onClick={() => setAttributesVisibility(true)}
                          iconStyle={{fontWeight: "normal"}}
                        >
                          <AttributeIcon/>
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip
                      title={isFullscreen
                        ? t("components.dashboard.actions.fullscreen.exit")
                        : t("components.dashboard.actions.fullscreen.enter")
                      }
                    >
                      <IconButton
                        aria-label={isFullscreen
                          ? t("components.dashboard.actions.fullscreen.exit")
                          : t("components.dashboard.actions.fullscreen.enter")
                        }
                        color="primary"
                        onClick={handleFullscreen}
                      >
                        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                      </IconButton>
                    </Tooltip>
                    <ExportButton
                      formats={nodeExtras?.DownloadFormats || []}
                      jsonStat={jsonStat}
                      isTableVisible={viewerIdx === 0}
                      isMapVisible={viewerIdx === 1}
                      isChartVisible={viewerIdx >= 2}
                      tableLayout={layoutObj?.layout}
                      mapId={mapId}
                      mapContainerId={`dashboard__${dashboardId}__view-container__${viewIdx}__view`}
                      mapLayout={layoutObj?.layout}
                      mapSettings={layoutObj?.mapSettings}
                      chartId={chartId}
                      chartContainerId={`dashboard__${dashboardId}__view-container__${viewIdx}__view`}
                      chartLayout={layoutObj?.layout}
                      chartSettings={layoutObj?.chartSettings}
                      labelFormat={layoutObj?.labelFormat}
                      datasetTitle={localizeI18nObj(view?.title, defaultLanguage, languages)}
                      submitDownload={handleDownload}
                      buttonStyle={{color: theme.palette.primary.main}}
                    />
                  </div>
                );

                const StaticFiltersComponent = (
                  <div
                    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__static-filters`}
                    className={`${classes.viewContainerHeaderStaticFilters} dashboard__view-container__header__static-filters`}
                  >
                    <DatasetStaticFilters
                      jsonStat={jsonStat}
                      layout={layout}
                      labelFormat={layoutObj.labelFormat}
                      isDimensionAllowed={dim => staticFilters.includes(dim)}
                    />
                  </div>
                );

                const ActiveFiltersComponent = (
                  <div
                    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__active-filters`}
                    className={`${classes.viewContainerHeaderActiveFilters} dashboard__view-container__header__active-filters`}
                  >
                    <DatasetFilters
                      jsonStat={jsonStat}
                      layout={layout}
                      filterTree={filterTree}
                      onSelect={(dimension, value) => onSelect(dashboardId, viewIdx, dimension, value)}
                    />
                  </div>
                );

                const PlaceHolderComponent = (
                  <div
                    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__placeholder`}
                    className={`${classes.viewContainerHeaderPlaceholder} dashboard__view-container__header__pleaceholder`}
                  />
                );

                const decimalSeparator = localizeI18nObj(view.decimalSeparator, defaultLanguage, languages);
                const decimalPlaces = view.decimalNumber;

                const timeDim = jsonStat.role?.time?.[0] || null;
                const invertedDims = (layoutObj.temporalDimOrder === TEMPORAL_DIM_ORDER_SELECTOR_VALUE_DESC && !layout.filters.includes(timeDim))
                  ? [timeDim]
                  : null;

                return (
                  <Fragment>
                    <div
                      id={`dashboard__${dashboardId}__view-container__${viewIdx}__header`}
                      className={`${classes.viewContainerHeader} dashboard__view-container__header`}
                      style={{
                        paddingBottom: dashboardElem[DASHBOARD_ELEM_SHOW_TITLE_KEY] ? 4 : 12
                      }}
                    >
                      {dashboardElem[DASHBOARD_ELEM_SHOW_TITLE_KEY]
                        ? (
                          <Fragment>
                            {TitleComponent}
                            {ControllersComponent}
                            {staticFilters.length > 0 && StaticFiltersComponent}
                            {dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] && filterTree && ActiveFiltersComponent}
                          </Fragment>
                        )
                        : staticFilters.length > 0 ?
                          (
                            <Fragment>
                              {StaticFiltersComponent}
                              {ControllersComponent}
                              {dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] && filterTree && ActiveFiltersComponent}
                            </Fragment>
                          )
                          : dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] && filterTree
                            ? (
                              <Fragment>
                                {ActiveFiltersComponent}
                                {ControllersComponent}
                              </Fragment>
                            )
                            : (
                              <Fragment>
                                {PlaceHolderComponent}
                                {ControllersComponent}
                              </Fragment>
                            )
                      }
                    </div>
                    <div
                      id={`dashboard__${dashboardId}__view-container__${viewIdx}__view`}
                      className={`${classes.viewContainerView} dashboard__view-container__view`}
                    >
                      {viewerIdx === 0
                        ? (
                          <Table
                            jsonStat={jsonStat}
                            layout={layout}
                            labelFormat={layoutObj.labelFormat}
                            showTrend={layoutObj.showTrend}
                            showCyclical={layoutObj.showCyclical}
                            decimalSeparator={decimalSeparator}
                            decimalPlaces={decimalPlaces}
                            emptyChar={layoutObj.tableEmptyChar}
                            isFullscreen={isFullscreen}
                            disableWheelZoom
                            invertedDims={invertedDims}
                            hierarchyOnlyAttributes={hierarchyOnlyAttributes}
                            hideHierarchyOnlyRows={hideHierarchyOnlyRows}
                            filterable
                            sortable
                          />
                        )
                        : viewerIdx === 1
                          ? (
                            <Map
                              mapId={mapId}
                              nodeId={view.nodeId}
                              jsonStat={jsonStat}
                              layout={layout}
                              labelFormat={layoutObj.labelFormat}
                              decimalSeparator={decimalSeparator}
                              decimalPlaces={decimalPlaces}
                              defaultDetailLevel={layoutObj.detailLevel}
                              disableDetailLevelSelector={!dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY]}
                              initialBaseLayer={layoutObj.mapSettings?.baseLayer}
                              defaultSettings={layoutObj.mapSettings}
                              isFullscreen={isFullscreen}
                              disableSettings
                              disableBaseLayer
                              showSingleGeometry={(filterDim || "").length > 0 && isDetailLevelFilterEnabled}
                              disableWheelZoom
                            />
                          )
                          : (
                            <Chart
                              chartId={chartId}
                              type={view.defaultView}
                              jsonStat={jsonStat}
                              layout={layout}
                              timePeriodsByFreq={timePeriodsByFreq}
                              labelFormat={layoutObj.labelFormat}
                              showTrend={layoutObj.showTrend}
                              showCyclical={layoutObj.showCyclical}
                              decimalSeparator={decimalSeparator}
                              decimalPlaces={decimalPlaces}
                              chartSettings={layoutObj.chartSettings}
                              disableWheelZoom
                              invertedDims={invertedDims}
                            />
                          )
                      }
                    </div>
                    <Dialog
                      open={isAttributesVisible}
                      fullWidth
                      maxWidth="md"
                      onClose={() => setAttributesVisibility(false)}
                    >
                      <CustomDialogTitle onClose={() => setAttributesVisibility(false)}>
                        {t("components.dashboard.dialogs.attributes.title")}
                      </CustomDialogTitle>
                      <DialogContent>
                        <AttributeList
                          datasetAttributes={datasetAttributes}
                          seriesAttributes={seriesAttributes}
                          labelFormat={layoutObj.labelFormat}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setAttributesVisibility(false)}>
                          {t("commons.confirm.close")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                );

              } catch (e) {
                return (
                  <CustomEmpty
                    text={t("components.dashboard.genericError")}
                  />
                );
              }

            } else {
              return <span/>
            }
          } else {
            return <span/>
          }
        })()}
      </Card>
    </div>
  )
}

export default compose(
  withTranslation(),
  withStyles(styles),
  withTheme,
  connect(state => ({
    hub: state.hub,
    defaultLanguage: state.app.language,
    languages: state.app.languages,
    minDashboardWidth: state.appConfig.minDashboardWidth
  }))
)(DashboardCol);