import React, {Fragment} from 'react';
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import I18nTextField from "../../i18n-text-field";
import Alert from "@material-ui/lab/Alert";
import {CRITERIA_FILTER_TYPE_PERIODS} from "../../../utils/criteria";

function MultiViewerTemplateBuilder(props) {
  const {
    template,
    onChange,
    criteria,
    timeDIm
  } = props;

  const {t} = useTranslation();

  if (!template) {
    return <span/>;
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        {criteria?.[timeDIm]?.type === CRITERIA_FILTER_TYPE_PERIODS && (
          <Grid item xs={12}>
            <Alert severity="warning">{t("scenes.dataViewer.warnings.viewTemplateLastNPeriods.label")}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <I18nTextField
              label={t("scenes.dataViewer.templateBuilder.tabs.options.cards.general.form.title")}
              required
              variant="outlined"
              value={template.title}
              onChange={value => onChange({...template, title: value})}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default MultiViewerTemplateBuilder;